<template>
    <div>
        <portal to="banner">
            <sc-page-header title="tenants"></sc-page-header>
        </portal>

        <div class="buttons width-container pd pdb0">
            <sc-button color="secondary" @click="$router.push({path:'new',append:true})" icon="plus">
                Add new
            </sc-button>
        </div>

        <div class="tenants width-container">
            <sc-table :table="table">
                <template #column-logo="{row}">
                    <sc-table-cell field="logo" :table="table">
                        <div v-if="hasLogo(row)" class="logo-wrap">
                            <img :src="row.ui_logo"/>
                        </div>
                    </sc-table-cell>
                </template>

                <template #column-id="{row}">
                    <sc-table-cell field="id" :table="table">
                        {{row.id}}
                    </sc-table-cell>
                </template>

                <template #column-name="{row}">
                    <sc-table-cell field="name" :table="table">
                        {{row.name}}
                    </sc-table-cell>
                </template>

                <template #column-actions="{row}">
                    <sc-table-cell :table="table" field="actions" style="display:flex;" :clickable="false" class="actions-cell">
                        <template #actions>
                            <sc-table-button icon="external-link-alt" @click.native="openTenant(row)"
                                             tooltip="Visit"/>

                            <sc-table-button icon="file-import" @click.native="$router.push(`/tenants/${row.id}/import-cm`)"
                                             tooltip="import cm" style="margin-left: .5em;"/>

                            <sc-table-button icon="edit" @click.native="$router.push(`/tenants/${row.id}`)"
                                             tooltip="edit" style="margin-left: .5em;"/>
                        </template>
                    </sc-table-cell>

                </template>
            </sc-table>

        </div>
    </div>
</template>

<script>

import axios from 'axios'

import ScTableButton from '@/components/common/table/sc-table-button'
// import ScTableCell from '@/components/common/table/sc-table-cell'

export default {
  name: 'all',
  components: {ScTableButton},
  data() {
    return {
      tenants: [],
      table: this.$stable.createTable({
        url: '',
        columns: {
          logo: {fill: false, sort: false},
          id: {},
          name: {fill: true, width: 100},
          actions: {sort: false},
        }
      })
    }
  },

  mounted() {
    this.fetch();
  },
  methods: {

    fetch() {
      axios.get(process.env.VUE_APP_URL + '/api/admin/tenant')
        .then((res) => {
          this.tenants = res.data;

          this.table.data = res.data;
          this.table.hasData = true;
          this.table.prefetch();
        })
    },

    hasLogo(row) {
      return row.ui_logo;
    },
    openTenant(row) {
      let path = window.location;

      if (row.domains && row.domains.length > 0)
        path = "https://"+row.domains[0].domain;

      window.open(path, "_blank");
    }
  }
}
</script>

<style scoped lang="scss">

.logo-wrap {
    max-width: 100%;
    img {
        max-width: inherit;
    }
}
.actions-cell {
    height: 84px;
}

</style>