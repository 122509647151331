var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"banner"}},[_c('sc-page-header',{attrs:{"title":"tenants"}})],1),_c('div',{staticClass:"buttons width-container pd pdb0"},[_c('sc-button',{attrs:{"color":"secondary","icon":"plus"},on:{"click":function($event){return _vm.$router.push({path:'new',append:true})}}},[_vm._v(" Add new ")])],1),_c('div',{staticClass:"tenants width-container"},[_c('sc-table',{attrs:{"table":_vm.table},scopedSlots:_vm._u([{key:"column-logo",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"field":"logo","table":_vm.table}},[(_vm.hasLogo(row))?_c('div',{staticClass:"logo-wrap"},[_c('img',{attrs:{"src":row.ui_logo}})]):_vm._e()])]}},{key:"column-id",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"field":"id","table":_vm.table}},[_vm._v(" "+_vm._s(row.id)+" ")])]}},{key:"column-name",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"field":"name","table":_vm.table}},[_vm._v(" "+_vm._s(row.name)+" ")])]}},{key:"column-actions",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{staticClass:"actions-cell",staticStyle:{"display":"flex"},attrs:{"table":_vm.table,"field":"actions","clickable":false},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('sc-table-button',{attrs:{"icon":"external-link-alt","tooltip":"Visit"},nativeOn:{"click":function($event){return _vm.openTenant(row)}}}),_c('sc-table-button',{staticStyle:{"margin-left":".5em"},attrs:{"icon":"file-import","tooltip":"import cm"},nativeOn:{"click":function($event){return _vm.$router.push(("/tenants/" + (row.id) + "/import-cm"))}}}),_c('sc-table-button',{staticStyle:{"margin-left":".5em"},attrs:{"icon":"edit","tooltip":"edit"},nativeOn:{"click":function($event){return _vm.$router.push(("/tenants/" + (row.id)))}}})]},proxy:true}],null,true)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }