<template>
    <div>
        <breadcrumbs :items="['Create location']"></breadcrumbs>
        <sc-form @submit="form.post()" :form="form">
            <template #field-parent_id>
                <sc-form-field :form="form" field="parent_id">
                    <location-list :location_id.sync="form.fields.parent_id"></location-list>
                </sc-form-field>
            </template>
        </sc-form>
    </div>
</template>

<script>

import LocationList from "@/components/locations/location-list";

export default {
  name: "new-usergroup",
  components: {LocationList},
  props: ['locations'],
  data() {
    return {
      form: this.$sform.createForm({
        fields: {
          name: '',
          description: '',
          parent_id: {default: this.locations[0].id}
        },
        success: () => {
          this.$notify.success('Location created')
          this.$emit('done')
        },
        url: `user-groups`
      }),
      roles: [],
    }
  }
}
</script>

<style scoped>

</style>