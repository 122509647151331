<template>
    <div>
        <div class="clear-search" @click="$emit('clear')">
            <i class="fas fa-times"></i>clear search
        </div>
    </div>
</template>

<script>
  export default {
    name: "sc-search-clear"
  }
</script>

<style scoped>
    .clear-search {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        font-size: 0.8em;
        cursor: pointer;
        background: #74b44f;
        color: #eee;
        box-sizing: border-box;
        border-radius: 3px;
    }

    .clear-search i {
        margin-right: 5px;
    }

    .clear-search:hover {
        color: #fff;
    }
</style>