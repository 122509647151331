import _index from './index.vue'
import _all from './all.vue'
import _allMine from './all/mine.vue'
import _detailsMine from './all/mineDetails.vue'
import _allOpen from './all/open.vue'
import _detailsOpen from './all/openDetails.vue'

const routes = {
  path: 'approvals',
  meta: {name: 'approval'},
  component: _index,
  children: [
    {
      path: '',
      meta: {name: 'all'},
      component: _all,
      children: [
        {
          path: 'mine',
          meta: {name: 'mine'},
          component: _allMine
        },
        {
          path: 'mine/:approval_id/details',
          meta: {name: 'mine'},
          component: _detailsMine,
        },
        {
          path: 'open',
          meta: {name: 'open'},
          component: _allOpen
        },
        {
          path: 'open/:approval_id/details',
          meta: {name: 'open'},
          component: _detailsOpen,
        },
      ]
    }
  ]
};

export default routes;