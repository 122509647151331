import Vue from 'vue'
import Vuex from 'vuex'
import time from './time.js'
import message from './message.js'
import user from './user.js'

Vue.use(Vuex);

let store = new Vuex.Store({
  // strict: process.env.NODE_ENV !== 'production',
  modules: {
    time,
    message,
    user
  },
  state: {

    filterSortingType: 'playerCount',

    sorting: {
      media: {
        default: {
          value: 'created_at',
          column: {
            target: 'created_at',
            type: 'text'
          },
          dir: 'desc',
        },
        current: {}
      },
      playlists: {
        default: {
          value: 'name',
          column: {
            target: 'name',
            type: 'text'
          },
          dir: 'asc',
        },
        current: {}
      },
      users: {
        default: {
          value: 'name',
          dir: 'asc',
          column: {
            target: 'name',
            type: 'text'
          }
        },
        current: {}
      },
      userGroup: {
        default: {
          value: 'name',
          column: {
            target: 'name',
            type: 'text'
          },
          dir: 'asc',
        },
        current: {}
      },
      role: {
        default: {
          value: 'name',
          column: {
            target: 'name',
            type: 'text'
          },
          dir: 'asc',
        },
        current: {}
      },
      approval: {
        default: {
          value: 'created',
          column: {
            target: 'created_at',
            type: 'text'
          },
          dir: 'asc',
        },
        current: {}
      },
      location: {
        default: {
          value: 'name',
          column: {
            target: 'name',
            type: 'text'
          },
          dir: 'asc',
        },
        current: {}
      },
      players: {
        default: {
          value: 'name',
          column: {
            target: 'name',
            type: 'text'
          },
          dir: 'asc',
        },
        current: {}
      },
      badwords: {
        default: {
          value: 'word',
          column: {
            target: 'word',
            type: 'text'
          },
          dir: 'asc',
        },
        current: {}
      },
    }

  },
  actions: {
    getUser({state, commit, getters}, talker) {
      return talker.api.get('/user/initial')
        .then(res => {
          commit('setUser', {user: res.data});
        })
    },

  },

  mutations: {

    setSorting(state, {type, sorting}) {
      state.sorting[type].current = sorting;
    },

    setUser(state, {user}) {
      state.user = user;
    },

    setStatus(state, {status}) {
      state.status = status
    },

    setServer(state, {server}) {
      state.server = server;
    },

    setState(state, {prop, value}) {
      state[prop] = value;
    },

  },
  getters: {

    UserHasPermission: (state) => (permission) => {
      if (!state.user.permissions) {
        return false;
      }
      if (state.user.permissions.includes(permission)) {
        return true;
      } else {
        return false;
      }
    },

    // UserIsAdmin: (state) => {
    //   if (!state.user.user_group) {
    //     return false;
    //   }
    //   if (state.user.user_group.hierarchy <= 10) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    //
    // UserIsAtLeast: (state) => (hierarchy) => {
    //   if (!state.user.user_group) {
    //     return false;
    //   }
    //   if (state.user.user_group.hierarchy <= hierarchy) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },

});

export default store;
