<template>
    <div>
        <div class="filters pd pdb0">
            <div class="filter">
                <div class="label">Status</div>
                <div class="filter-value">
                    <select v-model="status">
                        <option value="used">Used</option>
                        <option value="unused">Not used</option>
                        <option value="pending_approval">Pending approval</option>
                        <option value="expires">Expires soon</option>
                    </select>
                </div>
            </div>

        </div>
        <media-used v-if="status === 'used'"/>
        <media-unused v-if="status === 'unused'"/>
        <media-pending v-if="status === 'pending_approval'"/>
        <media-expires v-if="status === 'expires'"/>

    </div>
</template>

<script>
import MediaUsed from "./components/media-used";
import MediaUnused from "./components/media-unused";
import MediaPending from "./components/media-pending";
import MediaExpires from "./components/media-expires";

export default {
  name: "media-usage",
  components: {MediaExpires, MediaPending, MediaUnused, MediaUsed},
  data() {
    return {
      status: 'used',

    }
  },
}
</script>

<style scoped>
.filters {
    display: flex;
}

.label {
    margin-bottom: 0.5em;
    font-size: 0.8em;
}

select {
    outline: none;
    min-width: 15em;
    padding: 0 1em;
    height: 3.2em;
    font-size: .9em;
    box-sizing: border-box;
    border: 1px solid #aaa;
    color: var(--color-secondary);
    position: relative;
}
</style>
