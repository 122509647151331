var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sc-table',{attrs:{"table":_vm.table},on:{"view":_vm.view},scopedSlots:_vm._u([{key:"column-media",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"media"}},[_c('img',{staticClass:"thumbnail",attrs:{"src":row.thumbnail_storage_path}})])]}},{key:"column-name",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"name"}},[_c('div',{staticClass:"detail text-blue"},[_vm._v(" "+_vm._s(row.name)+" ")]),_c('div',{staticClass:"detail"},[_vm._v(" "+_vm._s(row.approved)+" ")])])]}},{key:"column-media_type",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"media_type"}},[_c('media-types',{attrs:{"type":row.media_type}})],1)]}},{key:"column-details",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"details"}},[(row.description)?_c('div',{staticClass:"detail"},[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Description: ")]),_vm._v(" "+_vm._s(row.description)+" ")]):_vm._e(),(row.width && row.height)?_c('div',{staticClass:"detail"},[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Dimensions: ")]),_vm._v(" "+_vm._s(row.width)+"x"+_vm._s(row.height)+" ")]):_vm._e(),(row.duration)?_c('div',{staticClass:"detail"},[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Duration: ")]),_vm._v(" "+_vm._s(row.duration)+" ")]):_vm._e(),(row.orientation)?_c('div',{staticClass:"detail"},[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Orientation: ")]),_vm._v(" "+_vm._s(row.orientation)+" ")]):_vm._e(),(row.creator)?_c('div',{staticClass:"detail"},[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Creator: ")]),_vm._v(" "+_vm._s(row.creator.email)+" ")]):_vm._e(),(row.owner)?_c('div',{staticClass:"detail"},[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Owner: ")]),_vm._v(" "+_vm._s(row.owner.name)+" ")]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }