<template>
    <div class="paginator-container" v-if="data && data.last_page > 1">
        <div class=" paginator" v-if="data" :class="{firstPage, lastPage}">
            <div class="item-count">{{data.total}} items</div>
            <div class="buttons">
                <div class="button toFirstpage" @click="$emit('changePage', 'first')"><i
                        class="fas fa-angle-double-left"></i>
                </div>
                <div class="button toPrevious" @click="$emit('changePage', 'previous')"><i
                        class="fas fa-angle-left"></i></div>
                <div class="pages">
                    {{data.current_page}} / {{data.last_page}}
                </div>
                <div class="button toNext" @click="$emit('changePage', 'next')"><i class="fas fa-angle-right"></i></div>
                <div class="button toLastpage" @click="$emit('changePage', data.last_page)"><i
                        class="fas fa-angle-double-right"></i>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
  export default {
    name: "sc-paginate",
    props: ['data'],

    computed: {
      lastPage() {
        return (this.data.current_page === this.data.last_page)
      },
      firstPage() {
        return (this.data.current_page === 1)
      }
    }
  }
</script>

<style scoped>
    .paginator-container {
        /*padding: 8px 0 0;*/
    }

    .inline-paginator .widget {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .paginator {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 700;
        font-size: 0.8em;
        color: #bbb;
        padding-left: 15px;
        background:#fff;
    }

    .inline-paginator .paginator-container {
        padding: 0;
    }

    .inline-paginator .paginator {
        padding-bottom: 16px;
    }

    .buttons {
        align-items: center;
        color: #888;
    }

    .buttons .pages {
        padding: 0 10px;
        font-weight: 700;
        color: #888;
        min-width: 55px;
        text-align: center;
    }

    .buttons .button {
        padding: 15px 15px;
        cursor: pointer;
        transition: all .15s ease-in-out;
    }

    .buttons .button:hover {
        background: #f0f0f0;
        color: #000;
    }

    .firstPage .toPrevious,
    .firstPage .toFirstpage,
    .lastPage .toNext,
    .lastPage .toLastpage {
        pointer-events: none;
        cursor: inherit;
        color: #ddd;
    }
</style>