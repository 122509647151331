<template>
    <div class="header">
        <div class="header-content pdw">
            <div @click="$router.push('/')" class="section logo">
                <img style="height:20px;" v-if="$store.state.user.theme === 'default'"
                     :src="require('@/img/logo-black.png')"/>
                <img style="height:20px;" v-if="$store.state.user.theme === 'dark'"
                     :src="require('@/img/logo-white.png')"/>
                <div class="main-name">{{ app_name }}</div>
            </div>
            <div class="section user">
                <div class="user-name">{{ $store.getters['user/name'] }}</div>
                <div class="options" @click="showMenu = !showMenu" :class="{active:showMenu}">
                    <awesome-icon icon="chevron-down"></awesome-icon>
                </div>
                <div class="menu" v-if="showMenu">
<!--                    <div class="menu-button" @click="profile()">My Profile</div>-->
<!--                    <div class="separator"></div>-->
                    <div class="menu-button logout" @click="logout()">sign out</div>
                </div>
                <div class="menu-backdrop" v-if="showMenu" @click.stop="showMenu = !showMenu"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: "sc-header",

  data() {
    return {
      showMenu: false,
    }
  },

  computed: {
    hasTitle() {
      return !!this.$slots['title']
    },

    app_name() {
      return process.env.VUE_APP_APP_NAME;
    }
  },

  methods: {
    goTo(url) {
      this.$router.push(url);
      this.showMenu = false;
    },

    profile() {
      this.$router.push(`/users/users/${this.$store.state.user.user.id}/details`);
      this.showMenu = false;
    },

    logout() {
      // Todo: fix this once it works
      // this.$talker.logout({router: this.$router})
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped lang="scss">
.header {
    @include themed() {
        background: t('color-header');
        color: t('text-color');
    }
    /*box-shadow: 2px 2px 10px 1px rgba(100, 100, 100, 0.25);*/
}

.header-content {
    display: flex;
    justify-content: space-between;
    height: 4.5em;
    width: 100%;
}

.section {
    display: flex;
    align-items: center;
    height: 100%;
}

.section.logo {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
}

.main-name {
    margin-top: 3px;
    font-weight: 800;
    font-size: 1.01em;
    text-transform: uppercase;
}

.user-name {
    font-weight: 700;
    margin-right: 1.5em;
}

.user {
    position: relative;
}

.user .options {
    height: 100%;
    width: 4em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background .15s ease-in-out;
}

.user .options:hover {
    background: rgba(0, 0, 0, 0.1);
}

.user .options.active {
    background: rgba(0, 0, 0, 0.1);
}

.user .options.active:hover {
    background: rgba(255, 255, 255, 0.2);
}

.user .menu {
    position: absolute;
    right: 0;
    width: 8em;
    top: 100%;
    border-radius: 0 0 10px 10px;
    @include themed() {
        background: t('color-header');
        color: t('text-color');
    }
    padding: 1em 0;
    border: 1px solid rgba(0, 0, 0, 0.3);
    z-index: 20;
    color: #333;
    box-sizing: border-box;
}

.menu-button {
    font-size: 0.8em;
    font-weight: 800;
    text-transform: uppercase;
    cursor: pointer;
    padding: 1em 2em;
    margin-bottom: 0.1em;
    text-align: center;
}

.menu-button:hover {
    background: rgba(0, 0, 0, 0.1);
}

.menu-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 15;
}

.menu-button.logout {

}

.menu .separator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0.5em 1em;
}

.title {
    height: 100%;
    display: flex;
    align-items: flex-end;
    font-weight: 700;
    padding-top: 0;
    font-size: 2.5em;
    line-height: 2em;
    text-transform: uppercase;
}

.theme--dark {
    .user .options.active,
    .user .options:hover {
        background: rgba(255, 255, 255, 0.1);
    }

    .menu-button:hover {
        background: rgba(255, 255, 255, 0.1);
    }
}
</style>