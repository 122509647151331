<template v-if="firstItem">
    <div v-if="expired" class="about-to-expire">
        Expired <br/>
        <span class="end-date">
            {{ firstItem.end_valid_date | toDate }}
        </span>
    </div>
    <div v-else-if="aboutToExpire" class="about-to-expire">
        Expires soon <br/>
        <span class="end-date">
            {{ firstItem.end_valid_date | toDate }}
        </span>
    </div>
</template>

<script>
export default {
  name: "expire-checker",
  props: {
    item: {type: Object},
    items: {}
  },
  data() {
    return {
      expireRange: moment().add('5', 'days')
    }
  },

  beforeMount() {
    if (!this.item && !this.items) {
      throw new Error("Missing props");
    }
  },

  computed: {
    firstItem() {
      if (this.item) {
        return this.item;
      } else {

        const nullCount = this.items.filter(i => i.end_valid_date === null).length
        if (nullCount !== this.items.length) {
          // Get applicable, any items with a end_valid_date
          const applicable = this.items.filter(i => i.end_valid_date !== null);
          // Sort applicable by end_valid_date (get the one that's closest.
          if (applicable.length > 1) {
            applicable.sort((a, b) => {
              let dateA = moment(a.end_valid_date);
              let dateB = moment(b.end_valid_date);

              if (dateA.isBefore(dateB)) {
                return -1
              } else if (dateA.isAfter(dateB)) {
                return 1
              } else {
                return 0;
              }
            })
          }
          return applicable[0];
        }
      }
    },

    aboutToExpire() {
      if (this.firstItem && this.firstItem.end_valid_date) {
        return moment(this.firstItem.end_valid_date).isSameOrBefore(this.expireRange)
      }
      return false;
    },
    expired() {
      if (this.firstItem && this.firstItem.end_valid_date) {
        return moment().endOf('day').isAfter(moment(this.firstItem.end_valid_date).endOf('day'));
      }
    }
  }
}
</script>

<style scoped>
.about-to-expire {
    color: #c10000;
    font-size: .9em;
    margin-top: .5em;
}

.end-date {
    font-size: .75em;
}
</style>