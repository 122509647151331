<template>
    <div v-if="ready">
        <portal to="banner">
            <sc-page-header :title="`${name} - Import CM`"/>
        </portal>

        <div class="buttons pd width-container pdb0">
            <sc-button class="back" @click="$router.push('/tenants')" icon="chevron-left">Back</sc-button>
        </div>

        <div class="width-container pdw">
            <form class="sc-form widget pd" @submit="submit">
                <sc-field name="Frameset Templates">
                    <sc-form-field-boolean :field.sync="form.fields.frameset_templates"/>
                </sc-field>
                <sc-field name="Media">
                    <sc-form-field-boolean :field.sync="form.fields.media"/>
                </sc-field>
                <sc-field name="Players">
                    <sc-form-field-boolean :field.sync="form.fields.players"/>
                </sc-field>
                <sc-field name="Playlists">
                    <sc-form-field-boolean :field.sync="form.fields.playlists"/>
                </sc-field>
                <sc-field name="Channels">
                    <sc-form-field-boolean :field.sync="form.fields.channels"/>
                </sc-field>
                <sc-field name="Templates">
                    <sc-form-field-boolean :field.sync="form.fields.templates"/>
                </sc-field>

                <sc-button color="secondary" :running="form.processing" :disabled="!canSubmit" type="submit"
                           @clicked="post()" icon="save">
                    Submit
                </sc-button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: "tenant-cm-import",
  data() {
    return {
      ready: false,
      tenant: {},
      form: this.$sform.createForm({
        fields: {
          // url: 'https://spoof.test/api/admin/tenant/'+this.$route.params.id,
          frameset_templates: {default: false},
          media: {default: false},
          players: {default: false},
          playlists: {default: false},
          channels: {default: false},
          templates: {default: false},
        }
      })
    }
  },

  computed: {
    name() {
      return this.tenant.name
    },

    canSubmit() {
      return this.form.fields.frameset_templates || this.form.fields.media || this.form.fields.players || this.form.fields.playlists || this.form.fields.channels || this.form.fields.templates
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_URL + `/api/admin/tenant/${this.$route.params.id}`)
      .then((res) => {
        this.tenant = res.data
        this.form.fields = res.data;
        this.ready = true;
      })
  },
  methods: {
    submit() {

      if (this.canSubmit) {
        this.form.processing = true;
        axios.post(process.env.VUE_APP_URL + `/api/admin/tenant/${this.$route.params.id}/import-cm`, this.form.fields)
          .then((res) => {
            this.$notify('Importing CM completed.');
            this.form.processing = false;
            this.$router.push('/tenants');
          })
          .catch((err) => {
            this.$notify.error("Something went wrong. Please try again later.");
            this.form.processing = false;
          })
      }
    }
  }
}
</script>
