var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel pd"},[_c('div',{staticClass:"filter-container"},[_c('div',{staticClass:"name"},[_vm._v("Date")]),_c('select-day',{staticClass:"filter",on:{"changed":_vm.changedDay}})],1),_c('div',{staticClass:"filter-container"},[_c('div',{staticClass:"name"},[_vm._v("Time window")]),(_vm.timePadding)?_c('select-time',{staticClass:"filter",attrs:{"padding":_vm.timePadding,"no-schedule":_vm.noSchedule},on:{"changed":_vm.changedTime}},[_c('div',{staticClass:"gaps"},[_c('div',{staticClass:"timelines"},_vm._l((_vm.gaps),function(gap){return (gap.valid)?_c('div',{staticClass:"timeline-container"},[_c('div',{staticClass:"timeline",style:({
                      left:gap.start + '%',
                      right:100 - gap.end + '%'
                    })})]):_vm._e()}),0)]),_c('div',{staticClass:"gaps playlist-schedule"},[_c('div',{staticClass:"timelines"},_vm._l((_vm.playlistScheduleTimelines),function(gap){return _c('div',{staticClass:"timeline-container"},[_c('div',{staticClass:"timeline",style:({
                      left:gap.start + '%',
                      right:100 - gap.end + '%'
                    })},[_c('div',{staticClass:"line"},[_vm._v("not playing")])])])}),0)])]):_vm._e()],1),_c('div',{staticClass:"items button-bar"},[_c('div',{staticClass:"item"},[(_vm.$store.getters.UserHasPermission('update-playlist'))?_c('div',{staticClass:"checkbox"},[_c('sc-table-checkbox',{attrs:{"status":_vm.selectStatus},nativeOn:{"click":function($event){return _vm.checkAll()}}})],1):_vm._e(),_c('div',{staticClass:"buttons-container"},[_c('div',{staticClass:"buttons",class:{active:_vm.selected.length > 0}},[(_vm.$store.getters.UserHasPermission('update-playlist'))?_c('sc-button',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.editSelected()}}},[_vm._v("Edit schedules ")]):_vm._e()],1)])])]),_c('div',{staticClass:"items items-timelines"},[_c('div',{staticClass:"gaps partial"},[_c('div',{staticClass:"timelines"},_vm._l((_vm.partialGaps),function(gap){return _c('div',{staticClass:"timeline-container"},[_c('div',{staticClass:"timeline",style:({
                      left:gap.start + '%',
                      right:gap.end + '%'
                    })})])}),0)]),_vm._l((_vm.items),function(item){return _c('div',{staticClass:"item"},[_c('div',{staticClass:"checkbox"},[(_vm.$store.getters.UserHasPermission('update-playlist'))?_c('sc-table-checkbox',{attrs:{"status":_vm.selected.includes(item.original.id)?'active':'false',"color":"dark"},nativeOn:{"click":function($event){return _vm.check(item.original.id)}}}):_vm._e()],1),_c('div',{staticClass:"item-name",on:{"click":function($event){return _vm.startEdit([item.original.id])}}},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":item.original.media_item.thumbnail_storage_path}})]),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.original.media_item.name)),_c('br'),(item.original.media_item.approved !== 'approved')?_c('span',{staticStyle:{"color":"#ff6d72","font-size":".8em","text-transform":"capitalize"}},[_vm._v("("+_vm._s(item.original.media_item.approved)+")")]):_vm._e()])]),_c('div',{staticClass:"timelines",on:{"click":function($event){return _vm.startEdit([item.original.id])}}},_vm._l((item.timelines),function(timeline){return _c('div',{staticClass:"timeline-container"},[_c('div',{staticClass:"timeline",style:({
                      left:timeline.start + '%',
                      right:timeline.end + '%'
                    })},[_c('div',{staticClass:"time start"},[(timeline.startContinues)?_c('div',{staticClass:"continues"},[_c('awesome-icon',{attrs:{"icon":"angle-double-left"}}),(timeline.startValue)?[_vm._v(_vm._s(timeline.startValue))]:[_c('awesome-icon',{attrs:{"icon":"infinity"}})]],2):_vm._e(),_c('div',[_vm._v(_vm._s(timeline.startLabel))])]),_c('div',{staticClass:"line",class:{pending:item.original.media_item.approved !== 'approved'}}),_c('div',{staticClass:"time end"},[(timeline.endContinues)?_c('div',{staticClass:"continues"},[(!timeline.endValue)?[_c('awesome-icon',{attrs:{"icon":"infinity"}})]:[_vm._v(_vm._s(timeline.endValue))],_c('awesome-icon',{attrs:{"icon":"angle-double-right"}})],2):_vm._e(),_c('div',[_vm._v(_vm._s(timeline.endLabel))])])])])}),0)])}),(_vm.noSchedule)?_c('div',{staticClass:"item empty"},[_c('div',{staticClass:"checkbox"}),_c('div',{staticClass:"item-name"}),_c('div',{staticClass:"timelines"},[_c('div',{staticClass:"timeline-container"},[_c('div',[_vm._v("Player not scheduled")]),_c('div',[_vm._v("on "+_vm._s(_vm.noSchedule)+"s")])])])]):(_vm.items.length === 0)?_c('div',{staticClass:"item empty"},[_c('div',{staticClass:"checkbox"}),_c('div',{staticClass:"item-name"}),_vm._m(0)]):_vm._e()],2),(_vm.editItems.length > 0)?_c('playlist-item-edit',{attrs:{"playlist":_vm.playlist,"items":_vm.editItems,"playlist_items":_vm.playlist_items},on:{"close":_vm.stopEditing}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timelines"},[_c('div',{staticClass:"timeline-container"},[_vm._v(" No scheduled items ")])])}]

export { render, staticRenderFns }