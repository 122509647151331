<template>
    <div class="sc-filter" v-if="display">
        <div class="filter-title" @click.stop="toggleCollapse">
            <div class="icon" :class="{collapsed:!collapsed}"><awesome-icon icon="chevron-down"></awesome-icon></div>
            <h2>
                <span v-if="title">{{ title }}</span>
                <span v-else>Filter title missing</span>
            </h2>

            <awesome-icon class="filter-clear" icon="times" v-if="val && val.length > 0" @click.stop="clearValue"/>
        </div>

        <transition name="expand"
                    @enter="enter"
                    @after-enter="afterEnter"
                    @leave="leave"
                    mode="out-in">
            <div class="filter-content" v-if="!collapsed">
                <!-- Icon class to offset -->
                <div class="icon"></div>

                <div class="filter-content-item">
                    <!-- TYPE===RADIO || TYPE===CHECKBOX -->
                    <template v-if="type==='radio' || type ==='checkbox'">
                        <div v-for="o in options" :key="'input-'+o" class="multi">
                            <label :for="`input-${o}-${type}`">{{ o }}</label>
                            <input :id="`input-${o}-${type}`" :value="o" :type="type" v-model="val"
                                   @change="update"/>
                        </div>
                    </template>

                    <!-- TYPE===SELECT -->
                    <select v-else-if="type==='select'" v-model="val" @change="update" class="line">
                        <option v-for="o in options" :key="`input-${o}-${type}`" :value="o">{{ o }}</option>
                    </select>


                    <!-- TYPE===SEARCH_SELECT -->
                    <template v-else-if="type==='search_select'">
                        <div class="search-select">
                            <input class="line" v-model="filterValue" @click="showDisplay" ref="filterInput"/>

                            <div class="filter-options options" v-if="displayOptions">
                                <div class="filter-option option" v-for="o in filteredOptions"
                                     @click.stop="selectOption(o)" :key="o">
                                    {{ o }}
                                </div>
                            </div>

                            <div class="selected-options" v-if="val">
                                <div class="selected-option" v-for="(o,i) in val" @click="removeOption(i)"
                                     :key="o+'-'+i">
                                    <awesome-icon icon="times"/>
                                    {{ o }}
                                </div>
                            </div>
                        </div>
                    </template>


                    <!-- TYPE===INPUT -->
                    <input v-else type="text" v-model="val" @input="slowUpdate" class="line"/>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
  name: "sc-filter",
  props: {
    'type': {
      type: String, validator: (val) => {
        return ['radio', 'checkbox', 'select', 'text', 'search_select'].includes(val);
      }
    },
    'title': {
      type: String, required: true
    },
    'options': {
      type: Array
    }
  },
  data() {
    return {
      collapsed: true,
      val: undefined,
      timeout: undefined,

      // Search select
      displayOptions: false,
      filterValue: undefined,
    }
  },

  computed: {
    display() {
      if (this.type && this.type !== 'text') {
        if (!this.options) {
          const e = new Error('[Vue warn]: Missing required prop: "options".\n' +
            '\n' +
            'found in \n');
          console.error(e);
          return false;
        }
      }
      return true;
    },

    formattedValue() {
      switch (this.type) {
        case 'checkbox':
          return this.val.join(',');
        default:
          return this.val;
      }
    },

    filteredOptions() {
      let currentSelection = this.val || [];
      let options = this.options.filter(o => !currentSelection.includes(o));

      if (this.filterValue) {
        return options.filter(o => o.toLowerCase().includes(this.filterValue.toLowerCase()))
      } else {
        return options
      }
    }
  },

  beforeDestroy() {
    clearTimeout(this.timeout);

    if (this.displayOptions) {
      this.hideDisplay(false)
    }
  },

  mounted() {
    if (this.defaults) {
      this.val = this.defaults;
      this.collapsed = false
    } else {
      if (this.type === 'checkbox') {
        this.val = [];
      }
    }
  },

  methods: {
    toggleCollapse() {
      if (!this.val || this.val.length === 0) {
        this.collapsed = !this.collapsed
      }
      this.hideDisplay();
    },

    clearValue() {
      this.val = this.type === 'checkbox' ? [] : undefined
      this.hideDisplay();
      this.$emit('update:value', undefined)
      this.$emit('filter')
    },

    update(e) {
      if (this.val) {
        this.$emit('update:value', this.formattedValue)
        this.$emit('filter', e)
      }
    },

    slowUpdate(e) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.update(e)
      }, 1000);
    },


    /**
     * TYPE === SEARCH_SELECT
     */
    showDisplay() {
      if (!this.displayOptions) {
        window.addEventListener('mousedown', this.hideDisplay)
      }
      this.displayOptions = true;
    },

    hideDisplay(e) {
      if (e && !e.target.className.includes('filter-option')) {
        this.displayOptions = false;
        window.removeEventListener('mousedown', this.hideDisplay);
      }
    },

    selectOption(option) {
      if (!this.val) {
        this.val = [option]
      } else {
        this.val.push(option)
      }
      this.$refs.filterInput.focus();
      if (this.filteredOptions.length === 0) {
        this.filterValue = undefined;
      }
      this.update();
    },
    removeOption(index) {
      this.val.splice(index, 1);
      this.update();
    },

    /**
     * Expanding transition
     * Duplicated code, to prevent dependency on other component.
     */

    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';
      element.style.opacity = 1;

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;
      // getComputedStyle(element).width;

      // Trigger the animation.
      // We use `setTimeout` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      setTimeout(() => {
        element.style.height = height;
      });
    },

    afterEnter(element) {
      element.style.height = 'auto';
    },

    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;
      element.style.opacity = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      setTimeout(() => {
        element.style.height = 0;
      });
    }
  }
}
</script>

<style scoped lang="scss">

.sc-filter {
    min-height: 2em;
    box-sizing: border-box;
    margin: 1em 0;
    position: relative;
    min-width: 12em;

    .filter-title {
        display: flex;
        height: 1.5em;
        align-items: center;
        cursor: pointer;
        user-select: none;
        margin: 0 0 .5em;
        position: relative;
        font-weight: 600;
        text-transform: capitalize;

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            margin-right: .5em;
            transition: transform .2s $easing;
            transform: rotateZ(-90deg);

            &.collapsed {
                transform: rotateZ(0deg);
            }
        }

        .filter-clear {
            position: absolute;
            justify-self: flex-end;
            cursor: pointer;
            opacity: .75;
            right: .5em;

            &:hover {
                opacity: 1;
            }
        }
    }

    .filter-content {
        position: relative;
        display: flex;
        font-size: .9em;
        font-weight: 500;
        margin-bottom: 1.75em;

        .icon {
            min-width: 24px;
            margin-right: .5em;
        }

        .filter-content-item {
            width: 100%;
            position: relative;

            .multi, .line {
                box-sizing: border-box;
                height: 2.25em;
                line-height: 1.5;
            }

            .multi {
                display: flex;
                padding-right: .25em;

                label, input {
                    cursor: pointer;
                }

                label {
                    flex: 1;
                    text-transform: capitalize;
                }

                //input[type=checkbox] {
                //    position: relative;
                //    height:0;
                //
                //    &::after {
                //        display: block;
                //        position: absolute;
                //        left: 9px;
                //        top: 5px;
                //        width: 5px;
                //        height: 10px;
                //        border: solid white;
                //        border-width: 0 3px 3px 0;
                //        -webkit-transform: rotate(45deg);
                //        -ms-transform: rotate(45deg);
                //        transform: rotate(45deg);
                //    }
                //}

                input[type=radio] {
                    position: relative;
                    height: 0;

                    &::after {
                        display: block;
                        position: absolute;
                        left: -.125em;
                        width: .75em;
                        height: .75em;
                        content: ' ';
                        border-radius: 50%;
                        border: 3px solid #FFF;
                        box-shadow: inset 0 0 0 #FFF;
                        background: #FFF;
                        transition: all var(--easing) .2s;
                    }

                    &:hover {
                        &::after {
                            background: var(--color-primary-light);
                        }
                    }

                    &:checked {
                        &::after {
                            background: var(--color-primary);
                        }
                    }
                }


            }

            .line {
                border: 1px solid #ccc;
                width: 100%;
            }

            .input-clear {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            input, select {
                text-indent: .5em;
            }


            .search-select {
                display: flex;
                flex-flow: column;
                position: relative;

                .options {
                    overflow-y: scroll;
                    border: 1px solid #aaa;
                    max-height: 200px;
                    position: absolute;
                    width: 100%;
                    background: #FFF;
                    top: 26px;
                    box-sizing: border-box;
                    z-index: 1;
                    color: #111;

                    .option {
                        padding: .5em .25em;
                        cursor: pointer;
                        transition: background .3s $easing;

                        &:hover {
                            background: #eee;
                        }
                    }
                }

                .selected-options {
                    margin-top: .25em;

                    .selected-option {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        color: #fff;
                        margin: .5em;

                        cursor: pointer;
                        //opacity: .5;

                        svg {
                            margin-right: .5em;
                        }

                        &:hover {
                            opacity: .8;
                        }
                    }
                }
            }
        }
    }
}

// Replace icon
select {
    background-size: 18px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDMyIDMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO3N0cm9rZTojMTE3NUFBO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2Utd2lkdGg6MnB4O308L3N0eWxlPjwvZGVmcz48dGl0bGUvPjxnIGlkPSJjaGV2cm9uLWJvdHRvbSI+PGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iMTYiIHgyPSI3IiB5MT0iMjAuNSIgeTI9IjExLjUiLz48bGluZSBjbGFzcz0iY2xzLTEiIHgxPSIyNSIgeDI9IjE2IiB5MT0iMTEuNSIgeTI9IjIwLjUiLz48L2c+PC9zdmc+);
    background-repeat: no-repeat;
    background-position: calc(100% - .3em) 50%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

/**
 * Expanding transition styling
 */

//* {
//  will-change: height, width;
//  transform: translateZ(0);
//  backface-visibility: hidden;
//  perspective: 1000px;
//}

.expand-enter-active,
.expand-leave-active {
    transition: all .2s $easing;
    overflow: hidden;
}

.expend-enter-to, .expand-leave {
    opacity: 1;
}

.expand-enter,
.expand-leave-to {
    height: 0;
    width: 0;
    opacity: 0;
}

</style>