<template>
    <div class="font-size" v-if="ready">
        <notification-panel></notification-panel>
        <router-view></router-view>
    </div>
</template>

<script>
const root = document.documentElement;

require(`@/design/_style_${process.env.VUE_APP_TARGET}.scss`)

if (process.env.VUE_APP_TARGET === 'ta') {
  require('@/design/_globals.scss');
  require('@/design/_form.scss');
}

export default {
  name: 'root',

  data() {
    return {
      ready: false
    }
  },

  created() {
    document.body.classList.add('env_' + process.env.VUE_APP_TARGET);
  },

  beforeMount() {
    require('./tenancy');
  },

  mounted() {
    this.$nextTick(() => {
      this.loadTenantConfig();
    })
  },

  methods: {
    loadTenantConfig() {
      let tc = localStorage.getItem('tenant-colors');
      let tl = localStorage.getItem('tenant-logos');

      if (typeof mfU !== 'undefined') {
        if (mfU.config && ![undefined, '', null].includes(mfU.config['tenant-colors']) && mfU.config['tenant-colors'].length > 0) {
          let value = JSON.stringify(mfU.config['tenant-colors'])

          // Check if there's a current value, and if it's the same as provided. If not update.
          if (tc !== value) {
            localStorage.setItem('tenant-colors', value);
            tc = value;
          }
        }

        if (![undefined, '', null].includes(mfU.logo)) {
          let value = mfU.logo;
          if (tl !== value) {
            localStorage.setItem('tenant-logos', value);
            tl = value;
          }
        }
      }

      if (tc) {
        let colors = JSON.parse(tc);

        colors.forEach((color) => {

          if (['style', 'brightness'].includes(color.type)) {
            root.style.setProperty(`--color-${color.type}`, color.value);
          } else {
            // for ['primary','secondary','tertiary','background', 'button']
            root.style.setProperty(`--color-${color.type}`, color.value.hex);
          }
        })

        let style = colors.find((color) => color.type === 'style');

        let brightness = 1;

        if (style === 'light') {
          brightness += colors.find((color) => color.type === 'brightness').value;
        } else {
          brightness -= colors.find((color) => color.type === 'brightness').value;
        }
        root.style.setProperty(`--color-brightness`, brightness);
      }

      if (tl) {
        root.style.setProperty('--logo', `url(${tl})`);
      }
      this.ready = true;
    },
  }
}

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap');
@import './css/reset.css';
@import './css/form.css';

:root {
    font-size: 62.5%;
}

@media screen and (min-width: 720px) {
    :root {
        font-size: 95%;
    }
}

html, body {
    margin: 0;
    padding: 0;
    min-height: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
}

body {
    overflow-y: scroll;

    &.env_ta {
        @include themed() {
            background: t('color-background');
        }
    }
}

#app {
    font-family: Montserrat, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
    @include themed() {
        color: t('text-color');
    }
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    min-height: 100%;
    position: relative;
}
</style>