import _index from './index.vue'
import _all from './all.vue'
import _edit from './edit.vue'

const routes = {
  path: 'user-management',
  meta: {name: 'users'},
  component: _index,
  children: [
    {
      path: '',
      meta: {name: 'all'},
      component: _all,
    },
    {
      path: ':user_id/edit',
      meta: {edit: true, name: 'edit'},
      component: _edit,
    },
  ]
};

export default routes;