<template>
    <div class="app-container">
        <div class="main-container" :style="{marginLeft:'0px'}">
            <div class="header-wrap">
                <div class="top-bar"></div>
                <div class="main-bar">
                    <div class="logo" @click="$router.push('/')">
<!--                        <img :src="require('@/img/logo-brand.png')"/>-->
                    </div>
                </div>
            </div>

            <div class="content-container">
                <div class="card">

                    <div class="login-icon">
                        <img :src="require('@/img/login-icon.png')"/>
                    </div>

                    <form v-on:submit.prevent class="login-form">

                        <div class="login-button-container">
                            <button class="login-button" type="submit" v-on:click.prevent="submit()">
                                Login
                            </button>
                        </div>

                    </form>
                </div>

                <div class="alt-login">
                    <router-link to="/user-login" class="alt-link">user login</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'sso-login',

  data() {
    return {
      error: '',
    }
  },

  mounted() {
    if (localStorage.getItem('error')) {
      this.$notify.error(this.decode(localStorage.getItem('error')));
      localStorage.removeItem('error');
    }
  },

  methods: {
    decode(string) {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = string;
      return textArea.value;
    },
    submit() {
      this.error = null;
      window.location = process.env.APP_URL + '/saml2/azure/login';
    },
  }
}

</script>

<style scoped lang="scss">
.app-container {
    min-height: 100%;
    height: 100%;
}

.main-container {
    transition: margin-left 0.4s var(--easing);
    height: 100%;
    display: flex;
    flex-flow: column;

    .header-wrap {
        position: relative;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

        .top-bar {
            background: var(--color-secondary);
            text-align: right;
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .main-bar {
            background: #fff;
            height: 6rem;
            display: flex;
            align-items: center;
            padding: 0 2.5rem;
            box-sizing: border-box;
            justify-content: space-between;

            .logo {
                cursor: pointer;
                background-image: var(--logo);
                height: 100%;
                width: 300px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }

        }
    }

    .content-container {
        flex: 1;
        display: flex;
        justify-content: center;

        .card {
            width: 350px;
            position: relative;
            height: auto;
            margin-top: 8em;

            .login-icon {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 3em 0;
            }

            .login-form {

                .row {
                    display: flex;
                    height: 50px;
                    align-items: center;
                    margin-bottom: 2em;
                    box-shadow: 0 0 5px #5B9DD9;
                    position: relative;

                    .icon {
                        width: 55px;
                        background: #FFF;
                        color: #242424;
                        display: flex;
                        height: 50px;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.5em;
                    }
                }

                .login-button-container {
                    margin-top: 2em;
                    display: flex;

                    .login-button {
                        outline: none;
                        border: none;
                        background: var(--color-primary);
                        width: auto;
                        margin: 0 auto;
                        padding: .75em 4em;
                        color: #fff;
                        font-size: 1em;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.alt-login {
    position: fixed;
    bottom: 2.5rem;
    left: 2.5rem;

    .alt-link {
        color: #a0a0a0;
        font-size: .8em;
        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
