<template>
    <div>
        <sc-table :table="table" @view="view">

            <template #column-media="{row}">
                <sc-table-cell :table="table" field="media">
                    <img class="thumbnail" :src="row.thumbnail_storage_path"/>
                </sc-table-cell>
            </template>

            <template #column-name="{row}">
                <sc-table-cell :table="table" field="name">
                    <div class="detail text-blue">
                        {{ row.name }}
                    </div>
                    <div class="detail">
                        {{ row.approved }}
                    </div>
                    <expire-checker :item="row"/>
                </sc-table-cell>
            </template>

            <template #column-media_type="{row}">
                <sc-table-cell :table="table" field="media_type">
                    <media-types :type="row.media_type"></media-types>
                </sc-table-cell>
            </template>

            <template #column-details="{row}">
                <sc-table-cell :table="table" field="details">
                    <div v-if="row.description" class="detail">
                        <span class="text-lightgrey">Description: </span> {{ row.description }}
                    </div>
                    <div v-if="row.width && row.height" class="detail">
                        <span class="text-lightgrey">Dimensions: </span> {{ row.width }}x{{ row.height }}
                    </div>
                    <div v-if="row.duration" class="detail">
                        <span class="text-lightgrey">Duration: </span> {{ row.duration }}
                    </div>
                    <div v-if="row.orientation" class="detail">
                        <span class="text-lightgrey">Orientation: </span> {{ row.orientation }}
                    </div>
                    <div v-if="row.creator" class="detail">
                        <span class="text-lightgrey">Creator: </span> {{ row.creator.email }}
                    </div>
                    <div v-if="row.owner" class="detail">
                        <span class="text-lightgrey">Owner: </span> {{ row.owner.name }}
                    </div>
                </sc-table-cell>
            </template>

        </sc-table>
    </div>
</template>

<script>
import ExpireChecker from "../../../../components/common/expire-checker";
export default {
  name: "media-expires",
  components: {ExpireChecker},
  data() {
    return {
      table: this.$stable.createTable({
        url: '/reports/media',
        fetchArguments: () => {
          return `&status=expires`
        },
        sortBy: 'updated_at',
        columns: {
          media: {sort: false},
          id: {
            width: 100,
            sort: false
          },
          name: {
            fill: true,
            sort: false
          },
          start_valid_date: {sort: false, type: 'date'},
          end_valid_date: {sort: false, type: 'date'},
          media_type: {sort: false},
          details: {
            sort: false,
            fill: true
          },
          created_at: {
            sort: false,
            target: 'created_at',
            type: 'date',
            align: 'right'
          },
        },
      }),
    }
  },

  mounted() {
    this.table.fetch();
  },

  methods: {
    view(item) {

    }
  }
}
</script>

<style scoped>
.thumbnail {
    max-width: 10em;
    max-height: 8em;
    object-fit: contain;
}

.detail {
    margin-bottom: .25em;
}
</style>