import _index from './index.vue'
import _details from './details.vue'
import _all from './all.vue'

const routes = {
  path: 'playlists',
  meta: {name: 'playlists'},
  component: _index,
  children: [
    {
      path: '',
      meta: {name: 'all'},
      component: _all,
    },
    {
      path: ':playlist_id/details',
      meta: {name: 'details'},
      component: _details,
    },
  ]
};

export default routes;