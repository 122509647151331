<template>
    <div>
        <div class="options">
            <select v-model="selected" @change="setChanged()">
                <option v-for="option in options" :value="option.id">{{option.name}}</option>
            </select>
        </div>
    </div>
</template>

<script>

  export default {
    name: "sc-field-select",
    props: ['resource', 'field'],
    data() {
      return {
        options: undefined,
        selected: '',
      }
    },
    mounted() {
      this.fetch();
    },
    methods: {
      fetch() {
        this.$talker.api.get(this.resource)
          .then(res => {
            this.options = res.data;
          })
      },
      setChanged() {
        this.$emit('update:field', this.selected)
      }
    }
  }
</script>

<style scoped>
    select {
        border: none;
        outline: none;
        width: 100%;
        padding: 1em;
        height: 3.2em;
        font-size: 1em;
        box-sizing: border-box;
        background: #eaeaea;
        border-radius: 3px;
        box-shadow: 0px 0px 0px 2px transparent;
        color: var(--color-secondary);
        transition: box-shadow 0.4s;
        position: relative;
    }
</style>
