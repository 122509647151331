<template>
    <div>
        <div class="cols">

            <div class="col approval">

                <div class="item status" v-if="item">
                    <div class="label">current status:</div>
                    <div class="line">
                        {{ item.status }}
                    </div>
                </div>

                <div class="label">Optional message</div>
                <textarea class="textarea" v-model="message" :disabled="!isPending"></textarea>

                <div class="btns" v-if="isPending">
                    <sc-button color="secondary" @click="approve()" :running="saving">Approve</sc-button>
                    <sc-button color="reject" @click="reject()" :running="saving">Reject</sc-button>
                </div>

                <div class="history" v-if="history">
                    <div class="label">history</div>
                    <div class="history-line" v-for="history in history">
                        <div class="action">{{ history.action }}</div>
                        <div class="message">{{ history.message }}</div>
                        <div class="created_at">
                            <div class="user">{{ history.name }}</div>
                            <div class="time">{{ history.responded_at | toDateHuman }}</div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="col media-item-col">
                <div class="media-item-header">New Media Item</div>
                <div class="media-item" v-if="mediaItem">

                    <div class="line">
                        <div class="label">Name</div>
                        <div class="value">{{ mediaItem.name }}</div>
                    </div>

                    <div class="line">
                        <div class="label">Type</div>
                        <div class="value">
                            <media-types :type="mediaItem.media_type" style="font-size:0.8em;"></media-types>
                        </div>
                    </div>

                    <div class="line">
                        <div class="label">Valid from</div>
                        <div class="value">{{ mediaItem.start_valid_date }}</div>
                    </div>

                    <div class="line">
                        <div class="label">Valid to</div>
                        <div class="value">{{ mediaItem.end_valid_date }}</div>
                    </div>

                    <div class="label label-fields" v-if="mediaItem.message">fields</div>
                    <div class="fields" v-if="mediaItem.message">
                        <div class="line" v-for="field in mediaItem.message.message_fields">
                            <div class="line-label">{{ field.name }}:</div>
                            <div class="value" v-if="field.value">{{ field.value }}</div>
                            <div class="value" v-else> ---</div>
                        </div>
                    </div>

                    <div class="line">
                        <template v-if="media_type === 'image'">
                            <img class="image-preview"
                                 :src="mediaItem.image_storage_path"/>
                        </template>
                        <template v-else-if="media_type === 'video'">
                            <video class="video-preview" controls autoplay muted loop>
                                <source :src="mediaItem.image_storage_path" type="video/webm"/>
                            </video>
                        </template>
                        <template v-else-if="media_type === 'message'">
                            <img class="image-preview"
                                 :src="mediaItem.image_storage_path"/>
                        </template>
                        <template v-else>
                            Unable to preview Scala Script at this moment.
                        </template>
                    </div>
                </div>

                <div v-if="pendingMediaItems.length > 0">
                    <div class="media-item">
                        <div>Included media items will be approved.</div>
                        <div v-for="i in pendingMediaItems" class="pending-item">
                            <div class="lines">
                                <div>
                                    <div class="label">name:</div>
                                    <div class="value">{{ i.name }}</div>
                                </div>
                                <div>
                                    <div class="label">Creator:</div>
                                    <div class="value">{{ i.creator.profile.name }}</div>
                                </div>
                            </div>

                            <img :src="i.thumbnail_storage_path"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MediaTypes from "../../../../components/common/media-types";

export default {
  components: {MediaTypes},
  data() {
    return {
      item: undefined,
      history: [],
      message: '',
      mediaItem: undefined,
      saving: false,
      pendingMediaItems: []
    }
  },

  computed: {
    media_type() {
      const types = {
        1: "image",
        2: "message",
        3: "scala script",
        4: "video"
      };
      return types[this.mediaItem.media_type]
    },

    isPending() {
      return this.item && this.item.status === 'pending';
    }
  },

  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.$talker.api.get(`approvals/todo/${this.$route.params.approval_id}`)
        .then(res => {
          this.item = res.data;
          let history = this.item.history;
          history.reverse();
          this.history = history;
          this.fetchMedia();
        })
    },

    fetchMedia() {
      this.$talker.api.get(`media/${this.item.subject_id}`)
        .then(res => {
          this.mediaItem = res.data;
        })
        .then(() => {
          if (this.media_type === 'message') {
            // Todo: Update backend for more resource friendly update
            // Look for included media items that are pending.
            // Find all template fields of type IMAGE
            let imageFieldNames = this.mediaItem.message.template.template_fields.filter(f => f.type === 'IMAGE').map(f => f.name);

            // Look in message fields where name matches the names of the template fields which have type IMAGE
            let internalIds = this.mediaItem.message.message_fields.filter(mF => imageFieldNames.includes(mF.name)).map(mF => mF.value);
            if (internalIds.length > 0) {
              this.$talker.api.get('media?media_type=1&sortby=name&sortdir=asc&status=pending')
                .then((res) => {
                  // Find media items of type IMAGE which are pending AND included in this message
                  this.pendingMediaItems = res.data.data.filter(item => internalIds.includes(item.id))
                })
            }
          }
        })
    },

    approve() {
      this.saving = true;

      let payload = {
        message: this.message
      };

      this.$talker.api.put(`approvals/${this.$route.params.approval_id}/approve`, payload)
        .then((res) => {

          // Todo: It's gonna work but this is quick and dirty. Should probably all be backend work.
          if (this.pendingMediaItems.length > 0) {
            // Find all pending approval items
            let mediaIds = this.pendingMediaItems.map(i => i.id);
            this.$talker.api.get('approvals/todo?pending=1')
              .then((res) => {
                // Match based on type and subject_id
                const matches = res.data.data.filter(d => mediaIds.includes(d.subject_id) && d.type === 'App\\Media')
                const subPayload = {
                  "message": `Approved as part of: ${this.media_type} with name: ${this.mediaItem.name}.`
                }
                // trigger single approve action for matching item
                let count = 0;
                matches.forEach(m => {
                  count++;
                  this.$talker.api.put(`approvals/${m.id}/approve`, subPayload)
                    .then(() => {
                      if (count === matches.length) {
                        this.$notify.success('Item approved');
                        this.$router.push('/approvals/open');
                        this.saving = false;
                      }
                    })
                })
              })
          } else {

            // Original part to finish up after approval, keep in case of rework pending items approval
            this.$notify.success('Item approved');
            this.$router.push('/approvals/open');
            this.saving = false;
          }
        })
        .catch(err => {
          this.saving = false;
          this.$notify.error(err.response.data);
        })
    },

    reject() {
      this.saving = true;

      let payload = {
        message: this.message
      };

      this.$talker.api.put(`approvals/${this.$route.params.approval_id}/reject`, payload)
        .then(res => {
          this.$notify.success('Item rejected');
          this.fetch();
          this.message = '';
          this.saving = false;
        })
        .catch(err => {
          this.saving = false;
          this.$notify.error(err.response.data);
        })
    }
  }
}
</script>

<style scoped lang="scss">
.item {
    margin-bottom: 20px;
}

.status .line {
    font-size: 1.5em;
    font-weight: 500;
}

.media-item {
    margin-bottom: 20px;
}

.btns {
    margin-top: 20px;
    display: flex;
}

.cols {
    display: flex;
    margin: 3em 0 2em;
}

.col {
    padding: 0 2em;
}

.col.approval {
    flex: 3;
}

.col.media-item-col {
    flex: 2;
}

.history {
    margin: 40px 0 30px;
}

.history-line {
    display: flex;
    background: #fafafa;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    font-size: 0.9em;
}

.history-line:last-child {
    border: none;
}

.history-line .action {
    flex: 1;
    font-weight: 700;
    padding-right: 0.5em;
}

.history-line .message {
    flex: 2;
}

.history-line .created_at {
    flex: 1;
    text-align: right;
}

.history-line .created_at .time {
    font-size: 0.8em;
    font-weight: 700;
    margin-top: 3px;
}

.approval .label {
    margin-bottom: 5px;
}

.media-item {
    background: #fafafa;
    border: 1px solid #ccc;
    padding: 15px 20px;
}

.media-item-header {
    text-transform: uppercase;
    margin-bottom: 10px;
}

.media-item .line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.media-item .line .label {
    width: 150px;
    font-size: 0.8em;
}

.media-item .line .line-label {
    width: 150px;
}

.media-item .label-fields {
    margin-bottom: 10px;
    font-size: 0.8em;
}

.media-item .fields {
    margin-left: 20px;
    margin-bottom: 20px;
    font-size: 0.9em;
}

.media-item .fields .label {
    font-size: 0.7em;
}

.textarea {
    min-width: 250px;
    min-height: 70px;
}

.image-preview, .video-preview {
    max-width: 100%;
}

.pending-item {
    display: flex;
    margin: 2em 0;
    justify-content: space-between;

    &:last-child {
        margin-bottom: 0;
    }

    .lines {
        & > div {
            margin-bottom: .5em;
        }

        .value {
            font-size: .9em;
        }
    }

    img {
        max-width: 50%;
    }
}
</style>
