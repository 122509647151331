<template>
    <component :is="type" class="tab pdt" :to="to" :replace="replace" :class="{active}">
        <slot></slot>
    </component>
</template>

<script>
  export default {
    name: "sc-tab",
    props: {
      active: {
        default: false,
      },
      to: {},
      replace: {
        type: Boolean,
        default: true,
      }
    },

    data() {
      return {
        type: 'div'
      }
    },

    mounted() {
      if (this.to) {
        this.type = 'router-link'
      }
    }
  }
</script>

<style scoped lang="scss">
    .tab {
        text-align: center;
        font-size: 0.9em;
        font-weight: 500;
        cursor: pointer;
        position: relative;
        border: 1px solid #ccc;
        border-right: none;
        background: #f8f8f8;
        color: var(--color-primary);
        transition: background .2s ease-in-out;
        transform: translateY(1px);
        display: flex;
        align-items: center;

        &:last-child {
            border-right: 1px solid #ccc;
        }

        &:hover {
            color: #111;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 4px;
            background: var(--color-secondary);
            opacity: 0;
            transition: opacity .2s ease-in-out;
        }

        &.active {
            color: #111;
            background: #fff;
            border-bottom: 1px solid transparent;

            &:before {
                opacity: 1;
            }
        }
    }
</style>