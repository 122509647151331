<template>
    <div>
        <breadcrumbs :items="['Add media']"></breadcrumbs>
        <sc-tabs>
            <sc-tab v-for='t in tabs' :active="tab === t" @click.native="openTab(t)" :key="t">
                <span style="text-transform: capitalize;">{{t}}</span></sc-tab>
        </sc-tabs>

        <new-image v-if='tabs.includes("image") && tab === "image"' @success="$emit('success')"></new-image>
        <new-video v-if='tabs.includes("video") && tab === "video"' @success="$emit('success')"></new-video>
    </div>
</template>

<script>
  import NewImage from "./new-image";
  import NewVideo from "./new-video";

  export default {

    props: {
      tabs: {type: Array, required: false, default: () => ['image', 'video'],
      validator: (values) => {
        const allowedValues = ['image','video'];
        // let valid = true;
        for (let i= 0; i < values.length; i++) {
          if (!allowedValues.includes(values[i])) {
            // Will result in false if the values array contains ANY non-allowed values.
            throw new Error(`${values[i]} is not an allowed value. Allowed values are: ${allowedValues}`);
          }
        }
        return true;
      }}
    },

    name: "add-media",
    components: {NewVideo, NewImage},
    data() {
      return {
        tab: this.tabs[0]
      }
    },

    methods: {
      openTab(tab) {
        this.tab = tab;
      }
    }
  }
</script>

<style scoped>

</style>