<template>
    <div class="media-type" :style="{justifyContent:justify}">
        <div class="icon">
            <awesome-icon v-if="type === 1" icon="image"></awesome-icon>
            <awesome-icon v-if="type === 2" icon="align-left"></awesome-icon>
            <awesome-icon v-if="type === 3" icon="code"></awesome-icon>
            <awesome-icon v-if="type === 4" icon="video"></awesome-icon>
        </div>
        
        <div class="name">
            {{typeName}}
        </div>
    </div>
</template>

<script>
  export default {
    name: "media-types",
    props: ['type', 'justify'],

    computed: {
      typeName() {
        if (this.type === 1) {
          return 'image'
        }
        if (this.type === 2) {
          return 'message'
        }
        if (this.type === 3) {
          return 'script'
        }
        if (this.type === 4) {
          return 'video'
        }

      }
    }
  }
</script>

<style scoped>
    .media-type {
        display: flex;
        text-transform: capitalize;
        align-items: center;
    }

    .icon {
        margin-right: 0.5em;
        margin-top: 0.1em;
        color: #999;
    }
</style>