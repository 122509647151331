<template>
    <div class="app-container">
        <div class="main-container" :style="{marginLeft:'0px'}">
            <div class="header-wrap">
                <div class="top-bar"></div>
                <div class="main-bar">
                    <div class="logo" @click="$router.push('/')">
<!--                        <img :src="require('@/img/logo-brand.png')"/>-->
                    </div>
                </div>
            </div>


            <div class="content-container">
                <div class="card">

                    <div class="login-icon">
                        Reset your password
                    </div>

                    <form v-on:submit.prevent class="login-form">

                        <div class="row empty" v-if="errors && errors.token">
                            <div class="input-error" >{{ errors.token }}</div>
                        </div>

                        <div class="row">
                            <div class="icon"><awesome-icon icon="user"/></div>
                            <input id="email" type="email" v-model="email" placeholder="email"
                                   name="email" required autocomplete="email" autofocus>
                            <div class="input-error" v-if="errors && errors.email">{{ errors.email }}</div>
                        </div>

                        <div class="row">
                            <div class="icon"><awesome-icon icon="lock"/></div>
                            <input id="password" type="password" v-model="password" placeholder="new password"
                                   name="email" required autocomplete="email" autofocus>
                            <div class="input-error" v-if="errors && errors.password">{{ errors.password }}</div>
                        </div>

                        <div class="row">
                            <div class="icon"><awesome-icon icon="lock"></awesome-icon></div>
                            <input id="password_confirmation" type="password" v-model="password_confirmation"
                                   placeholder="confirm password"
                                   name="email" required autocomplete="email" autofocus>
                            <div class="input-error" v-if="errors && errors.password_confirmation">{{ errors.password_confirmation }}</div>
                        </div>

                        <div class="login-button-container">
                            <button class="login-button" type="submit" v-on:click.prevent="submit()">
                                Save new password
                            </button>
                        </div>


                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'password-reset',

  data() {
    return {
      email: null,
      password: null,
      password_confirmation: null,
      errors: undefined,
    }
  },

  methods: {

    submit() {

      this.errors = {};

      if (!this.$route.query.token) {
        this.errors.token = "Reset token is required";
      }

      if (this.email === '' || this.email === null) {
        this.errors.email = "Email is required";
      }

      if (this.password === '' || this.password === null) {
        this.errors.password = "Password is required";
      }

      if (this.password_confirmation === '' || this.password_confirmation === null) {
        this.errors.password_confirmation = "Confirmation Password is required";
      } else if (this.password_confirmation !== this.password) {
        this.errors.password = "Passwords do not match";
        this.errors.password_confirmation = "Passwords do not match";
      }

      if (this.errors.token || this.errors.email || this.errors.password || this.errors.password_confirmation) {
        return;
      }

      let form = {
        email: this.email,
        token: this.$route.query.token,
        password: this.password,
        password_confirmation: this.password_confirmation,
      };

      this.$talker.api.post('password/reset', form)
        .then(() => {
          this.$notify.success('Password successfully updated');
          this.$router.push('/login')
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$notify.error(err.response.data.errors);
          } else {
            this.$notify.error(err.response.data);
          }
        })
    },
  }

}
</script>

<style scoped lang="scss">

.app-container {
    min-height: 100%;
    height: 100%;
}

.main-container {
    transition: margin-left 0.4s var(--easing);
    height: 100%;
    display: flex;
    flex-flow: column;

    .header-wrap {
        position: relative;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

        .top-bar {
            background: var(--color-secondary);
            text-align: right;
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .main-bar {
            background: #fff;
            height: 6rem;
            display: flex;
            align-items: center;
            padding: 0 2.5rem;
            box-sizing: border-box;
            justify-content: space-between;

            .logo {
                cursor: pointer;
                background-image: var(--logo);
                height: 100%;
                width: 300px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
        }
    }

    .content-container {
        flex: 1;
        display: flex;
        justify-content: center;

        .card {
            width: 350px;
            position: relative;
            height: auto;
            margin-top: 8em;

            .login-icon {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 2em 0;
                font-size: 2em;
                text-align: center;
            }

            .login-form {
                .row {
                    display: flex;
                    height: 50px;
                    align-items: center;
                    margin-bottom: 2em;
                    box-shadow: 0 0 5px #5B9DD9;
                    position: relative;

                    &.empty {
                        box-shadow: none;
                        height: 0;
                    }

                    .icon {
                        width: 55px;
                        background: #FFF;
                        color: #242424;
                        display: flex;
                        height: 50px;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.5em;
                    }

                    input {
                        width: 100%;
                        border: none;
                        box-sizing: border-box;
                        height: 100%;
                        padding: 0 15px;
                        color: #242424;
                        outline: none;
                        font-weight: 300;

                        &::placeholder {
                            color: #a0a0a0;
                        }
                    }

                    .input-error {
                        color: red;
                        position: absolute;
                        top: -1em;
                        font-size: .8em;
                    }
                }

                .login-button-container {
                    margin-top: 2em;
                    display: flex;

                    .login-button {
                        outline: none;
                        border: none;
                        background: var(--color-primary);
                        width: auto;
                        margin: 0 auto;
                        padding: .75em 4em;
                        color: #fff;
                        font-size: 1em;
                        cursor: pointer;
                    }
                }

                .forgot-password {
                    text-align: center;
                    width: 100%;
                    font-size: .8em;
                    color: #a0a0a0;
                    text-decoration: none;
                    display: block;
                    margin-top: 20px;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

</style>
