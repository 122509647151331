<template>
    <div>
        <div class="width-container pd main">
            <div class="tile-row">
                <router-link to="/media" class="tile" v-if="$store.getters.UserHasPermission('view-media')">
                    <div class="title">Media Library</div>
                    <div class="icon"><img :src="require('@/img/icon-upload.png')"/></div>
                    <div class="button">
                        <router-link to="/media">
                            Upload
                            <awesome-icon icon="chevron-right"/>
                        </router-link>
                    </div>
                </router-link>
                <router-link to="/messages" class="tile"
                             v-if="$store.getters.UserHasPermission('view-message')">
                    <div class="title">Messages</div>
                    <div class="icon"><img :src="require('@/img/icon-create.png')"/></div>
                    <div class="button">
                        <router-link to="/messages?action=new">
                            Create
                            <awesome-icon icon="chevron-right"/>
                        </router-link>
                    </div>
                </router-link>
                <router-link to="/playlists" class="tile" v-if="$store.getters.UserHasPermission('view-playlist')">
                    <div class="title">Manage Content</div>
                    <div class="icon"><img :src="require('@/img/icon-manage.png')"/></div>
                    <div class="button">
                        <router-link to="/playlists">Manage Content
                            <awesome-icon icon="chevron-right"/>
                        </router-link>
                    </div>
                </router-link>
                <router-link to="/approvals/mine" class="tile">
                    <div class="title">Approvals</div>
                    <div class="icon"><img :src="require('@/img/icon-edit.png')"/></div>
                    <div class="button">
                        <router-link to="/messages">View
                            <awesome-icon icon="chevron-right"/>
                        </router-link>
                    </div>
                </router-link>
                <!--                <div class="tile">-->
                <!--                    <div class="title">Channels</div>-->
                <!--                    <div class="icon"><img :src="require('~/img/icon-channels.png')"/></div>-->
                <!--                    <div class="button">-->
                <!--                        <router-link to="/channels">Edit Channels<i class="fas fa-chevron-right"></i></router-link>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div class="tile">-->
                <!--                    <div class="title">Network Access</div>-->
                <!--                    <div class="icon"><img :src="require('~/img/icon-network.png')"/></div>-->
                <!--                    <div class="button">-->
                <!--                        <router-link to="/networks">Explore Networks<i class="fas fa-chevron-right"></i></router-link>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>


export default {
  name: "all",

  methods: {
    goTo(url) {
      this.$router.push(url).catch(() => {
      });
    }
  }

}
</script>

<style scoped lang="scss">
.main {
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
}

.tile-row {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    grid-gap: 2.5rem;
}

.tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: var(--shading);
    box-sizing: border-box;
    padding: 2.5em 1em;
    text-align: center;

    .title {
        font-size: 1.5em;
        color: #444;
        font-weight: 500;
    }

    .icon {
        margin: 2.5em 0;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.7em 1.2em;
        background: var(--color-primary);
        font-weight: 600;
        text-transform: capitalize;
        color: #fff;

        svg {
            margin-left: 0.8em;
        }
    }
}
</style>
