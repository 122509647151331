<template>
    <div class="width-container pd">
        <breadcrumbs :items="[{name:'players',path:'/players'}, 'new']"></breadcrumbs>

        <div class="player-container">

            <sc-form @submit="post" :form="form">
                <template #field-polling-interval>
                    <sc-form-field :form="form" field="polling-interval" description="In minutes">
                        <input v-model="form.fields['polling-interval']" ref="initialInput" type="number" step="1"
                               min="1"
                               required/>
                    </sc-form-field>
                </template>

                <template #field-player-type>
                    <sc-form-field :form="form" field="player-type">
                        <select v-model="form.fields['player-type']"
                                :data-placeholder="!form.fields['player-type']">
                            <option disabled selected :value="undefined">Select a player type</option>
                            <option v-for="license in licenses" :value="license.type">{{ license.type }}</option>
                        </select>
                    </sc-form-field>
                </template>

                <template #field-frameset-template-id>
                    <sc-form-field :form="form" field="frameset-template-id" name="Frameset template">
                        <select v-model="form.fields['frameset-template-id']"
                                :data-placeholder="!form.fields['frameset-template-id']">
                            <option disabled selected :value="undefined">Select a frameset template</option>
                            <optgroup v-for="group in templateGroups" :label="group.name"
                                      v-if="group.options.length > 0">
                                <option v-for="frameset in group.options" :value="frameset.id">{{
                                        frameset.name
                                    }}
                                </option>
                            </optgroup>
                        </select>
                    </sc-form-field>
                </template>

                <template #field-time-slots>
                    <sc-form-field :form="form" field="time-slots">
                        <div class="schedules">
                            <div class="schedule" v-for="(schedule,index) in form.fields['time-slots']">

                                <div class="sc-field-error" v-if="errors">
                                    <div v-for="error in errors">
                                        <template v-if="Array.isArray(error)">
                                            <div v-for="e in error">{{ e }}</div>
                                        </template>
                                        <template v-else>{{ error }}</template>
                                    </div>
                                </div>

                                <div class="time">
                                    <div class="checkbox" @click="toggleAllDay()">
                                        <div class="checkbox-label">
                                            All Day
                                        </div>
                                        <sc-table-checkbox :status="allDay"
                                                           color="light"></sc-table-checkbox>
                                    </div>

                                    <template v-if="!allDay">
                                        <div class="time-start time-select">
                                            <input type="time" v-model="schedule['start-time']"/>
                                        </div>
                                        <div class="divider">-</div>
                                        <div class="time-end time-select">
                                            <input type="time" v-model="schedule['end-time']"/>
                                        </div>
                                    </template>
                                </div>

                                <sc-days :selected="schedule.days"
                                         :format="(v) => {return v.substring(0,3).toLowerCase()}"
                                         @select="selectDay(index, $event)"/>
                            </div>
                        </div>
                    </sc-form-field>
                </template>
            </sc-form>
        </div>
    </div>
</template>

<script>

export default {
  name: "new",
  data() {
    return {
      running: false,

      framesetTemplates: [],
      licenses: [],
      schedules: [],
      allDay: 'active',
      errors: {},
      form: this.$sform.createForm({
        url: '/admin/players',
        fields: {
          'name': '',
          'player-type': {default: undefined},
          'polling-interval': {default: 1},
          'frameset-template-id': {default: undefined},
          'time-slots': {
            default: [
              {
                'start-time': '00:00',
                'end-time': '24:00',
                'days': ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']
              }
            ]
          }
        },
        failure: (e) => {
          if (e.response) {
            if (e.response.status === 422) {
              // Time slot issue
              let errs = [];
              for (let key in e.response.data.errors) {
                e.response.data.errors[key].forEach((err) => errs.push(err.replace('time-slots.0.days', 'Time slot days')));
              }

              this.$notify.error({'Time slot': errs});
              this.errors = errs;
            } else {
              this.$notify.error(e.response);
            }
          } else {
            this.$notify.error("Something went wrong");
          }
        }
      }),
      ready: false
    }
  },

  computed: {
    templateGroups() {
      let groups = [{name: 'landscape', options: []}, {name: 'portrait', options: []}, {name: 'other', options: []}];

      this.framesetTemplates.forEach((fs) => {
        if (fs.hasOwnProperty('orientation')) {
          groups.find(g => g.name === fs.orientation).options.push(fs);
        } else {
          groups[2].options.push(fs);
        }
      })

      return groups;
    }
  },

  mounted() {
    this.fetchFramesets();
    this.fetchFeatures();
  },

  methods: {
    fetchFramesets() {
      this.$talker.api.get('/admin/frameset-templates')
        .then((res) => {
          this.framesetTemplates = res.data;
        })
    },

    fetchFeatures() {
      this.$talker.api.get('/admin/players/feature-licenses-list')
        .then((res) => {
          this.licenses = res.data.licenses;
          this.ready = true;
        })
    },

    selectDay(index, day) {
      let schedule = this.form.fields['time-slots'][index];
      let dayIndex = schedule.days.indexOf(day);
      if (dayIndex === -1) {
        schedule.days.push(day)
      } else {
        schedule.days.splice(dayIndex, 1);
      }
    },

    toggleAllDay() {
      let newEndTime;
      if (this.allDay === 'active') {
        this.allDay = false;
        newEndTime = '23:59'
      } else if (this.allDay === false) {
        this.allDay = 'active'
        newEndTime = '24:00'
      }

      this.form.fields['time-slots'][0]['end-time'] = newEndTime;
    },

    post() {
      this.errors = {};
      this.form.post()
        .then((res) => {
          // Player created
          this.$router.push('/players')
        })
    },
  }
}
</script>

<style scoped lang="scss">
.player-container {
    box-sizing: border-box;
    background: #fff;
    padding: 24px;
    width: inherit;
}

.content-container {
    display: flex;


    .schedules {
        width: 100%;


        .schedule {
            align-items: center;

            .time {
                display: flex;
                height: 50px;
                align-items: center;

                .checkbox {
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    margin-right: 2.25em;

                    .checkbox-label {
                        width: 128px;
                    }
                }

                .divider {
                    text-align: center;
                }

                margin-bottom: .5em;
            }

            .divider {
                width: 3rem;
            }
        }
    }
}

select[data-placeholder='true'] {
    color: var(--color-tertiary) !important;;
}

.button-row {
    display: flex;
    justify-content: flex-end;
    height: 36px;
    box-sizing: border-box;
}

</style>