<template>
    <children-route :animate="true"></children-route>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>

</style>