<template>
    <div>
        <sc-search class="search" :query="query" @searched="searched"></sc-search>
        <div class="items">
            <div class="item" v-for="item in items.data" @click="select(item)"
                 :class="{active:selected === item.id}">
                <div class="item-inner">
                    <img :src="item.thumbnail_storage_path"/>
                    <div class="sub">
                        <div class="name"><span>Name:</span><span> {{ item.name }}</span></div>
                        <div class="detail"><span>Start:</span><span>{{ item.start_valid_date | toDate }}</span>
                        </div>
                        <div class="detail" v-if="item.end_valid_date">
                            <span>End:</span><span>{{ item.end_valid_date | toDate }}</span></div>
                        <expire-checker :item="item" class="detail"/>
                    </div>
                </div>
            </div>
        </div>

        <sc-modal v-if="addMedia" @close="addMedia = false">
            <add-media @success="newMediaSuccess" :tabs="['image']"></add-media>
        </sc-modal>

        <sc-paginate class="pages" :data="items" @changePage="changePage">
        </sc-paginate>
        <br/>
        <sc-button style="float:right;margin-right: 0;" @click="addMedia = true">New Image</sc-button>
    </div>
</template>

<script>
import MixinPaginate from '@/mixins/sc-pagination'
import AddMedia from "../media/add-media";
import ExpireChecker from "./expire-checker";

export default {
  name: "media-picker",
  components: {ExpireChecker, AddMedia},
  props: ['field', 'template', 'type'],
  mixins: [MixinPaginate],
  data() {
    return {
      items: [],
      sortingType: 'media',
      selected: undefined,
      addMedia: false,
      mediaOrientation: undefined
    }
  },

  mounted() {
    if (this.type === 'image')
      this.determineOrientation()

    this.fetch();

    if (this.field.value) {
      this.selected = this.field.value;
    }
  },

  methods: {

    select(item) {
      this.$emit('selected', this.field, item.id);
      this.selected = item.id;
    },

    determineOrientation() {
      let description = this.field.idents ? this.field.idents[0].description : undefined;
      if (!description) {
        let templateField = this.$store.getters['message/getTemplate'].template_fields.find(f => f.type === 'IMAGE')
        if (templateField.idents && templateField.idents[0].description) {
          description = templateField.idents[0].description;
        }
      }

      if (description) {

        let dimensions = description.replace(/px/g, '').split('x');
        if (dimensions.length === 2) {
          if (parseInt(dimensions[0]) > parseInt(dimensions[1])) {
            this.mediaOrientation = 'landscape'
          } else if (parseInt(dimensions[0]) < parseInt(dimensions[1])) {
            this.mediaOrientation = 'portrait'
          }
        }
      }
    },

    fetch() {
      let query = `&media_type=${this.type === 'image' ? 1 : 4}&status=approved,pending`
      if (this.mediaOrientation) query += `&orientation=${this.mediaOrientation}`;

      this.$talker.api(this.paginatedURL('media') + query)
        .then(res => {
          this.items = res.data;
        });
    },

    newMediaSuccess() {
      this.addMedia = false;
      this.fetch();
    }
  }
}
</script>

<style scoped lang="scss">
.items {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    border: 1px solid #ccc;
}

.item {
    padding: 5px;
    box-sizing: border-box;
    min-width: 100px;
    width: 25%;
    border: 2px solid transparent;
    position: relative;
}

.item-inner {
    width: 100%;
    background: #eee;
    border: 1px solid #ddd;
    height: 100%;
    padding: 2px 2px 2px;
    box-sizing: border-box;
}

.item img {
    max-height: 80px;
    object-fit: contain;
    width: 100%;
}

.item.active {
    border: 2px solid var(--color-primary);
}

.pages {
    border: 1px solid #ccc;
    border-top: 0;
}

.search {
    border-bottom: 0 !important;
}

.sub {
    padding: 2px;
    box-sizing: border-box;
    pointer-events: none;

    .name, .detail {
        font-size: .6em;
        margin-bottom: .25em;
        display: flex;
        justify-content: space-between;

        span:first-child {
            margin-right: .3em;
        }
    }
}

.clear {
    margin: 0;
    margin-bottom: 5px;
}

</style>
