<template>
    <div>
        <div class="main">
            <sc-field-valid-date-range  :TTL="TTL" :errors="errorMessage"
                                        @validFromChange="validFromChange"
                                        @validToChange="validToChange"
                                        :validFromDate.sync="validFromTimestamp"
                                        :validToDate.sync="validToTimestamp"/>

            <div class="fields pd media-info" v-if="!$route.path.includes('new')">
                <div><span class="text-lightgrey">Created on: </span>{{message.created_at | toDatetime}}</div>
                <div><span class="text-lightgrey">Created by: </span>{{media.creator.name}}</div>
                <div><span class="text-lightgrey">Last Modified: </span>{{message.updated_at | toDateHuman}}</div>
                <div><span class="text-lightgrey">Current Status: </span>{{media.status}}</div>
            </div>
        </div>
    </div>
</template>

<script>
  import ScFieldValidDateRange from "../../../../components/common/sc-field-valid-date-range";
  export default {
    name: "schedule",
    components: {ScFieldValidDateRange},
    data() {
      return {
        message: this.$store.getters['message/getMessage'],
        form: this.$store.getters['message/getForm'],
        validFromTimestamp: '',
        validToTimestamp: '',
      }
    },

    mounted() {
      if (typeof (this.form) === 'undefined') {
        this.$router.push('/messages');
        return;

      } else {
        this.validFromTimestamp = this.form.startValidDate ? this.form.startValidDate : undefined;
        this.validToTimestamp = this.form.endValidDate ? this.form.endValidDate : undefined;
      }
    },

    computed: {
      media() {
        const data = this.$store.getters['message/getMedia'];
        if (data) {
          return {
            creator: data.creator.profile,
            approver: data.approved === 'approved' ? data.approver.profile : undefined,
            status: data.approved
          }
        }
      },
      errorMessage() {
        return this.$store.getters['message/getErrors'];
      },
      TTL() {
        return typeof mfC !== 'undefined' ? mfC.ttl_message : process.env.TTL_MESSAGE;
      },
    },

    methods: {
      validFromChange(value) {
        this.$emit('changed')
        this.form.startValidDate = value ? moment(value).format('YYYY-MM-DD') : '';

        this.$store.dispatch('message/UPDATE_FORM', this.form);
        this.$store.dispatch('message/VALIDATE');
      },

      validToChange(value) {
        this.$emit('changed')
        this.form.endValidDate = value ? moment(value).format('YYYY-MM-DD') : '';

        this.$store.dispatch('message/UPDATE_FORM', this.form);
        this.$store.dispatch('message/VALIDATE');
      }
    }
  }
</script>

<style scoped lang="scss">

    .main {
        display: flex;
        margin-bottom: 20px;
        border: 1px solid #B9BDC4;
        font-size: 0.9em;
    }

    .fields {
        flex: 1;
        color: #1E242A;

        &:first-child {
            border-right: 1px solid #B9BDC4;
        }

        .field {
            &.hasErrors {
                .field-label {
                    color: red;
                }

                input {
                    border: 1px solid red;
                }

                .field-error {
                    color: red;
                    font-size: 0.7em;
                    margin-bottom: 5px;
                    margin-top: -5px;

                }
            }
        }
    }

    .range {
        display: flex;
        align-items: center;

        .divider {
            margin: .75em 1em 0;
        }
    }

    .button-row {
        display: flex;
        justify-content: flex-end;
        height: 36px;
        box-sizing: border-box;
    }

    .media-info {
        div {
            line-height: 2em;
        }
    }

</style>