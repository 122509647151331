<template>
    <div>
        <side-panel>
            <sc-search :query="query" @searched="nameSearch"></sc-search>
            <br/>
            <sc-filter title="type" type="radio" :options="['image','video']"
                       @filter="searched" :value.sync="mediaType"/>
            <sc-filter title="status" type="radio" :options="['pending','approved','rejected','expired']"
                       @filter="searched" :value.sync="status"/>
            <sc-filter title="orientation" type="radio" :options="['landscape','portrait']"
                       @filter="searched" :value.sync="orientation"/>
            <sc-filter title="Creator" type="search_select" :options="creatorNames" v-if="availableCreators.length > 0"
                       @filter="searched" :value.sync="creators"/>
            <sc-filter title="Tag" type="search_select" :options="availableTags" v-if="availableTags.length > 0"
                       @filter="searched" :value.sync="tags"/>
        </side-panel>

        <div class="pd">

            <breadcrumbs :items="['media library']"></breadcrumbs>

            <div class="table-buttons pd pdb0">
                <sc-button color="secondary" @click="addMedia = true"
                           v-if="$store.getters.UserHasPermission('create-media')"><awesome-icon icon="plus"/>Add media
                </sc-button>
                <sc-button color="secondary" v-if="$store.getters.UserHasPermission('create-message')"
                           @click="$router.push('/messages?action=new')"><awesome-icon icon="plus"/>New Message
                </sc-button>
            </div>

            <sc-table :table="table" class="table" @view="view">

                <template #column-thumbnail="{row}">

                    <sc-table-cell :table="table" field="thumbnail">
                        <span v-if="row.upload_finished_flag === 0">Processing file.</span>
                        <img class="image" v-else-if="row.thumbnail_storage_path"
                             :src="row.thumbnail_storage_path"/>
                    </sc-table-cell>

                </template>

                <template #column-name="{row}">
                    <sc-table-cell :table="table" field="name">
                        <div class="detail text-blue">
                            {{ row.name }}
                        </div>
                        <div class="detail" style="text-transform: capitalize">
                            {{ row.approved }}
                        </div>
                        <expire-checker :item="row"/>
                    </sc-table-cell>
                </template>

                <template #column-media_type="{row}">
                    <sc-table-cell :table="table" field="media_type">
                        <media-types :type="row.media_type"></media-types>
                    </sc-table-cell>
                </template>

                <template #column-details="{row}">
                    <sc-table-cell :table="table" field="details">
                        <div v-if="row.description" class="detail">
                            <span class="text-lightgrey">Description: </span> {{ row.description }}
                        </div>
                        <div v-if="row.width && row.height" class="detail">
                            <span class="text-lightgrey">Dimensions: </span> {{ row.width }}x{{ row.height }}
                        </div>
                        <div v-if="row.duration" class="detail">
                            <span class="text-lightgrey">Duration: </span> {{ row.duration }}
                        </div>
                        <div v-if="row.orientation" class="detail">
                            <span class="text-lightgrey">Orientation: </span> {{ row.orientation }}
                        </div>
                        <div v-if="row.creator" class="detail">
                            <span class="text-lightgrey">Creator: </span> {{ row.creator.profile.name }}
                        </div>
                        <div v-if="row.owner" class="detail">
                            <span class="text-lightgrey">Owner: </span> {{ row.owner.name }}
                        </div>
                        <div v-if="row.tags.length > 0" class="detail">
                            <span class="text-lightgrey">Tags: </span> {{ row.tags.map(t => t.name).join(', ') }}
                        </div>
                    </sc-table-cell>
                </template>

                <template #column-actions="{row}">
                    <sc-table-cell :table="table" field="actions">
                        <template #actions>
                            <sc-table-button v-if="row.permissions.update" @click.native.stop="edit(row)"
                                             tooltip="edit"
                                             icon="edit"/>
                            <sc-table-button-delete v-if="row.permissions.delete" :item="row" @destroy="table.fetch()">
                            </sc-table-button-delete>
                        </template>
                    </sc-table-cell>
                </template>

            </sc-table>
        </div>

        <sc-modal v-if="addMedia" @close="addMedia = false">
            <add-media @success="newMediaSuccess"></add-media>
        </sc-modal>

        <sc-table-media-preview v-if="previewing"
                                :item="previewing"
                                @edit="edit(previewing)"
                                @closePreview="previewing = undefined"></sc-table-media-preview>
    </div>
</template>

<script>

import AddMedia from "@/components/media/add-media";
import ExpireChecker from "@/components/common/expire-checker";
import ScTableButtonDelete from "@/components/common/table/sc-table-button-delete";

export default {
  name: "all",
  components: {ExpireChecker, AddMedia, ScTableButtonDelete},
  data() {
    return {
      addMedia: false,

      // Search / filters
      query: '',
      mediaType: undefined,
      orientation: undefined,
      creators: [],
      availableCreators: [],
      tags: [],
      availableTags: [],
      status: undefined,

      previewing: undefined,

      args: '',
      table: this.$stable.createTable({
        url: '/media',
        sortBy: 'updated_at',
        sortDir: 'desc',
        fetchArguments: this.searchArguments,
        columns: {
          thumbnail: {sort: false, name: 'item', width: 250},
          name: {fill: true, sort: false},
          media_type: {
            width: 120
          },
          details: {sort: false, fill: true},
          updated_at: {
            name: 'last modified',
            type: 'date'
          },
          created_at: {
            type: 'date'
          },
          actions: {sort: false}
        },
      }),
    }
  },

  computed: {
    creatorNames() {
      return this.availableCreators.map((c) => c.name).sort()
    }
  },

  mounted() {
    this.fetchFilters();
    this.searched();
  },

  methods: {
    edit(item) {
      if (item.media_type === 1) {
        this.$router.push(`media/edit/image/${item.id}`)
      } else if (item.media_type === 2) {
        this.$router.push(`messages/${item.id}/edit`)
      } else if (item.media_type === 4) {
        this.$router.push(`media/edit/video/${item.id}`)
      }
    },

    view(item) {
      this.previewing = item
    },

    nameSearch(value) {
      this.query = value;
      this.searched()
    },

    searched() {

      let args = [];
      if (this.query) {
        args.push(`where_name=${this.query}`)
      }

      if (this.mediaType) {
        const MEDIA_TYPES = {'image': 1, 'video': 4};
        args.push('media_type=' + MEDIA_TYPES[this.mediaType])
      } else {
        args.push('media_type=1,4')
      }

      if (this.orientation) {
        if (typeof this.orientation === 'string' && this.orientation !== 'all'
          || typeof this.orientation === 'object' && !this.orientation.includes('all')
        ) {
          args.push(`orientation=${this.orientation}`);
        }
      }

      if (this.status) {
        if (this.status === 'expired') {
          args.push(`valid=expired`);
        } else {
          args.push(`status=${this.status}`)
        }
      }

      if (this.creators && this.creators.length > 0) {
        let ids = this.creators.map(c => this.availableCreators.find(ac => ac.name === c).id)
        args.push(`creator=${ids.join(',')}`)
      }

      if (this.tags && this.tags.length > 0) {
        args.push(`tags=${this.tags.join(',')}`);
      }

      if (args.length > 0) {
        this.args = '&' + args.join('&');
      } else {
        this.args = '';
      }

      this.table.fetch()
    },

    searchArguments() {
      if (this.args.length > 0) {
        return this.args;
      }
    },

    newMediaSuccess() {
      this.addMedia = false;
      this.table.fetch();
    },

    fetchFilters() {
      // Fetch creators
      this.$talker.api.get('/user/list')
        .then((res) => {
          if (res.data.length > 0) {
            this.availableCreators = res.data
            this.availableCreators.sort();
          }
        })

      this.$talker.api.get('/tags')
        .then((res) => {
          if (res.data.length > 0) {
            this.availableTags = res.data.split('|').sort();
          }
        })
    }
  }
}
</script>

<style scoped>

.image {
    max-width: 100%;
    max-height: 100px;
    object-fit: contain;
    margin: 2px 0;
    box-sizing: border-box;
}

.table {
    width: 100%;
}

.table-buttons {
    background: #fff;
    display: flex;
}

.detail {
    margin-bottom: .25em;
}

</style>
