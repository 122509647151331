<template>
    <div class="days">
        <div class="day" v-for="day in days" @click="select(day)"
             :class="{selected:isSelected(day)}">
            {{ formattedDay(day) }}
        </div>
    </div>
</template>

<script>
export default {
  name: "sc-days",
  props: {
    days: {default: () => {return ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']}},
    selected: {default: []},
    format: {required: false, type: Function},
  },

  methods: {
    formattedDay(day) {
      if (typeof this.format !== 'undefined') {
        return this.format(day)
      } else {
        return day
      }
    },

    select(day) {
      this.$emit('select', day);
    },

    isSelected(day) {
        return this.selected.includes(day)
    }
  }

}
</script>

<style scoped lang="scss">

.days {
    display: flex;
    width: 100%;
    flex: 1;

    .day {
        text-transform: capitalize;
        padding: 0.8em 1em;
        background: var(--color-tertiary);
        color: #FFF;
        border: 1px solid var(--color-primary);
        margin-right: 0.2em;
        font-size: 0.8em;
        user-select: none;
        cursor: pointer;
        font-weight: 600;

        &.selected {
            background: var(--color-secondary);
        }
    }
}

</style>