<template>
    <div>
        <div class="width-container pd">
            <breadcrumbs :items="['Locations']"></breadcrumbs>

            <div class="table-buttons pd pdb0" v-if="$store.getters.UserHasPermission('create-user-group')">
                <sc-button color="secondary" @click="addingNew = true"><awesome-icon icon="plus"/>New Location</sc-button>
            </div>

            <div class="content-container pd">
                <div class="locations">
                    <div class="header">
                        <div class="cell">name</div>
                        <div class="cell desc">description</div>
                        <div class="cell">Players</div>
                        <div class="cell actions">actions</div>
                    </div>
                    <div class="rows">
                        <location v-for="location in locations" :location="location" :key="'location-'+location.id"
                                  @deleted="fetch"></location>
                    </div>
                </div>
            </div>
        </div>

        <sc-modal v-if="addingNew" @close="addingNew = false">
            <new-usergroup @done="addingNewSuccess" :locations="locations"></new-usergroup>
        </sc-modal>
    </div>
</template>

<script>

import NewUsergroup from "./components/new-usergroup";
import Location from "./components/location";
import ScTab from "../../../components/common/sc-tab";

export default {
  name: "all",
  components: {ScTab, Location, NewUsergroup},
  data() {
    return {
      addingNew: false,
      query: '',
      locations: []
    }
  },

  mounted() {
    this.fetch();
  },

  methods: {

    fetch() {
      this.$talker.api.get('/players/filtered-on-channel')
        .then((res) => {
          this.locations = res.data;
        })
    },

    addingNewSuccess() {
      this.addingNew = false;
      this.fetch();
    },

    view(item) {
      this.$router.push({path: item.id + '/edit', append: true})
    },
  }
}
</script>

<style scoped lang="scss">
.table-buttons {
    background: #fff;
    display: flex;
}

.content-container {
    background: #FFF;
}

.locations {
    border: 1px solid var(--color-secondary);
    border-bottom: none;
    min-width: 400px;


    .header {
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        background: var(--color-secondary);
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        z-index: 2;
        position: relative;
        color: #FFF;
        align-content: center;
        font-size: .7em;
        font-weight: 700;


        .cell {
            flex: 1;
            display: flex;
            text-transform: capitalize;
            align-items: center;
            border-right: 1px solid var(--color-secondary);
            padding: 14px 16px;

            &:first-child {
                max-width: 300px;
            }

            &:last-child {
                border-right: none;
                max-width: 128px;
            }

        }
    }

    .rows {
        font-size: .9em;
    }
}

@media only screen and (max-width: 500px) {
    .locations {
        .header {
            .cell {
                &.desc {
                    display: none;
                    flex: 0;
                    padding: 0;
                    border: none;
                }
                &.actions {
                    max-width: 40px;
                }
            }
        }
    }
}

</style>
