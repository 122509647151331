<template>
    <div>
        <breadcrumbs :items="['Create user']"></breadcrumbs>

        <sc-form @submit="submit" :form="form">

            <sc-loading :form="form"></sc-loading>
            <div class="container">
                <div class="panel">
                    <sc-form-field :form="form" field="name" name="Name*">
                        <input v-model="form.fields.name" ref="initialInput"/>
                    </sc-form-field>
                    <sc-form-field :form="form" field="a_number" :name="form.fields.sso_user ? 'A-Number*' : 'A-Number'" >
                        <input v-model="form.fields.a_number"/>
                    </sc-form-field>
                    <sc-form-field :form="form" field="email" name="Email*">
                        <input v-model="form.fields.email" type="email"/>
                    </sc-form-field>
                    <sc-form-field :form="form" field="department">
                        <input v-model="form.fields.department"/>
                    </sc-form-field>
                    <sc-form-field :form="form" field="jobtitle">
                        <input v-model="form.fields.jobtitle"/>
                    </sc-form-field>
                    <sc-form-field :form="form" field="role"name="Role*">
                        <select v-model="form.fields.role" v-if="roles">
                            <option :value="role.id" v-for="role in roles">{{ role.name }}</option>
                        </select>
                    </sc-form-field>
                    <sc-form-field :form="form" field="user_group" name="location*">
                        <location-list :location_id.sync="form.fields.user_group"></location-list>
                    </sc-form-field>
                </div>

                <div class="panel">
                    <sc-form-field :form="form" field="sso_user">
                        <sc-table-checkbox @toggle="form.fields.sso_user = !form.fields.sso_user"
                                           :status="form.fields.sso_user ? 'active' : 'none'" style="height:2.5em;"/>
                    </sc-form-field>

                    <template v-if="!form.fields.sso_user">
                        <sc-form-field :form="form" field="password"name="Password*">
                            <input v-model="form.fields.password" type="password"/>
                        </sc-form-field>

                        <sc-form-field :form="form" field="password_confirmed" name="Password Confirmation*">
                            <input v-model="form.fields.password_confirmation" type="password"/>
                        </sc-form-field>
                    </template>
                </div>
            </div>
        </sc-form>
    </div>
</template>

<script>

import LocationList from "@/components/locations/location-list";
// import ScTableCheckbox from "@/components/common/table/sc-table-checkbox";

export default {
  name: "new-user",
  components: {LocationList},
  data() {
    return {
      form: this.$sform.createForm({
        url: `admin/users`,
        fields: {
          name: '',
          password: '',
          password_confirmation: '',
          email: '',
          a_number: '',
          department: '',
          jobtitle: '',
          role: {default: 0},
          user_group: {default: 0},
          set_active_user_group: {default: true},
          sso_user: {default: true}
        },
      }),

      user_groups: [],
      roles: [],
    }
  },

  mounted() {
    this.fetch();
  },

  methods: {
    submit() {
      this.form.post()
    },

    fetch() {

      this.$talker.api.get('user-groups')
        .then(res => {
          this.user_groups = res.data;
        })

      this.$talker.api.get('roles')
        .then(res => {
          this.roles = res.data;
        })
    }
  }
}
</script>

<style scoped lang="scss">

.container {
    width: 100%;
    display: flex;
    .panel {
        flex: 1;
        &:first-child {margin-right: 1em}
        &:last-child {margin-left: 1em}
    }
}


</style>