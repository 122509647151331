<template>
    <div>
        <div class="width-container pd">
            <breadcrumbs :items="[{name:'User Roles',path:'/roles'}, 'edit']"></breadcrumbs>

            <div class="widget pd">
                <div class="header">
                    <span>{{ role }}</span>
                </div>

                <sc-tabs>
                    <sc-tab :active="tab === 'main'" @click.native="setTab('main')">Properties</sc-tab>
                    <sc-tab :active="tab === 'permissions'" @click.native="setTab('permissions')">Permissions</sc-tab>
                </sc-tabs>

                <div v-if="tab === 'main'">
                    <sc-form @submit="submit()" :form="form">
                        <template #field-hierarchy>
                            <sc-form-field :form="form" field="hierarchy">
                                <input v-model="form.fields.hierarchy" required type="number" :min="minHierarchy"/>
                            </sc-form-field>
                        </template>
                    </sc-form>
                </div>

                <div v-if="tab === 'permissions'" :padded="false">

                    <sc-form :form="{}" @submit="savePermissions">

                        <sc-search @searched="filter"></sc-search>

                        <div class="permissions">

                            <div class="permission selected" v-for="p in selected" @click="toggleSelection(p.id)">
                                <awesome-icon icon="check" class="icon"/>
                                {{ prettyName(p.name) }}
                            </div>

                            <div class="permission" v-for="p in available"
                                 @click="toggleSelection(p.id)">
                                {{ prettyName(p.name) }}
                            </div>
                        </div>
                    </sc-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ScPaginate from "../../../components/common/sc-paginate";
import MixinPaginate from '@/mixins/sc-pagination'
import ScFieldBoolean from "../../../components/common/sc-field-boolean";

export default {
  components: {ScFieldBoolean, ScPaginate},
  mixins: [MixinPaginate],
  data() {
    return {

      tab: 'main',

      role: undefined,

      form: this.$sform.createForm({
        fields: {
          name: '',
          hierarchy: '',
          locked: '',
        },
        url: `roles/${this.$route.params.role_id}`
      }),

      currentPermissions: this.$stable.createTable({
        url: `roles/${this.$route.params.role_id}`,
        sortBy: 'name',
        columns: {
          name: {
            name: 'current permissions (click to remove)',
            fill: true
          }
        }
      }),

      allPermissions: this.$stable.createTable({
        url: `permissions`,
        sortBy: 'name',
        columns: {
          name: {
            name: 'available permissions (click to add)',
            fill: true,
          }
        }
      }),

      permissionFilter: undefined,

      permissions: [],
      items: undefined,
      sortedItems: undefined,
      sortingType: 'players',
    }
  },

  computed: {
    minHierarchy() {
      if (this.$store.state.user.roles) {
        return this.$store.state.user.roles[0].hierarchy === 1 ? 2 : this.$store.state.user.roles[0].hierarchy
      } else {
        return 2;
      }
    },

    filtered() {
      let set = this.allPermissions.data;
      if (this.permissionFilter) {
        set = set.filter((p) => p.name.indexOf(this.permissionFilter) !== -1);
      }
      return set;
    },

    selected() {
      return this.sort(this.filtered.filter((p) => this.isSelected(p.id)))
    },

    available() {
      return this.sort(this.filtered.filter((p) => !this.isSelected(p.id)))
    },
  },

  mounted() {
    this.form.get()
      .then(res => {
        this.role = res.name;
      })


    this.$talker.api.get(`roles/${this.$route.params.role_id}`)
      .then(res => {
        this.currentPermissions.data = res.data.permissions.map(p => p.id);
        this.currentPermissions.hasData = true;

        this.currentPermissions.prefetch();
      });

    this.allPermissions.fetch();
  },

  methods: {
    sort(set) {
      return set.sort((a, b) => {
        let aSelected = this.isSelected(a.id);
        let bSelected = this.isSelected(b.id);
        if (aSelected && !bSelected) {
          return -1;
        }
        if (bSelected && !aSelected) {
          return 1;
        } else {
          return 0;
        }
      });
    },

    prettyName(name) {
      return name.replaceAll('-', ' ');
    },

    filter(value) {
      this.permissionFilter = value;
    },

    setTab(tab) {
      this.tab = tab;
    },

    toggleSelection(id) {

      if (this.$store.getters.UserHasPermission('update-role')) {
        let currentIndex = this.currentPermissions.data.findIndex(x => x === id);

        if (currentIndex === -1) {
          this.currentPermissions.data.push(id);
        } else {
          this.currentPermissions.data.splice(currentIndex, 1);
        }
      }
    },

    isSelected(id) {
      return this.currentPermissions.data.includes(id);
    },

    savePermissions() {
      if (this.$store.getters.UserHasPermission('update-role')) {

        let payload = {
          permissions: this.currentPermissions.data
        };

        this.$talker.api.put(`roles/${this.$route.params.role_id}/permissions/sync`, payload)
          .then(res => {
            this.$notify.success('Permissions saved')
          })
          .catch(err => {
            this.$notify.error('Failed to save permissions')
          })
      } else {
        this.$notify.error("Insufficient rights")
      }
    },

    submit() {
      this.form.put()
        .then(() => {
          this.$router.push('/roles')
        })
    }
  }
}
</script>

<style scoped lang="scss">

.permissions {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: .5em;
    grid-row-gap: .5em;
    margin-top: 1em;

    .permission {
        padding: .75em 1.25em;
        border-radius: .25em;
        border: 1px solid var(--color-secondary);
        text-transform: capitalize;
        cursor: pointer;
        display: flex;
        align-items: center;

        .icon {
            display: none;
        }

        &.selected {
            background: var(--color-secondary);
            color: #FFF;
            font-weight: 500;

            .icon {
                display: inline-block;
                margin-right: .75em;
            }
        }
    }
}
</style>
