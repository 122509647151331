<template>
    <div>
        <div class="pd">

            <breadcrumbs :items="['reports', $route.meta.name]"></breadcrumbs>

            <div class="widget pd">
                <sc-tabs>
                    <sc-tab :active="$route.meta.name === 'media usage'" to="/reports/media-usage">Media usage
                    </sc-tab>
                    <sc-tab :active="$route.meta.name === 'templates'" to="/reports/templates">Templates
                    </sc-tab>
                    <sc-tab :active="$route.meta.name === 'users'" to="/reports/users">Users
                    </sc-tab>
                    <sc-tab :active="$route.meta.name === 'user activity'" to="/reports/user-activity">User Activity
                    </sc-tab>

                </sc-tabs>

                <div class="panel ">
                    <children-route></children-route>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
  export default {
    name: 'index',
  }
</script>

<style scoped lang="scss">

    .panel {
        border: 1px solid #ccc;
    }

</style>