<template>
    <div class="panel pd">
        <div class="filter-container">
            <div class="name">Date</div>
            <select-day class="filter" @changed="changedDay"/>
        </div>


        <div class="filter-container">
            <div class="name">Time window</div>
            <select-time :padding="timePadding" :no-schedule="noSchedule" class="filter" v-if="timePadding"
                         @changed="changedTime">

                <div class="gaps">
                    <div class="timelines">
                        <div class="timeline-container" v-for="gap in gaps" v-if="gap.valid">
                            <div class="timeline" :style="{
                          left:gap.start + '%',
                          right:100 - gap.end + '%'
                        }">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="gaps playlist-schedule">
                    <div class="timelines">
                        <div class="timeline-container" v-for="gap in playlistScheduleTimelines">
                            <div class="timeline" :style="{
                          left:gap.start + '%',
                          right:100 - gap.end + '%'
                        }">
                                <div class="line">not playing</div>
                            </div>
                        </div>
                    </div>
                </div>


            </select-time>

        </div>


        <div class="items button-bar">
            <div class="item">
                <div class="checkbox" v-if="$store.getters.UserHasPermission('update-playlist')">
                    <sc-table-checkbox @click.native="checkAll()" :status="selectStatus"></sc-table-checkbox>
                </div>
                <div class="buttons-container">
                    <div class="buttons" :class="{active:selected.length > 0}">
                        <sc-button color="secondary" @click="editSelected()"
                                   v-if="$store.getters.UserHasPermission('update-playlist')">Edit schedules
                        </sc-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="items items-timelines">
            <div class="gaps partial">
                <div class="timelines">
                    <div class="timeline-container" v-for="gap in partialGaps">
                        <div class="timeline" :style="{
                          left:gap.start + '%',
                          right:gap.end + '%'
                        }">
                        </div>
                    </div>
                </div>
            </div>
            <div class="item" v-for="item in items">
                <div class="checkbox">
                    <sc-table-checkbox @click.native="check(item.original.id)"
                                       v-if="$store.getters.UserHasPermission('update-playlist')"
                                       :status="selected.includes(item.original.id)?'active':'false'"
                                       color="dark"></sc-table-checkbox>
                </div>
                <div class="item-name" @click="startEdit([item.original.id])">
                    <div class="image">
                        <img :src="item.original.media_item.thumbnail_storage_path"/>
                    </div>
                    <div class="name">
                        {{ item.original.media_item.name }}<br/>
                        <span v-if="item.original.media_item.approved !== 'approved'"
                              style="color:#ff6d72;font-size: .8em;text-transform:capitalize;">({{
                                item.original.media_item.approved
                            }})</span>
                    </div>
                </div>
                <div class="timelines" @click="startEdit([item.original.id])">
                    <div class="timeline-container" v-for="timeline in item.timelines">
                        <div class="timeline" :style="{
                          left:timeline.start + '%',
                          right:timeline.end + '%'
                        }">
                            <div class="time start">
                                <div class="continues" v-if="timeline.startContinues">
                                    <awesome-icon icon="angle-double-left"></awesome-icon>
                                    <template v-if="timeline.startValue">{{ timeline.startValue }}</template>
                                    <template v-else>
                                        <awesome-icon icon="infinity"></awesome-icon>
                                    </template>
                                </div>
                                <div>{{ timeline.startLabel }}</div>
                            </div>
                            <div class="line" :class="{pending:item.original.media_item.approved !== 'approved'}"></div>
                            <div class="time end">
                                <div class="continues" v-if="timeline.endContinues">
                                    <template v-if="!timeline.endValue">
                                        <awesome-icon icon="infinity"></awesome-icon>
                                    </template>
                                    <template v-else>{{ timeline.endValue }}</template>
                                    <awesome-icon icon="angle-double-right"></awesome-icon>
                                </div>
                                <div>{{ timeline.endLabel }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item empty" v-if="noSchedule">
                <div class="checkbox">

                </div>
                <div class="item-name">

                </div>
                <div class="timelines">
                    <div class="timeline-container">
                        <div>Player not scheduled</div>
                        <div>on {{ noSchedule }}s</div>
                    </div>
                </div>
            </div>
            <div class="item empty" v-else-if="items.length === 0">
                <div class="checkbox">

                </div>
                <div class="item-name">

                </div>
                <div class="timelines">
                    <div class="timeline-container">
                        No scheduled items
                    </div>
                </div>
            </div>
        </div>

        <playlist-item-edit v-if="editItems.length > 0"
                            :playlist="playlist"
                            :items="editItems"
                            :playlist_items="playlist_items"
                            @close="stopEditing"
        ></playlist-item-edit>

    </div>
</template>

<script>
import SelectDay from "./select-day";
import SelectTime from "./select-time";
import PlaylistItemEdit from "./PlaylistItemEdit";

export default {
  name: "PlaylistItemSchedule",
  components: {PlaylistItemEdit, SelectTime, SelectDay},
  data() {
    return {
      day: undefined,
      timeRange: undefined,
      playlist_items: [],
      items: [],
      editItems: [],
      playlist: undefined,
      selected: [],
      gaps: [],
      partialGaps: [],
      playlistScheduleTimelines: [],
      timePadding: undefined,
      noSchedule: undefined,
    }
  },

  mounted() {
    this.fetchPlaylist();
  },

  computed: {
    selectStatus() {
      if (this.selected.length > 0 && this.selected.length === this.items.length) {
        return 'active'
      } else if (this.selected.length > 0 && this.selected.length < this.items.length) {
        return 'half'
      } else {
        return false
      }
    }
  },

  methods: {
    fetchPlaylist() {
      this.$talker.api(`playlists/${this.$route.params.playlist_id}`)
        .then(res => {
          this.playlist = res.data;
        })
        .then(() => {
          this.fetch();
        })
        .then(() => {
          this.createPlaylistSchedule();
        })
    },

    createPlaylistSchedule() {
      if (!this.playlist) return;
      this.playlistScheduleTimelines = [];
      this.noSchedule = undefined;

      let schedule = this.playlist.timeslots;

      let padding = {
        start: 0,
        end: 0,
      }

      let selectedDay = this.day.date.format('dddd').toUpperCase();
      if (!schedule.timeslot_weekdays.includes(selectedDay)) {
        this.timePadding = padding;
        this.noSchedule = selectedDay
        this.playlistScheduleTimelines.push({start: 0, end: 100})
        return
      }

      if (schedule.timeslot_start_time) {
        let start = this.returnUnitFromTime(schedule.timeslot_start_time);
        let startOffset = start / 23.99 * 100

        if (start > 0) {
          padding.start = Math.floor(start)
          this.playlistScheduleTimelines.push({start: 0, end: startOffset})
        }
      }

      if (schedule.timeslot_end_time) {
        let end = this.returnUnitFromTime(schedule.timeslot_end_time);
        let endOffset = end / 23.99 * 100

        if (end > 0) {
          padding.end = Math.floor(24 - end);
          this.playlistScheduleTimelines.push({start: endOffset, end: 100})
        }
      }
      this.timePadding = padding;


    },

    fetch() {
      this.$talker.api(`playlists/${this.$route.params.playlist_id}/items`)
        .then(res => {
          this.playlist_items = res.data;
          this.playlist_items.sort((a, b) => {
            if (a.sort_order > b.sort_order) {
              return 1
            }
            if (a.sort_order < b.sort_order) {
              return -1
            }
            return 0
          })
        })
        .then(res => {
          this.parse();
        })
        .then(res => {
          this.createTotal();
        })

    },

    createTotal() {
      let gaps = [
        {start: 0, end: 100, valid: true}
      ];

      let timeRange = {start: "00.00", end: "23.99"};

      for (let i = 0; i < this.playlist_items.length; i++) {

        // check if the playlist item is on the selected day
        if (!this.playlistItemValid(this.day, this.playlist_items[i])) continue;
        if (!this.mediaItemValid(this.day, this.playlist_items[i].media_item)) continue;

        if (this.playlist_items[i].media_item.approved !== 'approved') {
          continue;
        }

        let timelines = this.getPlaylistTimelines(this.day, timeRange, this.playlist_items[i].schedule);

        if (!this.playlist_items[i].schedule || (this.playlist_items[i].schedule.length === 0)) {
          timelines.push({
            start: 0,
            end: 0
          })
        }

        // loop each timeline
        for (let t = 0; t < timelines.length; t++) {
          let timeline = timelines[t];


          if (timeline.start >= (100 - timeline.end)) continue;

          // compare the timeline to all gaps and split them up if necessary
          for (let g = 0; g < gaps.length; g++) {
            let gap = gaps[g];
            if (gap.valid === false) {
              continue;
            }

            // timeline is originally a percentage from 100, this needs to be from 0
            let timelineEnd = (100 - timeline.end);

            let newStartGap, newEndGap;

            // timeline is completely inside gap
            if (gap.start <= timeline.start && gap.end >= timelineEnd) {
              gap.valid = false;
              newStartGap = {start: gap.start, end: timeline.start, valid: true}
              newEndGap = {start: timelineEnd, end: gap.end, valid: true}
            }

            // gap only intersects with the start of the timeline
            else if (gap.start <= timeline.start && gap.end > timeline.start) {
              gap.valid = false;
              newStartGap = {start: gap.start, end: timeline.start, valid: true}
            }
              //
            // gap only intersects with the end of the timeline
            else if (gap.start < timelineEnd && gap.end >= timelineEnd) {
              gap.valid = false;
              newEndGap = {start: timelineEnd, end: gap.end, valid: true}
            }
              //
            // gap is completely inside the timeline
            else if (gap.start >= timeline.start && gap.end <= timelineEnd) {
              gap.valid = false;
            }

            if (newStartGap) {

              // make sure the difference is atleast bigger than a rounding error
              let startDiff = Math.abs(newStartGap.start - newStartGap.end);
              if (startDiff > 0.0001) {

                gaps.push(newStartGap);
              }
            }

            if (newEndGap) {

              // make sure the difference is atleast bigger than a rounding error
              let diff = Math.abs(newEndGap.start - newEndGap.end);
              if (diff > 0.0001) {

                // special edge case for the end of the day (25:59 vs 24:00)
                if (!(newEndGap.start > 99.95 && newEndGap.end === 100)) {

                  gaps.push(newEndGap);

                }
              }
            }
          }
        }
      }
      this.gaps = gaps;
      this.createPartialGaps();
      this.createPlaylistSchedule();
    },

    createPartialGaps() {
      if (!this.timeRange) return;
      this.partialGaps = [];
      for (let i = 0; i < this.gaps.length; i++) {
        let gap = this.gaps[i];
        if (!gap.valid) continue;

        let rangeStart = Number(this.timeRange.start)
        let rangeEnd = Number(this.timeRange.end)

        let timeWindow = rangeEnd - rangeStart;

        let startOffset = (gap.start / 100 * 23.99) - rangeStart;
        let endOffset = rangeEnd - (gap.end / 100 * 23.99);

        let start = startOffset / timeWindow * 100;
        let end = endOffset / timeWindow * 100;

        if (start >= 100 || end >= 100) {
          continue;
        }

        let timeline = {
          start: start,
          end: end,
        }

        if (timeline.start < 0) {
          timeline.start = 0;
          timeline.startContinues = true;
        }

        if (timeline.end < 0) {
          timeline.end = 0;
          timeline.endContinues = true;
        }
        this.partialGaps.push(timeline);
      }
    },

    parse() {
      this.items = [];
      let items = []

      if (!this.timeRange) return;
      if (!this.day) return;

      if (!this.playlist.timeslots) return;
      let selectedDay = this.day.date.format('dddd').toUpperCase();
      if (!this.playlist.timeslots.timeslot_weekdays.includes(selectedDay)) {
        return;
      }

      for (let i = 0; i < this.playlist_items.length; i++) {

        if (!this.playlistItemValid(this.day, this.playlist_items[i])) {
          continue;
        }

        if (!this.mediaItemValid(this.day, this.playlist_items[i].media_item)) {
          continue;
        }

        let timelines = this.getPlaylistTimelines(this.day, this.timeRange, this.playlist_items[i].schedule);

        let item = {};
        item['id'] = i;
        item['original'] = {...this.playlist_items[i]};
        item['timelines'] = timelines;

        if (!this.playlist_items[i].schedule || (this.playlist_items[i].schedule.length === 0)) {
          item['timelines'].push({
            start: 0,
            end: 0,
            startLabel: this.returnTime(this.timeRange.start),
            endLabel: this.returnTime(this.timeRange.end),
            startContinues: true,
            endContinues: true,
          })
        }

        if (item['timelines'].length > 0) {
          items.push(item)
        }
      }
      this.items = items;
    },

    getPlaylistTimelines(day, timeRange, schedule) {
      if (!schedule) return [];
      let timelines = [];
      let selectedDay = day.date.format('dddd').toUpperCase();

      for (let i = 0; i < schedule.length; i++) {

        // Check if the Selected day is in the Timeslot days

        if (!schedule[i].days.includes(selectedDay)) {
          continue;
        }

        let rangeStart = Number(timeRange.start)
        let rangeEnd = Number(timeRange.end)

        let timeWindow = rangeEnd - rangeStart;

        let startOffset = this.returnUnitFromTime(schedule[i].startTime) - rangeStart;
        let endOffset = rangeEnd - this.returnUnitFromTime(schedule[i].endTime);

        let start = startOffset / timeWindow * 100;
        let end = endOffset / timeWindow * 100;

        if (start >= 100 || end >= 100) {
          continue;
        }

        let timeline = {
          start: start,
          end: end,
          startLabel: schedule[i].startTime,
          endLabel: schedule[i].endTime,
        }

        if (timeline.start < 0) {
          timeline.start = 0;
          timeline.startContinues = true;
          timeline.startValue = schedule[i].startTime;
          timeline.startLabel = this.returnTime(timeRange.start);
        }

        if (timeline.end < 0) {
          timeline.end = 0;
          timeline.endContinues = true;
          timeline.endValue = schedule[i].endTime;
          timeline.endLabel = this.returnTime(timeRange.end);
        }

        timelines.push(timeline);
      }

      return timelines;
    },

    playlistItemValid(day, playlist_item) {

      if (playlist_item.valid_range !== 1) {
        return true
      }

      if (!playlist_item.start_valid_date) {
        return false
      }

      if (playlist_item.start_valid_date && day.date.isBefore(playlist_item.start_valid_date, 'day')) {
        return false;
      }

      if (playlist_item.end_valid_date && day.date.isAfter(playlist_item.end_valid_date, 'day')) {
        return false;
      }
      return true;
    },

    mediaItemValid(day, media_item) {

      // TODO Media item can be invalid but should still show the Playlist item in the list
      // With a 'expired' tag below the media item and a dashed timeline or something.

      if (!media_item.start_valid_date) {
        return false
      }

      if (media_item.start_valid_date && day.date.isBefore(media_item.start_valid_date, 'day')) {
        return false;
      }

      if (media_item.end_valid_date && day.date.isAfter(media_item.end_valid_date, 'day')) {
        return false;
      }

      return true;
    },

    changedDay(day) {
      this.day = day;
      this.parse();
      this.createTotal();
      this.selected = []
    },

    changedTime(time) {
      this.timeRange = time;
      this.parse();
      this.createPartialGaps();
      this.selected = []
    },

    returnUnitFromTime(time) {
      let parts = time.split(':');
      let hours = parts[0];
      let minutes = 0;
      if (parts[1] !== undefined) {
        let min = parts[1];
        if (min.charAt(0) === '0' && min.length > 1) {
          min = min.charAt(1);
        }
        minutes = min / 60;
      }
      let hour = Number(hours);
      let minute = Number(minutes);
      return hour + minute;
    },

    returnTime(float) {
      let str = String(float);
      let hours = str.split('.')[0];
      let minutes = '00';
      if (str.split('.')[1]) {
        let min = (str.split('.')[1]);
        if (min.charAt(0) === '0' && min.length > 1) {
          min = min.charAt(1);
        }
        minutes = Math.round(min / 100 * 60);
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      return hours + ':' + minutes;
    },

    editSelected() {
      this.editItems = this.selected;
    },

    checkAll() {
      if (this.selected.length === this.items.length) {
        this.selected = [];
      } else {
        this.selected = this.items.map(x => x.original.id)
      }
    },

    check(id) {
      let index = this.selected.indexOf(id);
      if (index === -1) {
        this.selected.push(id)
      } else {
        this.selected.splice(index, 1);
      }
    },

    startEdit(items) {
      this.editItems = items
    },

    stopEditing() {
      this.editItems = []
      this.selected = []
      this.fetch();
    },
  }
}
</script>

<style scoped lang="scss">
.panel {
    border: 1px solid #ccc;
}

.filter-container {
    display: flex;
    padding: 1.5em 0 1em;
    position: relative;

    .name {
        width: 15rem;
        font-weight: 600;
        font-size: 1em;
        padding-bottom: 1em;
        margin-top: 0.5em;
    }

    .filter {
        flex: 5;
    }
}

.items {
    margin-top: 1em;
}

.item {
    display: flex;
    align-items: stretch;

    .item-name {
        width: 12rem;
        cursor: pointer;
        display: flex;
        align-items: center;

        .image {
            width: 3em;
            margin-right: 0.8em;

            img {
                width: 3em;
                max-height: 3em;
                object-fit: contain;
                border: 1px solid #ccc;
            }
        }

        .name {
            margin-top: 0.1em;
            font-size: 0.7em;
            font-weight: 500;
            word-break: break-all;
        }
    }

    .timelines {
        flex: 4;
        display: flex;
        flex-direction: column;
        margin: 0 5em;
        padding: 0.5em 0;
        box-sizing: border-box;
        justify-content: center;
        cursor: pointer;
        background: #e3f0f5;


        .timeline-container {
            position: relative;
            height: 2em;
            /*margin: 0 5em;*/
            box-sizing: border-box;
            /*background: pink;*/
            z-index: 5;


            .timeline {
                height: 100%;
                position: absolute;
                display: flex;
                box-sizing: border-box;
                align-items: center;


                .time {
                    width: 4em;
                    position: absolute;

                    &.start {
                        transform: translateX(-4.6em);
                        text-align: right
                    }

                    &.end {
                        right: 0;
                        transform: translateX(4.6em);
                    }

                    div {
                        font-size: 0.9em;
                        font-weight: 700;
                        color: #2b4e71;

                        &.continues {
                            font-size: 0.7em;
                        }
                    }
                }

                .line {
                    /*background: pink;*/
                    flex: 1;
                    height: 1em;
                    position: relative;
                    border-left: 2px solid #0073aa;
                    border-right: 2px solid #0073aa;
                    box-sizing: border-box;

                    &:after {
                        content: '';
                        position: absolute;
                        top: calc(50% - 1px);
                        left: 0;
                        right: 0;
                        border-bottom: 2px solid #0073aa;
                    }

                    &.pending:after {
                        border-bottom: 2px dashed #0073aa;
                    }
                }
            }
        }
    }
}

.items-timelines {
    z-index: 2;
    position: relative;

    .item {
        border-top: 1px dashed rgba(0, 0, 0, 0.2);
    }
}

.empty {
    .timeline-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        line-height: 1.3em;
        align-items: center;
        padding: 2em 0;
    }
}

.gaps {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    transform: translateY(0%);

    .timelines {
        height: 3em;
        position: relative;

        .timeline-container {
            z-index: 4;

            .timeline {
                height: 100%;
                top: 0;
                background: rgba(238, 46, 46, 0.69);
                position: absolute;
                box-sizing: border-box;
                border-radius: 3px;
                min-width: 3px;
            }
        }
    }

    &.playlist-schedule {
        .timeline-container {
            .timeline {
                background: #f6f6f6;
                color: rgba(0, 0, 0, 0.4);
                display: flex;
                justify-content: center;
                padding-top: 0.6rem;
                text-transform: capitalize;
                overflow: hidden;
                white-space: nowrap;
                font-size: 0.7em;
                font-weight: 700;
                border-radius: 0;
            }
        }

    }

    &.partial {
        bottom: 0.2em;
        top: 0.2em;
        right: 5rem;
        left: 20rem;
        z-index: 3;
        pointer-events: none;

        .timelines {
            height: 100%;

            .timeline {
                background: rgba(255, 0, 0, 0.3);
                border-radius: 4px;
            }
        }
    }

}

.checkbox {
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-bar {

    margin-top: -2em;
}

.buttons-container {
    position: relative;
    width: 20em;

    .buttons {
        opacity: 0.1;
        pointer-events: none;
        transition: all .3s ease-in-out;
        display: flex;
        align-items: center;
        height: 2.5em;

        &.active {
            opacity: 1;
            pointer-events: initial;
        }
    }

}


</style>