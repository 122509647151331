<template>
    <div class="notification-panel" :class="{active:notifications.length > 0}">

<!--        <transition name="fade" v-if="notifications.length > 0" appear>-->
<!--            <div class="backdrop" @click="notifications = []"></div>-->
<!--        </transition>-->

        <div class="notifications">
            <notification-panel-item
                    v-for="(notification, index) in notifications"
                    :notification="notification"
                    :key="notification.id"
                    @done="done(index)">
            </notification-panel-item>
        </div>
    </div>
</template>

<script>
import NotificationPanelItem from "./notification-panel-item.vue";
import {events} from './events.js';

export default {
  components: {NotificationPanelItem},
  name: 'notification-panel',

  data() {
    return {
      notifications: [],
      current_id: 0,
    }
  },

  computed: {
    withErrors() {
      return this.notifications.filter(n => n.type === 'error').length > 0
    }
  },

  created() {
    events.$on('notification', this.trigger);
  },

  methods: {
    trigger(data) {
      this.current_id++;
      this.notifications.push({
        type: data.type,
        message: data.message,
        title: data.title,
        id: this.current_id,
      })

    },
    done(index) {
      this.notifications.splice(index, 1);
    }
  }
}
</script>

<style scoped>
.notification-panel {
    position: fixed;
    top: 8em;
    right: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notification-panel.active {
    z-index: 200;
}

</style>