<template>
    <div>
        <sc-modal @close="$emit('close')">

            <div class="modal-head">
                <breadcrumbs
                        :items="['manage content', this.playlist.name, 'edit', (item) ? 'playlist item' : 'multiple items']"></breadcrumbs>
                <div class="close">
                    <sc-button @click="$emit('close')" style="margin:0;">
                        <awesome-icon icon="times"/>
                    </sc-button>
                </div>
            </div>

            <playlist-items-preview v-if="form.fields['playlist-items'].length > 0"
                                    :items="form.fields['playlist-items'].map(i => getItem(i).media_item)"
                                    @remove="removeSelected"/>

            <sc-tabs>
                <sc-tab :active="tab === 'schedule'">
                    Scheduling
                </sc-tab>
            </sc-tabs>

            <template v-if="tab === 'schedule'">
                <div class="panel pd">
                    <div class="pickers">
                        <sc-field-valid-date-range :TTL="TTL" :errors="errors" v-if="items.length > 0 && ready"
                                                   @validFromChange="validFromChange"
                                                   @validToChange="validToChange"
                                                   :validFromDate.sync="form.fields['valid-range']['start-valid-date']"
                                                   :validToDate.sync="form.fields['valid-range']['end-valid-date']"
                                                   classes="no-border"/>
                    </div>

                    <div class="schedules-header">
                        Time schedules:
                    </div>

                    <div class="schedule-headers" v-if="schedules.length > 0">
                        <div class="schedule-header-time">
                            Time of day
                        </div>
                        <div class="divider"></div>
                        <div class="schedule-header-day">
                            Days
                        </div>
                    </div>

                    <div class="schedules">
                        <div class="schedule" v-for="(schedule,index) in schedules">
                            <div class="time">
                                <div class="field-error" v-if="errors && errors.schedules && errors.schedules[index]">
                                    {{ errors.schedules[index].time }}
                                </div>
                                <div class="time-start time-select">
                                    <input type="time" v-model="schedule['start-time']"/>
                                </div>
                                <div class="divider">-</div>
                                <div class="time-end time-select">
                                    <input type="time" v-model="schedule['end-time']"/>
                                </div>
                            </div>
                            <div class="divider"></div>
                            <div class="field-error" v-if="errors && errors.schedules && errors.schedules[index]">
                                {{ errors.schedules[index].days }}
                            </div>
                            <sc-days :selected="schedule.days"
                                     :format="(v) => {return v.substring(0,3).toLowerCase()}"
                                     @select="selectDay(index, $event)"/>

                            <div class="remove">
                                <sc-button color="secondary" @click="removeSchedule(index)" style="margin:0"
                                           :disabled="updating">Remove
                                </sc-button>
                            </div>
                        </div>
                        <div class="schedule-button">
                            <sc-button color="secondary" @click="addSchedule" :disabled="updating"
                                       v-if="$store.getters.UserHasPermission('update-playlist')">Add new
                            </sc-button>
                        </div>
                    </div>
                </div>

                <div class="buttons schedule">
                    <sc-button @click="submit()" color="secondary" style="text-align:center" :disabled="updating"
                               :running="updating"
                               v-if="$store.getters.UserHasPermission('update-playlist')">
                        {{ (item) ? 'update' : `overwrite all (${this.form.fields['playlist-items'].length})` }}
                    </sc-button>
                </div>
            </template>

        </sc-modal>
    </div>
</template>

<script>
import ExpireChecker from "../../../../components/common/expire-checker";
import ScFieldValidDateRange from "../../../../components/common/sc-field-valid-date-range";
import PlaylistItemsPreview from "./PlaylistItemsPreview";

export default {
  name: "PlaylistItemEdit",
  components: {PlaylistItemsPreview, ScFieldValidDateRange, ExpireChecker},
  props: {
    playlist: {},
    items: {},
    playlist_items: {}
  },

  data() {
    return {
      tab: 'schedule',

      validFromTimestamp: '',
      validToTimestamp: '',
      errors: {},
      updating: false,
      form: this.$sform.createForm({
        url: 'playlist-items/update',
        fields: {
          'playlist': parseInt(this.$route.params.playlist_id),
          'playlist-items': [],
          'valid-range': {
            'use-valid-range': false,
            'start-valid-date': new Date(),
            'end-valid-date': null
          },
          'time-schedules': null
        },
        failure: ((e) => {
          this.updating = false;
          if (e.response) {
            if (e.response.status === 422) {
              // Time slot issue

              let errs = {};
              let notifications = {};

              for (let key in e.response.data.errors) {
                if (key.includes('playlist-items')) {
                  let item_name = this.playlist_items[key.split('.')[1]].media_item.name;

                  ['startValidDate', 'endValidDate'].forEach((t) => {
                    if (errs.hasOwnProperty(t)) {
                      if (!errs[t].includes('Range invalid'))
                        errs[t] += '\n Range invalid.';
                    } else {
                      errs[t] = 'Range invalid.';
                    }
                  })

                  notifications[item_name] = e.response.data.errors[key];

                } else {

                  e.response.data.errors[key].forEach((err) => {
                    if (typeof err === 'object') {
                      let message = err.message.join('/\n');
                      notifications[err.field] = message;

                      if (err.field === 'Valid to') {
                        if (!errs.endValidDate) {
                          errs.endValidDate = message
                        } else {
                          errs.endValidDate += '\n' + message;
                        }

                      } else if (err.field === 'Valid from') {
                        if (!errs.startValidDate) {
                          errs.startValidDate = message
                        } else {
                          errs.startValidDate += '\n' + message;
                        }
                      } else {
                        errs[err.field] = message;

                      }
                    } else {
                      if (key.includes('time-schedules')) {
                        let i = key.split('.')[1];
                        if (!errs.hasOwnProperty('schedules')) errs.schedules = {};
                        if (!errs.schedules.hasOwnProperty(i)) errs.schedules[i] = {days: undefined, time: undefined};

                        let notificationKey = `Time Schedules row ${parseInt(i) + 1}`;
                        if (!notifications.hasOwnProperty(notificationKey)) notifications[notificationKey] = [];

                        if (key.includes('days')) {
                          errs.schedules[i].days = err.replace(`time-schedules.${i}.days`, 'Days')
                          notifications[notificationKey].push(err.replace(`time-schedules.${i}.days`, 'Days'));
                        } else {
                          errs.schedules[i].time = err.replace(`The Time Schedule Time Range is incorrect. `, '')
                          notifications[notificationKey].push(err.replace(`The Time Schedule Time Range is incorrect. `, ''))
                        }
                      }
                    }
                  });
                }
              }

              this.errors = errs;
              this.$notify.error(notifications);

            } else {
              this.$notify.error(e.response);
            }
          } else {
            this.$notify.error("Something went wrong");
          }
        })
      }),

      schedules: [],

      item: undefined,
      ready: false
    }
  },

  computed: {
    TTL() {
      return typeof mfC !== 'undefined' ? mfC.ttl_playlistitem: process.env.TTL_PLAYLISTITEM;
    }
  },

  filters: {
    dayFormat(val) {
      return val.substring(0, 3).toLowerCase()
    }
  },

  mounted() {
    for (let i = 0; i < this.items.length; i++) {
      this.form.fields['playlist-items'].push(this.items[i])
    }

    if (this.items.length === 1) {
      this.setItem(this.items[0]);
    }
    this.ready = true;
  },

  methods: {

    removeSelected(media_item, index) {
      let candidates = this.getRemovalCandidateIds(media_item.id);

      if (candidates.includes(this.form.fields['playlist-items'][index])) {
        this.form.fields['playlist-items'].splice(index, 1);
      }

      if (this.form.fields['playlist-items'].length === 0) {
        this.$emit('close')
      }
    },

    getRemovalCandidateIds(media_id) {
      return this.playlist_items.filter(i => i.media_item.id === media_id).map(c => c.id);
    },

    getItem(id) {
      return this.playlist_items.find(x => x.id === id)
    },

    setItem(id) {
      let item = this.playlist_items.find(x => x.id === id)

      if (item.schedule) {
        this.schedules = item.schedule;
        for (let i = 0; i < this.schedules.length; i++) {
          this.schedules[i]['start-time'] = this.schedules[i]['startTime']
          this.schedules[i]['end-time'] = this.schedules[i]['endTime']
        }
      }

      if (item.valid_range === 1) {
        let range = this.form.fields['valid-range'];
        range['start-valid-date'] = item.start_valid_date;
        range['end-valid-date'] = item.end_valid_date;
        range['use-valid-range'] = true;
        this.$set(this.form.fields, 'valid-range', range)
      }

      this.item = item;
    },

    dateRangeValid() {
      this.errors = {};
      let emptyChecks = ['', null, undefined];

      if (emptyChecks.includes(this.form.fields['valid-range']['start-valid-date'])) {
        this.errors['startValidDate'] = 'Required field';
      }
      if (emptyChecks.includes(this.form.fields['valid-range']['end-valid-date'])) {
        this.errors['endValidDate'] = 'Required field';
      }

      return !emptyChecks.includes(this.form.fields['valid-range']['start-valid-date']) && !emptyChecks.includes(this.form.fields['valid-range']['end-valid-date']);
    },

    submit() {
      if (!this.dateRangeValid()) {
        return;
      }

      if (this.schedules.length > 0) {
        this.form.fields['time-schedules'] = this.schedules;
      } else {
        this.form.fields['time-schedules'] = null;
      }

      this.updating = true;

      this.form.put()
        .then(() => {
          this.updating = false;
          this.$emit('close')
        })
    },

    addSchedule() {
      this.schedules.push({
        'start-time': '09:00',
        'end-time': '17:00',
        'days': ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']
      })
    },

    removeSchedule(index) {
      this.schedules.splice(index, 1);
    },

    selectDay(index, day) {
      let schedule = this.schedules[index];
      let dayIndex = schedule.days.indexOf(day);
      if (dayIndex === -1) {
        schedule.days.push(day)
      } else {
        schedule.days.splice(dayIndex, 1);
      }
    },

    startValidCheck(d) {
      return moment(d).isSameOrBefore(this.validFromStartDate)
    },

    clearValid() {
      this.validToTimestamp = ''
      this.validFromTimestamp = ''
      this.form.fields['valid-range']['start-valid-date'] = ''
      this.form.fields['valid-range']['end-valid-date'] = ''
      this.form.fields['valid-range']['use-valid-range'] = false
    },

    validFromChange(value) {
      if (value) {
        this.form.fields['valid-range']['start-valid-date'] = moment(value).format('YYYY-MM-DD')
        this.form.fields['valid-range']['use-valid-range'] = true
      } else {
        this.form.fields['valid-range']['start-valid-date'] = ''
        if (!this.form.fields['valid-range']['end-valid-date']) {
          this.form.fields['valid-range']['use-valid-range'] = false
        }
      }
    },

    validToChange(value) {
      if (value) {
        this.form.fields['valid-range']['end-valid-date'] = moment(value).format('YYYY-MM-DD')
        this.form.fields['valid-range']['use-valid-range'] = true
      } else {
        this.form.fields['valid-range']['end-valid-date'] = ''
        if (!this.form.fields['valid-range']['start-valid-date']) {
          this.form.fields['valid-range']['use-valid-range'] = false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.cols {
    display: flex;
    padding: 0.5em;
    box-sizing: border-box;

    .col {
        flex: 1;

        &:first-child {
            margin-right: 0.5em;
        }
    }
}

.panel {
    border: 1px solid #ccc;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin: 2.5em 0 1em;

    > div {
        flex: 1;
    }
}


.step {
    text-transform: uppercase;
    font-size: .7em;
    font-weight: 700;
    margin-bottom: 0.5em;
}

.locked {
    pointer-events: none;
}

.valid-custom {
    margin-top: 1em;
    margin-bottom: 1.5em;

    label {
        font-size: 0.9em;
        margin-left: 0.75em;
    }
}

.pickers {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
    margin-top: 1.5em;


    .picker-name {
        font-size: 0.9em;
    }

    .divider {
        width: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1em;
    }
}

.schedules-header {
    border-bottom: 1px solid #ccc;
    margin-top: 2.5em;
    padding-bottom: 0.5em;
    margin-bottom: 1.5em;
}

.schedules {
    .schedule {
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;

        .time {
            display: flex;
            width: 210px;
            align-items: center;
            position: relative;

            .divider {
                text-align: center;
            }

            .time-select {
                font-size: 0.8em;

                &.time-start {
                    margin-right: 0.5em;
                }
            }
        }

        .divider {
            width: 5rem;
        }
    }
}

.schedule-headers {
    display: flex;
    font-size: 0.9em;
    margin-bottom: 0.5em;

    .schedule-header-time {
        width: 210px;
    }

    .divider {
        width: 5rem;
    }
}

.schedule-button {
    margin-top: 1.5em;
    margin-bottom: 2em;
}

.modal-head {
    display: flex;
    justify-content: space-between;
}

.clear {
    padding: 1em;
    text-transform: uppercase;
    font-size: 0.7em;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
    margin-top: 1em;
    margin-left: 3em;

    &:hover {
        background: #eee;
        cursor: pointer;
        color: #000;
    }
}

.media-image {
    max-width: 100%;
    height: 5em;
    object-fit: contain;
}

.field-error {
    position: absolute;
    color: #c10000;
    font-size: 0.7em;
    //margin-top: 5px;
    top: -1em;
}

.detail {
    margin-bottom: .25em;
}
</style>