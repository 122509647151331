<template>
    <div>
        <children-route :animate="false"></children-route>
    </div>
</template>

<script>
  export default {
    name: "index"
  }
</script>

<style scoped>

</style>