import _index from './index.vue'
import _all from './all.vue'
import _edit from './edit.vue'

const routes = {
  path: 'locations',
  meta: {name: 'locations'},
  component: _index,
  children: [
    {
      path: '',
      meta: {name: 'all'},
      component: _all,
    },
    {
      path: ':usergroup_id/edit',
      meta: {edit: true, name: 'edit'},
      component: _edit,
    },
  ]
};

export default routes;
