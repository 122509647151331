import { render, staticRenderFns } from "./sc-footer.vue?vue&type=template&id=4be43314&scoped=true&"
import script from "./sc-footer.vue?vue&type=script&lang=js&"
export * from "./sc-footer.vue?vue&type=script&lang=js&"
import style0 from "./sc-footer.vue?vue&type=style&index=0&id=4be43314&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4be43314",
  null
  
)

export default component.exports