<template>
    <div class="search">
        <input v-model="text" type="search" placeholder="Search..." @input="searched()"/>
        <div class="btn" @click="searched()"><awesome-icon icon="search"/></div>
    </div>
</template>

<script>
  export default {
    name: "sc-search",
    props: ['query'],

    data() {
      return {
        text: '',
        timeout: undefined,
      }
    },

    watch: {
      query: {
        handler(value) {
          this.text = value
        }
      }
    },

    mounted() {
      this.text = this.query;
      if (this.$route.query && this.$route.query.search) {
        this.text = this.$route.query.search;
      }
    },

    methods: {
      searched() {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$emit('searched', this.text)
        }, 300)
      },
    }

  }
</script>

<style scoped>
    .search {
        position: relative;
        border: 1px solid #ccc;
        box-sizing: border-box;
        padding-right: 30px;
        height: 30px;
    }

    .search input {
        border: none;
        height: 28px;
        box-sizing: border-box;
        padding: 0 10px;
        cursor: text;
        width: 100%;
    }

    .search .btn {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 30px;
        display: flex;
        cursor: pointer;
        background: #FFF;
        color: var(--color-secondary);
    }

    .search .btn svg {
        margin: auto;
    }
</style>