<template>
    <div class="location-list" :class="{selecting, fromBottom}">
        <div class="current" ref="selectBox" @click.stop="startSelecting">
            <template v-if="current">
                <div class="name">
                    {{current.name}}
                </div>
                <div class="toggle">
                    <awesome-icon icon="chevron-down"></awesome-icon>
                </div>
            </template>
            <template v-else>
                <slot></slot>
            </template>
        </div>
        <div class="select-list" v-if="selecting">
            <location-list-item :excluded_ids="excluded_ids" :selected="location_id" :parents="parents" @select="select"
                                :location="location"></location-list-item>
        </div>
        <transition name="fade">
            <div class="backdrop" v-if="selecting" @click.stop="selecting = false"></div>
        </transition>

    </div>
</template>

<script>
  import LocationListItem from "./location-list-item";

  export default {
    name: "location-list",
    components: {LocationListItem},
    props: {
      location_id: {},
      excluded_ids: {
        type: Array
      }
    },

    data() {
      return {
        location: {},
        current: undefined,
        selecting: false,
        parents: [],
        childrenParents: {},
        fromBottom: false,
      }
    },

    mounted() {
      this.fetch();
    },

    watch: {
      location_id(newVal, oldVal) {
        if (this.location.children) {
          this.current = this.findCurrent(newVal, this.location)
        }
      }
    },

    methods: {
      select(id) {
        this.$emit('update:location_id', id);
        this.selecting = false;
      },

      startSelecting() {
        if (!this.selecting) {
          this.fromBottom = (window.innerHeight - this.$refs.selectBox.getBoundingClientRect().top < 500 && window.innerHeight > 500);
          this.selecting = true;
        } else {
          this.selecting = false;
        }
        this.parents = [];
        this.openParents(this.location_id)
      },

      openParents(id) {
        if (this.childrenParents[id]) {
          this.parents.push(this.childrenParents[id])
          this.openParents(this.childrenParents[id])
        }
      },

      flatMap(location) {
        this.childrenParents[location.id] = location.parent_id
        for (let i = 0; i < location.children.length; i++) {
          this.flatMap(location.children[i])
        }
      },

      findCurrent(id, location) {
        if (id === location.id) {
          return location
        } else {
          for (let i = 0; i < location.children.length; i++) {
            let loc = this.findCurrent(id, location.children[i])
            if (loc) {
              return loc
            }
          }
        }
      },

      fetch() {
        this.$talker.api('user-groups')
          .then(res => {
            this.location = res.data;
            this.flatMap(this.location);
            if (this.location_id) {
              this.current = this.findCurrent(this.location_id, this.location)
            }
          })
      }
    }
  }
</script>

<style scoped lang="scss">
    .location-list {
        position: relative;

        &.selecting {
            z-index: 100;
        }

        .current {
            padding: 1em;
            height: 3.2em;
            font-size: 0.9em;
            box-sizing: border-box;
            border: 1px solid #aaa;
            color: var(--color-secondary);
            position: relative;
            z-index: 2;
            background: #fff;
            cursor: pointer;

            .toggle {
                position: absolute;
                right: 0.3em;
                top: 50%;
                transform: translateY(-50%);
                font-size: 0.7em;
                font-weight: 700;
            }
        }

        .select-list {
            background: #fff;
            border: 1px solid #aaa;
            border-top: 0;
            box-shadow: var(--shading);
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            z-index: 2;
            max-height: 450px;
            overflow-y: auto;
        }

        &.fromBottom .select-list {
            top: inherit;
            bottom: 100%;
            border-top: 1px solid #aaa;
            border-bottom: none;
        }

        .backdrop {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 1;
            background: rgba(0, 0, 0, 0.05);
        }
    }

    .fade-leave-active,
    .fade-enter-active {
        transition: opacity 0.8s var(--easing);
    }

    .fade-leave,
    .fade-enter-to {
        opacity: 1;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }


</style>