<template>

    <div class="width-container pd">
        <breadcrumbs :items="[{name:'Profanity Filter',path:'/badwords'}, 'edit']"></breadcrumbs>

        <div class="container">

            <sc-form @submit="put()" :form="form">
            </sc-form>
        </div>
    </div>
</template>

<script>
export default {

  data() {
    return {
      form: this.$sform.createForm({
        fields: {
          word: '',
        },
        url: `bad-words/${this.$route.params.badword_id}`
      }),
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.initialInput.focus();
    })
    this.form.get()
      .then(res => {
        this.form.fields.word = res.word;
      })
  },

  methods: {

    put() {
      if (this.$store.getters.UserHasPermission('update-bad-word')) {
        this.form.put()
          .then(res => {
            this.$router.push('/profanity-filter')
          })
      } else {
        this.$notify.error("Insufficient rights")
      }
    },

  }

}
</script>

<style scoped lang="scss">

.container {
    box-sizing: border-box;
    background: #fff;
    padding: 24px;
    width: inherit;
}

</style>
