<template>
    <div class="preview-items">
        <div class="preview-item" v-for="(item,index) in items" @click="$emit('remove',item,index)">

            <div class="image">
                <img :src="item.thumbnail_storage_path"/>
            </div>
            <div class="sub">
                <div class="name">
                    {{ item.name }} <br/>
                    <span v-if="item.approved !== 'approved'"
                          style="color:#ff6d72;font-size: .8em;text-transform:capitalize;">({{
                            item.approved
                        }})</span>
                </div>
                <div class="detail">
                    <span>Start:</span><span>{{ item.start_valid_date | toDate }}</span>
                </div>
                <div class="detail" v-if="item.end_valid_date">
                    <span>End:</span><span>{{ item.end_valid_date | toDate }}</span>
                </div>
                <expire-checker :item="item"/>
            </div>

            <div class="remove">
                <awesome-icon icon="times"/>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "PlaylistItemsPreview",
    props: {
      items: {required: true}
    }
}
</script>

<style scoped lang="scss">

.preview-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5em 0 1.5em;

    .preview-item {
        background: #fafafa;
        border: 1px solid #ccc;
        padding: 0.8em 1em;
        margin: 0 1em 1em 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: calc(20% - 1em);
        position: relative;
        cursor: pointer;

        .image {
            img {
                border: 1px solid #ccc;
                box-sizing: border-box;
                max-height: 4em;
                max-width: 10em;
                object-fit: contain;
            }
        }

        .sub {
            box-sizing: border-box;
            pointer-events: none;
            font-size: .8em;

            .name, .detail {
                margin-top: 0.2em;
                font-weight: 500;
                word-break: break-all;

                display: flex;
                justify-content: space-between;

                span:first-child {
                    margin-right: .3em;
                }
            }

            .name {
                margin-bottom: 0.5em;
            }
        }

        .remove {
            position: absolute;
            top: 0;
            right: 0;
            padding: 1em;
            color: #ccc;
            z-index: 1;
        }

        &:hover .remove {
            color: #111;
        }
    }
}

</style>