// import _profile from './profile.vue'
import _index from './index.vue'
import _all from './all.vue'
import _new from './new.vue'
import _edit from './edit.vue'
import _import from './tenant-cm-import'

const routes = {
  path: 'tenants',
  meta: {name: 'Tenants'},
  component: _index,
  children: [
    {
      path: '',
      component: _all
    },
    {
      path: 'new',
      component: _new
    },
    {
      path: ':id',
      component: _edit
    },
    {
      path: ':id/import-cm',
      component: _import
    }

  ]
};

export default routes;
