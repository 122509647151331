import _index from './index.vue'
import _all from './all.vue'

const routes = {
  path: 'home',
  meta: {name: 'home'},
  component: _index,
  children: [
    {
      path: '',
      meta: {name: 'all'},
      component: _all,
    },
  ]
};

export default routes;