<template>
    <div>
        <div class="width-container pd">
            <breadcrumbs :items="[{name: 'Manage Content', path: '/playlists'},'playlist', 'edit']"></breadcrumbs>

            <div class="widget pd">
                <div class="header">
                    <span v-if="playlist">{{ playlist.name }}</span>
                </div>

                <sc-tabs>
                    <sc-tab :active="tab === 'items'" @click.native="setTab('items')">Items</sc-tab>
                    <sc-tab :active="tab === 'timeline'" @click.native="setTab('timeline')">Timeline</sc-tab>
                </sc-tabs>

                <div v-if="tab === 'items'">

                    <playlist-item-list></playlist-item-list>

                </div>

                <div v-if="tab === 'timeline'">

                    <playlist-item-schedule></playlist-item-schedule>

                </div>
            </div>

        </div>


    </div>
</template>

<script>

import PlaylistItemList from "./components/PlaylistItemsList";
import PlaylistItemSchedule from "./components/PlaylistItemSchedule";

export default {
  name: 'show',
  components: {PlaylistItemSchedule, PlaylistItemList},
  data() {
    return {
      tab: 'items',
      playlist: undefined
    }
  },

  mounted() {
    this.fetch();
  },

  methods: {

    setTab(tab) {
      this.tab = tab;
    },

    fetch() {
      this.$talker.api(`playlists/${this.$route.params.playlist_id}`)
        .then(res => {
          this.playlist = res.data;
        });
    }
  }
}

</script>

<style scoped>

.panel {
    border: 1px solid #ccc;
}

.picklist {
    min-width: 20em;
}

</style>
