<template>
    <div>
        <breadcrumbs :items="['Create role']"></breadcrumbs>

        <sc-form @submit="form.post()" :form="form">
            <template #field-hierarchy>
                <sc-form-field :form="form" field="hierarchy">
                    <input v-model="form.fields.hierarchy" required type="number" :min="minHierarchy"/>
                </sc-form-field>
            </template>
        </sc-form>
    </div>
</template>

<script>

export default {
  name: "new-role",
  data() {
    return {
      form: this.$sform.createForm({
        fields: {
          name: '',
          hierarchy: '',
          locked: false,
        },
        success: () => {
          this.$notify.success('Role created')
          this.$emit('done')
        },
        url: `roles`
      }),

      user_groups: [],
      roles: [],
    }
  },

  computed: {
    minHierarchy() {
      if (this.$store.state.user.roles) {
        return this.$store.state.user.roles[0].hierarchy === 1 ? 2 : this.$store.state.user.roles[0].hierarchy
      } else {
        return 2;
      }
    }
  },

  mounted() {},
}
</script>

<style scoped>

</style>