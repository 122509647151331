<template>
    <div>
        <div class="footer">
            <div class="spacer" :style="{height:height + 'px'}"></div>
            <div class="main" :style="{paddingLeft:margin + 'px'}" ref="footer">
                <div class="inner pdw">
                    <div class="bottom pdh">
                        <div class="left">
                            <div class="line">Scala {{app_name}}</div>
                            <div class="line"><span class="version">v.</span> {{version}}</div>
                        </div>
                        <div class="right">
                            <div class="img">
                                <img style="height:15px;" v-if="$store.state.user.theme === 'default'"
                                     :src="require('@/img/logo-black.png')"/>
                                <img style="height:15px;" v-if="$store.state.user.theme === 'dark'"
                                     :src="require('@/img/logo-white.png')"/>
                            </div>
                            <div class="line">© SCALA {{ year }}. All rights reserved</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
  name: "sc-footer",
  props: ['margin'],

  data() {
    return {
      height: 0
    }
  },

  computed: {
    app_name() {
      return process.env.VUE_APP_APP_NAME
    },

    year() {
      return new Date().getFullYear()
    },

    version() {
      return process.env.VERSION
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.height = this.$refs.footer.offsetHeight
    })
  }

}
</script>

<style scoped lang="scss">
.footer {

}

.spacer {
    position: relative;
    margin-top:0em;
}

.main {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: padding-left 0.4s $easing;
    background: rgba(255, 255, 255, 0.3);
    color: rgba(0, 0, 0, 0.4);
}

.theme--dark .main {
    background: rgba(0, 0, 0, 0.08);
    color: rgba(255, 255, 255, 0.4);
}

.inner {
    font-weight: 600;
    font-size: 0.8em;
    line-height: 1.4em;
}

.bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.right {
    text-align: right;
}

.version {
    font-weight: 800;
}
</style>