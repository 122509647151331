<template>
    <div class="paginator-container">
        <div v-if="paginationData && paginationData.last_page > 1">
            <div class="paginator pdw" v-if="paginationData" :class="{firstPage, lastPage}">
                <div class="item-count">{{ paginationData.total }} items</div>
                <div class="buttons">
                    <div class="button toFirstpage" @click="changePage('first')">
                        <awesome-icon icon="angle-double-left"></awesome-icon>
                    </div>
                    <div class="button toPrevious" @click="changePage('previous')">
                        <awesome-icon icon="angle-left"></awesome-icon>
                    </div>
                    <div class="pages">
                        {{ paginationData.current_page }} / {{ paginationData.last_page }}
                    </div>
                    <div class="button toNext" @click="changePage('next')">
                        <awesome-icon icon="angle-right"></awesome-icon>
                    </div>
                    <div class="button toLastpage" @click="changePage('last')">
                        <awesome-icon icon="angle-double-right"></awesome-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="loader" v-if="loadingCount > 0"></div>
    </div>
</template>

<script>
export default {
  name: "sc-paginate",
  props: {
    data: {},
    loading: {
      type: Number,
      default: 0
    },
    table: {}
  },

  computed: {
    paginationData() {
      if (this.table) {
        return this.table.data
      } else if (this.data) {
        return this.data
      }
    },
    lastPage() {
      return (this.paginationData.current_page === this.paginationData.last_page)
    },
    firstPage() {
      return (this.paginationData.current_page === 1)
    },
    loadingCount() {
      if (this.table) {
        return this.table.loading;
      } else if (this.loading) {
        return this.loading;
      } else {
        return false
      }
    }
  },

  methods: {
    changePage(target) {
      if (this.table) {
        this.table.changePage(target);
      } else {
        if (target === 'last') {
          this.$emit('changePage', this.paginationData.last_page)
        } else {
          this.$emit('changePage', target)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.paginator-container {
  position: relative;
}

.inline-paginator .widget {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.paginator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 0.8em;
  color: #bbb;
  padding-top: 8px;
  padding-right: 0;
  border: 1px solid #ccc;
  border-top: 0;
}

.inline-paginator .paginator-container {
  padding: 0;
}

.inline-paginator .paginator {
  padding-bottom: 16px;
}

.buttons {
  align-items: center;
  color: #888;
}

.buttons .pages {
  padding: 0 10px;
  font-weight: 700;
  color: #888;
  min-width: 55px;
  text-align: center;
}

.buttons .button {
  padding: 15px 15px;
  cursor: pointer;
  transition: all .15s ease-in-out;
}

.buttons .button:hover {
  background: #f0f0f0;
  color: #000;
}

.firstPage .toPrevious, .firstPage .toFirstpage, .lastPage .toNext, .lastPage .toLastpage {
  pointer-events: none;
  cursor: inherit;
  color: #ddd;
}

.theme--dark {
  .buttons {
    color: #eee;
  }

  .buttons .button:hover {
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
  }

  .firstPage .toPrevious, .firstPage .toFirstpage, .lastPage .toNext, .lastPage .toLastpage {
    pointer-events: none;
    cursor: inherit;
    color: #888;
  }
}

.loader {
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  height: 4px;
  transition: background .15s ease-in-out;
  color: #fff;
  font-size: 1em;
  font-weight: 700;
  overflow: hidden;
  z-index: 5;
}

.loader:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  height: 100%;
  background: rgba(27, 39, 112, 0.4);
  animation: rowloader 1.5s ease-in-out infinite;
}

.theme--dark .loader:after {
  background: #d52e30;
}

@keyframes rowloader {
  0% {
    left: 0;
    width: 0;
    opacity: 0;
  }
  30% {
    left: 0;
    opacity: 1;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}

</style>