<template>
  <div class="breadcrumbs">
    <div class="breadcrumb" v-for="(item,index) in items">

      <div class="arrow"><awesome-icon icon="chevron-right"/></div>

      <template v-if="index === items.length -1">
        <div class="text current">{{ isObject(item) ? item.name : item }}</div>
      </template>

      <template v-else>
        <div class="text" v-if="!isObject(item)"> {{ item }}</div>
        <router-link v-else :to="item.path" class="text link">{{item.name}}</router-link>
      </template>

    </div>
  </div>
</template>

<script>
export default {
  name: "breadcrumbs",
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
      validator: (v) => {
        /**
         * Validate type and/or presence of items.
         * Accepted values are items:
         * - with type of 'string'
         * - with type of 'object' AND have the key, value pairs of name and path
         */
        for (let i = 0; i < v.length; i++) {
          if (['undefined','string'].includes(typeof v[i])) continue;
          if (!v[i].name || !v[i].path) return false;
        }
        return true;
      }
    }
  },
  methods: {
    isObject(item) {
      return typeof item === 'object'
    }
  }
}
</script>

<style scoped lang="scss">
.breadcrumbs {
  display: flex;
  padding: 10px 0 30px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.arrow {
  margin-right: 10px;
}

.text {
  font-weight: 500;
  text-transform: capitalize;
  border-bottom: 1px solid transparent;
  transition: border $easing .1s;
  box-sizing: border-box;
  transform: translateY(1px);

  &.link:hover {
    border-bottom: 1px solid currentColor;
  }
}

</style>