<template>
    <portal to="sc-modal">
        <div class="modal">
            <div class="modal-content pd">
                <slot></slot>
            </div>
            <div class="backdrop" @click="$emit('close')"></div>
        </div>
    </portal>
</template>

<script>
  export default {
    name: "sc-modal"
  }
</script>

<style scoped>
    .modal {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        /*display: flex;*/
        z-index: 100;
        padding: 6em 0 2em;
        overflow: auto;
        background: rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
    }

    .modal-content {
        background: #fff;
        margin: auto;
        border-radius: 4px;
        position: relative;
        z-index: 1;
        max-width: 1200px;
    }

    .backdrop {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
    }
</style>