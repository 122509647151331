import Vue from 'vue';
import VueRouter from 'vue-router';
import notify from './plugins/notification-panel'
import moment from 'moment-timezone'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// import FileSaver from 'file-saver'
import PortalVue from 'portal-vue'
import lodash from 'lodash'

window.moment = moment;
window._ = lodash;
// window.FileSaver = FileSaver;

Vue.use(notify);
Vue.use(VueRouter);
Vue.use(notify);
Vue.use(DatePicker);
Vue.use(PortalVue);

const files = require.context('./components/common/', true, /\.vue$/i);
files.keys().map(key => {
  Vue.component(key.split('/').pop().split('.')[0], files(key).default)
});

const filters = require.context('./filters', false, /\.js$/i);
filters.keys().map(key => {
  Vue.filter(key.split('/').pop().split('.')[0], require('./filters/' + key.split('/').pop().split('.')[0]).default)
});

const functions = require.context('./functions', false, /\.js$/i);
let _functions = {};
functions.keys().map(key => {
  _functions[key.split('/').pop().split('.')[0]] = require('./functions/' + key.split('/').pop().split('.')[0]).default
});
Vue.prototype.$functions = _functions;

import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(fas);
Vue.component('awesome-icon', FontAwesomeIcon);
