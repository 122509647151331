var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main"},[_c('div',{staticClass:"width-container left pd"},[_c('div',{staticClass:"title"},[(!_vm.displayModal)?_c('h2',[_vm._v("Please select a Template")]):_c('h2',[_vm._v("Enter message name")])]),_c('div',{staticClass:"options"},[(_vm.displayModal)?_c('sc-form',{attrs:{"form":{}},on:{"submit":_vm.continueTemplate}},[_c('sc-field',{attrs:{"field":"name"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.messageName),expression:"messageName"}],domProps:{"value":(_vm.messageName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.messageName=$event.target.value}}})])],1):_c('sc-table',{attrs:{"table":_vm.table},on:{"view":_vm.selectTemplate},scopedSlots:_vm._u([{key:"column-thumbnail",fn:function(ref){
var row = ref.row;
return [_c('img',{staticClass:"thumbnail",attrs:{"src":_vm.thumbnailPath(row.thumbnail_name)}})]}},{key:"column-name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"cell-content"},[_c('span',{staticClass:"text-blue"},[_vm._v(_vm._s(row.name))]),_c('br'),_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(row.approved))])])]}},{key:"column-details",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"cell-content"},[(row.description)?_c('div',[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Description: ")]),_vm._v(" "+_vm._s(row.description)+" ")]):_vm._e(),(row.width && row.height)?_c('div',[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Dimensions: ")]),_vm._v(" "+_vm._s(row.width)+"x"+_vm._s(row.height)+" ")]):_vm._e(),(row.duration)?_c('div',[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Duration: ")]),_vm._v(" "+_vm._s(row.duration)+" ")]):_vm._e(),(row.orientation)?_c('div',[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Orientation: ")]),_vm._v(" "+_vm._s(row.orientation)+" ")]):_vm._e()])]}},{key:"column-updated_at",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"cell-content"},[(row.updated_at)?_c('div',[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Last modified:")]),_vm._v(" "+_vm._s(row.updated_at)+" ")]):_vm._e(),(row.creator)?_c('div',[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Creator:")]),_vm._v(" "+_vm._s(_vm.profileName(row.creator))+" ")]):_vm._e()])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }