<template>
    <transition name="scroll">
        <div class="scroll-top" v-if="display" @click="scrollToTop">
            <awesome-icon icon="chevron-up"/>
        </div>
    </transition>
</template>

<script>
export default {
  name: "sc-scroll-top",
  data() {
    return {
      scrollY: 0
    }
  },
  computed: {
    display() {
      return this.scrollY > 100;
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.getScroll);
  },
  mounted() {
    window.addEventListener('scroll', this.getScroll);
  },
  methods: {
    getScroll() {
      this.scrollY = window.scrollY;
    },
    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }
}
</script>

<style scoped lang="scss">

.scroll-top {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 3rem;
    height: 3rem;
    background: rgba(0, 0, 0, .3);
    color: #FFF;
    z-index: 90;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .25em;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.scroll-top:hover {
    background: rgba(0, 0, 0, .5);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.scroll-enter-active, .scroll-leave-active {
    transition: transform .3s cubic-bezier(.25, .8, .25, 1);
}

.scroll-enter, .scroll-leave-to {
    transform: translateY(100px);
}

.scroll-enter-to, .scroll-leave {
    transform: translateY(0px);
}
</style>