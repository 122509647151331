var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('side-panel',[_c('sc-search',{attrs:{"query":_vm.query},on:{"searched":_vm.nameSearch}}),_c('sc-filter',{attrs:{"title":"orientation","type":"radio","options":['landscape','portrait'],"value":_vm.orientation},on:{"filter":_vm.searched,"update:value":function($event){_vm.orientation=$event}}}),_c('sc-filter',{attrs:{"title":"status","type":"radio","options":['pending','approved','rejected','expired'],"value":_vm.status},on:{"filter":_vm.searched,"update:value":function($event){_vm.status=$event}}}),(_vm.availableCreators.length > 0)?_c('sc-filter',{attrs:{"title":"Creator","type":"search_select","options":_vm.creatorNames,"value":_vm.creators},on:{"filter":_vm.searched,"update:value":function($event){_vm.creators=$event}}}):_vm._e()],1),_c('div',{staticClass:"pd"},[_c('breadcrumbs',{attrs:{"items":['messages']}}),_c('div',{staticClass:"table-buttons pd pdb0"},[(_vm.$store.getters.UserHasPermission('create-message'))?_c('sc-button',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$router.push('/messages?action=new')}}},[_c('awesome-icon',{attrs:{"icon":"plus"}}),_vm._v("New Message ")],1):_vm._e()],1),_c('sc-table',{attrs:{"table":_vm.table},on:{"view":_vm.view},scopedSlots:_vm._u([{key:"column-thumbnail",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"thumbnail"}},[(row.upload_finished_flag === 0)?_c('span',[_vm._v("Processing file.")]):(row.thumbnail_storage_path)?_c('img',{staticClass:"image",attrs:{"src":row.thumbnail_storage_path}}):_vm._e()])]}},{key:"column-name",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"name"}},[_c('div',{staticClass:"detail text-blue"},[_vm._v(" "+_vm._s(row.name)+" ")]),_c('div',{staticClass:"detail",staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(row.approved)+" ")]),_c('expire-checker',{staticClass:"detail",attrs:{"item":row}})],1)]}},{key:"column-details",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"details"}},[(row.description)?_c('div',{staticClass:"detail"},[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Description: ")]),_vm._v(" "+_vm._s(row.description)+" ")]):_vm._e(),(row.width && row.height)?_c('div',{staticClass:"detail"},[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Dimensions: ")]),_vm._v(" "+_vm._s(row.width)+"x"+_vm._s(row.height)+" ")]):_vm._e(),(row.duration)?_c('div',{staticClass:"detail"},[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Duration: ")]),_vm._v(" "+_vm._s(row.duration)+" ")]):_vm._e(),(row.orientation)?_c('div',{staticClass:"detail"},[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Orientation: ")]),_vm._v(" "+_vm._s(row.orientation)+" ")]):_vm._e(),(row.creator)?_c('div',{staticClass:"detail"},[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Creator: ")]),_vm._v(" "+_vm._s(row.creator.profile.name)+" ")]):_vm._e(),(row.owner)?_c('div',{staticClass:"detail"},[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Owner: ")]),_vm._v(" "+_vm._s(row.owner.name)+" ")]):_vm._e(),(row.tags.length > 0)?_c('div',{staticClass:"detail"},[_c('span',{staticClass:"text-lightgrey"},[_vm._v("Tag(s): ")]),_vm._v(" "+_vm._s(row.tags.map(function (t) { return t.name; }).join(', '))+" ")]):_vm._e()])]}},{key:"column-actions",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"actions"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(row.permissions.update)?_c('sc-table-button',{attrs:{"tooltip":"edit","icon":"edit"}}):_vm._e(),(row.permissions.delete)?_c('sc-table-button-delete',{attrs:{"item":row},on:{"destroy":function($event){return _vm.table.fetch()}}}):_vm._e()]},proxy:true}],null,true)})]}}])}),(_vm.$route.query.action === 'new')?_c('sc-modal',{on:{"close":_vm.closeNew}},[_c('breadcrumbs',{attrs:{"items":['Choose a Template']}}),_c('new-message')],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }