<template>
    <div class="button-container" :class="envClass">
        <button class="button" :class="[color, {running, disabled}]" @click.stop="$emit('click',$event)">
            <div class="text">
                <div class="icon" v-if="icon">
                    <awesome-icon :icon="icon"></awesome-icon>
                </div>
                <slot></slot>
            </div>
            <fade-in-transition>
                <div class="running" :class="[color]" v-if="running"></div>
            </fade-in-transition>
        </button>
    </div>
</template>

<script>
export default {
  name: "sc-button",
  props: {
    color: {},
    running: {},
    padded: {
      type: Boolean,
      default: false,
    },
    tabInline: {
      type: Boolean,
      default: false,
    },
    icon: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    envClass() {
      return 'env_'+process.env.VUE_APP_TARGET
    }
  }
}
</script>

<style scoped lang="scss">

.button-container {
    margin-right: 32px;


    .button {
        font-size: inherit;
        border: 1px solid #ccc;
        background: #fff;
        color: var(--color-button);
        padding: 0 30px;
        height: 40px;
        box-sizing: border-box;
        transition-property: color, box-shadow;
        transition-duration: .15s, 0s;
        transition-timing-function: ease-in-out;
        cursor: pointer;
        position: relative;
        display: inline-block;
        outline: none;
        overflow: hidden;

        &:hover {
            color: #111;
        }

        &:active {
            background: #eee;
        }

        &.secondary {
            background: var(--color-primary);
            color: #FFF;
            border: 1px solid var(--color-primary);

            &:hover {
                filter: brightness(var(--color-brightness));
            }

            &:active {
                background: var(--color-primary);
                border: 1px solid #026a9c;
            }
        }

        &.reject {
            background: #b44548;
            color: #eee;
            border: 1px solid #b44548;
            transition: filter var(--easing) var(--transition-duration);

            &:hover {
                filter: brightness(var(--color-brightness));
            }

            &:active {
                background: #96393c;
            }
        }

        .text {
            font-weight: 600;
            text-transform: capitalize;
            font-size: 0.9em;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        svg, i {
            margin-right: .5em;
        }

        &.disabled {
            color: #ccc;
            pointer-events: none;
        }

        &.running {
            pointer-events: none;

            &:after {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }

    &.disabled {
        color: #ccc;
        pointer-events: none;
    }

    &.running {
        pointer-events: none;

        &:after {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }


    &.env_ta {
        .button {
            border: none;
            border-radius: 5px;
            @include themed() {
                background: t('element-background');
                color: t('button-color');
                box-shadow: t('shading');
            }
            padding: 12px 16px 12px 16px;
            box-sizing: border-box;
            // box-shadow: 1px 3px 5px 1px rgba(0, 0, 0, 0.05);
            transition-property: color, box-shadow;
            transition-duration: .15s, 0s;
            transition-timing-function: ease-in-out;
            cursor: pointer;
            position: relative;
            display: inline-block;
            outline: none;
            overflow: hidden;
            margin: 0;

            &:hover {
                @include themed() {
                    color: t('button-color-hover');
                }
            }

            &:active {
                transform: translateY(1px);
            }

            &.disabled,
            &.running {
                color: #111;
                pointer-events: none;
            }
            &.running:after {
                background-color: rgba(250, 0, 0, 0.1);
            }

            &.secondary {
                @include themed() {
                    background: t('accent');
                }
                color: #f0f0f0;
                &:hover {
                    color: #FFF;
                }
                &.running {
                    color: #ddd;
                    &:after {
                        background-color: rgba(250, 255, 255, 0.3);
                    }
                }
            }

            &.dark {
                background: $color-secondary;
                color: #fff;
            }

            .text {
                font-weight: 700;
                text-transform: uppercase;
                font-size: 0.7em;
                user-select: none;
                display: flex;
                align-items: center;
            }

            .icon {
                width: 16px;
                text-align: left;
            }




        }

    }
}

</style>