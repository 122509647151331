<template>

    <div class="location" @click.prevent.stop="displayChildren = !displayChildren">
        <div class="row">

            <div class="cell name">
                <div class="wrapper" :style="nameOffset">
                    <awesome-icon :icon="displayChildren ? 'chevron-down' : 'chevron-right'" class="chevron"
                    v-if="hasChildren"/>
                    <div class="chevron" v-else/>
                    {{ location.name }}
                </div>
            </div>

            <div class="cell desc">
                {{ location.description }}
            </div>

            <div class="cell players">
                <div v-if="location.players.length > 0" class="player-line">Location players: {{
                        location.players.length
                    }}
                </div>
                <div v-if="childrenPlayerCount > 0" class="player-line nested">Nested players: {{
                        childrenPlayerCount
                    }}
                </div>
            </div>

            <div class="cell actions">
                <sc-table-button tooltip="edit" icon="edit" v-if="$store.getters.UserHasPermission('update-user-group')"
                                 @click.native.stop="$router.push(`/locations/${location.id}/edit`)"/>
                <sc-table-button-delete :item="location.name" @destroy="startDelete"
                                        v-if="$store.getters.UserHasPermission('delete-user-group')"/>
            </div>
        </div>

        <sc-modal v-if="deleteError" @close="deleteError=undefined">

            <div class="text">Unable to delete location: <span class="item">{{ location.name }}</span></div>
            <div class="text">{{ deleteError }}</div>
            <div class="buttons">
                <sc-button @click="deleteError = undefined">
                    Cancel
                </sc-button>
            </div>

        </sc-modal>

        <location v-if="displayChildren" v-for="child in location.children" :location="child" :depth="depth + 1"
                  :key="'location-'+child.id" @deleted="$emit('deleted')"></location>
    </div>

</template>

<script>

export default {
  name: "location",
  props: {
    location: {required: true},
    depth: {required: false, default: 0, type: Number}
  },
  data() {
    return {
      displayChildren: this.depth === 0,
      deleteError: undefined,
    }
  },
  computed: {
    hasChildren() {
      return this.location.children.length > 0
    },

    childrenPlayerCount() {
      let count = 0;
      for (let i = 0; i < this.location.children.length; i++) {
        count += this.childWidthChildrenHasPlayers(this.location.children[i])
      }
      return count;
    },

    nameOffset() {
      let offset = this.depth * 10;
      return {
        marginLeft: offset + 'px',
      }
    }
  },

  methods: {

    childWidthChildrenHasPlayers(child) {
      let count = 0;

      count = child.players.length;

      for (let i = 0; i < child.children.length > 0; i++) {
        count += this.childWidthChildrenHasPlayers(child.children[i])

      }

      return count;
    },

    startDelete() {
      let name = this.location.name;
      let id = this.location.id
      this.$talker.api.delete('/user-groups/' + this.location.id)
        .then(() => {
          this.$notify.success(`Location ${name} deleted`);
          this.$emit('deleted', id);
        })
        .catch((err) => {
          this.$notify.error('Something went wrong');
          this.deleteError = err.response.data;
        })
    }
  }
}
</script>

<style scoped lang="scss">

.location {
    position: relative;


    .row {
        box-sizing: border-box;
        position: relative;
        border-bottom: 1px solid var(--color-secondary);
        display: flex;
        transition-property: top, background;
        transition-duration: .2s, .15s;
        transition-timing-function: ease-in-out;

        @include themed() {
            background: t('element-background');
        }

        &:hover {
            cursor: pointer;
            background: #eee;
        }

        .cell {
            flex: 1;
            text-align: left;
            display: flex;
            align-items: center;
            border-right: 1px solid var(--color-secondary);
            padding: 14px 16px;

            .wrapper {
                text-align: left;
                display: flex;
                align-items: center;
                padding: 0;
                margin: 0;
            }

            &.players {
                flex-flow: column;
                align-items: flex-start;
                justify-content: center;

                .player-line {
                    margin-bottom: .3em;

                    &.nested {
                        text-indent: .75em;
                        margin-bottom: 0;
                        color: grey;
                    }
                }
            }

            &:last-child {
                border-right: none;
            }

            &.name {
                max-width: 300px;

                .chevron {
                    display: flex;
                    justify-content: center;
                    width: 1em;
                    margin-right: 10px;
                    flex: initial;
                    opacity: .7;
                    transition: all $easing .5s;
                }

                &:hover {
                    .chevron {
                        opacity: 1;
                    }
                }
            }

            &.actions {
                max-width: 128px;

                .action {
                    cursor: pointer;
                }
            }
        }
    }
}

.text {
    margin-bottom: 20px;
    text-align: center;
}

.text .item {
    font-weight: 800;
}

.buttons {
    justify-content: center;
}

@media only screen and (max-width: 500px) {
    .location {
        .row {
            .cell {
                &.desc {
                    display: none;
                }

                &.actions {
                    max-width: 40px;
                }
            }
        }
    }
}

</style>