<template>
    <div v-if="ready">
        <portal to="banner">
            <sc-page-header :title="`${name} - Edit`"/>
        </portal>

        <div class="buttons pd width-container pdb0">
            <sc-button class="back" @click="$router.push('/tenants')" icon="chevron-left">Back</sc-button>
        </div>

        <div class="width-container pdw">
            <form class="mcform">
                <div class=" sc-form widget pd" style="flex:1;">
                    <div class="header"><span>Details</span></div>

                    <section class="general">
                        <sc-field :field="form.fields.name" name="name *">
                            <input type="text" v-model="form.fields.name" required/>
                        </sc-field>

                        <sc-field :field="form.fields.sub_domain" name="domain *"
                                  :description="`https://${form.fields.sub_domain || 'my-domain'}.scala.eu`">
                            <input type="text" v-model="form.fields.sub_domain" placeholder="my-domain" required/>
                        </sc-field>

                        <sc-field name="AWS S3 Region *">
                            <select type="text" v-model="form.fields.aws_s3_region" required>
                                <option v-for="r in regions" :value="r.value">{{ r.label }}</option>
                            </select>
                        </sc-field>

                        <sc-field name="Activity Logging *">
                            <sc-form-field-boolean :field.sync="form.fields.activity_logging"/>
                        </sc-field>

                        <sc-field name="Approval Flow *">
                            <sc-form-field-boolean :field.sync="form.fields.approval_flow"/>
                        </sc-field>

                        <sc-field name="GDPR Compliant *">
                            <sc-form-field-boolean :field.sync="form.fields.gdpr_complaint"/>
                        </sc-field>

                    </section>

                    <section>
                        <div class="header"><span>Scala</span></div>
                        <sc-field :field="form.fields.scala_cm_url" name="Content Manager URL *">
                            <input type="url" v-model="form.fields.scala_cm_url" required/>
                        </sc-field>
                        <sc-field :field="form.fields.scala_cm_api_user_name" name="API User *">
                            <input type="text" v-model="form.fields.scala_cm_api_user_name" required/>
                        </sc-field>
                        <sc-field :field="form.fields.scala_cm_api_user_password" name="API Password *">
                            <input type="password" v-model="form.fields.scala_cm_api_user_password" required/>
                        </sc-field>
                        <sc-field :field="form.fields.scala_cm_network_name" name="Network Name *">
                            <input type="text" v-model="form.fields.scala_cm_network_name" required/>
                        </sc-field>
                    </section>

                    <section>
                        <div class="header"><span>Time To Live (in days)</span></div>

                        <sc-field name="TTL Password">
                            <input type="number" step="1" min="1" v-model="form.fields.ttl_password"/>
                        </sc-field>
                        <sc-field name="TTL Media">
                            <input type="number" step="1" min="1" v-model="form.fields.ttl_media"/>
                        </sc-field>
                        <sc-field name="TTL Messages">
                            <input type="number" step="1" min="1" v-model="form.fields.ttl_message"/>
                        </sc-field>
                        <sc-field name="TTL Playlist items">
                            <input type="number" step="1" min="1" v-model="form.fields.ttl_playlistitem"/>
                        </sc-field>
                    </section>

                    <sc-button color="secondary" :running="form.processing" type="submit" @clicked="post()" icon="save" disabled>
                        Submit
                    </sc-button>
                </div>

                <div class="settings" style="flex:1;">
                    <div class="sc-form widget pd">
                        <div class="header"><span>Settings - Mail</span></div>

                        <sc-field name="Use custom">
                            <sc-form-field-boolean :field.sync="form.fields.mail_custom"/>
                        </sc-field>

                        <section v-if="form.fields.mail_custom">
                            <h2>Mail Settings</h2>
                            <sc-field name="Host">
                                <input type="url" v-model="form.fields.mail_host"/>
                            </sc-field>
                            <sc-field name="Port">
                                <input type="number" v-model="form.fields.mail_port"/>
                            </sc-field>
                            <sc-field name="Username">
                                <input type="text" v-model="form.fields.mail_username"/>
                            </sc-field>
                            <sc-field name="Password">
                                <input type="text" v-model="form.fields.mail_password"/>
                            </sc-field>
                            <sc-field name="Encryption">
                                <input type="text" v-model="form.fields.mail_encryption"/>
                            </sc-field>
                            <sc-field name="Mail From Name">
                                <input type="text" v-model="form.fields.mail_from_name"/>
                            </sc-field>
                            <sc-field name="Mail From Address">
                                <input type="email" v-model="form.fields.mail_from_address"/>
                            </sc-field>
                        </section>
                    </div>

                    <div class="sc-form widget pd">
                        <div class="header"><span>Settings - SSO</span></div>
                        <sc-field name="Enable *">
                            <sc-form-field-boolean :field.sync="form.fields.sso"/>
                        </sc-field>

                        <section v-if="form.fields.sso">
                            <sc-field :field="form.fields.sso_provider" name="SSO Provider">
                                <select v-model="form.fields.sso_provider">
                                    <optgroup label="Azure">
                                        <option value="saml2_azure">SAML2</option>
                                        <option value="oauth_azure">OAUTH</option>
                                    </optgroup>
                                </select>
                            </sc-field>

                            <template v-if="form.fields.sso_provider === 'saml2_azure'">
                                <sc-field name="App ID">
                                    <input v-model="form.fields.saml2_azure_ipd_app_id" type="text"/>
                                </sc-field>
                                <sc-field name="X509">
                                    <input v-model="form.fields.saml2_azure_ipd_x509" type="text"/>
                                </sc-field>
                            </template>
                            <template v-if="form.fields.sso_provider === 'oauth_azure'">
                                <sc-field name="Key">
                                    <input v-model="form.fields.oatuh_azure_ipd_key" type="text"/>
                                </sc-field>
                                <sc-field name="Secret">
                                    <input v-model="form.fields.oauth_azure_ipd_secret" type="password"/>
                                </sc-field>
                            </template>
                        </section>
                    </div>

                    <div class="sc-form widget pd">
                        <div class="header"><span>Settings - Media</span></div>
                        <sc-field name="Customise">
                            <sc-form-field-boolean :field.sync="form.fields.media_custom"/>
                        </sc-field>
                        <section v-if="form.fields.media_custom">
                            <section style="flex:1;">
                                <div class="header"><span>Image</span></div>
                                <sc-field name="max_size (MB)">
                                    <input type="number" step="1" min="1" v-model="form.fields.image_max_size"/>
                                </sc-field>
                                <sc-field name="max_width (px)">
                                    <input type="number" step="1" min="1" v-model="form.fields.image_max_width"/>
                                </sc-field>
                                <sc-field name="max_height (px)">
                                    <input type="number" step="1" min="1" v-model="form.fields.image_max_height"/>
                                </sc-field>
                                <sc-field name="min_width (px)">
                                    <input type="number" step="1" min="1" v-model="form.fields.image_min_width"/>
                                </sc-field>
                                <sc-field name="min_height (px)">
                                    <input type="number" step="1" min="1" v-model="form.fields.image_min_height"/>
                                </sc-field>

                            </section>
                            <section style="flex:1;">
                                <div class="header"><span>Video</span></div>
                                <sc-field name="max_size (MB)">
                                    <input type="number" step="1" min="1" v-model="form.fields.video_max_size"/>
                                </sc-field>
                                <sc-field name="max_width (px)">
                                    <input type="number" step="1" min="1" v-model="form.fields.video_max_width"/>
                                </sc-field>
                                <sc-field name="max_height (px)">
                                    <input type="number" step="1" min="1" v-model="form.fields.video_max_height"/>
                                </sc-field>
                                <sc-field name="min_width (px)">
                                    <input type="number" step="1" min="1" v-model="form.fields.video_min_width"/>
                                </sc-field>
                                <sc-field name="min_height (px)">
                                    <input type="number" step="1" min="1" v-model="form.fields.video_min_height"/>
                                </sc-field>
                                <sc-field name="min_frame_rate">
                                    <input type="number" step="1" min="1"
                                           v-model="form.fields.video_min_frame_rate"/>
                                </sc-field>
                                <sc-field name="max_duration (seconds)">
                                    <input type="number" step="1" min="1" v-model="form.fields.video_max_duration"/>
                                </sc-field>
                            </section>
                        </section>
                    </div>
                </div>

            </form>
        </div>
    </div>
</template>

<script>
const AWS_REGIONS = [
  {label: "Frankfurt", value: "eu-central-1"},
  {label: "Ireland", value: "eu-west-1"},
  {label: "London", value: "eu-west-2"},
  {label: "Paris", value: "eu-west-3"},
  {label: "Stockholm", value: "eu-north-1"},
]
import axios from 'axios';

export default {
  name: "edit",
  data() {
    return {
      ready: false,
      tenant: {},
      form: this.$sform.createForm({
        fields: {
          // url: 'https://spoof.test/api/admin/tenant/'+this.$route.params.id,
          name: {},
          sub_domain: {
            name: 'domain'
          },
          "activity_logging": {default: true, type: "boolean"},
          "approval_flow": {default: false, type: "boolean"},
          "gdpr_complaint": {default: true, type: "boolean"},

          // SSO
          "sso": {default: false, type: 'boolean'},
          "sso_provider": {default: "saml2_azure"},

          // TTL
          "ttl_password": {default: 60},
          "ttl_media": {default: 90},
          "ttl_message": {default: 90},
          "ttl_playlistitem": {default: 90},

          // MEDIA
          "media_custom": false,
          "image_max_size": {default: 12},
          "image_max_width": {default: 1920},
          "image_max_height": {default: 600},
          "image_min_width": {default: 1920},
          "image_min_height": {default: 600},
          "video_max_size": {default: 60},
          "video_max_width": {default: 1920},
          "video_max_height": {default: 600},
          "video_min_width": {default: 1920},
          "video_min_height": {default: 600},
          "video_min_frame_rate": {default: 25},
          "video_max_duration": {default: 25},

          // Mail
          "mail_custom": {default: false},
          "mail_host": {},
          "mail_port": {default: 2525},
          "mail_username": {},
          "mail_password": {type: 'password'},
          "mail_encryption": {default: "tls"},
          "mail_from_name": {},
          "mail_from_address": {},

          // AMAZON
          "aws_s3_region": {default: "eu-west-1"},
          "saml2_azure_idp_x509": {},
          "saml2_azure_idp_app_id": {},

          // Scala
          "scala_cm_url": {},
          "scala_cm_api_user_name": {},
          "scala_cm_api_user_password": {},
          "scala_cm_network_name": {}
        }
      })
    }
  },

  computed: {
    name() {
      return this.tenant.name
    },

    regions() {
      return AWS_REGIONS;
    }

  },
  mounted() {
    axios.get(process.env.VUE_APP_URL + `/api/admin/tenant/${this.$route.params.id}`)
      .then((res) => {
        this.tenant = res.data
        this.form.fields = res.data;
        this.ready = true;
      })
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
section {
    margin-bottom: 2em;

    h2 {
        font-weight: 600;
        margin-bottom: .5em;
    }
}

.mcform {
    background: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2em;
    margin-top: 2em;

    .sc-form {
        margin: 0 0 2em;
        width: 100%;
    }
}

</style>