<template>
    <div>
        <div class="main" v-if="form">
            <div class="fields pd">
                <!-- OTHER FIELDS -->
                <div class="field" v-for="(field,i) in form.fields" :class="{hasErrors:hasErrors(field)}">
                    <div class="field-label" :class="{required: template.template_fields[i].required}">
                        {{ fieldLabel(template.template_fields[i]) }}
                    </div>

                    <div class="input">
                        <div class="field-error" v-if="fieldError(field)">{{ fieldError(field) }}</div>

                        <!-- TYPE === TEXT -->
                        <div class="string" v-if="checkType(field, 'BASIC')">
                            <input v-if="template.template_fields[i].widgetValues[0].maxLines === 1"
                                   v-model="field.value" @change="inputChanged"
                                   :maxlength="template.template_fields[i].widgetValues[0].maxCharacters"
                                   placeholder="Add / edit text here..."/>

                            <textarea v-else v-model="field.value" @change="inputChanged"
                                      :rows="template.template_fields[i].widgetValues[0].maxLines"
                                      :maxlength="template.template_fields[i].widgetValues[0].maxCharacters"
                                      placeholder="Add / edit text here..."/>

                            <div class="string-limits">
                                <div class="lines">
                                    Lines: {{ template.template_fields[i].widgetValues[0].maxLines }}
                                </div>
                                <div class="characters">
                                    Characters:
                                    {{
                                        field.value.length
                                    }}/{{ template.template_fields[i].widgetValues[0].maxCharacters }}
                                </div>
                            </div>
                        </div>

                        <!-- TYPE === RADIO // BOOLEAN -->
                        <div class="radios" v-else-if="checkType(field, 'RADIO')"
                             :class="{toggle: template.template_fields[i].widgetValues.length <= 2}">
                            <!-- Default first -->
                            <div class="radio" v-for="val in template.template_fields[i].widgetValues">

                                <input type="radio" :name="field.name" :id="`${field.name}-${val.id}`"
                                       v-model="field.value" :value="processedPickValue(field, val)"
                                       :selected="field.value === processedPickValue(field, val)" @change="inputChanged"/>

                                <label :for="`${field.name}-${val.id}`">
                                    {{ getLabelOrPickValue(val) }}
                                </label>
                            </div>
                        </div>

                        <!-- TYPE === IMAGE -->
                        <div class="image" v-else-if="checkType(field, 'IMAGE')">
                            <media-picker :field="field" @selected="selectedMedia"
                                          type="image"/>
                        </div>
                        <!-- TYPE === VIDEO -->
                        <div class="image" v-else-if="checkType(field, 'VIDEO')">
                            <media-picker :field="field" @selected="selectedMedia"
                                          type="video"/>
                        </div>

                        <!-- TYPE === SELECT -->
                        <div v-else-if="checkType(field, 'PICKLIST')" class="picklist">
                            <select v-model="field.value" @change="inputChanged">
                                <option v-for="val in template.template_fields[i].widgetValues" :value="val.pickValue">
                                    {{ getLabelOrPickValue(val) }}
                                </option>
                            </select>
                        </div>

                        <!-- TYPE === SLIDER (INTEGER) -->
                        <div v-else-if="checkType(field, 'SLIDER')" class="slider">
                            <div class="slider-value">
                                <input type="number" step="1" v-model="field.value"
                                       :min="template.template_fields[i].widgetValues[0].minValue"
                                       :max="template.template_fields[i].widgetValues[0].maxValue"
                                       @change="inputChanged"/>
                            </div>
                            <div class="slider-input">
                                <div class="slider-min">{{ template.template_fields[i].widgetValues[0].minValue }}</div>
                                <div class="slider-max">{{ template.template_fields[i].widgetValues[0].maxValue }}</div>
                                <input type="range" :min="template.template_fields[i].widgetValues[0].minValue"
                                       @change="inputChanged"
                                       :max="template.template_fields[i].widgetValues[0].maxValue"
                                       v-model="field.value"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="preview-container pd">
                <div class="preview-label">
                    <span class="text-lightgrey" style="margin-right: .5em;">Preview:</span>
                    <b>{{ $store.getters['message/getName'] }}</b>
                </div>

                <div class="preview">
                    <transition name="fade-in-transition">
                        <div class="loader" v-if="loadingPreview > 0"></div>
                    </transition>
                    <img v-if="previewUrl" :src="previewUrl"/>
                    <div v-else class="placeholder"></div>
                </div>

                <div class="get-preview-container">
                    <div class="get-preview" @click="getPreview(true)">refresh</div>
                </div>
            </div>
        </div>
        <div style="font-size: .8em;float:left;">* Required</div>
    </div>
</template>

<script>

export default {
  name: "message-form",

  data() {
    return {
      previewUrl: undefined,
      loadingPreview: 0,
      nameRequiredError: false,
      template: this.$store.getters['message/getTemplate'],
      form: this.$store.getters['message/getForm'],
      timeout: undefined
    }
  },

  computed: {
    errorMessage() {
      return this.$store.getters['message/getErrors'];
    }
  },

  beforeDestroy() {
    clearTimeout(this.timeout)
  },

  mounted() {
    if (typeof (this.template) === 'undefined' || typeof (this.form) === 'undefined') {
      this.$router.push('/messages');
      return;
    }

    if (this.template.permissions) {
      this.permissions = this.template.permission;
    }

    this.getPreview(true);
  },

  methods: {

    getLabelOrPickValue(value) {
      let label = value.pickValue;
      if (value.hasOwnProperty('idents') && value.idents.length > 0 && value.idents[0].hasOwnProperty('label')) {
        // TODO there's a language code available. HARD selecting first item in array now.
        label = value.idents[0].label;
      }
      return label;
    },

    fieldLabel(templateField) {
      return templateField.idents ? templateField.idents[0].label : templateField.displayName
    },

    selectedMedia(field, id) {
      field.value = id;
      this.getPreview();
    },

    hasErrors(field) {
      if (this.errorMessage && this.errorMessage.hasOwnProperty(field.name)) {
        return true
      }
    },

    fieldError(field) {
      if (this.errorMessage && this.errorMessage.hasOwnProperty(field.name)) {
        return this.errorMessage[field.name]
      }
    },

    processedPickValue(field, val) {
      if (['IMAGE','VIDEO'].includes(field.type)) {
        val = val.mediaItemId;
      } else {
        val = val.pickValue;
      }

      return val;
    },

    inputChanged(value) {
      // Update store
      this.$store.dispatch('message/UPDATE_FORM', this.form);
      this.$emit('changed')

      // Set timeout for getting preview
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.getPreview();
      }, 1000)
    },

    getPreview(mount) {
      if (!mount) {
        this.$store.dispatch('message/CLEAR_ERROR');
      }

      this.loadingPreview++;

      let payload = {...this.form};
      payload.name = this.uuidv4();

      this.$talker.api.post('message/live-message-preview', payload, {responseType: 'blob'})
        .then(res => {
          let reader = new FileReader();
          reader.readAsDataURL(res.data);
          reader.onload = () => {
            this.previewUrl = reader.result;
          }
          this.loadingPreview--;
        })
        .catch(err => {
          if (!mount) {

            if (!err.response.data) {
              let message = "Could not get preview. Potential bad words in message.";
              if (err.message) {
                message = err.message;
              }
              this.$notify.error(message)

            } if (err.response.data) {
              // Run error response through reader because it's a blob
              let reader = new FileReader();

              reader.readAsText(err.response.data);

              reader.onload = () => {
                // Read the blob now we can do stuff
                let errorMessage = {};
                let parsed = JSON.parse(reader.result);

                // Result available now.
                if (parsed.hasOwnProperty('errors')) {
                  let errors = parsed.errors;

                  for (const [key, value] of Object.entries(errors)) {
                    errorMessage[value[0].field] = value[0].message
                  }
                } else if (parsed.hasOwnProperty('message')) {
                  this.$notify.error(parsed.message)
                }


                this.$store.dispatch('message/SET_ERRORS', errorMessage)
              };
            }
          }

          this.loadingPreview--;
        })
    },

    checkType(_field, _type) {
      let field = this.template.template_fields.find(x => x.name === _field.name);
      if (field) {
        if (field.widgetType === _type || field.type === _type) {
          return true;
        }
      }
    },

    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
  }
}
</script>

<style scoped lang="scss">

.main {
    display: flex;
    margin-bottom: 20px;
    border: 1px solid #B9BDC4;
    font-size: 0.9em;
}

.fields {
    flex: 1;
    border-right: 1px solid #B9BDC4;
    color: #1E242A;
    padding-top: 0;
    padding-bottom: 0;
}

.field {
    padding: 2em 0;
    border-bottom: 1px solid #B9BDC4;
    display: flex;

    .input {
        width: 100%;
    }

    &:last-child {
        border-bottom: none;
    }

    .field-label {
        text-transform: capitalize;
        margin-bottom: 1em;
        margin-right: 1em;
        width: 100px;
        flex-shrink: 0;
        word-break: break-all;

        &.required {
            position: relative;

            &::before {
                display: block;
                content: '*';
                position: absolute;
                left: -.5em;
            }
        }
    }

    .radios {
        display: flex;
        align-content: space-between;
        flex-flow: row wrap;
        position: relative;
        margin-bottom: -1em;

        .radio {
            display: flex;
            align-items: center;
            margin-bottom: 1em;

            label {
                margin-right: 1.5em;
                cursor: pointer;
            }

            input {
                cursor: pointer;
                position: relative;
                display: block;
                margin-right: 1.5em;
                width: 0;
                height: 0;

                &::after {
                    display: block;
                    position: absolute;
                    top: -.75em;
                    left: -.125em;
                    width: .75em;
                    height: .75em;
                    content: ' ';
                    border-radius: 50%;
                    border: 3px solid var(--color-secondary);
                    //box-shadow: inset 0 0 0 var(--color-secondary);
                    background: var(--color-secondary);
                    transition: all var(--easing) .2s;
                }

                &:hover {
                    &::after {
                        background: rgba(255, 255, 255, 0.5);
                    }
                }

                &:checked {
                    &::after {
                        background: #FFF;
                    }
                }
            }
        }

        &.toggle {
            .radio {
                label {
                    position: relative;
                    background: var(--color-primary-light);
                    border: 1px solid var(--color-primary);
                    color: var(--color-primary);
                    display: block;
                    padding: .75rem 1.75rem;
                    margin: initial;
                    box-sizing: border-box;
                    font-weight: 500;
                }

                input {
                    position: absolute;
                    width: 0;
                    height: 0;
                    opacity: 0;

                    &:checked + label {
                        color: #FFF;
                        background: var(--color-secondary);
                        text-indent: .25rem;
                        font-weight: 500;
                        padding-right: 1.5rem;

                        &:before {
                            display: block;
                            content: '';
                            position: absolute;
                            transform: translateX(-.75rem) translateY(0) rotate(45deg);
                            height: 8px;
                            width: 4px;
                            border-bottom: 2px solid #fff;
                            border-right: 2px solid #FFF;
                        }
                    }
                }

                &:first-child label {
                    border-right: none;
                }
            }
        }
    }

    .string, .picklist {
        input, select, textarea {
            width: 100%;
            border: 1px solid #ccc;
            box-sizing: border-box;
            background: #f6f6f6;
            padding: 0 15px;
            font-weight: 500;
            font-size: .8em;
            height: 48px;
        }

        // Replace icon
        select {
            background-size: 18px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23fa8136;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;%7D%3C/style%3E%3C/defs%3E%3Ctitle/%3E%3Cg id='chevron-bottom'%3E%3Cline class='cls-1' x1='16' x2='7' y1='20.5' y2='11.5'/%3E%3Cline class='cls-1' x1='25' x2='16' y1='11.5' y2='20.5'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: calc(100% - 15px) 50%;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
        }

        textarea {
            padding: 10px 15px;
            font-size: 1em;
            resize: none;
            height: auto;
        }

        .string-limits {
            font-size: .8em;
            display: flex;
            justify-content: space-between;
            margin-top: .25rem;
        }
    }

    .slider {
        display: flex;

        .slider-value {
            position: relative;
            flex: 0;
            max-width: 100px;
            margin-right: 1em;

            input {
                position: relative;
                top: -.25em;
                padding: .25em;
                width: inherit;
                max-width: inherit;
                border: 1px solid #B9BDC4;
                background: #fafafa;
                text-align: center;
            }
        }

        .slider-input {
            flex: 3;
            position: relative;

            .slider-min, .slider-max {
                position: absolute;
                top: -1.5em;
                color: var(--color-primary);
                padding: .25em;
                border-radius: 3px;
                border: 1px solid #B9BDC4;
                opacity: .75;
                cursor: default;
                z-index: 0;
            }

            .slider-max {
                right: 0;
            }

            input[type=range] {
                position: relative;
                z-index: 1;
                -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
                width: 100%; /* Specific width is required for Firefox. */
                background: transparent; /* Otherwise white in Chrome */

                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                }

                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                }

                &:focus {
                    outline: none;
                }

                &::-ms-track {
                    width: 100%;
                    cursor: pointer;
                    /* Hides the slider so custom styles can be added */
                    background: transparent;
                    border-color: transparent;
                    color: transparent;
                }

                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    margin-top: -.75em; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
                    //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                    //border: 1px solid var(--color-secondary-light);
                    height: 2em;
                    width: 1em;
                    border-radius: 3px;
                    background: var(--color-secondary);
                    cursor: pointer;
                }

                &::-moz-range-thumb, &::-ms-thumb {
                    //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                    border: 1px solid #000000;
                    height: 2em;
                    width: 1em;
                    border-radius: 3px;
                    background: var(--color-secondary);
                    cursor: pointer;
                }

                &::-webkit-slider-runnable-track {
                    width: 100%;
                    height: .5em;
                    cursor: pointer;
                    //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
                    background: #fafafa;
                    border-radius: 1.3px;
                    border: 0.2px solid #B9BDC4;
                }


            }
        }
    }

    &.hasErrors {
        .field-error {
            color: #c10000;
            font-size: 0.7em;
            margin-bottom: 5px;
            margin-top: -5px;

        }
    }
}

.preview-container {
    flex: 1;

    .preview-label {
        text-transform: capitalize;
    }

    .preview {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: 2px solid #B9BDC4;
        background: #fafafa;
        margin: 2em 0;

        img {
            max-height: 600px;
            width: 100%;
        }

        .placeholder {
            width: 200px;
            height: 200px;
            background: #eee;
            border-radius: 3px;
        }
    }

    .get-preview-container {
        text-align: right;

        .get-preview {
            text-transform: uppercase;
            font-size: 0.6em;
            padding: 8px 10px;
            background: #fafafa;
            color: #555;
            border: 1px solid #ccc;
            display: inline-block;
            border-radius: 3px;
            margin-top: 5px;
            cursor: pointer;

            &:hover {
                color: #111;
                background: #fff;
            }
        }
    }
}

.fields, .preview-container {
    flex: 1;
    flex-shrink: 0;
    /*box-sizing: border-box;*/
}

.loader {
    position: absolute;
    top: -0px;
    left: 0;
    right: -2px;
    height: 6px;
    transition: background .15s ease-in-out;
    background: #ccc;
    color: #fff;
    font-size: 1em;
    font-weight: 700;
    overflow: hidden;
    z-index: 5;
}

.loader:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    height: 100%;
    background: rgba(27, 39, 112, 0.4);
    animation: rowloader 1.5s ease-in-out infinite;
}

@keyframes rowloader {
    0% {
        left: 0;
        width: 0;
        opacity: 0;
    }
    30% {
        left: 0;
        opacity: 1;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 0%;
    }
}

.fade-in-transition-enter-active {
    transition-property: opacity;
    transition-duration: var(--transition-duration);
    transition-timing-function: var(--easing);
}

.fade-in-transition-enter {
    opacity: 0;
}

.fade-in-transition-enter-to {
    opacity: 1;
}

.fade-in-transition-leave-active {
    transition-property: opacity;
    transition-duration: var(--transition-duration);
    transition-timing-function: var(--easing);
}

.fade-in-transition-leave {
    opacity: 1;
}

.fade-in-transition-leave-to {
    opacity: 0;
}

</style>

