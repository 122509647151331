import { render, staticRenderFns } from "./openDetails.vue?vue&type=template&id=56cd5414&scoped=true&"
import script from "./openDetails.vue?vue&type=script&lang=js&"
export * from "./openDetails.vue?vue&type=script&lang=js&"
import style0 from "./openDetails.vue?vue&type=style&index=0&id=56cd5414&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56cd5414",
  null
  
)

export default component.exports