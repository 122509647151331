<template>
    <div class="step"
         :class="{active: isActive, current: isCurrent}">
        <div class="dot"/>
        <div class="name">{{ step.label }}</div>
    </div>
</template>

<script>
export default {
  name: "step-default",
  props: {
    step: {required: true},
    isCurrent: {required: false, default: false},
    isActive: {required: false, default: false},
  }
}
</script>

<style scoped lang="scss">
.step {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 10em;

    .dot {
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        background: #eee;
        transition: all var(--easing) var(--transition-duration);
        transition-delay: calc(var(--transition-duration) / 2);
        box-shadow: inset 1px 1px 0 1px rgba(0, 0, 0, 0.05);


    }

    .name {
        margin-top: .75em;
        text-transform: capitalize;
    }

    &.active {

        .dot {
            background: var(--color-secondary);
            box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.5),
            inset -1px -1px 4px 1px rgba(0, 0, 0, 0.1);
        }
    }

    &.current {
        .name {
            font-weight: 500;
        }
    }
}
</style>