<template>
    <div class="sc-form-row" :class="envClass">
        <div class="form-label-row">
            <div class="sc-field-label" :class="{required}">
                <span v-if="name">{{ name | cleanLabel }}</span>
                <span v-else>{{ field | cleanLabel }}</span>
            </div>
            <div class="sc-field-error" v-if="errors">
                <div v-for="error in errors">
                    <template v-if="Array.isArray(error)">
                        <div v-for="e in error">{{ e }}</div>
                    </template>
                    <template v-else>{{ error }}</template>
                </div>
            </div>
            <div class="sc-field-description" v-if="description">
                <span>{{ description }}</span>
            </div>
        </div>
        <div class="input">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: "sc-field",
  props: {
    form: Object,
    field: {
      type: String,
      default: 'field'
    },
    description: {
      type: String
    },
    name: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    }
  },

  filters: {
    cleanLabel(value) {
      if (!value) return '';
      value = value.toString();
      value = value.charAt(0).toUpperCase() + value.slice(1);
      value = value.split('_').join(' ');
      value = value.split('-').join(' ');
      return value;
    }
  },

  computed: {
    envClass() {
      return 'env_'+process.env.VUE_APP_TARGET
    },
    errors() {
      if (this.form && this.form.errors.errors) {
        if (this.form.errors.errors.hasOwnProperty(this.field)) {
          return this.form.errors.errors[this.field];
        }
      }

    }
  }
}
</script>

<style scoped lang="scss">
.sc-form-row {
    &.env_app {
        margin: 0 0 32px;

        .form-label-row {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            margin: 8px 2% 0 0;
            padding: 0 0;
        }

        .sc-field-label {
            font-weight: 500;
            margin-bottom: 0.5em;
            display: flex;
            position: relative;
        }

        .sc-field-label.required::after {
            display: block;
            position: relative;
            top: -.25em;
            margin-left: .25em;
            content: '*';
        }

        .sc-field-label span {
            font-size: 0.8em;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.8);
            letter-spacing: 0.05em;
        }

        .sc-field-error {
            font-weight: 500;
            font-size: 0.8em;
            color: #c10000;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 0.7em;
            line-height: 1.5em;
        }

        .sc-field-description {
            margin: 2px 0 0;
        }

        .sc-field-description span {
            font-weight: 500;
            color: rgba(0, 0, 0, 0.7);
            font-size: 0.7em;
        }

        .sc-form-row .input {
            width: 100%;
            display: inline-block;
            box-sizing: border-box;
            max-width: calc(30% + 15em);
        }

        .sc-form-row input {
            outline: none;
            width: 100%;
            padding: 16px 0 16px 16px;
            line-height: 16px;
            font-size: 0.9em;
            box-sizing: border-box;
            color: var(--color-secondary);
            border: 1px solid #aaa;
            position: relative;
        }

        .sc-form-row input:focus {
            border: 1px solid #333;
        }


        .sc-form-row input[type=checkbox] {

        }

        .sc-form-row select {
            outline: none;
            width: 100%;
            padding: 1em;
            height: 3.2em;
            font-size: .9em;
            box-sizing: border-box;
            border: 1px solid #aaa;
            color: var(--color-secondary);
            position: relative;
        }

        .sc-form-row select:focus {
            border: 1px solid #333;
        }
    }

    &.env_ta {
        margin: 0 0 32px;
        display: flex;

        .sc-form-group .sc-form-row {
            margin-bottom: 16px;
        }

        .form-label-row {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            margin: 8px 2% 0 0;
            padding: 0 0;
            width: 38%;
        }

        .sc-field-label {
            font-weight: 500;
            margin-right: 1em;
            display: flex;
        }

        .sc-field-label span {
            //font-size: 0.8em;
            font-weight: 600;
            line-height: 16px;
            text-transform: uppercase;
            @include themed() {
                color: t('text-color');
            }
        }

        .sc-field-error {
            padding-top: 0.5em;
            font-weight: 700;
            font-size: 0.7em;
            color: #c10000;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .theme--dark .sc-field-error {
            color: #db4b3f;
        }

        .sc-field-description span {
            font-weight: 500;
            @include themed() {
                color: t('text-color');
            }
            font-size: 0.7em;
        }

        .input {
            width: 58%;
            margin-left: 2%;
        }

        textarea,
        input {
            border: none;
            outline: none;
            width: 100%;
            padding: 16px 0 16px 16px;
            line-height: 16px;
            font-size: 0.9em;
            box-sizing: border-box;
            @include themed() {
                background: t('color-background');
                color: t('text-color');
            }

            background: purple;
            border-radius: 3px;
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), inset 1px 1px 2px rgba(0, 0, 0, 0.1);
            transition: box-shadow 0.4s;
            position: relative;
        }

        textarea:focus,
        input:focus {
            box-shadow: 0 0 0 2px $color-tertiary, inset 1px 1px 2px rgba(0, 0, 0, 0.1);
        }

        textarea {
            min-height: 10em;
            max-height: 30em;
            min-width: 100%;
            max-width: 100%;
        }

        input[type=checkbox]:focus {
            box-shadow: none;
        }

        input[type=checkbox] {
            margin-left: 1em;
        }

        select {
            border: none;
            outline: none;
            width: 100%;
            padding: 1em;
            height: 3.2em;
            font-size: 1em;
            box-sizing: border-box;
            @include themed() {
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIwLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0LjkgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQuOSAxMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM0NDQ0NDQ7fQo8L3N0eWxlPgo8dGl0bGU+YXJyb3dzPC90aXRsZT4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIxLjQsNC43IDIuNSwzLjIgMy41LDQuNyAiLz4KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIzLjUsNS4zIDIuNSw2LjggMS40LDUuMyAiLz4KPC9zdmc+Cg==) no-repeat 100% 50%;
                background-color: t('color-background');
                color: t('text-color');
            }
            border-radius: 3px;
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), inset 1px 1px 2px rgba(0, 0, 0, 0.1);
            transition: box-shadow 0.4s;
            position: relative;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
        }

        select:focus {
            box-shadow: 0 0 0 2px $color-tertiary, inset 1px 1px 2px rgba(0, 0, 0, 0.1);
        }

        .sc-form-group .form-label-row {
            width: calc(38% - 18px);
        }

        .sc-form-group .input {
            width: calc(58% + 18px);
        }

        .sc-form-group .sc-field-label:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 15px;
            margin-top: -7px;
            height: 15px;
            border-bottom: 1px solid #eee;
            border-left: 1px solid #eee;
            border-bottom-left-radius: 2px;
        }

        .sc-field-link {
            margin-top: 0.5em;
            display: flex;
            justify-content: flex-start;
        }

        .sc-field-link a {
            @include themed() {
                background: t('color-nav');
            }
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(255, 255, 255, 0.8);
            font-weight: 700;
            text-transform: uppercase;
            font-size: 0.6em;
            border-radius: 0.3em;
            padding: 6px 16px;
            transition: background .15s ease-in-out;
        }

        .sc-field-link a:hover {
            @include themed() {
                background: t('color-nav-hover');
            }
        }
    }

}

</style>