<template>
    <div class="width-container pd">
        <breadcrumbs :items="items"/>

        <div class="message-container">
            <stepper :steps="steps" :currentStep="currentIndex">
                <template #steps>
                    <step-with-error v-for="(s,i) in steps" :step="s" :isActive="i <= currentIndex"
                                     :isCurrent="i === currentIndex" :hasError="stepHasError(i)"
                                     :ke="'step-'+i" @click.native="goTo(s,i)"/>
                </template>
            </stepper>

            <router-view @changed="setDirty" :errorMessage="errorMessage"></router-view>

            <div class="button-row">
                <sc-button @click="nextTab" color="secondary" style="margin-right:0;" :running="running"
                           :disabled="updating" v-if="['form','schedule'].includes($route.meta.name)">
                    Next
                </sc-button>

                <sc-button @click="publish" color="secondary" style="margin-right:0;" :running="running"
                           :disabled="updating" v-else>
                    Create
                </sc-button>
            </div>

        </div>
    </div>
</template>

<script>
import Stepper from "@/views/app/messages/components/stepper";
import StepWithError from "@/views/app/messages/components/step-with-error";

export default {
  name: "new-process",
  components: {StepWithError, Stepper},
  data() {
    return {
      updating: false,
      unsaved: false,
      running: false,

      steps: [
        {label: 'create', step: 'create', path: '/messages/new/create'},
        {label: 'schedule', step: 'schedule', path: '/messages/new/schedule'},
        {label: 'locations', step: 'locations', path: '/messages/new/locations'}
      ],
    }
  },
  computed: {
    // currentStep() {
    //   return this.steps[this.currentIndex];
    // },

    currentIndex() {

      let currentPath = this.$route.path;

      if (this.steps.length > 0) {
        return this.steps.findIndex((s) => s.path === currentPath);
      }

      return 0

    },

    items() {
      const items = [{name: 'messages', path: '/messages'}, {
        name: 'Create message',
        path: '/messages?action=new'
      }]
      if (this.$store.getters['message/getName']) {
        items.push({name: this.$store.getters['message/getName'], path: '/messages/new/create'});
      }

      if (this.$route.meta.name === 'schedule') {
        items.push('schedule')
      }

      if (this.$route.meta.name === 'locations') {
        items.push('locations')
      }

      return items;
    },

    errorMessage() {
      return this.$store.getters['message/getErrors']
    },

    hasCreateError() {
      if (this.errorMessage) {
        let keys = Object.keys(this.errorMessage);
        let count = 0;
        if (keys.includes('endValidDate')) count++;
        if (keys.includes('startValidDate')) count++;
        return (keys.length - count) > 0;
      }
    },

    hasScheduleError() {
      if (this.errorMessage) {
        let keys = Object.keys(this.errorMessage);
        return keys.includes("endValidDate") || keys.includes("startValidDate");
      }
    }
  },

  beforeDestroy() {
    this.$store.dispatch('message/CLEAR')
  },

  beforeRouteLeave(to, from, next) {
    if (this.unsaved) {
      const answer = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },

  methods: {
    setDirty() {
      this.unsaved = true;
    },

    formValid() {
      if (this.errorMessage) {
        this.updating = false;
        this.running = false;
        this.$notify.error('Please fill in all fields');
        return false;
      }
      return true;
    },

    nextTab() {
      switch (this.$route.meta.name) {
        case "form":
          this.$router.push('/messages/new/schedule')
          break;
        case "schedule":
          this.$router.push('/messages/new/locations')
          break;
      }
    },

    publish() {
      // Disable button
      this.updating = true;
      this.running = true;

      // Validate form fields
      // continue if the form is 'valid'
      this.$store.dispatch('message/VALIDATE')
      if (!this.formValid()) return;

      // Prepare payload for update
      let payload = {...this.$store.getters['message/getForm']};

      this.$talker.api.post('messages/', payload)
        .then(res => {
          //TODO: Should get id in response and redirect to edit
          this.$notify.success(`Message '${payload.name}' created`);
          this.running = false;
          this.unsaved = false;
          this.$router.push('/messages')
        })
        .catch(err => {
          if (err.response.data.errors) {
            let errorMessage = {};
            for (let [key, value] of Object.entries(err.response.data.errors)) {
              if (key === 'fields.0') key = 'Image';
              errorMessage[key] = value[0];
            }
            this.$store.dispatch('message/SET_ERRORS', errorMessage);
            this.$notify.error(errorMessage);
          } else if (err.response.data.message) {
            this.$notify.error(err.response.data.message);
          }
          this.running = false;
          this.updating = false;
        })
    },

    stepHasError(index) {
      if (index === 0) {
        return this.hasCreateError || false;
      } else if (index === 1) {
        return this.hasScheduleError || false;
      } else {
        return false
      }
    },

    goTo(s,i) {
      if (this.currentIndex !== i) {
        this.$router.push(s.path);
      }
    },


    errorClick(s,i) {
      if (this.stepHasError(i)) {
        this.$router.push(s.path);
      }
    }
  }
}
</script>

<style scoped>
.message-container {
    background: #fff;
    padding: 24px;
    width: inherit;
}

.button-row {
    display: flex;
    justify-content: flex-end;
    height: 36px;
    box-sizing: border-box;
}

</style>
