import _index from './index.vue'
import users from './users.vue'
import userActivity from './user-activity.vue'
import mediaUsage from './media-usage.vue'
import templates from './templates.vue'

const routes = {
  path: 'reports',
  redirect: '/reports/media-usage',
  component: _index,
  children: [
    {
      path: 'media-usage',
      meta: {name: 'media usage'},
      component: mediaUsage,
    },
    {
      path: 'templates',
      meta: {name: 'templates'},
      component: templates,
    },
    {
      path: 'users',
      meta: {name: 'users'},
      component: users,
    },
    {
      path: 'user-activity',
      meta: {name: 'user activity'},
      component: userActivity,
    },

  ]
};

export default routes;