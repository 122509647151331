import './bootstrap.js'
import Root from './root'
import store from './store/store.js'
import Vue from 'vue';

import appRoutes from './router/app/routes'
import taRoutes from './router/ta/routes'

let router;
switch (process.env.VUE_APP_TARGET) {
  case "ta":
    router = taRoutes;
    break;
  default:
    router = appRoutes;
    break;
}

new Vue({
  router,
  store,
  render: h => h(Root)
}).$mount('#app');