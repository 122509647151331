<template>
    <div class="action-container">
        <div class="action">
            <awesome-icon v-if="icon" class="icon" :icon="icon" :rotation="rotate"></awesome-icon>
            <slot></slot>
        </div>
        <div class="tooltip" v-if="tooltip">
            <div class="tooltip-text">{{tooltip}}</div>
        </div>
    </div>
</template>

<script>
export default {
  name: "sc-table-button",
  props: ['tooltip', 'background', 'icon', 'rotate']
}
</script>

<style scoped lang="scss">
.action-container {
    position: relative;
}

.action-container:hover .tooltip {
    animation: fadein .15s ease-in-out 0.3s forwards;
}

@keyframes fadein {
    from {
        opacity: 0;
        transform: translate(-50%, calc(100% + 0px));
    }
    to {
        opacity: 1;
        transform: translate(-50%, calc(100% + 9px));
    }
}

.action {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include themed() {
        background: t('button-background');
        color: t('button-color');
    }
    border-radius: 2px;
    cursor: pointer;
    transition: all .15s ease-in-out;
}

.action:hover {
    @include themed() {
        background: t('button-background-hover');
        color: t('button-color-hover');
    }
}

.tooltip {
    position: absolute;
    top: 4px;
    height: 1.5em;
    line-height: 1.5em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.6em;
    text-align: center;
    transform: translate(-50%, calc(100% + 9px));
    @include themed() {
        background: t('button-color');
        color: t('button-background');
    }
    border-radius: 3px;
    color: #fff;
    padding: 4px 8px;
    z-index: 50;
    white-space: nowrap;
    left: 50%;
    pointer-events: none;
    opacity: 0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.tooltip-text {
    position: relative;
    z-index: 2;
}

.tooltip:before {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 10px;
    height: 10px;
    @include themed() {
        background: t('button-color');
    }
    z-index: 30;
}

.icon {
    font-size:0.8em;
}
</style>