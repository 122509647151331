import moment from 'moment'

const time = {

  namespaced: true,
  state: {
    now: moment()
  },

  getters: {},

  actions: {
    start: ({state, commit}) => {
      setInterval(() => {
        commit('updateTime')
      }, 1000 * 60)
    }
  },

  mutations: {
    updateTime(state) {
      state.now = moment();
    }
  },

};

export default time;