<template>
    <div class="container" :style="{width:width + 'px'}" :class="{open, closed:!open}">
        <div class="close" @click="toggleNav()">
            <div class="nav-icon">
                <i v-show="open" class="fas fa-chevron-left"></i>
                <i v-show="!open" class="fas fa-bars"></i>
            </div>
        </div>
        <div class="nav" :style="{width:maxWidth + 'px'}">
            <router-link to="/messages">
                <div class="nav-icon">
                    <i class="fas fa-comment-alt"></i>
                </div>
                <div class="nav-name">Messages</div>
            </router-link>
            <router-link to="/media">
                <div class="nav-icon">
                    <i class="fas fa-photo-video"></i>
                </div>
                <div class="nav-name">Media</div>
            </router-link>
            <router-link to="/users">
                <div class="nav-icon">
                    <i class="fas fa-user"></i>
                </div>
                <div class="nav-name">Users</div>
            </router-link>
            <router-link to="/usergroups">
                <div class="nav-icon">
                    <i class="fas fa-users"></i>
                </div>
                <div class="nav-name">User Groups</div>
            </router-link>
            <router-link to="/locations">
                <div class="nav-icon">
                    <i class="fas fa-map-marked"></i>
                </div>
                <div class="nav-name">Locations</div>
            </router-link>
            <router-link to="/profanity-filter">
                <div class="nav-icon">
                    <i class="fas fa-deaf"></i>
                </div>
                <div class="nav-name">Bad words</div>
            </router-link>
        </div>
    </div>
</template>

<script>
  export default {
    name: "sc-nav",
    data() {
      return {
        open: false,
        width: 60,
        maxWidth: 200,
        minWidth: 60,
      }
    },
    mounted() {
      this.$emit('setMargin', this.width)
    },
    methods: {
      toggleNav() {
        if (this.open) {
          this.open = false;
          this.width = this.minWidth;
        } else {
          this.open = true;
          this.width = this.maxWidth;
        }
        this.$emit('setMargin', this.width);
      }
    }
  }
</script>

<style scoped>
    .container {
        position: fixed;
        left: 0;
        top: 0;
        background: rgb(67, 73, 88);
        transition: width .4s var(--easing);
        height: 100%;
        overflow-x: hidden;
        color: #fff;
        z-index: 5;
    }

    .close {
        height: 4em;
        display: flex;
        align-items: center;
        /*background: #5d647d;*/
        color: #fff;
        cursor: pointer;
        transition: background .15s ease-in-out;
    }

    .close:hover {
        background: #545970;
    }

    .logo {
        width: 100%;
        background: #545970;
        height: 5em;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-top: 0;
        padding-bottom: 0;
    }

    .logo img {
        width: 33px;
    }

    .logo .name {
        margin-left: 1em;
    }

    .logo .name .top {
        font-size: 0.7em;
        margin-left: 0.1em;
        font-weight: 800;
        letter-spacing: 0.03em;
    }

    .logo .name .sub {
        font-size: 1.2em;
        font-weight: 700;
        letter-spacing: 0.03em;
    }

    .nav {
        /*padding-top: 16px;*/
        /*background: #333;*/
        /*margin: 32px 32px;*/
        overflow: hidden;
    }

    .nav-icon {
        padding: 0 24px;
        width: 12px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1em;
        color: rgba(255, 255, 255, 0.8);
    }

    .nav-name {
        font-size: 0.8em;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.8);
        transition-property: color, transform;
        transition-timing-function: var(--easing);
        transition-duration: 0.6s;
        transition-delay: .10s;
        transform: translate3d(0px, 0, 0);
    }

    .closed .nav-name {
        color: rgba(255, 255, 255, 0.0);
        transform: translate3d(-20px, 0, 0);
        transition-delay: .0s;
        transition-duration: .4s;
        transition-timing-function: ease-in-out;
    }

    .nav a {
        background: rgba(0, 0, 0, 0);
        transition: background .15s ease-in-out;
        text-decoration: none;
        text-transform: uppercase;
        /*padding: 16px 32px;*/
        height: 60px;
        /*font-size: 0.8em;*/
        margin-bottom: 0px;
        display: flex;
        align-items: center;
    }

    .nav a:hover {
        background: #545970;
    }

    .nav a.router-link-active {
        background: #545970;
    }


</style>