<template>
    <div class="help-wrapper" :class="{open: display}" @click.stop="toggle">
        <button class="help-button">
            <awesome-icon icon="question"/>
        </button>

        <transition name="fade">
            <div class="backdrop" v-if="display" @click.stop.prevent="display = false"></div>
        </transition>

        <transition name="slide-down">
            <div class="menu-list" v-if="display">
                <a :href="path" title="Fidelity Manual" class="menu-option" target="_blank">
                    <span>Manual</span>
                </a>
            </div>
        </transition>

    </div>
</template>

<script>
export default {
  name: "sc-help",
  data() {
    return {
      display: false
    }
  },
  computed: {
    path() {
      const file = this.$store.getters.UserHasPermission('create-user-admin') ? 'manual-admin.pdf' : 'manual.pdf'
      return '/storage/manual/'+file;
    }
  },

  methods: {
    toggle() {
      this.display = true;
    }
  }
}

</script>

<style scoped lang="scss">
.help-wrapper {

    height: 1.5rem;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 3;
    border-right: 1px solid rgba(255,255,255,0.4);
    padding-right: .5rem;
    margin-right: .5rem;

    .help-button {
        color: rgba(255, 255, 255, .8);
        user-select: none;
        font-size: 1em;
        transition: color .3s var(--easing);
        background: var(--color-primary);
        border: none;
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
    }

    .menu-list {
        position: absolute;
        top: calc(100% + .5em);
        background: #fff;
        width: auto;
        left: 50%;
        transform: translate(-50%, 0.5em);
        box-sizing: border-box;
        padding: 0.5em 0;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        z-index: 4;

        &.slide-down-leave-active,
        &.slide-down-enter-active {
            transition-property: opacity, transform;
            transition-duration: .25s;
            transition-timing-function: $easing;
        }

        &.slide-down-leave,
        &.slide-down-enter-to {
            opacity: 1;
            transform: translate(-50%, 0.5em);
        }

        &.slide-down-enter {
            opacity: 0;
            transform: translate(-50%, -1em);
        }

        &.slide-down-leave-to {
            opacity: 0;
        }

        .menu-option {
            display: block;
            padding: 0.8em 1.5em;
            white-space: nowrap;
            transition: background .15s ease-in-out;

            &:hover {
                background: rgba(0, 0, 0, .03);
                cursor: pointer;
            }

            span {
                font-weight: 500;
                font-size: 0.9em;
                color: var(--color-primary);
            }
        }
    }

    .backdrop {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 3;
        background: rgba(0, 0, 0, 0.1);
        pointer-events: none;
    }

    &:hover, &.open {
        .help-button {
            color: rgba(255, 255, 255, 1);
        }
    }

    &.open {
        opacity: 1;
        cursor: initial;

        .backdrop {
            pointer-events: initial;
        }
    }
}

</style>