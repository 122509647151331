<template>
    <transition name="expand"
                @enter="enter"
                @after-enter="afterEnter"
                @leave="leave"
                mode="out-in">
        <slot></slot>
    </transition>
</template>
<script>
export default {
  name: "expanding-transition",
  methods: {
    enter(element) {
      const width = getComputedStyle(element).width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';
      element.style.opacity = 1;
      const height = getComputedStyle(element).height;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = '6rem';
      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;
      // getComputedStyle(element).width;
      // Trigger the animation.
      // We use `setTimeout` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },

    leave(element) {
      const height = getComputedStyle(element).height;
      element.style.height = height;
      element.style.opacity = 0;
      if (height && height.substring(0, height.length - 2) <= 120) {
        element.style.transition = 'opacity 0.2s'
      }
      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;
      setTimeout(() => {
        element.style.height = '6rem';
      });
    }
  }
}
</script>
<style scoped lang="scss">
/** {*/
/*will-change: height, width;*/
/*transform: translateZ(0);*/
/*backface-visibility: hidden;*/
/*perspective: 1000px;*/
/*}*/

.expand-leave-active,
.expand-enter-active {
    transition-property: height, opacity;
    transition-duration: 0.4s, 0.2s;
    transition-timing-function: $easing, ease-in-out;
    overflow: hidden;
}

.expand-enter,
.expand-leave-to {
    height: 6rem;
    opacity: 0;
}
</style>
