<template>
    <div class="width-container pdh">

        <sc-tabs>
            <sc-tab to="colors" :active="$route.meta.name.includes('colors')">Colors</sc-tab>
            <sc-tab to="logo" :active="$route.meta.name.includes('logo')">Logo</sc-tab>
        </sc-tabs>

        <router-view>
        </router-view>

    </div>
</template>

<script>

import StylingColor from "./components/styling-color";
import StylingLogo from "./components/styling-logo";

export default {
  name: "PortalStyling",
  components: {
    StylingLogo,
    StylingColor,
  },

  mounted() {
    if (this.$route.meta.name === 'styling') {
      this.$router.push('/admin/styling/colors')
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.meta.name === 'styling') {
      next('colors');
    } else {
      next();
    }
  },


}
</script>

<style scoped lang="scss">
</style>