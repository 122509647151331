<template>
    <div>
        <div class="filters pd pdb0">
            <div class="filter">
                <div class="label">To date</div>
                <div class="filter-value">
                    <select v-model="range" @change="fetch()">
                        <option value="now">Now</option>
                        <option value="today">Today</option>
                        <option value="this-week">This week</option>
                        <option value="this-month">This month</option>
                        <option value="this-year">This year</option>
                    </select>
                </div>
            </div>

        </div>
        <sc-table :table="table" @view="view"></sc-table>
    </div>
</template>

<script>
export default {
  name: "users",

  data() {
    return {
      range: 'this-month',
      table: this.$stable.createTable({
        sortBy: 'user',
        columns: {
          user: {fill: true},
          last_login: {
            type: 'date'
          },
          // days_since_last_login: {},
          location: {},
          "metrics.messages_created": {
            name: 'messages created'
          },
          "metrics.messages_updated": {
            name: 'messages updated'
          },
          "metrics.media_created": {
            name: 'media created'
          },
          "metrics.media_updated": {
            name: 'media updated'
          },
          "metrics.logged_in": {
            name: 'logged in'
          }
        },
      }),
    }
  },

  mounted() {
    this.fetch();
  },

  methods: {

    fetch() {
      this.table.page = 1;
      this.table.url = '/reports/users/' + this.range;
      this.table.fetch();
    },

    view(item) {
      this.$router.push(`/user-management/${item.id}/edit`)
    }
  }


}
</script>

<style scoped>
.filters {
    display: flex;
}

.label {
    margin-bottom: 0.5em;
    font-size: 0.8em;
}

select {
    outline: none;
    min-width: 15em;
    padding: 0 1em;
    height: 3.2em;
    font-size: .9em;
    box-sizing: border-box;
    border: 1px solid #aaa;
    color: var(--color-secondary);
    position: relative;
}
</style>
