<template>
    <div>

        <div class="width-container pd">
            <breadcrumbs :items="[{name:'locations',path: '/locations'}, 'edit']"></breadcrumbs>

            <div class="widget pd">
                <div class="header">
                    <span>{{ usergroup }}</span>
                </div>

                <sc-tabs>
                    <sc-tab :active="tab === 'main'" @click.native="setTab('main')">Properties</sc-tab>
                    <sc-tab :active="tab === 'players'" @click.native="setTab('players')">Players</sc-tab>
                </sc-tabs>

                <div v-if="tab === 'main'">
                    <sc-form @submit="submit()" :form="form">

                        <template #field-parent_id>
                            <sc-form-field :form="form" field="parent_id" name="Parent Location">
                                <location-list :location_id.sync="form.fields.parent_id"></location-list>
                            </sc-form-field>
                        </template>
                    </sc-form>
                </div>

                <div v-if="tab === 'players'" :padded="false">
                    <sc-form :form="{}" @submit="savePlayers">

                        <sc-search @searched="filter"/>

                        <div class="players">
                            <div v-for="p in selectedPlayers" class="player selected" @click="togglePlayer(p)">
                                <awesome-icon icon="check" class="icon"/>
                                {{ p.name }}
                            </div>

                            <div v-for="p in availablePlayers" class="player" @click="togglePlayer(p)">
                                {{ p.name }}
                            </div>
                        </div>

                    </sc-form>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import LocationList from "../../../components/locations/location-list";

export default {
  components: {LocationList},
  data() {
    return {
      tab: 'main',
      running: false,

      usergroup: undefined,

      form: this.$sform.createForm({
        fields: {
          name: '',
          description: '',
          parent_id: ''
        },
        url: `user-groups/${this.$route.params.usergroup_id}`
      }),

      currentPlayers: this.$stable.createTable({
        url: `user-groups/${this.$route.params.usergroup_id}/players`,
        sortBy: 'name',
        columns: {
          name: {
            name: 'current players (click to remove)',
            fill: true
          }
        }
      }),

      allPlayers: this.$stable.createTable({
        url: `admin/players/free`,
        sortBy: 'name',
        columns: {
          name: {
            name: 'available players (click to add)',
            fill: true,
          }
        }
      }),
      playerFilter: undefined,
    }
  },

  computed: {

    all() {
      if (this.allPlayers.data) {
        return this.allPlayers.data.data ? this.allPlayers.data.data : this.allPlayers.data
      } else {
        return []
      }
    },

    availablePlayers() {
      let selected = this.selectedPlayers.map(p => p.id);
      return this.sort(this.all.filter((p) => {
        if (this.playerFilter) {
            return !selected.includes(p.id) && p.name.indexOf(this.playerFilter) !== -1
        } else {
          return !selected.includes(p.id)
        }
      }))
    },

    selectedPlayers() {
      if (this.playerFilter) {
        return this.sort(this.currentPlayers.data.filter((p) => p.name.indexOf(this.playerFilter) !== -1));
      } else {
        return this.sort(this.currentPlayers.data);
      }
    }

  },

  mounted() {
    this.form.get()
      .then(res => {
        this.usergroup = res.name
      })

    this.fetchLocationPLayers();
    this.allPlayers.fetch()
  },

  watch: {

    'form.fields.parent_id': {
      handler(newValue, oldValue) {
        if (parseInt(this.$route.params.usergroup_id) === newValue) {

          this.form.errors.record({'parent_id': {message: 'Invalid Parent Location'}})
          this.$notify.error('Invalid Parent Location');
        } else if (this.form.errors) {
          this.form.errors.clear();
        }
      }
    },
  },

  methods: {

    fetchLocationPLayers() {
      this.$talker.api.get(this.currentPlayers.url)
        .then((res) => {
          this.currentPlayers.data = res.data.players || []
          this.currentPlayers.hasData = true;
          this.currentPlayers.prefetch()
        })
    },

    setTab(tab) {
      this.tab = tab;
    },

    togglePlayer(player) {
      if (this.$store.getters.UserHasPermission('update-user-group')) {
        let index = this.currentPlayers.data.findIndex((p) => p.id === player.id);

        if (index !== -1) {
          // remove
          this.currentPlayers.data.splice(index, 1);
        } else {
          // add
          this.currentPlayers.data.push(player)
        }
      }
    },

    sort(set) {
      return set.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      });
    },

    filter(value) {
      this.playerFilter = value;
    },

    savePlayers() {
      this.running = true;
      let payload = {
        players: []
      };

      this.currentPlayers.data.map(x => {
        payload.players.push(x.id)
      });

      this.$talker.api.post(`user-groups/${this.$route.params.usergroup_id}/players/sync`, payload)
        .then(res => {
          this.$notify.success('Players saved')
          this.allPlayers.fetch();
          this.running = false;
        })
        .catch(err => {
          this.$notify.error('Failed to save players')
          this.running = false;
        })
    },

    hasErrors() {
      return Object.keys(this.form.errors.errors).length > 0;
    },

    submit() {

      if (!this.hasErrors()) {

        this.running = true;
        this.form.put()
          .then(() => {
            this.$router.push('/locations')
          })
          .catch(err => {
            this.$notify.error('Failed to update location');
            this.running = true;
          })
      } else {
        this.$notify.error('Resolve errors first');
      }
    },
  }

}
</script>

<style scoped lang="scss">

.sections {
    display: flex;
}

.sections .section {
    flex: 1;
    border-right: 1px solid #ccc;
}

.sections .section:last-child {
    border: none;
}

.added {
    border: 1px solid #777;
    border-radius: 3px;
    font-size: 0.7em;
    font-weight: 900;
    padding: 0.1em 0.5em;
    margin-left: 1em;
}

.players {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: .5em;
    grid-row-gap: .5em;
    margin-top: 1em;

    .player {
        padding: .75em 1.25em;
        border-radius: .25em;
        border: 1px solid var(--color-secondary);
        text-transform: capitalize;
        cursor: pointer;
        display: flex;

        .icon {
            display: none;
        }

        &.selected {
            background: var(--color-secondary);
            color: #FFF;
            font-weight: 500;

            .icon {
                display: inline-block;
                margin-right: .75em;
            }
        }
    }
}
</style>
