<template>
    <div class="fields" :class="classes">
        <div class="range">
            <div class="field schedule-time" :class="{hasErrors:fieldError('startValidDate')}">
                <div class="field-label">Valid from:</div>
                <div class="field-error" v-if="fieldError('startValidDate')">{{ fieldError('startValidDate') }}</div>
                <div class="field-value">
                    <date-picker v-model="validFromTimestamp" type="date" style="margin:16px 0"
                                 :disabled-date="startValidCheck" format="DD MMM YYYY" lang="en"
                                 :first-day-of-week="1" @change="changeStart"></date-picker>
                </div>
            </div>

            <div class="divider">
                -
            </div>

            <div class="field schedule-time" :class="{hasErrors:fieldError('endValidDate')}">
                <div class="field-label">Valid to:</div>
                <div class="field-error" v-if="fieldError('endValidDate')">{{ fieldError('endValidDate') }}</div>
                <div class="field-value">
                    <date-picker v-model="validToTimestamp" type="date" style="margin:16px 0"
                                 :disabled-date="endValidCheck" format="DD MMM YYYY" lang="en"
                                 :first-day-of-week="1" @change="changeEnd"></date-picker>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "sc-field-valid-date-range",
  props: {
    errors: {},
    TTL: {},
    validFromDate: {required: true},
    validToDate: {required: true},
    classes: {required: false, type: String, default: 'pd'}
  },
  data() {
    return {
      validFromTimestamp: null,
      validToTimestamp: null,
      validStartDate: '',
      validEndDate: ''
    }
  },
  computed: {
    errorMessage() {
      return this.errors || {}
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.validFromDate) {
        this.validFromTimestamp = new Date(this.validFromDate)
        this.changeStart(this.validFromTimestamp)
      }
      this.validToTimestamp = this.validToDate ? new Date(this.validToDate) : ''
    })
  },
  methods: {
    fieldError(field) {
      if (this.errorMessage && this.errorMessage.hasOwnProperty(field)) {
        return this.errorMessage[field]
      }
    },

    startValidCheck(d) {
      if (this.TTL) {
        return moment(d).isBefore(moment().startOf('day')) || moment(d).isSameOrAfter(moment().endOf('day').add(this.TTL, 'days'));
      }
      return moment(d).isBefore(moment().startOf('day'));
    },

    endValidCheck(d) {
      let start, end;
      if ([null, undefined, ''].includes(this.validFromTimestamp)) {
        return true;
      } else {
        start = moment(d).startOf('day').isBefore(moment(this.validFromTimestamp).startOf('day'));
      }

      if (this.TTL) {
        let amount = parseInt(this.TTL);
        end = moment(d).endOf('day').isSameOrAfter(moment(this.validFromTimestamp).endOf('day').add(amount, 'days'));
      }

      if (end !== undefined) {
        return start || end
      } else {
        return start
      }
    },

    changeStart(value) {
      this.$emit('update:validFromDate',value);
      this.$emit('validFromChange',value);
    },

    changeEnd(value) {
      this.$emit('update:validToDate',value);
      this.$emit('validToChange',value);
    }
  }
}
</script>

<style scoped lang="scss">

.fields {
    flex: 1;
    color: #1E242A;

    .field {
        .field-label {
            font-size: 0.8em;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.8);
            letter-spacing: 0.05em;
        }

        &.hasErrors {
            .field-error {
                position: absolute;
                color: #c10000;
                font-size: 0.7em;
                margin-top: 5px;

            }
        }
    }
}

.range {
    display: flex;
    align-items: center;
    flex-flow: row wrap;

    .divider {
        margin: .75em 1em 0;
    }
}

.no-border {
    border: none !important;
}
</style>