<template>
    <div>

        <div class="width-container pd">
            <breadcrumbs :items="[{name:'users',path:'/user-management'}, 'edit']"></breadcrumbs>

            <div class="widget pd">
                <div class="header">
                    <span>{{name}}</span>
                </div>

                <sc-tabs>
                    <sc-tab :active="tab === 'main'" @click.native="setTab('main')">Personal Information</sc-tab>
                    <sc-tab :active="tab === 'password'" @click.native="setTab('password')" v-if="!sso_user">Change Password</sc-tab>
                </sc-tabs>

                <div v-if="tab === 'main'">
                    <sc-form @submit="put()">

                        <sc-loading :form="form"></sc-loading>
                        <sc-field :form="form" field="name" required>
                            <input v-model="form.fields.name" ref="initialInput" required/>
                        </sc-field>
                        <sc-field :form="form" field="a_number" name="A Number" :required="sso_user ? true : false">
                            <input v-model="form.fields.a_number" :required="sso_user ? true : false"/>
                        </sc-field>
                        <sc-field :form="form" field="email" required>
                            <input v-model="form.fields.email" type="email" required/>
                        </sc-field>
                        <sc-field :form="form" field="department">
                            <input v-model="form.fields.department"/>
                        </sc-field>
                        <sc-field :form="form" field="jobtitle">
                            <input v-model="form.fields.jobtitle"/>
                        </sc-field>
                        <sc-field :form="form" field="role">
                            <select v-model="form.fields.role">
                                <option :value="role.id" v-for="role in roles">{{role.name}}</option>
                            </select>
                        </sc-field>
                        <sc-field :form="form" field="user_group" name="location">
                            <location-list :location_id.sync="form.fields.user_group"></location-list>
                        </sc-field>

                        <template #buttons>
                            <sc-button type="submit" color="secondary"><i class="fas fa-save"></i>Update user
                            </sc-button>
                        </template>
                    </sc-form>
                </div>

                <div v-if="tab === 'password' && !sso_user">
                    <sc-form @submit="passwordput()">
                        <sc-loading :form="passwordform"></sc-loading>
                        <sc-field :form="passwordform" field="password" name="new password" required>
                            <input v-model="passwordform.fields.password" type="password" required/>
                        </sc-field>
                        <sc-field :form="passwordform" field="password_confirmation" required>
                            <input v-model="passwordform.fields.password_confirmation" type="password" required/>
                        </sc-field>
                        <template #buttons>
                            <sc-button type="submit" color="secondary"><i class="fas fa-save"></i>reset password
                            </sc-button>
                        </template>
                    </sc-form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
  import LocationList from "../../../components/locations/location-list";

  export default {
    components: {LocationList},
    data() {
      return {

        tab: 'main',
        name: undefined,
        sso_user: true,
        form: this.$sform.createForm({
          fields: {
            name: '',
            email: '',
            a_number: '',
            department: '',
            jobtitle: '',
            role: 0,
            user_group: 0,
            set_active_user_group: true
          },
          url: `admin/users/${this.$route.params.user_id}`
        }),
        user_groups: [],
        roles: [],
        passwordform: this.$sform.createForm({
          fields: {
            password: '',
            password_confirmation: ''
          },
          url: `admin/users/${this.$route.params.user_id}/reset-password`
        }),
      }
    },

    mounted() {
      this.$nextTick(() => {
        this.$refs.initialInput.focus();
      })
      this.form.get()
        .then(res => {
          this.name = res.profile.name;
          this.form.fields.name = res.profile.name;
          this.form.fields.department = res.profile.department;
          this.form.fields.a_number = res.profile.a_number;
          this.form.fields.role = res.roles[0].id;
          this.form.fields.user_group = res.user_groups[0].id;
          this.form.fields.jobtitle = res.profile.jobtitle;
          this.form.fields.set_active_user_group = true
          this.sso_user = res.sso_user;
        })
      this.fetch();
    },

    methods: {

      setTab(tab) {
        this.tab = tab;
      },

      put() {
        this.form.put()
          .then(res => {
            this.$router.push('/user-management')
          })
      },

      passwordput() {
        this.passwordform.put()
          .then(res => {
            this.$notify.succes('Password changed')
          })
      },

      post() {
        this.form.post()
          .then(() => {
            this.$router.push('/user-management')
          })
      },

      fetch() {

        this.$talker.api.get('user-groups')
          .then(res => {
            this.user_groups = res.data;
          })

        this.$talker.api.get('roles')
          .then(res => {
            this.roles = res.data;
          })
      }
    }

  }
</script>

<style scoped>

</style>
