<template>
    <div class="wrap">
        <div class="btn" @click="next(-1)">
            <awesome-icon icon="chevron-left"/>
        </div>
<!--        <sc-days :selected="[selected['label']]" @select="select"/>-->
        <div class="days">

            <div class="day" v-for="day in days" @click="select(day)" :class="{active:day['id'] === selected['id']}">
                {{day.label}}
            </div>

        </div>
        <div class="btn" @click="next(1)">
            <awesome-icon icon="chevron-right"/>
        </div>
    </div>
</template>

<script>
  import moment from 'moment-timezone'

  export default {
    name: "select-day",

    data() {
      return {
        reach: 3,
        days: [],
        selected: {},
        offset: 0,
      }
    },

    mounted() {
      this.populate(this.offset);
      this.select(this.days[this.reach]);
    },

    methods: {

      next(offset) {
        this.offset = this.offset + offset;
        this.populate(this.offset)
        this.select(this.days[this.reach]);
      },

      populate(offset) {
        let days = [];
        let totalDays = this.reach * 2 + 1
        for (let i = 0; i < (totalDays); i++) {
          let day = {}
          let now = moment().add(offset, 'weeks').subtract((this.reach - i), 'days');
          day['id'] = i;
          day['date'] = now;
          day['label'] = now.format('ddd D MMM')

          days.push(day);
        }
        this.days = days;
      },

      select(day) {
        this.selected = day;
        this.$emit('changed', day)
      }
    }
  }
</script>

<style scoped lang="scss">
    .wrap {
        display: flex;

        .days {
            display: flex;
            align-items: stretch;
            border: 1px solid #888;
            background: #eee;
            flex: 1;

            .day {
                flex: 1;
                border-right: 1px solid #888;
                text-align: center;
                cursor: pointer;
                user-select: none;
                display: flex;
                align-items: center;
                justify-content: center;

                &:last-child {
                    border: none;
                }

                &.active {
                    background: #0073aa;
                    color: #fff;
                }
            }

        }

        .btn {
            width: 5em;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #333;
            cursor: pointer;

            &:hover {
                background: rgba(0, 0, 0, 0.05);
            }

        }
    }
</style>