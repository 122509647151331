<template>
    <div>
    </div>
</template>

<script>
export default {
  name: "all"
}
</script>

<style scoped>

</style>