<template>
    <sc-form :form="form" @submit="form.post()">
<!--        <sc-field :form="form" field="name">-->
<!--            <input v-model="form.fields.name"/>-->
<!--        </sc-field>-->
<!--        <sc-field :form="form" field="description">-->
<!--            <input v-model="form.fields.description"/>-->
<!--        </sc-field>-->
<!--        <sc-button slot="buttons" @click="form.post()" color="secondary">Create</sc-button>-->
    </sc-form>
</template>

<script>
  export default {
    name: "new-playlist",

    data() {
      return {
        form: this.$sform.createForm({
          url: 'playlists',
          fields: {
            name: '',
            description: ''
          },
          success: res => {
            console.log(res.data.id);
          }
        })
      }
    }
  }
</script>

<style scoped>

</style>