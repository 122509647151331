import _index from './index.vue'
import _all from './all.vue'
import _new from './new.vue'
import _edit from './edit.vue'

const routes = {
  path: 'players',
  meta: {name: 'players'},
  component: _index,
  children: [
    {
      path: '',
      meta: {name: 'all'},
      component: _all,
    },
    {
      path: 'new',
      meta: {name: 'player-new'},
      component: _new,
    },
    {
      path: ':id',
      meta: {name: 'player'},
      component: _edit,
      children: [
        {
          path: 'edit',
          meta: {name: 'player-edit'},

        }
      ]
    }
  ]
}

export default routes;
