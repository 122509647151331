import VueRouter from 'vue-router';
// import _children from '@/components/common/children-route.vue'

import login from '@/views/auth/ta-login.vue'
import app from '@/views/ta/app.vue';
import _tenants from '@/views/ta/tenants/routes'


const RCONF = {cookie: undefined};
if (typeof mfC !== 'undefined') {
  RCONF.cookie = mfC.cookie;
} else {
  RCONF.cookie = process.env.VUE_APP_COOKIE_NAME;
}

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0}
  },
  routes: [
    {
      path: '/',
      redirect: '/tenants',
      meta: {requiresAuth: true, navRoot: true},
      component: app,
      children: [
        _tenants
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: login,
      beforeEnter: (to, from, next) => {
          let cookie = (RCONF.cookie) ? RCONF.cookie : 'bearer';
          if (localStorage.getItem(cookie)) {
          next({
            path: '/'
          })
        }
        else {
          next();
        }
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
});

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         let cookie = (RCONF.cookie) ? RCONF.cookie : 'bearer';
//         if (!localStorage.getItem(cookie)) {
//             let query = to.query;
//             query['redirect'] = to.path;
//             next({
//                 path: '/login',
//                 query: query,
//             });
//         } else {
//             next();
//         }
//     } else {
//         next();
//     }
// });

export default router;
