<template>
    <div class="width-container pd">
        <breadcrumbs :items="items"/>
        <div class="message-container">
            <sc-tabs>
                <sc-tab :active="$route.meta.name==='form'"
                        @click.native="$router.push({name: 'message-edit'})"><span
                        :class="{error:hasEditError}">Edit</span>
                </sc-tab>
                <sc-tab :active="$route.meta.name==='schedule'"
                        @click.native="$router.push('/messages/'+$route.params.id+'/schedule')"><span
                        :class="{error:hasScheduleError}">Schedule</span>
                </sc-tab>
                <sc-tab :active="$route.meta.name==='locations'"
                        @click.native="$router.push('/messages/'+$route.params.id+'/locations')" style="z-index: 1;">
                    Locations
                </sc-tab>
            </sc-tabs>

            <router-view v-if="ready" @changed="setDirty" :errorMessage="errorMessage"></router-view>

            <div class="button-row" v-if="canUpdate">
                <sc-button @click="publish" color="secondary" style="margin-right:0;" :running="running"
                           :disabled="updating">
                    Update
                </sc-button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: "edit",
  data() {
    return {
      ready: false,
      updating: false,
      unsaved: false,
      running: false
    }
  },

  computed: {
    items() {
      const items = [{name: 'messages', path: '/messages'}, {
        name: 'Edit',
        path: `/messages/${this.$route.params.id}/edit`
      },];
      if (this.ready) items.push({
        name: this.$store.getters['message/getName'],
        path: `/messages/${this.$route.params.id}/edit`
      });

      if (this.$route.meta.name === 'schedule') {
        items.push('schedule')
      }

      if (this.$route.meta.name === 'players') {
        items.push('players')
      }

      return items;
    },

    errorMessage() {
      return this.$store.getters['message/getErrors']
    },

    hasEditError() {
      if (this.errorMessage) {
        let keys = Object.keys(this.errorMessage);
        let count = 0;
        if (keys.includes('endValidDate')) count++;
        if (keys.includes('startValidDate')) count++;

        return (keys.length - count) > 0;
      }
    },

    hasScheduleError() {
      if (this.errorMessage) {
        let keys = Object.keys(this.errorMessage);
        return keys.includes("endValidDate") || keys.includes("startValidDate");
      }
    },

    canUpdate() {
      return this.$store.getters.UserHasPermission('update-message');
    }
  },

  mounted() {
    this.fetchMessage()
  },

  beforeDestroy() {
    this.$store.dispatch('message/CLEAR');
  },

  beforeRouteLeave(to, from, next) {
    if (this.unsaved) {
      const answer = window.confirm('You have unsaved changes. Are you sure you want to leave?');
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },

  methods: {
    setDirty() {
      this.unsaved = true;
    },

    fetchMessage() {
      this.$talker.api.get('media/' + this.$route.params.id)
        .then(res => {
          this.$talker.api.get('media/' + this.$route.params.id + '/usage')
            .then((usage) => {
              res.data.playlists = [];
              if (usage.data && usage.data.playlists && usage.data.playlists.length > 0) {
                res.data.playlists = usage.data.playlists.map((pl) => pl.id);
              }
            })
            .then(() => {
              this.$store.dispatch('message/SET_EXISTING', res.data)
                .then(() => {
                  this.ready = true;
                })
            })


        })
        .catch((err) => {
          this.$notify.error("Something went wrong getting message.")
          console.error(err);
        })
    },

    formValid() {
      if (this.errorMessage) {
        this.updating = false;
        this.running = false;
        this.$notify.error('Please fill in all fields');
        return false;
      }
      return true;
    },

    publish() {

      if (this.canUpdate) {
        // Disable button
        this.updating = true;
        this.running = true;

        // Validate form fields
        // continue if the form is 'valid'
        this.$store.dispatch('message/VALIDATE')
        if (!this.formValid()) return;

        // Prepare payload for update
        let payload = {...this.$store.getters['message/getForm']};

        const id = this.$store.getters['message/getMessage'].id;
        this.$talker.api.put('messages/' + id, payload)
          .then(res => {
            this.$notify.success(`Message '${payload.name}' updated`);
            this.updating = false;
            this.unsaved = false;
            this.running = false;
          })
          .catch(err => {
            if (err.response.data.errors) {
              let errorMessage = {};
              for (let [key, value] of Object.entries(err.response.data.errors)) {
                if (key === 'fields.0') key = 'Image';
                errorMessage[key] = value[0]
              }
              this.$store.dispatch('message/SET_ERRORS', errorMessage);
              this.$notify.error(errorMessage);
            } else if (err.response.data.message) {
              this.$notify.error(err.response.data.message);
            }
            this.updating = false;
            this.running = false;
          })
      }
    }
  }
}
</script>

<style scoped>
.message-container {
    background: #fff;
    padding: 24px;
    width: inherit;
}

.button-row {
    display: flex;
    justify-content: flex-end;
    height: 36px;
    box-sizing: border-box;
}

.error {
    color: #c10000;
}
</style>
