<template>
    <div class="app-container">
        <!--<sc-nav @setMargin="setMargin"></sc-nav>-->
        <div class="main-container" :style="{marginLeft:margin + 'px'}">
            <sc-header class="fixed-header"></sc-header>
            <portal-target name="sc-modal" transition="fade-in-transition"></portal-target>
            <div class="content-container">
                <div class="outer">
                    <portal-target name="side-panel-left"></portal-target>
                </div>
                <children-route class="route-container" :hasFooter="true" :animate="false"></children-route>
                <div class="outer"></div>
                <sc-scroll-top/>
            </div>
        </div>
        <display-version/>
    </div>
</template>

<script>

import ScScrollTop from "@/components/common/sc-scroll-top";
import DisplayVersion from "@/components/DisplayVersion";

export default {
  name: 'app',
  components: {DisplayVersion, ScScrollTop},
  data() {
    return {
      margin: 0,
    }
  },
  mounted() {

    this.$store.dispatch('getUser', this.$talker)
      .catch(err => {
        if (err.response && err.response.data && err.response.data.message) {
          this.$notify.error(err.response.data.message);
        }
        if (err.response && err.response.status === 401) {
          this.$notify.error('Unauthorized');
          this.$store.dispatch('logout', {router: this.$router})
        }
      })

  },
  methods: {
    setMargin(value) {
      this.margin = value;
    },


  }
}
</script>

<style scoped>
.app-container {
    min-height: 100%;
    height: 100%;
}

.main-container {
    transition: margin-left 0.4s var(--easing);
    height: 100%;
    display: flex;
    flex-flow: column;
}

.fixed-header {
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    z-index: 90
}

.content-container {
    margin-top: 8.5rem;
    position: relative;
    flex: 1;
    display: flex;
}

.outer {
    /*background: #aaa;*/
    /*flex: 1;*/
}

.route-container {
    flex: 8;
    display: flex;
}

.vue-portal-target {
    height: 100%;
}

.scroll-to-top {
    position: fixed;
    z-index: 90;
    background: blue;
    width: 2rem;
    height: 2rem;
    bottom: 2rem;
    right: 2rem;
}

</style>

