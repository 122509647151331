<template>
    <div class="canvas-container" ref="container">

        <canvas class="slicer" :class="{dragging: drag.mouseDown}"
                :width="canvasWidth" :height="canvasHeight"
                :style="{width:canvasWidth+'px', height:canvasHeight+'px'}"
                ref="slicer"></canvas>

        <canvas class="window" :class="{dragging: drag.mouseDown}"
                :width="canvasWidth" :height="canvasHeight"
                :style="{width:canvasWidth+'px', height:canvasHeight+'px'}"
                ref="window"></canvas>

        <div class="controls">
            <div class="control in" @click="zoomIn">
                <awesome-icon icon="search-plus"/>
            </div>
            <div class="control out" @click="zoomOut">
                <awesome-icon icon="search-minus"/>
            </div>

            <div class="control up" @click="moveUp">
                <awesome-icon icon="chevron-up"/>
            </div>
            <div class="control down" @click="moveDown">
                <awesome-icon icon="chevron-down"/>
            </div>
            <div class="control left" @click="moveLeft">
                <awesome-icon icon="chevron-left"/>
            </div>
            <div class="control right" @click="moveRight">
                <awesome-icon icon="chevron-right"/>
            </div>
        </div>
    </div>
</template>

<script>
const root = document.documentElement;

export default {
  name: "slicer",
  props: {
    item: {required: true, type: Object},
    img: {}
  },
  data() {
    return {
      ready: false,
      canvas: undefined,
      ctx: undefined,
      windowCtx: undefined,
      captureCanvas: undefined,
      captureCtx: undefined,

      canvasControls: {
        scale: 1,
      },
      drag: {
        mouseDown: false,
        startX: 0,
        startY: 0,
      },
      currentPosX: 0,
      currentPosY: 0,
      zoomed: false,
      updateTimeout: undefined,
      initialLogo: undefined,

      // mounted stuff
      containerWidth: 0,
      containerHeight: 0
    }
  },
  computed: {
    canvas_size() {
      return this.item.canvas_size ? this.item.canvas_size : 400
    },

    max_width() {
      return this.item.width ? this.item.width : 300
    },
    max_height() {
      return this.item.height ? this.item.height : 300
    },

    canvasWidth() {
      return this.containerWidth;

    },
    canvasHeight() {
      return this.containerHeight;
    }
  },
  mounted() {
    this.containerWidth = this.$refs.container.clientWidth;
    this.containerHeight = (this.containerWidth / 16) * 9;

    this.$nextTick(() => {
      this.initialLogo = root.style.getPropertyValue('--logo');


      // Prep canvas
      this.canvas = this.$refs.window;
      this.canvas.addEventListener('mousedown', this.canvasMouseDown);
      this.canvas.addEventListener('mouseup', this.canvasMouseUp);
      this.canvas.addEventListener('mousemove', this.canvasMouseMove);
      this.ctx = this.$refs.slicer.getContext('2d');
      this.windowCtx = this.$refs.window.getContext('2d');
      this.resetCanvas();

      // Prep capture canvas
      this.captureCanvas = document.createElement('canvas');
      this.captureCanvas.width = this.max_width;
      this.captureCanvas.height = this.max_height;
      this.captureCtx = this.captureCanvas.getContext('2d');

      if (this.img) {
        this.placeImage(true)
      }
    })
  },

  watch: {
    img(newValue, oldValue) {
      if (newValue)
        this.placeImage(true);
    }
  },

  methods: {
    placeImage(center) {
      // Center image
      if (center) {
        this.currentPosX = (this.canvasWidth - this.img.width) / 2;
        this.currentPosY = (this.canvasHeight - this.img.height) / 2;
      }
      this.ctx.drawImage(this.img, this.currentPosX, this.currentPosY);
      this.updateCanvas()
    },

    resetCanvas() {
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      this.drawRect();
    },

    reset() {
      // Reset logo
      root.style.setProperty('--logo', this.initialLogo);
      // if (this.img) {
      //   this.$refs['logo-upload'].files[0].value = null;
      //   this.$refs['logo-upload'].value = '';
      //   this.img = undefined;
      // }
      this.resetCanvas();
    },

    drawRect() {
      let center = {x: this.$refs.slicer.width / 2, y: this.$refs.slicer.height / 2};

      let lineWidth = 4;
      this.windowCtx.lineWidth = lineWidth;
      this.windowCtx.strokeStyle = "red";
      this.windowCtx.fillStyle = "blue";

      let x1 = center.x - (this.max_width / 2) - (lineWidth / 2);
      let x2 = this.max_width + (lineWidth);

      let y1 = center.y - (this.max_height / 2) - (lineWidth / 2);
      let y2 = this.max_height + (lineWidth);

      let region = new Path2D();

      region.rect(0, 0, this.canvasWidth, this.canvasHeight);
      region.rect(x1, y1, x2, y2);

      this.windowCtx.clip(region, "evenodd");
      this.windowCtx.fillStyle = "rgba(0,0,0,.5)";
      this.windowCtx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
    },

    updateCanvas() {
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

      let position = {x: 0, y: 0}

      if (this.drag.mouseDown) {
        position.x = this.drag.offsetX;
        position.y = this.drag.offsetY;
      } else {
        position.x = this.currentPosX;
        position.y = this.currentPosY;
      }

      let size = {w: 0, h: 0};
      if (this.canvasControls.scale > 0.2) {
        size.w = this.img.width * this.canvasControls.scale;
        size.h = this.img.height * this.canvasControls.scale;

        // if (this.zoomed) {
        //   // Get diff in original width and scaled width
        //   position.x -= (size.w - this.img.width)/2
        //   position.y -= (size.h - this.img.height)/2
        //   this.$nextTick(() => {
        //     this.zoomed = false;
        //   })
        //
        // }
      }

      this.ctx.drawImage(this.img, position.x, position.y, size.w, size.h)

      clearTimeout(this.updateTimeout);
      this.updateTimeout = setTimeout(() => {
        this.updateLogo()
      }, 500);
    },

    updateLogo() {
      let center = {x: this.$refs.slicer.width / 2, y: this.$refs.slicer.height / 2};
      let startX = center.x - (this.max_width / 2);
      let startY = center.y - (this.max_height / 2);

      // Get slice
      let slice = this.ctx.getImageData(startX, startY, this.max_width, this.max_height);

      // clean
      this.captureCtx.clearRect(0, 0, this.max_width, this.max_height);
      this.captureCtx.putImageData(slice, 0, 0);

      let result = this.captureCanvas.toDataURL("image/png", 1.0);
      this.dataUrl = result;
      this.$emit('changed', result);
      // root.style.setProperty('--logo', `url(${result})`);
    },

    canvasMouseDown(e) {
      // if (this.editing) {
        this.drag.startX = e.offsetX;
        this.drag.startY = e.offsetY;

        this.drag.mouseDown = true;
      // }
    },
    canvasMouseUp(e) {

      let dragDiffX = e.offsetX - this.drag.startX;
      let dragDiffY = e.offsetY - this.drag.startY;

      // Apply to currentPosX
      this.currentPosX += dragDiffX;
      this.currentPosY += dragDiffY;

      this.drag.mouseDown = false;
    },
    canvasMouseMove(e) {
      if (this.drag.mouseDown && this.img) {

        // Move mouse horizontal
        let diffX = e.offsetX - this.drag.startX;
        let diffY = e.offsetY - this.drag.startY;
        this.drag.offsetX = this.currentPosX + diffX;
        this.drag.offsetY = this.currentPosY + diffY;

        this.updateCanvas()
      }
    },

    zoomIn(e) {
      this.canvasControls.scale += .1;
      // this.zoomed = true;
      this.updateCanvas();
    },
    zoomOut(e) {
      this.canvasControls.scale -= .1;
      // this.zoomed = true;
      this.updateCanvas();
    },
    moveLeft(e) {
      this.canvasControls.offsetX = this.currentPosX;
      this.currentPosX -= 1;
      this.updateCanvas();
    },
    moveRight(e) {
      this.currentPosX += 1;
      this.updateCanvas();
    },
    moveUp(e) {
      this.currentPosY -= 1;
      this.updateCanvas();
    },
    moveDown(e) {
      this.currentPosY += 1;
      this.updateCanvas();
    },
  }
}
</script>

<style scoped lang="scss">
.heading {
    text-transform: capitalize;
    margin-bottom: 1em;
}

.canvas-container {
    position: relative;
    width: 100%;

    .slicer, .window {
        position: relative;
        width: 100%;
        //transition: all var(--easing) var(--transition-duration);

        background-color: #FFF;
        background-image: linear-gradient(45deg, #c8c8c8 25%, transparent 25%),
        linear-gradient(-45deg, #c8c8c8 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #c8c8c8 75%),
        linear-gradient(-45deg, transparent 75%, #c8c8c8 75%);
        border-radius: 1em;
        background-size: 20px 20px;
        background-position: 0 0, 0 10px, 10px -10px, -10px 0px;

        &:hover {
            cursor: grab;
        }

        &.dragging {
            cursor: grabbing;
        }
    }

    .window {
        position: absolute;
        left: 0;
        background: none;
    }

    .editor-info {

    }

    .controls {
        height: 99px;
        position: absolute;
        bottom: 4em;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(255, 0, 0, 0.2);

        .control {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;

            width: 30px;
            height: 30px;
            position: absolute;
            background: #FFF;
            border-radius: .5em;
            box-shadow: var(--shading);

            &:hover {
                background: #f1f7fa;
            }

            &.in {
                top: 0;
                left: calc(50% + 20px);
            }

            &.out {
                top: 0;
                left: calc(50% - 50px);
            }

            &.up {
                top: 0;
                left: calc(50% - 15px);
            }

            &.down {
                bottom: 0;
                left: calc(50% - 15px);
            }

            &.left {
                top: calc(50% - 15px);
                left: calc(50% - 50px);
            }

            &.right {
                top: calc(50% - 15px);
                left: calc(50% + 20px);
            }
        }
    }
}
</style>