<template>
    <transition-group name="fade-in-transition" mode="out-in" tag="div">
        <slot></slot>
    </transition-group>
</template>

<script>
  export default {
    name: 'fade-in-transition-group',
  }
</script>