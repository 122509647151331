const user = {
  namespaced: true,

  state: {

    user: {},

    theme: 'default',
    theme_rule: 'default',

  },

  actions: {

    getUser({state, commit, getters, dispatch}, {talker}) {
      return talker.api.get('/users/mine')
        .then(res => {
          commit('SET_USER', {user: res.data});
          if (res.data.user_profile && res.data.user_profile.additional && res.data.user_profile.additional.theme) {
            dispatch('setTheme', res.data.user_profile.additional.theme)
          }
          return res;
        })
    },

    setTheme({state, commit}, _theme_rule) {
      let theme_rule = (!_theme_rule) ? state.theme_rule : _theme_rule;
      if (theme_rule === 'inherit') {
        if (window.matchMedia('(prefers-color-scheme:dark)').matches === true) {
          commit('CHANGE_THEME', {theme_rule, theme: 'dark'});
        } else {
          commit('CHANGE_THEME', {theme_rule, theme: 'default'});
        }
      } else {
        commit('CHANGE_THEME', {theme_rule, theme: theme_rule});
      }
      if (_theme_rule) {
        localStorage.setItem('theme', _theme_rule);
      }

    },

  },

  mutations: {

    SET_USER(state, {user}) {
      state.user = user;
    },

    CHANGE_THEME(state, {theme_rule, theme}) {
      let html = document.querySelector('html');
      if (html) {
        if (theme === 'dark') {
          html.classList.replace('theme--default', 'theme--dark');
        } else {
          html.classList.replace('theme--dark', 'theme--default');
        }
      }
      state.theme_rule = theme_rule;
      state.theme = theme;
    }

  },

  getters: {

    profile: (state) => {
      if (state.user && state.user.user_profile) {
        return state.user.user_profile
      }
    },

    name: (state) => {
      if (state.user && state.user.user_profile && state.user.user_profile.name) {
        return state.user.user_profile.name;
      }
    },

    firstName: (state) => {
      if (state.user && state.user.user_profile && state.user.user_profile.name) {
        return state.user.user_profile.name.split(' ')[0];
      }
    },

    isAdmin: (state) => {
      if (!state.user || !state.user.user_role) {
        return false;
      }
      if (state.user.user_role.name === 'administrator') {
        return true;
      } else {
        return false;
      }
    },

    isAtLeast: (state) => (hierarchy) => {
      if (!state.user || !state.user.user_role) {
        return false;
      }
      if (state.user.user_role.hierarchy <= hierarchy) {
        return true;
      } else {
        return false;
      }
    },

    hasPermission: (state, getters) => (permission) => {
      if (getters.isAdmin) {
        return true;
      }
      if (!state.user.permissions) {
        return false;
      }
      if (state.user.permissions.includes(permission)) {
        return true;
      } else {
        return false;
      }
    },

  },

};

export default user;