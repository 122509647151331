<template>
    <div class="action-container" :class="{deleting}">
        <sc-table-button tooltip="delete" icon="trash" @click.native.stop="deleting = true">
        </sc-table-button>

        <sc-modal class="modal" v-if="deleting" @close="deleting = false">
            <div class="text">{{text}} <span class="item">{{item}}</span></div>
            <div class="confirm">Are you sure?</div>
            <div class="buttons">
                <sc-button @click="deleting = false">
                    Cancel
                </sc-button>
                <sc-button color="secondary" @click="destroy()">
                    Yes, delete
                </sc-button>
            </div>
        </sc-modal>
    </div>
</template>

<script>
  export default {
    name: "sc-table-action-delete",
    props: {
      text: {
        default: "About to delete"
      },
      item: {}
    },
    data() {
      return {
        deleting: false,
      }
    },
    methods: {
      destroy() {
        this.$emit('destroy');
        this.deleting = false;
      }
    }
  }
</script>

<style scoped>
    .action-container {
        position: relative;
        height: 27px;
    }

    .action {
        padding: 7px;
        color: #999;
        background: #eee;
        margin-right: 10px;
        border-radius: 3px;
        width: 30px;
        text-align: center;
        cursor: pointer;
    }

    .text {
        margin-bottom: 20px;
        text-align: center;
    }

    .text .item {
        font-weight: 800;
    }

    .buttons {
        justify-content: center;
    }

    .buttons .button-container:last-child {
        margin: 0;
    }

    .confirm {
        margin-bottom: 20px;
        text-align: center;
    }

    .deleting .action {
        pointer-events: none;
    }

    .action:hover {
        color: #555;
    }
</style>