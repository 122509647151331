<template>
    <portal to="side-panel-left">
        <div class="panel">
            <div class="panel-content">
                <slot></slot>
            </div>
        </div>
    </portal>
</template>

<script>
  export default {
    name: "side-panel"
  }
</script>

<style scoped>
    .panel {
        background: var(--color-secondary);
        height: 100%;
        max-width: 20em;
        box-sizing: border-box;
        box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.1);
        min-width: 15em;
        color: #FFF;
    }

    .panel-content {
        padding: 2em 2.5em;
    }
</style>