<template>
    <div>
        <div class="width-container pd">

            <breadcrumbs :items="[{name: 'Media library',path:'/media'},'Update Image']"></breadcrumbs>
            <div class="media-container">
                <sc-form :form="form" @submit="checkUsage">
                    <div class="cols">
                        <div class="col">
                            <div class="media-item" v-if="media">
                                <div class="thumbnail">
                                    <img :src="media.image_storage_path"/>
                                </div>
                                <sc-form-field name="name">
                                    <div class="name">{{ media.name }}</div>
                                </sc-form-field>
                                <sc-form-field name="Creator" v-if="media.creator && media.creator.profile">
                                    <div>{{ media.creator.profile.name }}</div>
                                </sc-form-field>
                                <sc-form-field name="Owner" v-if="media.owner">
                                    <div>{{ media.owner.name }}</div>
                                </sc-form-field>
                                <sc-form-field name="Approved by" v-if="media.approver && media.approver.profile">
                                    <div>{{ media.approver.profile.name }}</div>
                                </sc-form-field>
                            </div>
                        </div>

                        <div class="col">
                            <div class="replace">Replace image</div>
                            <div class="dropzone" ref="dropzone" @drop.prevent="addFile" @dragover.prevent
                                 @dragenter="dragging=true"
                                 @dragend="dragging=false"
                                 @dragleave="dragging=false" :class="{dragging, hidden:!hasDragAndDrop}">

                                <div style="font-size: .8em;line-height: 1.25em;">
                                    <div v-if="fileRequirements.width.min">Min. Width: {{
                                            fileRequirements.width.min
                                        }}px
                                    </div>
                                    <div v-if="fileRequirements.width.max">Max. Width: {{
                                            fileRequirements.width.max
                                        }}px
                                    </div>
                                    <div v-if="fileRequirements.height.min">Min. Height: {{
                                            fileRequirements.height.min
                                        }}px
                                    </div>
                                    <div v-if="fileRequirements.height.max">Max. Height: {{
                                            fileRequirements.height.max
                                        }}px
                                    </div>
                                    <div v-if="fileRequirements.size">Size: {{ fileRequirements.size | byteToMB }}</div>
                                </div>

                                <div class="files-list">
                                    <div class="file" v-for="file in form.fields.image_data">
                                        {{ file.name }} ({{ file.size | byteToMB }})
                                        <button @click="removeFile(file)" title="Remove"><awesome-icon icon="times"/>
                                        </button>
                                    </div>
                                </div>
                                <div class="dropzone-info">
                                    <awesome-icon icon="upload"/>
                                    <div class="dropzone-info-link">
                                        <label for="file"><span>Choose file</span> or drag it here</label>
                                    </div>
                                </div>
                            </div>

                            <input id="file" :class="{hidden:hasDragAndDrop}" type="file" ref="files" multiple
                                   @change="addFiles" :accept="acceptedTypes"/>

                            <br/>
                            <sc-form-field name="name" required :form="{errors: {errors: errors}}" field="name">
                                <input type="text" v-model="form.fields.name" required/>
                            </sc-form-field>

                            <sc-form-field description="Separate tags by comma" :form="{errors: {errors: errors}}" field="tags" name="tags">
                                <input type="text" v-model="form.fields.tags"/>
                            </sc-form-field>

                            <sc-field-valid-date-range :TTL="TTL" :errors="errors"
                                                       @validFromChange="validFromChange"
                                                       @validToChange="validToChange"
                                                       :validFromDate.sync="form.fields.startValidDate"
                                                       :validToDate.sync="form.fields.endValidDate" classes="" v-if="ready"/>
                        </div>
                    </div>
                </sc-form>
            </div>
        </div>
        <sc-modal class="modal" v-if="displayModal" @close="displayModal = false">
            <div class="usage-header">
                <span class="attention">{{ previousImg.name }}</span> is used in the following messages and playlists
            </div>

            <div class="usage" v-if="usage">
                <div class="usage-col messages">
                    <div class="header">Messages</div>
                    <div class="sub-header">
                        These messages will be <span class="attention">updated</span>.<br/>
                        These messages will stop playing until the image is <span class="attention">approved</span>.
                    </div>
                    <div class="content">
                        <div class="content-line" v-for="message in usage.messages">
                            <div>{{ message.name }}</div>
                            <img :src="message.thumbnail_storage_path"/>
                        </div>
                        <div class="none" v-if="usage.messages.length === 0">
                            No messages found
                        </div>
                    </div>

                </div>
                <div class="usage-col playlists">
                    <div class="header">Playlists</div>
                    <div class="sub-header">
                        The media item will be <span class="attention">updated</span> in these playlists.<br/>
                        The media item will not be played until the image is <span class="attention">approved</span>.
                    </div>
                    <div class="content">
                        <div class="content-line" v-for="playlist in usage.playlists">
                            {{ playlist.name }}
                        </div>
                        <div class="none" v-if="usage.playlists.length === 0">
                            No playlists found
                        </div>
                    </div>

                </div>
            </div>

            <div class="text">About to update <span class="item">{{ previousImg.name }}</span></div>
            <div class="confirm">Are you sure?</div>

            <div class="buttons">
                <sc-button @click="displayModal = false">
                    Cancel
                </sc-button>
                <sc-button color="secondary" :disabled="uploading" @click="submit()">
                    Yes, update
                </sc-button>
            </div>
        </sc-modal>
    </div>
</template>

<script>
import ScFieldValidDateRange from "@/components/common/sc-field-valid-date-range";

export default {
  name: 'edit-image',
  components: {ScFieldValidDateRange},
  data() {
    return {
      dragging: false,
      hasDragAndDrop: false,
      ready: false,
      previousImg: undefined,
      displayModal: false,
      uploading: false,
      usage: undefined,
      media: undefined,
      errors: {},
      form: this.$sform.createForm({
        url: 'media/'+this.$route.params.id,
        fields: {
          name: '',
          tags: {default: []},
          image_data: {default: []},
          startValidDate: {},
          endValidDate:{}
        }
      })
    }
  },

  filters: {
    byteToMB(value) {
      return (value / 1024 ** 2).toFixed(2) + ' MB';
    },
  },

  computed: {
    uploadDisabled() {
      return !this.ready || this.displayModal || this.uploading;
    },
    fileRequirements() {
      const isProd = typeof mfC !== 'undefined';
      return {
        width: {
          min: isProd ? mfC.image.min_width : process.env.IMAGE_MIN_WIDTH,
          max: isProd ? mfC.image.max_width: process.env.IMAGE_MAX_WIDTH
        },
        height: {
          min: isProd ? mfC.image.min_height : process.env.IMAGE_MIN_HEIGHT,
          max: isProd ? mfC.image.min_height : process.env.IMAGE_MAX_HEIGHT
        },
        size: isProd ? mfC.image.max_file_size : process.env.IMAGE_MAX_FILE_SIZE,
      }
    },
    acceptedTypes() {
      let types = typeof mfC !== 'undefined' ? mfC.image.mime_types : process.env.IMAGE_MIME_TYPES;
      if (types) {
        return types;
      }
    },
    TTL() {
      return typeof mfC !== 'undefined' ? mfC.ttl_media : process.env.TTL_MEDIA;
    }
  },

  mounted() {
    this.fetchMedia();
    this.$nextTick(() => {
      let div = this.$refs.dropzone;
      if (div) {
        if ((('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window) {
          this.hasDragAndDrop = true;
        }
      }
    })
  },

  methods: {
    fetchMedia() {
      this.form.get()
        .then((res) => {
          this.media = res;
          this.previousImg = res;
          if (res.upload_finished_flag === 0) {
            this.$notify.error("Still processing file.");
            this.$router.push('/media');
            return;
          }
          this.form.fields.tags = res.tags.map(t => t.name).join(',');

          this.form.fields.startValidDate = res.start_valid_date;
          this.form.fields.endValidDate = res.end_valid_date;
          this.ready = true;
        })
        .catch(error => {
          this.$notify.error(error.response.data.errors)
        })
    },

    addFiles(e) {
      let file = e.target.files[0];
      if (this.form.fields.image_data.length > 0) {
        this.form.fields.image_data = [];
      }
      this.form.fields.image_data.push(file);
    },

    removeFile(file) {
      this.form.fields.image_data = this.form.fields.image_data.filter(f => {
        return f != file;
      });
    },

    addFile(e) {
      let droppedFile = e.dataTransfer.files[0];
      if (!droppedFile) return;
      if (this.form.fields.image_data.length > 0) {
        this.form.fields.image_data = []
      }
      this.form.fields.image_data.push(droppedFile);
      this.dragging = false;
    },

    checkUsage() {
      if (!this.fieldValidation()) {
        return
      }
      this.displayModal = true;
      this.$talker.api.get(`media/${this.$route.params.id}/usage`)
        .then(res => {
          this.usage = res.data
        })
    },

    fieldValidation() {
      this.errors = {};
      let emptyChecks = ['', null, undefined];

      if (emptyChecks.includes(this.form.fields.startValidDate)) {
        this.errors['startValidDate'] = 'Required field';
      }
      if (emptyChecks.includes(this.form.fields.endValidDate)) {
        this.errors['endValidDate'] = 'Required field';
      }
      if (emptyChecks.includes(this.form.fields.name)) {
        this.errors['name'] = ['Required field'];
      }

      return Object.keys(this.errors).length === 0
    },

    containsProfanity() {
      return this.$talker.api.post('/bad-words/check', {
        'fields': [
          {
            'name': 'name',
            'value': this.form.fields.name
          },
          {
            'name': 'tags',
            'value': this.form.fields.tags
          }
        ]
      })
        .catch((err) => {
          throw err;
        })
    },

    submit() {
      this.form.processing = true;

      this.containsProfanity()
        .then(() => {
          this.displayModal = false;


          let formdata = new FormData();
          if (this.form.fields.image_data.length === 1) {
            formdata.append('image_data', this.form.fields.image_data[0]);
          }
          formdata.append('name', this.form.fields.name);
          formdata.append('tags', this.form.fields.tags.split(','))
          formdata.append('startValidDate', moment(this.form.fields.startValidDate).format('YYYY-MM-DD'));
          formdata.append('endValidDate', moment(this.form.fields.endValidDate).format('YYYY-MM-DD'));

          let config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Accept': 'application/json'
            }
          };
          console.log("posting this", formdata);
          this.$talker.api.post('media/' + this.$route.params.id + '/image/update', formdata, config)
            .then(() => {
              this.$notify.success('Media updated');
              this.form.fields.image_data = [];
              this.form.processing = false;
            })
            .catch(error => {
              let response;
              let errors = {};
              if (typeof error.response.data === 'object') {

                if (Array.isArray(error.response.data.errors)) {
                  response = error.response.data.errors;
                } else {
                  for (let key in error.response.data.errors) {
                    if (key !== 'message') {
                      response = error.response.data.errors[key][0].message || error.response.data.errors[key][0]
                      errors[key] = response;
                    }
                  }
                  if (!response && error.response.data.message) {
                    response = error.response.data.message
                  }
                }
              } else {
                if (error.response.status === 413) {
                  response = 'Request Entity Too Large';
                } else {
                  response = 'Something went wrong.';
                }
              }
              this.errors = errors;
              this.$notify.error(response)
              this.form.processing = false;
            })
        })
        .catch((err) => {
          let errors = {}
          let title = undefined;
          if (err.response && err.response.data && err.response.data.errors) {
            title = err.response.data.message;
            for(let key in err.response.data.errors) {
              err.response.data.errors[key].forEach((e) => {
                errors[e.field] = [e.message]
              });
            }
          }

          this.form.processing = false;
          this.displayModal = false;
          this.errors = errors;
          this.$notify.error(errors, title)
        })
    },

    validFromChange(value) {
      if (!value) {
        this.errors.startValidDate = 'Required field';
      }
      this.form.fields.startValidDate = value;
    },

    validToChange(value) {
      if (!value) {
        this.errors.endValidDate = 'Required field';
      }
      this.form.fields.endValidDate = value;
    }
  }

}
</script>

<style scoped lang="scss">

.media-container {
    background: #fff;
    padding: 24px;
    width: inherit;
}

.button-row {
    display: flex;
    justify-content: flex-end;
    height: 36px;
    box-sizing: border-box;
}

.dropzone {
    width: 100%;
    background: #eee;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.dropzone.dragging {
    background: #e7eee7;
}

.dropzone.dragging .files-list {
    pointer-events: none;
}

.files-list {
    text-align: right;
    margin-bottom: 20px;
}

.files-list .file {
    margin-bottom: 5px;
}

.file button {
    border: 1px solid #ccc;
    border-radius: 100px;
    cursor: pointer;
}

.file button i {
    font-size: 10px;
    color: #333;

}

.dropzone .dropzone-info {
    color: #333;
    text-align: center;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
}

.dropzone .dropzone-info .dropzone-info-link:hover {
    color: #81828b;
    cursor: pointer;
}

.dropzone.dragging .dropzone-info {
    pointer-events: none;
}

.dropzone-info label {
    cursor: pointer;
    padding: 30px;
}

.dropzone-info span {
    font-weight: 900;

}

.dropzone .fa-upload {
    font-size: 3em;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.1);
}

.hidden {
    display: none;
}

/* MODAL */
.text {
    margin-bottom: 20px;
    text-align: center;
}

.text .item {
    font-weight: 800;
}

.buttons {
    justify-content: center;
}

.modal .buttons .button-container:last-child {
    margin: 0;
}

.confirm {
    margin-bottom: 20px;
    text-align: center;
}

.usage {
    display: flex;
    margin-bottom: 10px;
}

.usage .header {
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: 700;
    margin-bottom: 5px;
}

.usage-col {
    flex: 1;
    width: 400px;
    margin: 20px;
}

.content-line {
    display: flex;
    background: #f0f0f0;
    border-bottom: 1px solid #ccc;
    padding: 10px 15px;
    box-sizing: border-box;
}

.content-line:last-child {
    border: none;
}

.sub-header {
    margin: 5px 0;
    font-size: 0.9em;
}

.usage-header {
    margin-bottom: 10px;
    text-align: center;
}

.attention {
    font-weight: 900;
}

.content-line img {
    max-width: 100px;
    margin-left: 10px;
    max-height: 100px;
    object-fit: contain;
}

.none {
    padding: 10px;
    font-size: 0.8em;
}

.cols {
    display: flex;

    .col {
        width: 50%;

        &:first-child {
            margin-right: 2em;
        }

        &:last-child {
            margin-left: 2em;
        }
    }
}

.media-item {
    margin-bottom: 3em;
    width: 100%;

    .thumbnail {
        margin-bottom: 1.5em;
    }

    .thumbnail img {
        max-width: 100%;
        max-height: 20em;
        object-fit: contain;
    }

    .name {

    }
}

.replace {
    font-weight: 500;
    margin-bottom: 0.5em;
    font-size: 0.8em;
    margin-top: 8px;
}
</style>
