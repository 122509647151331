<template>
    <div class="main" v-if="items">
        <div class="group-breadcrumbs">
            <div v-for="(value, index) in groupIndexes" @click="breadCrumbClick(index)"
                 :class="{current: index === groupIndexes.length -1}" class="crumb">
                <awesome-icon icon="chevron-right" style="margin-right: .25em;" v-if="index > 0"/>
                <span>{{ groups[index][value].name }}</span>
            </div>
        </div>

        <group v-if="currentGroup" :group="currentGroup" @openGroup="openGroup" :index="currentIndex"
               @view="viewLocation"
               @toggleLocation="toggleLocation"
               :selected="items"
               @playerUpdate="playerUpdate"
               :key="currentGroup[currentIndex].id+'-group'" :playlists="loadedPlaylists"/>

    </div>
</template>

<script>
import Group from "./group";

export default {
  name: "locations",
  components: {Group},
  data() {
    return {
      template: this.$store.getters['message/getTemplate'],
      form: this.$store.getters['message/getForm'],
      loadedPlaylists: [...this.$store.getters['message/getForm'].playlists],
      playlists: [],
      items: [],
      selected: {},
      clicking: false,
      groups: [],
      groupIndexes: [],
      groupTransition: 'slide-in'
    }
  },

  computed: {
    currentGroup() {
      return this.groups[this.groups.length - 1];
    },

    currentIndex() {
      return this.groupIndexes[this.groupIndexes.length - 1];
    }
  },

  mounted() {
    if (typeof this.template === 'undefined' || typeof this.form === 'undefined') {
      this.$router.push('/message');
      return;
    }

    this.fetch();
  },

  methods: {
    breadCrumbClick(index) {
      this.groupTransition = "slide-out";
      let diff = (this.groupIndexes.length - 1) - index;
      for (let i = 0; i < diff; i++) {
        this.groups.pop();
        this.groupIndexes.pop();
      }
    },

    viewLocation(index) {
      this.$set(this.groupIndexes, this.groupIndexes.length - 1, index);
      this.groupTransition = false;
    },

    openGroup(group, index) {
      this.groupTransition = "slide-in";

      this.groups.push(group);
      this.groupIndexes.push(index);
    },


    toggleLocation(loc, state) {
      let newState;
      if (state !== undefined) {
        // Use state if given
        newState = state

      } else {
        // Determine new state based on current 'selected' state of location
        newState = loc.hasOwnProperty('selected') ? !loc.selected : true
      }

      loc.selected = newState;

      if (loc.children.length > 0) {
        for (let i = 0; i < loc.children.length; i++) {
          this.toggleLocation(loc.children[i], newState);
        }
      }

      if (loc.players && loc.players.length > 0) {
        for (let i = 0; i < loc.players.length; i++) {
          if (!newState) {
            delete loc.players[i].selected;
          }
          loc.players[i].selected = newState;
        }
      }

      if (state === undefined) {
        // Recursively search and update location
        this.updateLocation(this.items, loc)
        this.collectPlaylists();
        this.updateForm();
      }
    },

    playerUpdate(location) {
      this.updateLocation(this.items, location)
      this.collectPlaylists();
      this.updateForm();
    },

    updateLocation(set, loc) {
      for (let i = 0; i < set.length; i++) {
        if (set[i].id === loc.id) {
          this.$set(set, i, loc);
          break;
        }
        if (set[i].children.length > 0) {
          this.updateLocation(set[i].children, loc);
        }
      }
    },

    collectPlaylists(set, collected) {
      if (!set) {
        this.playlists = [];
        set = this.items
      }

      for (let i = 0; i < set.length; i++) {
        this.collectPlaylists(set[i].children, collected);

        if (set[i].players && set[i].players.length > 0) {
          for (let j = 0; j < set[i].players.length; j++) {
            if (set[i].players[j].selected
              && !this.playlists.includes(set[i].players[j].playlist_id)) {
              this.playlists.push(set[i].players[j].playlist_id);
            }
          }
        }
      }
    },

    updateForm() {
      this.form.playlists = this.playlists;
      this.$store.commit('message/setLocations', this.items);
      this.$store.dispatch('message/UPDATE_FORM', this.form);
    },

    fetch() {
      this.$talker.api.get(`/players/filtered-on-channel?orientation=${this.template.orientation}`)
        .then((res) => {
          if (Array.isArray(res.data)) {
            this.items = res.data
          } else {
            this.items = [res.data]
          }
        })
        .then(() => {
          this.openFirst();
        })
    },

    openFirst() {
      // open 'first group'
      this.groups.push(this.items);
      this.groupIndexes.push(0);
    }
  }
}
</script>

<style scoped lang="scss">
.main {
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #B9BDC4;
    font-size: 0.9em;
    max-width: 100%;
}

.group-breadcrumbs {
    display: flex;
    margin: 1em 0 2em;

    .crumb {
        cursor: pointer;
        opacity: .8;
        transition: all $easing .1s;
        margin-right: .5em;

        &:hover {
            opacity: 1;
        }

        &.current {
            font-weight: bold;

            span {
                text-decoration: underline;
            }
        }
    }
}
</style>
