<template>
    <div>
        <div class="main">
            <div class="width-container left pd">

                <div class="title">
                    <h2 v-if="!displayModal">Please select a Template</h2>
                    <h2 v-else>Enter message name</h2>
                </div>

                <div class="options">

                    <sc-form v-if="displayModal" :form="{}" @submit="continueTemplate">
                        <sc-field field="name">
                            <input v-model="messageName"/>
                        </sc-field>
<!--                        <sc-button slot="buttons" @click="continueTemplate" color="secondary">Next</sc-button>-->
                    </sc-form>


                    <!-- TODO: Correct values to display -->
                    <sc-table :table="table" @view="selectTemplate" v-else>

                        <template #column-thumbnail="{row}">
                            <img class="thumbnail" :src="thumbnailPath(row.thumbnail_name)"/>
                        </template>

                        <template #column-name="{row}">
                            <div class="cell-content">
                                <span class="text-blue">{{row.name}}</span>
                                <br/>
                                <span style="text-transform: capitalize">{{row.approved}}</span>
                            </div>
                        </template>

                        <template #column-details="{row}">
                            <div class="cell-content">
                                <div v-if="row.description">
                                    <span class="text-lightgrey">Description: </span> {{row.description}}
                                </div>
                                <div v-if="row.width && row.height">
                                    <span class="text-lightgrey">Dimensions: </span> {{row.width}}x{{row.height}}
                                </div>
                                <div v-if="row.duration">
                                    <span class="text-lightgrey">Duration: </span> {{row.duration}}
                                </div>
                                <div v-if="row.orientation">
                                    <span class="text-lightgrey">Orientation: </span> {{row.orientation}}
                                </div>
                            </div>
                        </template>

                        <template #column-updated_at="{row}">
                            <div class="cell-content">
                                <div v-if="row.updated_at"><span class="text-lightgrey">Last modified:</span>
                                    {{row.updated_at}}
                                </div>
                                <div v-if="row.creator"><span class="text-lightgrey">Creator:</span>
                                    {{profileName(row.creator)}}
                                </div>
                            </div>
                        </template>

                    </sc-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

  export default {
    name: 'new-modal',

    data() {
      return {
        active: 'layout-type',

        table: this.$stable.createTable({
          url: '/templates',
          sortBy: 'name',
          columns: {
            thumbnail: {
              sort: false,
              name: 'Template',
              width: 250
            },
            name: {},
            details: {},
            updated_at: {
              name: 'last modified',
              type: 'date',
              width: 300
            },

          }
        }),

        // NEW STUFF
        displayModal: false,
        messageName: undefined,
        profiles: []

      }
    },

    mounted() {
      this.fetch();
      this.table.fetch();
    },

    methods: {
      fetch() {
        this.$talker.api('/user/list')
          .then((res) => {
            this.profiles = res.data.map((u) => {
              return {id: u.id, name: u.name}
            });
          })
      },

      profileName(id) {
        const p = this.profiles.find(p => p.id === id);
        return p ? p.name : id
      },

      thumbnailPath(path) {
        return path
      },

      selectTemplate(t) {
        this.$store.commit('message/setTemplate', t);
        this.displayModal = true;
      },

      continueTemplate() {
        this.$store.dispatch('message/SET_NAME', this.messageName)
          .then(() => {
            this.$store.commit('message/setForm');
            this.$router.push({name: 'message-create'})
          })
      },

      setDirty() {
        this.unsaved = true;
      },

      setErrorMessage(message) {
        this.errorMessage = message;
      },
    }
  }
</script>

<style scoped lang="scss">

    .main {
        display: flex;
    }

    .title {
        text-align: center;
        color: #515B6B;
        font-size: 2em;
        margin-bottom: 1rem;
    }

    .thumbnail {
        max-width: 100%;
        max-height: 100px;
        object-fit: contain;
        margin: .75em 0;
    }

    .cell-content {
        font-size: .9em;
        margin: .75em 0;
        line-height: 1.5em;
        word-break: break-all;
    }
</style>
