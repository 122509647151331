<template>
    <div class="container" :style="{width:width + 'px'}" :class="{open, closed:!open}">
        <div class="close" @click="toggleNav()">
            <div class="nav-icon">
                <awesome-icon icon="chevron-left" v-show="open"></awesome-icon>
                <awesome-icon icon="bars" v-show="!open"></awesome-icon>
            </div>
        </div>
        <div class="nav" :style="{width:maxWidth + 'px'}">
<!--            <router-link to="/" class="home">-->
<!--                <div class="nav-icon">-->
<!--                    <awesome-icon icon="home"></awesome-icon>-->
<!--                </div>-->
<!--                <div class="nav-name">Home</div>-->
<!--            </router-link>-->
            <router-link to="/tenants">
                <div class="nav-icon">
                    <awesome-icon icon="house-user"></awesome-icon>
                </div>
                <div class="nav-name">Tenants</div>
            </router-link>
<!--            <router-link to="/servers" v-if="$user.hasPermission('servers-view')">-->
<!--                <div class="nav-icon">-->
<!--                    <awesome-icon icon="server"></awesome-icon>-->
<!--                </div>-->
<!--                <div class="nav-name">Servers</div>-->
<!--            </router-link>-->
<!--            <router-link to="/users"-->
<!--                         v-if="$user.hasPermission('user-view') || $user.hasPermission('user-group-view')">-->
<!--                <div class="nav-icon">-->
<!--                    <awesome-icon icon="users"></awesome-icon>-->
<!--                </div>-->
<!--                <div class="nav-name">Users & Groups</div>-->
<!--            </router-link>-->
<!--            <router-link to="/errors" v-if="$user.hasPermission('errors-view')">-->
<!--                <div class="nav-icon">-->
<!--                    <awesome-icon icon="bug"></awesome-icon>-->
<!--                </div>-->
<!--                <div class="nav-name">Error log</div>-->
<!--            </router-link>-->
<!--            <router-link to="/update-log" v-if="$user.hasPermission('change-log-view')">-->
<!--                <div class="nav-icon">-->
<!--                    <awesome-icon icon="history"></awesome-icon>-->
<!--                </div>-->
<!--                <div class="nav-name">Change log</div>-->
<!--            </router-link>-->
<!--            <router-link to="/settings" v-if="$user.hasPermission('settings')">-->
<!--                <div class="nav-icon">-->
<!--                    <awesome-icon icon="cog"></awesome-icon>-->
<!--                </div>-->
<!--                <div class="nav-name">System Settings</div>-->
<!--            </router-link>-->
        </div>
    </div>
</template>

<script>
export default {
  name: "ta-nav",
  data() {
    return {
      open: false,
      width: 60,
      maxWidth: 230,
      minWidth: 60,
    }
  },
  created() {
    if (localStorage.getItem('nav-open') === '1') {
      this.open = true;
      this.width = 230;
    }
  },
  mounted() {
    this.$emit('setMargin', this.width)
  },
  methods: {
    toggleNav() {
      if (this.open) {
        this.open = false;
        this.width = this.minWidth;
        localStorage.setItem('nav-open', '0');
      } else {
        this.open = true;
        this.width = this.maxWidth;
        localStorage.setItem('nav-open', '1');
      }
      this.$emit('setMargin', this.width);
    },
  }
}
</script>

<style scoped lang="scss">
.container {
    position: fixed;
    left: 0;
    top: 0;
    @include themed() {
        background: t('color-nav');
        color: t('text-color');
    }
    transition: width .4s $easing;
    height: 100%;
    overflow-x: hidden;
    color: #fff;
    z-index: 5;
}

.close {
    height: 4em;
    display: flex;
    align-items: center;
    /*background: #5d647d;*/
    color: #fff;
    cursor: pointer;
    transition: background .15s ease-in-out;
}

.nav a:hover,
.nav a.router-link-active,
.close:hover {
    @include themed() {
        //background: t('color-nav-hover');
    }
}

.nav a.router-link-active.home {
    background: none;
}

.nav {

}

.nav-icon {
    padding: 0 24px;
    width: 12px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
    color: rgba(255, 255, 255, 0.8);
    z-index: 2;
}

.nav-name {
    font-size: 0.8em;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
    transition-property: color, transform;
    transition-timing-function: $easing;
    transition-duration: 0.6s;
    transition-delay: .10s;
    transform: translate3d(0px, 0, 0);
    z-index: 2;
}

.closed .nav-name {
    color: rgba(255, 255, 255, 0.0);
    transform: translate3d(-20px, 0, 0);
    transition-delay: .0s;
    transition-duration: .4s;
    transition-timing-function: ease-in-out;
}

.nav a {
    background: rgba(0, 0, 0, 0);
    transition: background .15s ease-in-out;
    text-decoration: none;
    text-transform: uppercase;
    height: 60px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    position: relative;

    &:hover {
        &:before {
            opacity: 1;
        }
    }
}

a:before {
    content: '';
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    z-index: 1;
    width: 250%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 0 2px 0 0;
    @include themed() {
        background: t('color-nav-hover');
    }
    transform-origin: left;
    pointer-events: none;
    transition-property: opacity, transform;
    transition-timing-function: $easing, $easing;
    transition-duration: 0.3s, 0.5s;
}

a.router-link-active.home:before {
    opacity: 0;
}

a.router-link-exact-active.home:before,
a.router-link-active:before {
    opacity: 1;
}

.closed {

    a:before {
        transform: skewY(15deg) translateY(-7px);
    }

    a.router-link-active.home:before {
        opacity: 0;
    }

    a.router-link-active.home:hover {
        &:before {
            opacity: 1;
        }
    }

    a.router-link-exact-active.home:before,
    a.router-link-active:before {
        opacity: 1;
    }
}

</style>