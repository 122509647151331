<template>
    <div>
        <div class="time" :class="{hidden:noSchedule}">
            <div class="num start">0:00</div>
            <div class="bar-wrap" ref="bar">
                <div class="bar" ref="slider"></div>
                <slot></slot>
                <div class="tooltip start" :style="{left:start / 24 * 100 + '%'}">{{startLabel}}</div>
                <div class="tooltip end" :style="{left:end / 24 * 100 + '%'}">{{endLabel}}</div>
                <div class="arrow" :style="{borderLeft, borderRight}"></div>
            </div>
            <div class="num end">23:59</div>

        </div>
    </div>
</template>

<script>
  import noUiSlider from 'nouislider'
  import style from 'nouislider/dist/nouislider.css'

  export default {
    name: "select-time",

    props: {
      padding: {},
      noSchedule: {}
    },

    data() {
      return {
        reach: 24,
        hours: [],

        start: '',
        end: '',

        startLabel: '',
        endLabel: '',

        width: 0,
      }
    },

    computed: {
      borderLeft() {
        let px = this.width * (this.start / 24);
        return `${px}px solid transparent`
      },
      borderRight() {
        let px = this.width - (this.width * (this.end / 24));
        return `${px}px solid transparent`
      }
    },

    mounted() {

      this.$nextTick(() => {
        this.initiate();

        this.width = this.$refs.bar.offsetWidth;

      });

      this.$emit('changed', {start: "00.00", end: "23.99"})

    },

    created() {
      window.addEventListener("resize", this.resizeHandler);
    },

    beforeDestroy() {
      window.removeEventListener("resize", this.resizeHandler);
    },

    methods: {

      resizeHandler() {
        this.width = this.$refs.bar.offsetWidth;
      },


      initiate() {

        this.slider = noUiSlider.create(this.$refs.slider, {
          start: [0, 23.99],
          padding: [this.padding.start, this.padding.end],
          margin: 1.5,
          tooltips: [false, false],
          behaviour: 'drag-tap',
          connect: true,
          range: {
            'min': 0,
            'max': 23.99
          },
          step: 0.5
        });

        this.slider.on('update', () => {
          // runs when moving the sliders. Used for displaying the values.
          let times = this.slider.get();
          this.start = times[0];
          this.end = times[1];

          this.startLabel = this.returnTime(times[0]);
          this.endLabel = this.returnTime(times[1]);
          this.$emit('changed', {start: this.start, end: this.end})
        })

      },

      returnTime(float) {
        let str = String(float);
        let hours = str.split('.')[0];
        let minutes = '00';
        if (str.split('.')[1]) {
          let min = (str.split('.')[1]);
          if (min.charAt(0) === '0' && min.length > 1) {
            min = min.charAt(1);
          }
          minutes = Math.round(min / 100 * 60);
          if (minutes < 10) {
            minutes = "0" + minutes;
          }
        }
        if (hours < 10) {
          hours = "0" + hours;
        }
        return hours + ':' + minutes;
      },

      select(hour) {
        this.selected = hour;
        this.$emit('changed', hour)
      }
    }
  }
</script>

<style scoped lang="scss">

    .time {
        display: flex;
        align-items: center;
        padding-top: 2em;
        padding-bottom: 2.5rem;

        .num {
            width: 5rem;
            padding: 0 1.5em;
            box-sizing: border-box;
            font-size: 0.9em;
            font-weight: 700;
            color: #2b4e71;

            &.start {
                text-align: right;
            }
        }

        .bar-wrap {
            flex: 1;
            position: relative;
        }

        .bar {
            position: relative;
            z-index: 2;
            background: rgba(220, 220, 220, 0.8);
            border: none;
        }

        &.hidden {
            .bar, .tooltip, .arrow {
                opacity: 0;
            }
        }
    }

    .tooltip {
        position: absolute;
        top: 1.8rem;
        background: #fff;
        z-index: 3;
        border: 1px solid #222;
        transform: translateX(-50%);
        font-size: 0.9em;
        padding: 0.4em;
        font-weight: 600;
        border-radius: 3px;
    }

    .arrow {
        position: absolute;
        top: 1.2rem;
        width: 100%;
        border-bottom: 5rem solid #e3f0f5;
        box-sizing: border-box;
        z-index: 1;
        pointer-events: none;
    }

</style>

<style>
    .bar .noUi-connect {
        background: #0073aa;
    }
</style>