<template>
    <div class="header" :key="$route.fullPath">
        <div class="header-content">
            <div class="title pdw" v-if="title">
                {{ title }}
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: "sc-page-header",
  props: ['title']
}
</script>

<style scoped>
.header-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 6rem;
}

.title {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    padding-top: 0;
    min-height: 6rem;
    font-size: 2em;
    color: #FFF;
    text-transform: uppercase;
}
</style>