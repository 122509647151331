import axios from 'axios'

export default {
  install(Vue, params) {
    Vue.prototype.$talker = new Talker(params)
  }
};

class Talker {
  constructor({baseURL = 'https://www.example.com', client_secret = 'my dirty little secret', client_id = 1, grant_type = 'password', cookie = 'bearer'}) {
    this.baseURL = baseURL;
    this.client_secret = client_secret;
    this.grant_type = grant_type;
    this.client_id = client_id;
    this.cookie = cookie;

    this.api = axios.create({
      baseURL: baseURL + '/api',
    });

    if (localStorage.getItem(cookie)) {
      this.api.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem(cookie);
    }
  }

  login(email, password, url) {
    return Promise.resolve()
      .then(() => {
        return new Promise((resolve, reject) => {
          let credentials = {
            ...(email ? {username: email} : reject('no username')),
            ...(password ? {password} : reject('no password')),
            ...(this.client_secret ? {client_secret: this.client_secret} : reject('no client secret')),
            ...(this.client_id ? {client_id: this.client_id} : reject('no client id')),
            ...(this.grant_type ? {grant_type: this.grant_type} : reject('no grant type')),
          };

          return resolve(credentials)
        });
      })
      .then(credentials => {
        return new Promise((resolve, reject) => {

          let route = this.baseURL + (url ? url : '/oauth/token');

          axios.post(route, credentials)
            .then(res => {
              if (res.data.access_token) {
                localStorage.setItem(this.cookie, res.data.access_token);
                this.api.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
                return resolve()
              } else {
                return reject('No access token received')
              }
            })
            .catch(error => {
              this.api.defaults.headers.common['Authorization'] = null;
              if (localStorage.getItem(this.cookie)) {
                localStorage.removeItem(this.cookie);
              }
              if (error.response && error.response.data) {
                reject(error.response.data);
              } else {
                reject({message: 'Failed to login', error: error})
              }
            });
        });
      })

  }

  logout(router, user) {
    return Promise.resolve()
      .then(() => {
        if (user.hasOwnProperty('sso_user') && user.sso_user === 0) {
          this.api.get('/user/logout')
            .then((res) => {
              if (router && router.history.current.name !== 'login') {
                router.push('/login')
              }
            })
        }

        this.api.defaults.headers.common['Authorization'] = null;
        if (localStorage.getItem(this.cookie)) {
          localStorage.removeItem(this.cookie);
        }

        if (user.sso_user === 1) {
          location.href = this.baseURL + '/saml2/azure/logout'
        }
      })
      .catch(err => {
        console.error(err);
      })
  }
}
