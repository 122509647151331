<template>
    <div class="header-wrap" :class="{selecting:showMenu ||showSystem}">
        <div class="top-bar">
            <sc-help/>
            <div class="logout" @click="handleLogout">
                <div class="logout-btn">Logout</div>
            </div>
        </div>
        <div class="main-bar">
            <div class="logo" @click="$router.push('/')"></div>
            <div class="menus-container">
                <approval-checker v-if="$store.getters.UserHasPermission('approve-content')"/>

                <!-- Desktop -->
                <div class="desktop">
                    <div class="menu no-list">
                        <router-link @click.native="close()" to="/media" class="menu-button"
                                     v-if="$store.getters.UserHasPermission('view-media')">
                            <span>Media Library</span>
                        </router-link>
                        <router-link @click.native="close()" to="/messages" class="menu-button"
                                     v-if="$store.getters.UserHasPermission('view-message')">
                            <span>Messages</span>
                        </router-link>
                        <router-link @click.native="close()" to="/playlists" class="menu-button"
                                     v-if="$store.getters.UserHasPermission('view-playlist')">
                            <span>Manage Content</span>
                        </router-link>
                    </div>

                    <div class="menu" v-if="hasSystemAccess">
                        <transition name="fade">
                            <div class="backdrop" v-if="showMenu || showSystem" @click="close()"></div>
                        </transition>

                        <button class="menu-button" @click="openSystem()">
                            <span>Settings</span>
                            <awesome-icon icon="caret-down"></awesome-icon>
                        </button>
                        <transition name="slide-down">
                            <div class="menu-list" v-if="showSystem">

                                <router-link @click.native="close()" to="/admin/styling" class="menu-option"
                                             v-if="$store.getters.UserHasPermission('view-user-admin') || $store.getters.UserHasPermission('viewAny-user-admin')">
                                    <!-- TODO: wrap with-correct permission-->
                                    <span>Portal Configuration</span>
                                </router-link>

                                <router-link @click.native="close()" to="/user-management" class="menu-option"
                                             v-if="$store.getters.UserHasPermission('view-user-admin') || $store.getters.UserHasPermission('viewAny-user-admin')">
                                    <span>Users</span>
                                </router-link>
                                <router-link @click.native="close()" to="/locations" class="menu-option"
                                             v-if="$store.getters.UserHasPermission('view-user-group') || $store.getters.UserHasPermission('viewAny-user-group')">
                                    <span>Locations</span>
                                </router-link>
                                <router-link @click.native="close()" to="/roles" class="menu-option"
                                             v-if="$store.getters.UserHasPermission('view-role') || $store.getters.UserHasPermission('viewAny-role')">
                                    <span>User Roles</span>
                                </router-link>
                                <router-link @click.native="close()" to="/players" class="menu-option"
                                             v-if="$store.getters.UserHasPermission('view-player') || $store.getters.UserHasPermission('viewAny-player')">
                                    <span>Players</span>
                                </router-link>
                                <router-link @click.native="close()" to="/profanity-filter" class="menu-option"
                                             v-if="$store.getters.UserHasPermission('view-bad-word') || $store.getters.UserHasPermission('viewAny-bad-word')">
                                    <span>Profanity Filter</span>
                                </router-link>
                                <router-link @click.native="close()" to="/reports" class="menu-option"
                                             v-if="$store.getters.UserHasPermission('view-report')"><span>Reports</span>
                                </router-link>
                            </div>
                        </transition>
                    </div>

                    <div class="menu no-list user">
                        <router-link class="menu-button" to="/my-profile" @click.native="close()">
                            <awesome-icon icon="user" style="margin-right: .5em;"/>
                            <span v-if="user && user.profile">{{ user.profile.name }}</span>
                            <span v-else>User</span>
                        </router-link>
                    </div>
                </div>

                <!-- Mobile -->
                <div class="mobile">

                    <div class="menu-toggle" @click="showMobile = true;">
                        <awesome-icon icon="bars"></awesome-icon>
                    </div>

                    <div class="menu" :class="{display: showMobile}">
                        <div class="menu-toggle" @click="showMobile = false;">
                            <awesome-icon icon="times"></awesome-icon>
                        </div>

                        <div class="items">
                            <router-link class="menu-option" to="/my-profile" @click.native="close()">
                                <awesome-icon icon="user" style="margin-right: .5em;"/>
                                <span v-if="user && user.profile">{{ user.profile.name }}</span>
                                <span v-else>User</span>
                            </router-link>
                        </div>
                        <div class="items">
                            <router-link @click.native="close()" to="/" class="menu-option">
                                <span>Home</span>
                            </router-link>
                            <router-link @click.native="close()" to="/media" class="menu-option"
                                         v-if="$store.getters.UserHasPermission('view-media')">
                                <span>Media Library</span>
                            </router-link>
                            <router-link @click.native="close()" to="/messages" class="menu-option"
                                         v-if="$store.getters.UserHasPermission('view-message')">
                                <span>Messages</span>
                            </router-link>
                            <router-link @click.native="close()" to="/playlists" class="menu-option"
                                         v-if="$store.getters.UserHasPermission('view-playlist')">
                                <span>Manage Content</span>
                            </router-link>
                        </div>

                        <div class="items" v-if="hasSystemAccess">
                            <router-link @click.native="close()" to="/admin/styling" class="menu-option"
                                         v-if="$store.getters.UserHasPermission('view-user-admin') || $store.getters.UserHasPermission('viewAny-user-admin')">
                                <!-- TODO: wrap with-correct permission-->
                                <span>Portal Configuration</span>
                            </router-link>

                            <router-link @click.native="close()" to="/user-management" class="menu-option"
                                         v-if="$store.getters.UserHasPermission('view-user-admin') || $store.getters.UserHasPermission('viewAny-user-admin')">
                                <span>Users</span>
                            </router-link>
                            <router-link @click.native="close()" to="/locations" class="menu-option"
                                         v-if="$store.getters.UserHasPermission('view-user-group') || $store.getters.UserHasPermission('viewAny-user-group')">
                                <span>Locations</span>
                            </router-link>
                            <router-link @click.native="close()" to="/roles" class="menu-option"
                                         v-if="$store.getters.UserHasPermission('view-role') || $store.getters.UserHasPermission('viewAny-role')">
                                <span>User Roles</span>
                            </router-link>
                            <router-link @click.native="close()" to="/players" class="menu-option"
                                         v-if="$store.getters.UserHasPermission('view-player') || $store.getters.UserHasPermission('viewAny-player')">
                                <span>Players</span>
                            </router-link>
                            <router-link @click.native="close()" to="/profanity-filter" class="menu-option"
                                         v-if="$store.getters.UserHasPermission('view-bad-word') || $store.getters.UserHasPermission('viewAny-bad-word')">
                                <span>Bad Words</span>
                            </router-link>
                            <router-link @click.native="close()" to="/reports" class="menu-option"
                                         v-if="$store.getters.UserHasPermission('view-report')"><span>Reports</span>
                            </router-link>
                        </div>

                    </div>
                </div>
            </div>
            <transition name="fade">
                <div class="backdrop" v-if="showMenu || showSystem" @click.passive="close()"></div>
            </transition>
        </div>
    </div>
</template>

<script>

import ApprovalChecker from "./approval-checker";
import ScHelp from "../sc-help";

export default {
  name: "sc-header",
  components: {ScHelp, ApprovalChecker},
  data() {
    return {
      showMenu: false,
      showSystem: false,
      showMobile: false
    }
  },

  filters: {
    capitals(val) {
      let split = val.split(' ');
      if (split[1]) {
        return split[0].charAt(0) + split[1].charAt(0)
      } else {
        return split[0].charAt(0) + split[0].charAt(1)
      }
    }
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    hasSystemAccess() {
      if (
        this.$store.getters.UserHasPermission('view-user-admin') ||
        this.$store.getters.UserHasPermission('viewAny-user-admin') ||
        this.$store.getters.UserHasPermission('view-user-group') ||
        this.$store.getters.UserHasPermission('viewAny-user-group') ||
        this.$store.getters.UserHasPermission('view-user-role') ||
        this.$store.getters.UserHasPermission('viewAny-user-role') ||
        this.$store.getters.UserHasPermission('view-player') ||
        this.$store.getters.UserHasPermission('viewAny-player') ||
        this.$store.getters.UserHasPermission('view-bad-word') ||
        this.$store.getters.UserHasPermission('viewAny-bad-word') ||
        this.$store.getters.UserHasPermission('view-report')
      ) {
        return true
      }
    }
  },

  methods: {
    handleLogout() {
      this.$talker.logout(this.$router, this.$store.state.user)
    },

    close() {
      this.showMenu = false;
      this.showSystem = false;
      this.showMobile = false;
    },

    openMenu() {
      this.showSystem = false;
      this.showMenu = !this.showMenu;
    },

    openSystem() {
      this.showMenu = false;
      this.showSystem = !this.showSystem;
    }
  }
}
</script>

<style scoped lang="scss">

.header-wrap {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

    .top-bar {
        background: var(--color-secondary);
        text-align: right;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .logout {
            background: var(--color-primary);
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
            padding: 0 5em;
            color: #fff;
            text-transform: uppercase;
            cursor: pointer;
            font-weight: 500;
        }
    }


    .main-bar {
        background: #fff;
        height: 6rem;
        display: flex;
        align-items: center;
        padding: 0 2.5rem;
        box-sizing: border-box;
        justify-content: space-between;

        .logo {
            cursor: pointer;
            background-image: var(--logo);
            height: 100%;
            width: 300px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        .menus-container {
            display: flex;
            position: relative;
            z-index: 2;

            .desktop {
                position: relative;
                width: auto;
                display: flex;

                .menu {
                    position: relative;

                    .menu-button {
                        font-family: Montserrat, sans-serif;
                        padding: 1em;
                        color: var(--color-primary);
                        margin: 0 .5em;
                        user-select: none;
                        position: relative;
                        transition: background .2s ease-in-out;
                        border: none;
                        background: none;
                        font-size: 1em;
                        text-transform: capitalize;
                        font-weight: 600;
                        line-height: inherit;
                        height: inherit;
                        display: flex;

                        &:hover {
                            background: rgba(0, 0, 0, .03);
                            cursor: pointer;
                        }

                        svg {
                            margin: 0 0 0 .5em;
                        }
                    }

                    &.no-list {
                        .menu-button {
                            display: inline-block;
                        }

                        &.user {
                            min-width: 9em;

                            a.menu-button span {
                                width: 100%;
                                text-align: center;
                            }
                        }
                    }

                    .menu-list {
                        position: absolute;
                        top: 100%;
                        background: #fff;
                        width: auto;
                        left: 50%;
                        transform: translate(-50%, 0.5em);
                        box-sizing: border-box;
                        padding: 0.5em 0;
                        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);

                        &.slide-down-leave-active,
                        &.slide-down-enter-active {
                            transition-property: opacity, transform;
                            transition-duration: .25s;
                            transition-timing-function: $easing;
                        }

                        &.slide-down-leave,
                        &.slide-down-enter-to {
                            opacity: 1;
                            transform: translate(-50%, 0.5em);
                        }

                        &.slide-down-enter {
                            opacity: 0;
                            transform: translate(-50%, -1em);
                        }

                        &.slide-down-leave-to {
                            opacity: 0;
                        }

                        .menu-option {
                            display: block;
                            padding: 0.8em 1.5em;
                            white-space: nowrap;
                            transition: background .15s ease-in-out;

                            &:hover {
                                background: rgba(0, 0, 0, .03);
                                cursor: pointer;
                            }

                            span {
                                font-weight: 500;
                                font-size: 0.9em;
                                color: var(--color-primary);
                            }
                        }
                    }
                }
            }

            .mobile {
                display: none;
                height: inherit;
                width: 100%;
                position: relative;

                .menu-toggle {
                    height: 100%;
                    width: 2em;
                    position: relative;
                    font-size: 1.5em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    &:hover {
                        transition: background .2s ease-in-out;
                        background: rgba(0, 0, 0, .03);
                    }
                }

                .menu {
                    position: fixed;
                    z-index: 200;
                    top: 2.5em;
                    bottom: 0;
                    right: 0;
                    box-sizing: border-box;
                    width: 287px;
                    background: #FFF;
                    display: block;
                    flex-flow: column;
                    padding: 1em;
                    box-shadow: -2px 0 2px 1px rgba(0, 0, 0, 0.1);
                    transform: translateX(110%);
                    transition: transform $easing $transition-duration;
                    will-change: transform;

                    &.display {
                        transform: translateX(0);
                    }

                    .menu-toggle {
                        height: 2em;
                        top: .25em;
                        left: calc(100% - 2em);
                    }

                    .items {
                        display: flex;
                        flex-flow: column;
                        border-bottom: 1px solid #ccc;
                        padding: 0.5em 0;

                        &:last-child {
                            border-bottom: none;
                        }

                        .menu-option {
                            font-family: Montserrat, sans-serif;
                            padding: 1em;
                            color: var(--color-primary);
                            margin: 0 .5em;
                            user-select: none;
                            position: relative;
                            transition: background .2s ease-in-out;
                            border: none;
                            background: none;
                            font-size: 1em;
                            text-transform: capitalize;
                            font-weight: 600;
                            line-height: inherit;
                            height: inherit;
                            display: flex;

                            &:hover {
                                background: rgba(0, 0, 0, .03);
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        .backdrop {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.1);
            cursor: default;

            pointer-events: none;
        }
    }

    &.selecting {
        .main-bar {
            .backdrop {
                pointer-events: initial;
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .header-wrap {
        .main-bar {
            .menus-container {
                .desktop {
                    display: none;
                }

                .mobile {
                    display: block;
                }
            }
        }
    }
}


.fade-leave-active,
.fade-enter-active {
    transition: opacity 1s $easing;
}

.fade-leave,
.fade-enter-to {
    opacity: 1;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}


</style>
