<template>
    <span class="expires-soon-indicator" v-if="count > 0">
        Expires soon: {{ count }}
    </span>
</template>

<script>
export default {
  name: "PlaylistExpiresSoonChecker",
  props: {
    id: {required: true}
  },
  data() {
    return {
      expireRange: moment().add('5', 'days'),
      count: 0,
      items: undefined
    }
  },

  mounted() {
    this.$talker.api(`playlists/${this.id}/items`)
      .then(res => {
        this.items = res.data;

      })
      .then(() => {
        if (this.id === 7) {
          console.log(this.items)
        }
        this.getExpireSoonAmount()
      })
  },

  methods: {
    getExpireSoonAmount() {
      this.count = this.items.filter((i) => {
        let toCheck = [i];
        if (i.media_item) {
          // Also check attached media item
          toCheck.push(i.media_item);
        }

        const nullCount = toCheck.filter(item => item.end_valid_date === null).length
        let applicable;

        if (nullCount !== toCheck.length) {
          // Get applicable, any items with a end_valid_date
          applicable = toCheck.filter(item => item.end_valid_date !== null);
          // Sort applicable by end_valid_date (get the one that's closest.
          if (applicable.length > 1) {
            applicable.sort((a, b) => {
              let dateA = moment(a.end_valid_date);
              let dateB = moment(b.end_valid_date);

              if (dateA.isBefore(dateB)) {
                return -1
              } else if (dateA.isAfter(dateB)) {
                return 1
              } else {
                return 0;
              }
            })
          }
        }

        return moment(applicable[0].end_valid_date).isSameOrBefore(this.expireRange)
      }).length;
    }
  }
}
</script>

<style scoped>
.expires-soon-indicator {
    color: #c10000;
    font-size: .9em;
    margin-top: .5em;
}
</style>