<template>
    <div>
        <div class="width-container pd">
            <breadcrumbs :items="['user', 'personal information']"></breadcrumbs>

            <div class="widget pd">
                <div class="header">
                    <span v-if="user">{{ user.profile.name }}</span>
                </div>

                <sc-tabs>
                    <sc-tab :active="tab === 'main'" @click.native="setTab('main')">Personal Information</sc-tab>
                    <sc-tab :active="tab === 'password'" @click.native="setTab('password')"
                            v-if="!$store.state.user.sso_user">Change Password
                    </sc-tab>
                </sc-tabs>

                <div v-if="tab === 'main'">
                    <sc-form @submit="updateUser()" :padded="false" :form="profileForm">

                        <div class="sections">
                            <div class="section pd">
                                <sc-form-field :form="profileForm" field="name">
                                    <input v-model="profileForm.fields.name" required/>
                                </sc-form-field>
                                <sc-form-field :form="profileForm" field="a_number" name="a Number"
                                               v-if="$store.getters.UserHasPermission('update-user-admin')">
                                    <input v-model="profileForm.fields.a_number"/>
                                </sc-form-field>
                                <sc-form-field :form="profileForm" field="department">
                                    <input v-model="profileForm.fields.department"/>
                                </sc-form-field>
                                <sc-form-field :form="profileForm" field="jobtitle">
                                    <input v-model="profileForm.fields.jobtitle"/>
                                </sc-form-field>
                            </div>
                            <div class="section pd">
                                <sc-form-field :form="profileForm" field="email">
                                    <input :value="email" type="email" disabled/>
                                </sc-form-field>
                                <sc-form-field :form="profileForm" field="a_number" name="a Number"
                                               v-if="!$store.getters.UserHasPermission('update-user-admin')">
                                    <input v-model="profileForm.fields.a_number" disabled/>
                                </sc-form-field>
                                <sc-form-field :form="profileForm" field="role">
                                    <input v-model="role" disabled type="text"/>

                                </sc-form-field>
                                <sc-field :form="profileForm" field="user_group" name="location">
                                    <input v-model="user_group" type="text" disabled/>
                                </sc-field>
                            </div>
                        </div>
                    </sc-form>
                </div>

                <div v-if="tab === 'password' && !$store.state.user.sso_user">
                    <sc-form @submit="updatePassword()" :form="passwordForm">
                        <template #field-old_password>
                            <sc-form-field :form="passwordForm" field="old_password">
                                <input v-model="passwordForm.fields.old_password" type="password" required/>
                            </sc-form-field>
                        </template>
                        <template #field-password>
                            <sc-form-field :form="passwordForm" field="password">
                                <input v-model="passwordForm.fields.password" type="password" required/>
                            </sc-form-field>
                        </template>
                        <template #field-password_confirmation>
                            <sc-form-field :form="passwordForm" field="password_confirmation" name="confirm password">
                                <input v-model="passwordForm.fields.password_confirmation" type="password" required/>
                            </sc-form-field>
                        </template>
                    </sc-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'my-profile',
  data() {
    return {

      user: undefined,

      tab: 'main',

      email: '',
      role: '',
      user_group: '',

      profileForm: this.$sform.createForm({
        fields: {
          name: '',
          department: '',
          jobtitle: '',
          a_number: ''
        },
        success: () => {
          this.$notify.success('Profile updated')
        },
        url: `user`,
      }),

      passwordForm: this.$sform.createForm({
        fields: {
          old_password: '',
          password: '',
          password_confirmation: '',
        },
        success: () => {
          this.$notify.success('Password changed')
        },
        url: `user/change-password`,
      }),
    }
  },

  computed: {
    canChangePassword() {
      for (let field in this.passwordForm.fields) {
        if (this.passwordForm.fields[field] === '') return true;
      }
      return false;
    }
  },

  mounted() {
    this.fetch();
  },

  methods: {

    setTab(tab) {
      this.tab = tab;
    },

    fetch() {
      this.$talker.api.get('user')
        .then(res => {
          this.user = res.data;
          this.profileForm.fields.name = res.data.profile.name;
          this.profileForm.fields.a_number = res.data.profile.a_number;
          this.profileForm.fields.department = res.data.profile.department;
          this.profileForm.fields.jobtitle = res.data.profile.jobtitle;
          this.email = res.data.email;
          this.role = res.data.roles.map(v => v.name).join(', ');
          this.user_group = res.data.user_groups.map(v => v.name).join(', ');
        })
        .catch(() => {
          this.$notify.error("Unable to load profile");
        })
    },

    updateUser() {
      this.profileForm.put()
    },

    updatePassword() {
      this.passwordForm.put()
        .then(() => {
          this.passwordForm.fields.old_password = '';
          this.passwordForm.fields.password = '';
          this.passwordForm.fields.password_confirmation = '';
        })
    },
  }
}
</script>

<style scoped>

.sections {
    display: flex;
}

.sections .section {
    flex: 1;
    border-right: 1px solid #ccc;
}

.sections .section:last-child {
    border: none;
}
</style>
