<template>
    <div class="stepper">
        <div class="gutter" :style="gutterStyle"/>
        <div class="progress" :style="progressStyle"/>

        <div class="steps" :class="clickable">
            <slot name="steps" v-if="hasCustomSteps"></slot>
            <step-default v-else
                          v-for="(s,i) in steps" @click="clickStep(i)" :step="s" :isCurrent="i === currentStep" :isActive="i <=currentStep"/>
        </div>

    </div>
</template>

<script>
import StepDefault from "@/views/app/messages/components/step-default";
export default {
  name: "stepper",
  components: {StepDefault},
  props: {
    steps: {
      required: true,
      default: () => {
        return []
      }
    },
    offset: {
      required: false,
      default: 5
    },
    currentStep: {
      required: true,
      default: 0
    },

    completedSteps: {
      required: false,
      default: () => {return []}
    },

    clickable: {
      required: false,
      default: false
    },

  },

  data() {
    return {
      activeIndex: 0
    }
  },

  computed: {
    hasCustomSteps() {
      return !!this.$slots.steps
    },

    gutterStyle() {
      return {
        left: this.offset + 'em',
        right: this.offset + 'em'
      }
    },

    progressStyle() {
      let percentage = (100 / (this.steps.length - 1)) * this.currentStep;
      let offset = (this.offset * 2) / 100 * percentage;

      return {
        left: this.offset + 'em',
        width: `calc(${percentage}% - ${offset}em)`
      }
    }
  },
  methods: {
    clickStep(i) {
      if (this.clickable) {
        this.$emit('update:currentStep', i)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.stepper {
    width: 100%;
    position: relative;
    margin: 1em 0 2em;

    .gutter {
        height: .33em;
        background: #ffffff;
        box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.1),
        inset -2px -2px 1px rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 25%;
        transform: translateY(-50%);
        left: 5em;
        right: 5em;
        bottom: 0;
    }

    .progress {
        width: calc(66% - 5em);
        height: .33em;
        position: absolute;
        top: 25%;
        transform: translateY(-50%);
        left: 5em;
        background: var(--color-secondary);
        box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.1),
        inset -2px -2px 1px rgba(0, 0, 0, 0.2);
        filter: brightness(1.1);
        transition: all var(--easing) var(--transition-duration);
    }

    .steps {
        position: relative;
        display: flex;
        justify-content: space-between;

        &.clickable {
            .step {
                cursor: pointer;

                &:hover {
                    .dot {
                        background: var(--color-tertiary);
                    }
                }
            }
        }
    }
}
</style>