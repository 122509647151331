<template>
    <div class="version" v-if="version">
        <span>{{ version }}</span>
        <span v-if="!isProduction"> (development)</span>
    </div>
</template>
<script>
export default {
  name: 'display-version',
  computed: {
    isProduction() {
      return process.env.NODE_ENV === 'production'
    },
    version() {
      return process.env.VERSION
    }
  }
}
</script>
<style scoped lang="scss">
.version {
    position: fixed;
    bottom: 0;
    font-size: .9em;
    padding: 1em;
    color: rgba(180, 180, 180, 1);
}
</style>