<template>
    <div>
        <side-panel>
            <sc-search @searched="searched"></sc-search>
        </side-panel>

        <div class="width-container pd">

            <breadcrumbs :items="['Profanity filter']"></breadcrumbs>

            <div class="table-buttons pd pdb0" v-if="$store.getters.UserHasPermission('create-bad-word')">
                <sc-button color="secondary" @click="$router.push({path: 'new', append: true})"><awesome-icon icon="plus"/>Add Profanity
                </sc-button>
            </div>

            <sc-table :table="table" @view="view">

                <template #column-actions="{row}">
                    <template>
                        <sc-table-cell #actions :table="table" field="actions">
                            <sc-table-button-delete v-if="$store.getters.UserHasPermission('delete-bad-word')"
                                                    @destroy="destroy(row)" :item="row.word">
                            </sc-table-button-delete>
                        </sc-table-cell>
                    </template>
                </template>

            </sc-table>
        </div>
    </div>
</template>

<script>

export default {

  data() {
    return {
      query: '',
      table: this.$stable.createTable({
        fetchArguments: () => {
          return this.query
        },
        url: '/bad-words',
        sortBy: 'word',
        columns: {
          word: {},
          actions: {
            width: 200,
            sort: false
          },
        },
      }),
    }
  },

  mounted() {
    this.table.fetch()
  },

  methods: {
    searched(value) {
      if (value) {
        this.query = `&where_name=${value}`
      } else {
        this.query = ''
      }
      this.table.fetch();
    },

    view(item) {
      if (this.$store.getters.UserHasPermission('update-bad-word')) {
        this.$router.push({path: item.id + '/edit', append: true})
      }
    },

    destroy(item) {
      this.$talker.api.delete(`bad-words/${item.id}`)
        .then(res => {
          this.$notify.success(`profanity: ${item.word} deleted`);
          this.table.fetch();
        })
    }
  }
}
</script>

<style scoped>
.table-buttons {
    background: #fff;
    display: flex;
}
</style>
