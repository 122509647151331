<template>
    <div class="approval-checker">
        <router-link class="environment nav-btn bordered" :class="{active:count > 0}"
                     to="/approvals/open">
            <div class="count">{{ count }}</div>
            <div class="text">Open Approval<span v-if="count !== 1">s</span></div>
        </router-link>
    </div>
</template>

<script>

export default {
  name: "approval-checker",

  data() {
    return {
      timer: undefined,
      count: 0
    }
  },

  mounted() {

    this.timer = setInterval(() => {
      this.fetch();
    }, 60000);

    this.fetch();
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  methods: {
    fetch() {
      this.$talker.api.get('approvals/todo?pending=1')
        .then(res => {
          this.count = res.data.total;
        });
    }
  }
}
</script>

<style scoped>
.approval-checker {
    border-right: 1px solid #ccc;
    margin-right: 0.5em;
    min-width: 194px;
    box-sizing: border-box;
}

.bordered {
    padding: 1em 0.6em;
    box-sizing: border-box;
    font-weight: 500;
    transition: background .15s ease-in-out;
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
    color: var(--color-primary);
    margin-right: 15px;
}

.nav-btn.bordered:hover {
    background: rgba(0, 0, 0, .03);
}

.bordered i {
    margin-right: 8px;
}

.count {
    font-weight: 900;
    margin: 0 8px 0 4px;
}

.text {
    font-size: 1em;
    margin-right: 5px;
}
</style>
