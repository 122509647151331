<template>
    <div class="panel pd">
        <sc-form :form="{}" @submit="submit">

            <div class="logo-input" v-if="!file">
                <div class="dropzone" ref="dropzone" @drop.prevent="dropFile" @dragover.prevent
                     @dragenter="dragging=true"
                     @dragend="dragging=false"
                     @dragleave="dragging=false" :class="{dragging, hidden:!hasDragAndDrop}">

                    <div class="dropzone-info">
                        <awesome-icon icon="upload"></awesome-icon>
                        <div class="dropzone-info-link">
                            <label for="file"><span>Select an image</span> or drag it here</label>
                        </div>
                    </div>

                    <input id="file" :class="{hidden:hasDragAndDrop}" type="file"
                           ref="logo-upload"
                           @change="handleImageSelect" accept="image/png, image/jpeg"/>
                </div>
            </div>

            <div class="slicer" v-else>
                <slicer :item="{width: 900, height: 288}" :img="selected"
                        @changed="handleChange"/>

                <div class="editor-info" v-if="file">
                    <div class="current">
                        <p>{{ file.name }}</p>
                        <button @click.stop.prevent="reset" title="remove">
                            <awesome-icon icon="times"/>
                        </button>
                    </div>

                    <div class="constraints">
                        <p class="title">Cutout: </p>
                        <p>- Width: {{ cutout.width }}px</p>
                        <p>- Height: {{ cutout.height }}px</p>

                    </div>
                </div>
            </div>

        </sc-form>
    </div>
</template>

<script>

import axios from "axios";
import Slicer from "./slicer";

const root = document.documentElement;

export default {
  name: "styling-logo",
  components: {Slicer},
  data() {
    return {
      hasDragAndDrop: false,
      dragging: false,

      file: undefined,
      selected: undefined,
      img: undefined,
      initialLogo: undefined,
      changed: false,

      cutout: {
        width: 900,
        height: 288
      },

      logos: [
        {
          type: 'logo',
          value: undefined
        },
      ],
      running: false,
      hasChanged: false,
    }
  },

  mounted() {
    this.$nextTick(() => {

      let div = this.$refs.dropzone;
      if (div) {
        if ((('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window) {
          this.hasDragAndDrop = true;
        }
      }

      let logoElement = document.getElementsByClassName('logo')[0]

      this.initialLogo = getComputedStyle(logoElement).backgroundImage;
      if (this.initialLogo.includes('url')) {
        this.initialLogo = this.initialLogo.split('"')[1];
      }

      this.img = new Image();
      this.img.src = this.initialLogo;

      this.img.onload = () => {
        this.selected = this.img;
      };
    })
  },

  // beforeRouteLeave (to, from , next) {
  //   const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
  //   if (answer) {
  //     this.reset();
  //     next()
  //   } else {
  //     next(false)
  //   }
  // },

  // beforeDestroy() {
  //
  //   if (this.hasChanged) {
  //     let x = confirm('Continue without saving?');
  //
  //     if (x) {
  //       this.reset();
  //     } else {
  //
  //     }
  //   }
  //
  // },
  //
  methods: {
    reset() {
      // Reset logo
      root.style.setProperty('--logo', `url(${this.initialLogo})`);
      if (this.img) {
        // if (this.$refs['logo-upload'].files.length > 0) {
        //   this.$refs['logo-upload'].files[0].value = null;
        //   this.$refs['logo-upload'].value = '';
        // }
        this.img = undefined;
      }

      this.file = undefined;
      this.selected = undefined;
      this.changed = false;
    },

    /**
     * SECTION LOGO UPLOAD
     */
    handleImageSelect(e) {
      this.selected = undefined;
      this.$nextTick(() => {
        this.file = e.target.files[0];
        this.setImage(e.target.files[0]);
        this.changed = true;
        this.hasChanged = true;
      })

    },

    setImage(data) {
      this.img = new Image();
      this.img.src = URL.createObjectURL(data);
      this.img.onload = () => {
        this.selected = this.img
      }
    },
    dropFile(e) {
      let file = e.dataTransfer.files[0];
      if (!file) return;
      this.file = file;
      this.$nextTick(() => {
        this.setImage(file);
        this.changed = true;
        this.dragging = false;
      })
    },

    handleChange(result) {
      this.logos.find((l) => l.type === 'logo').value = result;
      root.style.setProperty('--logo', `url(${result}`)
    },

    submit() {
      this.running = true;

      // Write to localstorage
      let save = false;

      for (let i = 0; i < this.logos.length; i++) {
        let l = this.logos[i];
        if (l.value) {
          save = true;
          break;
        }
      }
      if (save) {
        let id;
        if (typeof mfC === 'undefined') {
          id = process.env.VUE_APP_TENANT_ID;
        } else {
          id = mfC.id;
        }

        // TODO: Swap out with talker version when operational
        let logo =
        {
          type: "logo",
          value: this.logos[0].value.split('base64,')[1]
        };

        axios.put('https://spoof.test/api/admin/tenant/' + id + '/ui-logo', {"tenant-logo": logo})
          .then((res) => {
            this.$notify.success('Logo updated');
            // Write to localstorage
            // Need to write stringified to local storage
            localStorage.setItem('tenant-logos', JSON.stringify(this.logos))
          })
          .catch((err) => {
            this.$notify.error(err.response.data.message)
          })
      }

      this.running = false;
      this.hasChanged = false;
    },
  },

}
</script>

<style scoped lang="scss">

.logo-input {
    width: 100%;

    .dropzone {
        width: 100%;
        background: #eee;
        padding: 10px;
        box-sizing: border-box;
        position: relative;

        .dropzone-info {
            color: #333;
            text-align: center;
            font-size: 1.2em;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 20px 0;
            flex: 1;

            label {
                cursor: pointer;
                padding: 30px;
            }

            span {
                font-weight: 900;
            }

            .fa-upload {
                font-size: 3em;
                margin-bottom: 20px;
                color: rgba(0, 0, 0, 0.1);
            }

            .dropzone-info-link:hover {
                color: #81828b;
                cursor: pointer;
            }
        }

        &.dragging {
            background: #e7eee7;

            .dropzone-info {
                pointer-events: none;
            }

            .files-list {
                pointer-events: none;
            }
        }

        .hidden {
            display: none;
        }
    }
}

.slicer {
    max-width: 100%;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    position: relative;

    .editor-info {
        position: absolute;
        top: 1em;
        right: 1em;
        padding: 0.75em 1em;

        pointer-events: none;

        background: rgba(255, 255, 255, 0.75);
        box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);

        .current {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                margin-left: 1em;
                cursor: pointer;
                border-radius: 50%;
                border: none;
                height: 2em;
                width: 2em;
                display: flex;
                justify-content: center;
                align-items: center;
                pointer-events: initial;
                transition: filter var(--easing) var(--transition-duration);

                &:hover {
                    filter: brightness(1.2);
                }
            }
        }

        .constraints {
            margin-top: .5em;

            .title {

                font-weight: 600;
            }
        }
    }
}
</style>