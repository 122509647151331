<template>
    <div class="width-container pd">
        <breadcrumbs :items="[{name:'Profanity filter',path:'/profanity-filter'}, 'new']"></breadcrumbs>

        <div class="container">

        <sc-form @submit="submit()" :form="form">
        </sc-form>
        </div>
    </div>
</template>

<script>
export default {

  data() {
    return {
      form: this.$sform.createForm({
        fields: {
          word: '',
        },
        url: `bad-words`
      }),
      saving: false,
    }
  },

  methods: {

    submit() {
      this.saving = true;
      this.form.post()
        .then(res => {
          this.$router.push('/profanity-filter')
        })
        .catch(err => {
          this.saving = false;
        })
    },

  }

}
</script>

<style scoped lang="scss">

.container {
    box-sizing: border-box;
    background: #fff;
    padding: 24px;
    width: inherit;
}

</style>
