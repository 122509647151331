<template>
    <div>
        <div class="filters pd pdb0">
            <div class="filter">
                <div class="label">To date</div>
                <div class="filter-value">
                    <select v-model="range" @change="fetch()" d>
                        <option value="now">Now</option>
                        <option value="today">Today</option>
                        <option value="this-week">This week</option>
                        <option value="this-month">This month</option>
                        <option value="this-year">This year</option>
                    </select>
                </div>
            </div>
            <div class="filter total">
                <div class="label">
                    Total used
                </div>
                <div class="filter-value total">
                    <div class="total-value">{{ total }}</div>
                </div>
            </div>

        </div>
        <sc-table :table="table" @view="view"></sc-table>

    </div>
</template>

<script>
export default {
  name: "templates",

  data() {
    return {
      range: 'this-month',
      table: this.$stable.createTable({
        sortBy: 'used',
        columns: {
          used: {width: 120,},
          name: {width: 300, fill: true},
        },
      }),
      total: 0
    }
  },

  mounted() {
    this.fetch();
  },

  computed: {
    url() {
      return '/reports/templates/' + this.range
    },
  },

  methods: {
    fetch() {
      this.table.page = 1;
      this.table.url = '/reports/templates/' + this.range;
      this.table.fetch()
        .then((res) => {
            if (res.data.length > 0) {
              this.total = res.data.reduce((a, b) => {
                return a + b.used
              }, 0)
            }
          }
        );
    },

    view(item) {

    }
  }
}
</script>

<style scoped>
.filters {
    display: flex;
    justify-content: space-between;
}

.label {
    margin-bottom: 0.5em;
    font-size: 0.8em;
}

.filter.total {
    justify-self: flex-end;
}

select, .filter-value.total {
    outline: none;
    min-width: 15em;
    padding: 1em;
    height: 3.2em;
    font-size: .9em;
    box-sizing: border-box;
    border: 1px solid #aaa;
    color: var(--color-secondary);
    position: relative;
}

.filter-value.total {
    min-width: auto;
    text-align: center;
    background-color: rgba(239, 239, 239, 0.3);
    border-color: rgba(118, 118, 118, 0.3);
}
</style>
