<template>
    <div>
        <div class="width-container pd">

            <breadcrumbs :items="['Users']"></breadcrumbs>

            <div class="table-buttons pd pdb0" v-if="$store.getters.UserHasPermission('create-user-admin')">
                <sc-button color="secondary" @click="addingNew = true"><awesome-icon icon="plus"/>New User</sc-button>
            </div>

            <sc-table :table="table" @view="view">

                <template #column-actions="{row}">
                    <sc-table-cell :table="table" field="actions">
                        <template #actions>
                            <sc-table-button v-if="$store.getters.UserHasPermission('update-user-admin')"
                                             @click.native.stop="view(row)"
                                             tooltip="edit"
                                             icon="edit"/>
                            <sc-table-action-delete v-if="$store.getters.UserHasPermission('delete-user-admin')"
                                                    :item="`User: ${row.name}`" @destroy="deleteUser(row)"/>
                        </template>
                    </sc-table-cell>
                </template>

            </sc-table>
        </div>

        <sc-modal v-if="addingNew" @close="addingNew = false">
            <new-user @done="addingNewSuccess"></new-user>
        </sc-modal>
    </div>
</template>

<script>

import NewUser from "./components/new-user";

export default {
  name: "all",
  components: {NewUser},
  data() {
    return {
      addingNew: false,
      query: '',
      table: this.$stable.createTable({
        url: '/admin/users',
        sortBy: 'name',
        columns: {
          name: {},
          jobtitle: {},
          department: {},
          location_name: {name: 'location', fill: true},
          role_name: {},
          actions: {
            sort: false
          }
        },
      }),
    }
  },

  mounted() {
    this.table.fetch();
  },

  methods: {

    addingNewSuccess() {
      this.addingNew = false;
      this.table.fetch();
    },

    view(item) {
      if (item.role_name !== 'super-admin' && this.$store.getters.UserHasPermission('update-user-admin')) {
        this.$router.push({path: item.id + '/edit', append: true})
      }
    },

    deleteUser(user) {
      this.$talker.api.delete('admin/users/' + user.id)
        .then(() => {
          this.$notify.success('User deleted')
          this.table.fetch();
        })
        .catch((err) => {
          if (err.response) {
            this.$notify.error(err.response)
          } else {
            this.$notify.error('something went wrong')
          }
        })
    }
  }
}
</script>

<style scoped>
.table-buttons {
    background: #fff;
    display: flex;
}

</style>
