<template>
    <div>
        <side-panel>
            <div class="filters">
                <div class="filters-header">
                    <awesome-icon class="icon" icon="chevron-down"></awesome-icon>
                    Showing
                </div>

                <div class="filter" @click="setFilter('pending')" :class="{active:pending === 1}">
                    <div class="filter-label">Pending</div>
                    <input style="pointer-events:none" v-model="pending" type="checkbox"/>
                </div>
                <div class="filter" @click="setFilter('rejected')" :class="{active:rejected === 1}">
                    <div class="filter-label">Rejected</div>
                    <input style="pointer-events:none" v-model="rejected" type="checkbox"/>
                </div>
                <div class="filter" @click="setFilter('approved')" :class="{active:approved === 1}">
                    <div class="filter-label">Approved</div>
                    <input style="pointer-events:none" v-model="approved" type="checkbox"/>
                </div>
            </div>
        </side-panel>

        <sc-table :table="table" @view="view">
            <template #column-thumbnail="{row}">
                <sc-table-cell :table="table" field="thumbnail">
                    <img v-if="row.media" class="img" :src="row.media.thumbnail_storage_path"/>
                </sc-table-cell>
            </template>

            <template #column-type="{row}">
                <sc-table-cell :table="table" field="type">
                    <media-types v-if="row.media" :type="row.media.media_type" justify="flex-start"
                                 style="font-size:0.7em"/>
                </sc-table-cell>
            </template>
        </sc-table>
    </div>
</template>

<script>

import MediaTypes from "@/components/common/media-types";

export default {
  components: {MediaTypes},
  data() {
    return {
      pending: 1,
      rejected: 1,
      approved: 0,

      table: this.$stable.createTable({
        url: 'approvals',
        fetchArguments: () => {
          return `&pending=${this.pending}&rejected=${this.rejected}&approved=${this.approved}`
        },
        sortBy: 'status',
        columns: {
          thumbnail: {
            width: 220,
          },
          media: {
            width: 200,
            target: 'media.name'
          },
          type: {
            width: 150,
            visible: true,
            predefined: true,
            target: 'media.media_type'
          },
          status: {
            width: 150,
            visible: true,
            predefined: true,
            target: 'status'
          },
          created: {
            width: 200,
            visible: true,
            predefined: true,
            type: 'date',
            target: 'created_at'
          },
        }
      }),
    }
  },

  mounted() {
    this.table.fetch();
  },

  methods: {
    view(item) {
      this.$router.push({path: item.id + '/details', append: true})
    },

    setFilter(type) {
      if (this[type] === 1) {
        this[type] = 0
      } else {
        this[type] = 1
      }
      this.table.fetch();
    },
  }
}
</script>

<style scoped lang="scss">


.actions {
    display: flex;
}

.action {
    padding: 7px;
    color: #999;
    background: #eee;
    margin-right: 10px;
    border-radius: 3px;
    width: 30px;
    text-align: center;
}

.action:hover {
    color: #555;
}

.img {
    max-width: 200px;
    object-fit: contain;
}

.filters {
    align-items: center;
    min-width: 12em;
}

.filters-header {
    margin-bottom: 1em;
    font-weight: 600;

    .icon {
        margin-right: 0.5em;
    }
}

.filter {
    padding: 0.5em 0;
    margin: 0.2em 0 0 1.8em;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 0.9em;

    &:hover {
        input[type=checkbox]::after {
            border-color: var(--color-primary-light);
        }
    }

    input[type=checkbox] {
        position: relative;
        height: 0;

        &::before {
            display: block;
            position: absolute;
            content: ' ';
            background: #FFF;
            width: 1.2em;
            height: 1.2em;
            top: -.55em;
            border-radius: .2em;
        }

        &::after {
            display: block;
            position: absolute;
            content: ' ';
            top: -.5em;
            left: 4.5px;
            width: 4px;
            height: 9px;
            border: solid transparent;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            transition: all $easing .2s;
        }

        &:checked {
            &::after {
                border-color: var(--color-primary);
            }
        }
    }
}
</style>
