<template>
    <transition name="notification-transition" appear>
        <div class="notification width-container" :class="[notification.type]" @click="done()"
             @mouseenter="clearTimer"
             @mouseleave="timer">
            <div class="type" :class="notification.type">
                <awesome-icon :icon="iconFor(notification.type)" class="fa-2x"/>
            </div>
            <div class="content">

                <awesome-icon class="close" icon="times"/>

                <div class="data">
                    <div class="name">{{ notification.title }}</div>

                    <div class="value">
                        <template v-if="typeof(notification.message) === 'object'">
                            <div class="item" v-for="(item,key) in notification.message">
                                <div class="item-label">{{ key }}</div>
                                <template v-if="typeof(item) === 'object'">
                                    <div class="item-value" v-for="line in item">{{ line }}</div>
                                </template>
                                <div v-else class="item-value">{{ item }}</div>
                            </div>
                        </template>
                        <template v-else>
                            <div>{{ notification.message | capitalize }}</div>
                        </template>

                    </div>
                </div>

            </div>

        </div>
    </transition>
</template>

<script>
import ScButton from "../../components/common/sc-button";

export default {
  name: 'notification-panel-item',
  components: {ScButton},
  props: ['notification'],
  data() {
    return {
      fading: false,
      timeout: undefined,
      timing: this.notification.type === 'error' ? 60000 : 5000
    }
  },

  filters: {
    capitalize(str) {
      if (typeof str === 'string') {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
    }
  },

  created() {
    this.timer();
    this.$parent.$on('done', this.done);
  },

  methods: {
    iconFor(type) {
      if (type === 'warning') {
        return 'exclamation-circle'
      } else if (type === 'error') {
        return 'times-circle'
      } else {
        return 'check-circle'
      }
    },

    timer() {
      this.timeout = setTimeout(() => {
        this.done();
      }, this.timing);
    },
    clearTimer() {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    },
    done() {
      this.fading = true;
      this.clearTimer();
      setTimeout(() => {
        this.$emit('done');
      });
    }
  }
}
</script>

<style scoped>

.notification {
    margin: 1em;
    box-shadow: 1px 1px 40px 1px rgba(0, 0, 0, .2);
    border-radius: 0.2em;
    background: rgba(255, 255, 255, 1);
    position: relative;
    display: flex;
    cursor: pointer;
    z-index: 195;
    min-width: 300px;
    overflow: hidden;
}

.notification-content {
    display: flex;
}

.type {
    flex-shrink: 0;
    width: 5em;
    height: inherit;
    position: relative;
    background: #16b029;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
}

.type.warning {
    background: #ffcf7c;
}

.type.error {
    background: #ff7c7c;
}

.content {
    display: flex;
    max-height: 500px;
}

.content .close {
    font-size: 1.5em;
    position: absolute;
    right: 1em;
    top: .5em;
    opacity: .5;
    z-index: 100;
    transition: opacity .3s $easing;
}
.content .close:hover {
    opacity: 1;
}

.content .data {
    width: 100%;
    position: relative;
    overflow-y: auto;
    padding: 1em 1.5em;
}

.notification .name {
    font-weight: 800;
    text-transform: capitalize;
    margin-bottom: .75em;
}

.notification .code {
    white-space: pre-wrap;
    word-wrap: break-word
}

.notification .value {
    color: #555;
    font-size: 1em;
    min-width: 80px;
    max-width: 300px;
    font-weight: 500;
}

.notification.error .value {
    max-width: initial;
}


.item {
    margin-bottom: 15px;
}

.item:last-child {
    margin-bottom: 0;
}

.item-label {
    font-weight: 700;
    margin-bottom: 5px;
}

.item-value {
    margin-bottom: 5px;
    font-size: 0.9em;
    margin-left: 10px;
}

.notification-transition-leave-active,
.notification-transition-enter-active {
    transition: all .3s var(--easing);
}

.notification-transition-leave,
.notification-transition-enter-to {
    opacity: 1;
    transform: translateX(0px);
}

.notification-transition-enter,
.notification-transition-leave-to {
    opacity: 0;
    transform: translateX(200px);
}

</style>