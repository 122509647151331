<template>
    <div class="banner">
        <div class="banner-content">
            <div>
                <portal-target name="banner" tag="div" transition="expanding-transition">
                </portal-target>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "sc-banner",
  props: ['test'],

}
</script>

<style scoped lang="scss">
.banner {
    //min-height: 8em;
    position: relative;
    z-index: 4;
    @include themed() {
        background: t('color-banner');
        box-shadow: t('shading-strong');
    }
}
</style>