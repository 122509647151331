const message = {
  namespaced: true,
  state: {
    name: undefined,
    form: undefined,
    template: undefined,
    message: undefined,
    errors: undefined,
    media: undefined,
    locations: undefined
  },

  actions: {
    SET_NAME({commit}, name) {
      return new Promise(resolve => {
        commit('setName', name);
        resolve();
      })
    },

    UPDATE_FORM({commit}, form) {
      commit('updateForm', form);
    },

    SET_EXISTING({commit}, data) {
      return new Promise((resolve) => {
        commit('setName', data.name);
        commit('setTemplate', data.message.template);
        commit('setMessage', data.message);
        commit('setMedia', data);

        const form = {
          name: data.name,
          template: {
            id: data.message.template.id
          },
          startValidDate: data.start_valid_date,
          endValidDate: data.end_valid_date,
          playlists: data.playlists,
          fields: data.message.template.template_fields.map(x => {
            return {
              type: x.type,
              name: x.name,
              value: data.message.message_fields.find(mf => mf.name === x.name).value || ''
            }
          })
        };
        commit('updateForm', form);

        resolve();
      })
    },

    CLEAR({commit}) {
      commit('clear');
    },
    CLEAR_ERROR({commit}) {
      commit('clearErrors');
    },
    VALIDATE({commit, state}) {
      new Promise((resolve) => {
        let emptyCount = 0;
        let errorMessage = {};

        if (state.form.name === '') {
          emptyCount++;
          // this.nameRequiredError = true;
        }

        // Validate presence based on used template required state on field.
        for (let i = 0; i < state.form.fields.length; i++) {
          if (state.template.template_fields[i].required && [undefined, ''].includes(state.form.fields[i].value)) {
            emptyCount++;
            errorMessage[state.form.fields[i].name] = 'Required field'
          }
        }
        if (state.form.endValidDate === '') {
          emptyCount++;
          errorMessage['endValidDate'] = 'Required field'
        }
        if (state.form.startValidDate === '') {
          emptyCount++;
          errorMessage['startValidDate'] = 'Required field'
        }

        if (emptyCount > 0) {
          commit('setErrors', errorMessage);
        } else {
          commit('clearErrors');
        }
        resolve();
      })
    },
    SET_ERRORS({commit}, errors) {
      commit('setErrors', errors);
    }
  },

  mutations: {
    clear(state) {
      state.name = undefined;
      state.form = undefined;
      state.template = undefined;
      state.errors = undefined;
      state.message = undefined;
      state.media = undefined;
      state.locations = undefined;
    },

    clearErrors(state) {
      state.errors = undefined;
    },

    setName(state, name) {
      state.name = name;
    },

    setForm(state) {
      const form = {};
      form.name = state.name;
      form.template = {
        id: state.template.id
      };
      form.startValidDate = new Date();
      form.endValidDate = '';
      form.playlists = [];
      form.fields = [];
      state.template.template_fields.map(x => {
          /**
           * Default value to empty string forcing validation on new.
           * If useDefault is set update value with default value.
           * Image doesn't use default. Set to empty to start,
           * this will force user to select and image.
           **/
          let value = '';
          if (x.useDefault && (
            x.type !== 'IMAGE' && x.type !== 'VIDEO'
            || (x.widgetType === 'RADIO' && ['VIDEO', 'IMAGE'].includes(x.type))
          )) {
            value = x.value;

            // TODO: make pretty
            if (x.widgetType === 'RADIO' && ['VIDEO', 'IMAGE'].includes(x.type)) {
              value = x.mediaItemId;
            }

          } else if (['IMAGE','VIDEO'].includes(x.type)) {
            value = null;
          }

          form.fields.push({
            widgetType: x.widgetType,
            type: x.type,
            name: x.name,
            value: value
          })
        }
      )

      state.form = form;
    },

    setMedia(state, data) {
      state.media = data;
    },
    updateForm(state, form) {
      state.form = form;
    },
    setTemplate(state, template) {
      state.template = template;
    },
    setMessage(state, message) {
      state.message = message;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    setLocations(state, locations) {
      state.locations = locations;
    }
  },
  getters: {
    getName(state) {
      return state.name;
    }
    ,

    getForm(state) {
      return state.form;
    }
    ,

    getTemplate(state) {
      return state.template;
    }
    ,

    getMessage(state) {
      return state.message;
    }
    ,

    getErrors(state) {
      return state.errors;
    }
    ,

    getMedia(state) {
      return state.media;
    }
    ,
    getLocations(state) {
      return state.locations;
    }
  }
}

export default message;

