<template>
    <div class="app-container">
        <ta-nav @setMargin="setMargin"></ta-nav>
        <div class="main-container" :style="{marginLeft:margin + 'px'}">
            <ta-header/>
            <sc-banner/>
            <portal-target name="sc-modal" transition="fade-in-transition"></portal-target>
            <children-route class="route-container" :animate="true"></children-route>
        </div>
        <sc-footer :margin="margin"></sc-footer>
    </div>
</template>

<script>
import TaNav from "@/components/ta/ta-nav";
import ScFooter from "@/components/ta/sc-footer";
import TaHeader from "@/components/ta/ta-header";
import ScBanner from "@/components/common/sc-banner";

export default {
  name: 'app',
  components: {ScBanner, TaHeader, ScFooter, TaNav},
  data() {
    return {
      margin: 60,
      theme: 'default',
      mql: undefined
    }
  },

  created() {
    if (localStorage.getItem('theme')) {
      let theme = localStorage.getItem('theme');
      if (theme === 'inherit' || theme === 'default' || theme === 'dark') {
        this.$store.dispatch('user/setTheme', localStorage.getItem('theme'))
      } else {
        localStorage.removeItem('theme')
      }
    }
    this.mql = window.matchMedia('(prefers-color-scheme:dark)');
    this.mql.addListener(this.mqlChange);
    this.mqlChange(this.mql);
  },

  beforeDestroy() {
    this.mql.removeListener(this.mqlChange)
  },

  mounted() {

    // this.$store.dispatch('user/getUser', {talker: this.$talker})
    //   .then(res => {
    //     if (res.data.password_reset === 1) {
    //       let query = this.$route.query;
    //       query['redirect'] = this.$route.path;
    //       this.$router.push({path: `/users/users/${res.data.id}/settings`, query: query})
    //     }
    //   })
    //   .catch(error => {
    //     if (error.response.status === 401) {
    //       this.$notify.error('Unauthorized');
    //       this.$talker.logout({router: this.$router})
    //         .catch(err => {
    //           console.error(err);
    //         })
    //     }
    //   });

    // this.$store.dispatch('getTranslations', {talker: this.$talker})
  },
  methods: {

    setMargin(value) {
      this.margin = value;
    },
    mqlChange(event) {
      this.$store.dispatch('user/setTheme','default');
    }
  }
}
</script>

<style lang="scss">

.app-container {
    min-height: 100vh;
    position: relative;
}

.main-container {
    transition: margin-left 0.4s $easing;
}

.route-container {
    //   box-shadow: inset 0 5px 10px -5px rgba(100, 100, 100, 0.1);
}

</style>