import notificationPanel from './notification-panel.vue'

const components = [
  notificationPanel
];

import {events} from './events'

const notifier = {
  install(Vue, params) {

    components.forEach((e) => {
      Vue.component(e.name, e);
    });

    Vue.prototype.$notify = {

      success(message) {
        events.$emit('notification', {'type': 'success', 'message': message, 'title': 'Success'});
      },

      error(message, title) {
        events.$emit('notification', {'type': 'error', 'message': message, 'title': title || 'An error occurred'});
      }

    }

  }
};

export default notifier;