/**
 * Sort an array based on the column that is currently selected
 *
 * @param   {Array}   _array      The array you wish to sort
 * @param   {Object}  _sorting    The object that determines the sorting criteria
 */
export default function sortArray(_array, _sorting) {

  let sorting;
  if (!_sorting.current.value) {
    sorting = _sorting.default;
  } else {
    sorting = _sorting.current;
  }

  return _array.sort((valOne, valTwo) => {
    let a = valOne;
    let b = valTwo;
    if (sorting.dir === 'desc') {
      a = valTwo;
      b = valOne;
    }
    let left, right;

    if (sorting.column.type === 'count') {
      left = _.get(b, sorting.column.target).length;
      right = _.get(a, sorting.column.target).length;
    }

    else if (sorting.column.type === 'lastReported') {
      if (a.state.lastReportedTimestamp) {
        left = moment(a.state.lastReportedTimestamp).unix();
      } else if (a.state.lastReported) {
        left = moment(a.state.lastReported).unix();
      } else {
        return 1;
      }
      if (b.state.lastReportedTimestamp) {
        right = moment(b.state.lastReportedTimestamp).unix();
      } else if (b.state.lastReported) {
        right = moment(b.state.lastReported).unix();
      } else {
        return 1;
      }
    }

    else if (sorting.column.type === 'groupname') {
      let mergedNameLeft = (typeof a.details.name !== 'undefined') ? a.details.name + a.name : a.name;
      let mergedNameRight = (typeof b.details.name !== 'undefined') ? b.details.name + b.name : b.name;
      left = mergedNameLeft.toLowerCase();
      right = mergedNameRight.toLowerCase();
    }

    else if (sorting.column.type === 'date') {
      let leftDate = _.get(a, sorting.column.target);
      if (typeof left === 'undefined') {
        return 1;
      }
      left = moment(leftDate).unix();
      let rightDate = _.get(b, sorting.column.target);
      if (typeof right === 'undefined') {
        return 1;
      }
      right = moment(rightDate).unix();
    }

    else {
      let leftVal = _.get(a, sorting.column.target);
      if (typeof leftVal === 'undefined') {
        return 1;
      }
      if (leftVal === null) {
        if (sorting.dir === 'asc') {
          return 1;
        } else {
          return -1;
        }
      }
      let rightVal = _.get(b, sorting.column.target);
      if (typeof rightVal === 'undefined') {
        return 1;
      }
      if (rightVal === null) {
        if (sorting.dir === 'asc') {
          return -1;
        } else {
          return 1;
        }
      }
      if (typeof leftVal === 'string') {
        left = leftVal.toLowerCase();
      }
      if (typeof rightVal === 'string') {
        right = rightVal.toLowerCase();
      }
      if (typeof leftVal === 'number' && typeof rightVal === 'number') {
        left = rightVal;
        right = leftVal;
      }
    }

    if (left < right) {
      return -1;
    }
    if (left > right) {
      return 1;
    }
    return 0;
  });

}