import VueRouter from 'vue-router';
// import _children from '@/components/common/children-route.vue'

import app from '@/views/app/app.vue';
import home from '@/views/app/home/routes.js';
import usermanagement from '@/views/app/user-management/routes.js';
import badwords from '@/views/app/badwords/routes.js';
import user from '@/views/app/user/routes.js';
import usergroups from '@/views/app/usergroups/routes.js';
import roles from '@/views/app/roles/routes.js';
import media from '@/views/app/media/routes.js';
import playlists from '@/views/app/playlists/routes.js';
import approval from '@/views/app/approval/routes.js';
import messages from '@/views/app/messages/routes.js';
import players from '@/views/app/players/routes.js';
import reports from '@/views/app/reports/routes.js';

import userLogin from '@/views/auth/login.vue'
import login from '@/views/auth/sso-login.vue'
import forgot from '@/views/auth/forgot.vue'
import reset from '@/views/auth/password-reset.vue'

import admin from '@/views/app/admin/routes.js';


const RCONF = {cookie: undefined};
if (typeof mfC !== 'undefined') {
  RCONF.cookie = mfC.cookie;
} else {
  RCONF.cookie = process.env.VUE_APP_COOKIE_NAME;
}

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0}
  },
  routes: [
    {
      path: '/',
      redirect: '/home',
      meta: {requiresAuth: true, navRoot: true},
      component: app,
      children: [
        home,
        user,
        usermanagement,
        usergroups,
        media,
        playlists,
        badwords,
        approval,
        messages,
        roles,
        players,
        reports,
        admin
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: process.env.NODE_ENV === 'development' ? userLogin : login ,
      beforeEnter: (to, from, next) => {
          let cookie = (RCONF.cookie) ? RCONF.cookie : 'bearer';
          if (localStorage.getItem(cookie)) {
          next({
            path: '/'
          })
        }
        else {
          next();
        }
      }
    },
    {
      path: '/user-login',
      name: 'user-login',
      component: userLogin
    },
    {
      path: '/forgot-password',
      component: forgot,
      beforeEnter: (to, from, next) => {
          let cookie = (RCONF.cookie) ? RCONF.cookie : 'bearer';
          if (localStorage.getItem(cookie)) {
          next({
            path: '/'
          })
        }
        else {
          next();
        }
      }
    },
    {
      path: '/password-reset',
      component: reset,
      beforeEnter: (to, from, next) => {
          let cookie = (RCONF.cookie) ? RCONF.cookie : 'bearer';
          if (localStorage.getItem(cookie)) {
          next({
            path: '/'
          })
        }
        else {
          next();
        }
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
});

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        let cookie = (RCONF.cookie) ? RCONF.cookie : 'bearer';
        if (!localStorage.getItem(cookie)) {
            let query = to.query;
            query['redirect'] = to.path;
            next({
                path: '/login',
                query: query,
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
