<template>
    <div>
        <div class="width-container pd">

            <breadcrumbs :items="['Manage Content', 'Playlists']"></breadcrumbs>

            <sc-modal v-if="creatingPlaylist === true" @close="creatingPlaylist = false">
                <breadcrumbs :items="['Create a New Playlist']"/>

                <new-playlist></new-playlist>
            </sc-modal>

            <sc-table :table="table" @view="view">

                <template #column-thumbnail="{row}">
                    <img class="thumbnail" v-if="row.thumbnail_storage_path"
                         :src="row.thumbnail_storage_path"/>
                    <div class="thumbnail placeholder" v-else>
                        <img :src="require('@/img/icon-manage.png')"/>
                    </div>
                </template>

                <template #column-details="{row}">
                    <sc-table-cell :table="table" field="details">
                        <div v-if="row.description" class="detail">
                            <span class="text-lightgrey">Description: </span> {{ row.description }}
                        </div>
                        <div v-if="row.duration" class="detail">
                            <span class="text-lightgrey">Duration: </span> {{ row.duration }}
                        </div>
                        <div class="detail">
                            <span class="text-lightgrey">Location: </span> {{ getLocation(row) }}
                        </div>
                        <div class="detail" v-if="row.item_count && row.item_count > 0">
                            <span class="text-lightgrey">Items: </span> {{ row.item_count }} <br />
                            <PlaylistExpiresSoonChecker :id="row.id" v-if="row.item_count > 0"/>
                        </div>
                    </sc-table-cell>
                </template>

            </sc-table>
        </div>
    </div>
</template>

<script>

import NewPlaylist from "../../../components/playlists/new-playlist";
import PlaylistExpiresSoonChecker from "./components/PlaylistExpiresSoonChecker";

export default {
  name: "all",
  components: {PlaylistExpiresSoonChecker, NewPlaylist},
  data() {
    return {
      query: '',
      creatingPlaylist: false,
      table: this.$stable.createTable({
        url: '/playlists',
        sortBy: 'updated_at',
        sortDir: 'desc',
        columns: {
          thumbnail: {sort: false, name: 'playlist', width: 250},
          name: {fill: true},
          details: {sort: false, fill: true},
          updated_at: {
            name: 'last modified'
          },
        },
      }),
    }
  },

  mounted() {
    this.table.fetch();
  },

  methods: {
    view(item) {
      this.$router.push({path: item.id + '/details', append: true})
    },

    getLocation(item) {
      if (item && item.timeslot && (item.timeslot.channel_player && item.timeslot.channel_player.length > 0)
            && item.timeslot.channel_player[0].player
            && (item.timeslot.channel_player[0].player.user_groups && item.timeslot.channel_player[0].player.user_groups.length > 0)) {
        return item.timeslot.channel_player[0].player.user_groups[0].name
      } else {
        return "Player not attached to location"
      }
    }
  }
}
</script>

<style scoped lang="scss">

.thumbnail {
    max-width: 100%;
    max-height: 100px;
    object-fit: contain;
    margin: 2px 0;
    position: relative;

    &.placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;

        img {
            opacity: .4;
        }
    }
}

.table-buttons {
    background: #fff;
    display: flex;
}

.detail {
    margin-bottom: .25em;
}

</style>
