<template>
    <div class="affected">
        <!-- Affected elements -->
        <h1 class="heading pd">Preview</h1>


        <div class="buttons-overview pdw">
            <div class="overview-row">
                <div class="cell"></div>
                <div class="cell title">Normal</div>
                <div class="cell title">Running</div>
                <div class="cell title">Disabled</div>
            </div>

            <div class="overview-row" v-for="c in ['primary','secondary','reject']">
                <div class="cell title">{{ c }}</div>
                <div class="cell">
                    <sc-button :color="c">Button</sc-button>
                </div>
                <div class="cell">
                    <sc-button :color='c' :running="true">Button</sc-button>
                </div>
                <div class="cell">
                    <sc-button :color='c' :disabled="true">Button</sc-button>
                </div>
            </div>

            <div class="overview-row">

                <sc-table-checkbox :status="checkbox" @toggle="checkbox= checkbox === 'active' ? 'off' : 'active'"/>
                <br/>
                <br/>
            </div>

            <sc-days :selected="selectedDays" @select="toggleDay" :format="(v) => {return v.substring(0, 3).toLowerCase()}"/>

        </div>

        <div class="tables">
            <sc-table :table="table">

                <template #column-actions="{row}">
                    <sc-table-cell :table="table" field="actions">
                        <template #actions>
                            <sc-table-button tooltip="edit"
                                             icon="edit"/>
                            <sc-table-button-delete :item="row"/>
                        </template>
                    </sc-table-cell>
                </template>

            </sc-table>
        </div>
    </div>
</template>

<script>
export default {
  name: "AffectedElements",
  data() {
    return {
      // Affected
      table: this.$stable.createTable({
        sortBy: "Column one",
        columns: {
          "Column one": {},
          actions: {
            width: 200,
            sort: false
          },
        }
      }),
      // checkbox
      checkbox: 'active',
      //days
      selectedDays: ['MONDAY','TUESDAY','WEDNESDAY'],
    }
  },
  mounted() {
    // SET FAKE DATA FOR TABLE
    this.table.data = [
      {"Column one": 'cell content', "Column two": 'cell content', "Actions": undefined},
    ]
    this.table.hasData = true;
    this.table.prefetch();
  },
  methods: {
    toggleDay(day)  {
      let indexOf = this.selectedDays.indexOf(day);
      if (indexOf === -1) {
        this.selectedDays.push(day);
      } else {
        this.selectedDays.splice(indexOf,1);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.affected {
    border: 1px solid #ccc;
    box-sizing: border-box;
}
.buttons-overview {

    .overview-row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 1em;
        margin-bottom: .25em;

        .cell {
            display: flex;
            justify-content: left;
            align-items: center;

            ::v-deep .button-container {
                margin-right: 0;
                width: 100%;

                .button {
                    width: 100%;
                }
            }

            &.title {
                font-weight: 600;
            }

            .button {
                width: 100%;
            }
        }
    }
}

.heading {
    text-transform: capitalize;
    margin-bottom: 1em;
}

h1.heading {
    font-size: 1.5em;
}

h2.heading {
    font-size: 1.25em
}

.title {
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: .5em;
    text-align: center;
}

</style>