<template>
    <div class="pd">
        <side-panel>
            <sc-search :query="query" @searched="searched"></sc-search>
        </side-panel>

        <breadcrumbs :items="['players']"></breadcrumbs>

        <div class="table-buttons pd pdb0">
            <sc-button color="secondary" v-if="$store.getters.UserHasPermission('create-player')"
                       @click="$router.push('/players/new')">
                <awesome-icon icon="plus"/> New Player
            </sc-button>
        </div>

        <sc-table :table="table">

            <template #column-featureLicenseType="{row}">
                <sc-table-cell :table="table" field="featureLicenseType">
                    {{ row.featureLicenseType.type }}
                </sc-table-cell>
            </template>

            <template #column-status="{row}">
                <sc-table-cell :table="table" field="status" v-if="row.state">
                    <span style="text-transform:lowercase">{{ row.state.state }}</span>
                    <template #sub><span style="text-transform:lowercase">{{ row.state.planState }}</span></template>
                </sc-table-cell>
            </template>

            <template #column-playback="{row}">
                <sc-table-cell :table="table" field="playback" v-if="row.state">
                    <span style="text-transform:lowercase">{{ row.state.active }}</span>
                </sc-table-cell>
            </template>

            <template #column-actions="{row}">
                <sc-table-cell :table="table" field="actions">
                    <sc-table-button icon="edit" v-if="$store.getters.UserHasPermission('update-player')" @click.native="editPlayer(row)" tooltip="edit"/>
                </sc-table-cell>
            </template>

        </sc-table>
    </div>
</template>

<script>

export default {
  name: "all",

  data() {
    return {
      query: '',
      args: undefined,
      table: this.$stable.createTable({
        fetchArguments: () => {
          return this.args
        },
        url: '/admin/players',
        sortBy: 'updated_at',
        sortDir: 'desc',
        columns: {
          id: {},
          name: {},
          status: {
            target: 'state.state',
            width: 250,
            sort: false
          },
          last_reported: {
            target: 'state.lastReportedTimestamp',
            type: 'date',
            sort: false,
          },
          playback: {
            target: 'state.active',
            sort: false,
          },
          player_ip: {
            target: 'state.ip',
            sort: false,
          },
          featureLicenseType: {
            sort: false,
            name: 'type',
          },
          updated_at: {
            type: 'date'
          },
          actions: {
            sort: false
          }
        }
      })
    }
  },

  mounted() {
    this.searched();
  },
  methods: {
    searched(string) {
      this.query = string;

      if (this.query) {
        this.args = `&where_name=${this.query}`
      } else {
        this.args = undefined;
      }
      this.table.fetch();
    },

    editPlayer(p) {
      this.$router.push(`/players/${p.id}/edit`)
    }
  }
}
</script>

<style scoped>

.table-buttons {
    background: #fff;
    display: flex;
}

</style>