<template>
    <div>
        <div class="panel pd">

            <div class="buttons" style="justify-content: flex-start">
                <sc-button color="secondary" @click="addingNew = true"
                           v-if="$store.getters.UserHasPermission('update-playlist')">Add media
                </sc-button>
                <sc-button color="secondary" @click="editSelected()" v-if="selected.length > 0">Edit schedules
                </sc-button>
                <sc-button color="secondary" @click="startDelete(selected)" v-if="selected.length > 0">Remove media
                </sc-button>
            </div>

            <div>
                <div class="headers">
                    <div class="checkbox" v-if="$store.getters.UserHasPermission('update-playlist')">
                        <sc-table-checkbox @click.native="checkAll()" :status="selectStatus"
                                           color="dark"></sc-table-checkbox>
                    </div>
                    <div class="image">Media</div>
                    <div class="name">name</div>
                    <div class="details">details</div>
                    <div class="actions"></div>
                </div>
                <draggable class="playlist-items items" v-if="items"
                           :list="items" :group="{name:'media'}" @change="setDirty">
                    <div class="playlist-item item" v-for="(id,index) in items" :key="index">
                        <div class="item-inner">
                            <div class="checkbox" v-if="$store.getters.UserHasPermission('update-playlist')">
                                <sc-table-checkbox @click.native="check(id)"
                                                   :status="selected.includes(id)?'active':'false'"
                                                   color="dark"></sc-table-checkbox>
                            </div>
                            <div class="image">
                                <img :src="getMediaItem(id).thumbnail_storage_path"/>
                            </div>
                            <div class="name">
                                <div class="name">
                                    {{ getMediaItem(id).name }}
                                    <expire-checker :items="[playlist_items.find(i => i.id === id), getMediaItem(id)]"/>
                                </div>
                                <span v-if="getMediaItem(id).approved !== 'approved'" style="color:#ff6d72;font-size: .8em;text-transform:capitalize;">({{getMediaItem(id).approved}})</span>
                            </div>
                            <div class="details">
                                <media-types :type="getMediaItem(id).media_type"></media-types>
                            </div>
                            <div class="actions">
                                <div class="buttons" v-if="$store.getters.UserHasPermission('update-playlist')">
                                    <sc-table-button tooltip="edit" icon="edit" @click.native.stop="startEdit([id])"/>
                                    <sc-table-button tooltip="delete" icon="trash"
                                                     @click.native.stop="startDelete([id])"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </draggable>
                <div class="item empty" v-if="items.length === 0">No items found</div>
            </div>
            <div class="buttons" v-if="$store.getters.UserHasPermission('update-playlist')">
                <div v-if="!unsaved" class="dnd">Drag & Drop to change play order</div>
                <sc-button color="secondary" v-if="unsaved" :running="saving" @click="submit()"><i
                        class="fas fa-save"></i>Save sorting
                </sc-button>
            </div>
        </div>

        <playlist-item-add v-if="addingNew"
                           :playlist="playlist"
                           @close="newlyAdded"
        ></playlist-item-add>

        <playlist-item-edit v-if="editItems.length > 0"
                            :playlist="playlist"
                            :items="editItems"
                            :playlist_items="playlist_items"
                            @close="stopEditing"
        ></playlist-item-edit>

        <sc-modal @close="deleting = []" v-if="deleting.length > 0">
            <div class="delete">
                <div>About to remove ({{ deleting.length }}) Media items from the playlist.</div>
                <div>Are you sure?</div>
                <sc-button color="secondary" @click="deleteSelected">Yes, delete</sc-button>
            </div>
        </sc-modal>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import PlaylistItemAdd from "./PlaylistItemAdd";
import PlaylistItemEdit from "./PlaylistItemEdit";
import ExpireChecker from "../../../../components/common/expire-checker";

export default {
  name: "PlaylistItemList",
  components: {ExpireChecker, PlaylistItemEdit, PlaylistItemAdd, draggable},
  data() {
    return {
      playlist: undefined,
      playlist_items: [],
      changingOrder: false,
      saving: false,
      unsaved: false,
      items: [],
      addingNew: false,
      editItems: [],
      selected: [],
      deleting: []
    }
  },

  mounted() {
    this.fetchOthers();
  },

  computed: {
    selectStatus() {
      if (this.selected.length > 0 && this.selected.length === this.playlist_items.length) {
        return 'active'
      } else if (this.selected.length > 0 && this.selected.length < this.playlist_items.length) {
        return 'half'
      } else {
        return false
      }
    }
  },

  methods: {

    editSelected() {
      this.editItems = this.selected;
    },

    startDelete(ids) {
      this.deleting = ids;
    },

    deleteSelected() {
      let payload = {
        "playlist-id": [parseInt(this.$route.params.playlist_id)],
        "playlist-items": this.deleting
      };

      this.$talker.api.put('playlist-items/remove', payload)
        .then(res => {
          this.stopEditing()
          this.deleting = []
        })
        .catch(() => {
          this.$notify.error('Failed to remove')
        })
    },

    checkAll() {
      if (this.selected.length === this.playlist_items.length) {
        this.selected = [];
      } else {
        this.selected = this.playlist_items.map(x => x.id)
      }
    },

    check(id) {
      let index = this.selected.indexOf(id);
      if (index === -1) {
        this.selected.push(id)
      } else {
        this.selected.splice(index, 1);
      }
    },


    submit() {

      this.saving = true;

      let payload = {
        "playlist-id": [this.$route.params.playlist_id],
        "playlist-items": this.items
      };

      this.$talker.api.post(`playlist-items/sync-sort-order`, payload)
        .then(res => {
          this.$notify.success('Playlist sorting saved');
          this.saving = false;
          this.unsaved = false;
        })
        .catch(err => {
          this.saving = false;
        })
    },

    getMediaItem(id) {
      return this.playlist_items.find(x => x.id === id).media_item
    },

    fetchOthers() {

      this.$talker.api(`playlists/${this.$route.params.playlist_id}`)
        .then(res => {
          this.playlist = res.data;
        });

      this.$talker.api(`playlists/${this.$route.params.playlist_id}/items`)
        .then(res => {
          this.playlist_items = res.data;
          this.items = [];
          this.playlist_items.sort((a, b) => {
            if (a.sort_order > b.sort_order) {
              return 1
            }
            if (a.sort_order < b.sort_order) {
              return -1
            }
            return 0
          })
          for (let i = 0; i < this.playlist_items.length; i++) {
            this.items.push(this.playlist_items[i].id)
          }
        });
    },

    newlyAdded() {
      this.fetchOthers();
      this.unsaved = false;
      this.addingNew = false;
    },

    startEdit(items) {
      this.editItems = items
    },

    stopEditing() {
      this.fetchOthers();
      this.editItems = [];
      this.selected = [];
    },

    setDirty() {
      this.unsaved = true;
    }
  }
}
</script>

<style scoped lang="scss">
.columns {
    display: flex;
    align-items: flex-start;
}

.col {
    flex: 8;
    /*padding: 15px;*/
    box-sizing: border-box;
    margin: 0 10px;
    position: relative;
}

.col.drag-info {
    flex: 1;
    position: relative;
}

.col.drag-info .drag-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.2);
    position: absolute;
    font-weight: 700;
    left: 0;
    right: 0;
    top: 100px;
}

.col.drag-info .drag-info-container .info {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.2em;
}

.col.drag-info .drag-info-container .info .and {
    font-size: 3em;
    margin: -12px 0;
    color: rgba(0, 0, 0, 0.04);
}

.col.drag-info .drag-info-container .info .and-arrows {
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.2);
    margin: 3px 0;
}

.col.drag-info .drag-info-container .info .and-arrows i {
    color: rgba(0, 0, 0, 0.1);
}

.items {

}

.item {
    min-width: 10em;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-bottom: 0;

    &:last-child {
        border-bottom: 1px solid #ccc;
    }

    .item-inner {
        padding: 8px 10px;
        box-sizing: border-box;
        display: flex;
        background: #fff;
        cursor: move;
        position: relative;
    }

    .image {
        flex: 1;
        margin-right: 1em;

        img {
            width: 100%;
            max-height: 100px;
            object-fit: contain;
        }
    }

    .name {
        flex: 2;
        word-break: break-word;
    }

    .details {
        flex: 2;
    }
}

.headers {
    margin-top: 1.5em;
    background: #515B6B;
    color: #fff;
    font-size: 0.8em;
    padding: 0.8em 1em;
    box-sizing: border-box;
    text-transform: capitalize;
    font-weight: 600;
    display: flex;
    overflow: hidden;


    .image {
        flex: 1;
    }

    .name, .details {
        flex: 2;
    }
}

.checkbox {
    flex: 0.2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttons {
    display: flex;
    justify-content: center;
    margin-top: 1em;
}

.panel {
    border: 1px solid #ccc;
}

.dnd {
    font-weight: 600;
    font-size: 1.1em;
    margin: 1em 0;
}

.edit-icon {
    padding: 1em;
    background: #0073aa;
    color: #fff;
    font-size: 0.8em;
    cursor: pointer;
}

.actions {
    flex: 1;
}

.actions {
    .buttons {
        margin: 0;
        display: flex;
        align-items: center;

    }
}

.delete > div {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 1em;
}

.empty {
    text-align: center;
    padding: 2em;
}
</style>
