<template>
    <div>
        <sc-form :form="form" @submit="submit">
            <template #field-video_data>
                <div class="dropzone" ref="dropzone" @drop.prevent="addFile" @dragover.prevent
                     @dragenter="dragging=true"
                     @dragend="dragging=false"
                     @dragleave="dragging=false" :class="{dragging, hidden:!hasDragAndDrop}">

                    <div style="font-size: .8em;line-height: 1.25em;" class="file-requirements">
                        <div v-if="fileRequirements.width.min">Min. Width: {{
                                fileRequirements.width.min
                            }}px
                        </div>
                        <div v-if="fileRequirements.width.max">Max. Width: {{
                                fileRequirements.width.max
                            }}px
                        </div>
                        <div v-if="fileRequirements.height.min">Min. Height: {{
                                fileRequirements.height.min
                            }}px
                        </div>
                        <div v-if="fileRequirements.height.max">Max. Height: {{
                                fileRequirements.height.max
                            }}px
                        </div>
                        <div v-if="fileRequirements.framerate">Framerate: {{
                                fileRequirements.framerate
                            }}
                        </div>
                        <div v-if="fileRequirements.size">Max. Size: {{ fileRequirements.size | byteToMB }}</div>
                        <div v-if="fileRequirements.amount" :class="{limitReached:fileLimitReached }">
                            Max. Amount: {{ fileRequirements.amount }}
                        </div>
                    </div>

                    <div class="files-list">
                        <div class="file" v-for="file in form.fields.video_data">
                            {{ file.name }} ({{ file.size | byteToMB }})
                            <button @click="removeFile(file)" title="Remove"><awesome-icon icon="times"></awesome-icon></button>
                        </div>
                    </div>
                    <div class="dropzone-info" :class="{limitReached:fileLimitReached}">
                        <awesome-icon icon="upload"></awesome-icon>
                        <div class="dropzone-info-link">
                            <label for="file"><span>Select video files</span> or drag them here</label>
                        </div>
                        <div v-if="fileLimitReached" style="font-size:0.8em;margin-top:0.5em;font-weight:800;color:#1175aa">
                            ( max file amount reached )
                        </div>
                    </div>
                </div>
                <input id="file" :class="{hidden:hasDragAndDrop, limitReached:fileLimitReached }" type="file"
                       ref="files"
                       multiple
                       @change="addFiles" :accept="acceptedTypes"/>


                <br/>
            </template>

            <template #field-startValidDate>
                <sc-field-valid-date-range :TTL="TTL" :errors="errors"
                                           @validFromChange="validFromChange"
                                           @validToChange="validToChange"
                                           :validFromDate.sync="form.fields.startValidDate"
                                           :validToDate.sync="form.fields.endValidDate" classes=""/>
            </template>

            <template #field-endValidDate>
                <div style="display:none;"/>
            </template>

            <template #field-override_flag>
                <div style="display:none;"/>
            </template>

        </sc-form>
    </div>
</template>

<script>
import ScFieldValidDateRange from "../common/sc-field-valid-date-range";

export default {
  name: 'new-video',
  components: {ScFieldValidDateRange},
  data() {
    return {
      tags: '',
      files: [],
      dragging: false,
      hasDragAndDrop: false,
      validFromTimestamp: new Date(),
      validToTimestamp: '',
      errors: {},
      form: this.$sform.createForm({
        url: 'media/multiple-video-upload',
        fields: {
          video_data: {default: []},
          tags: {
            description: 'Separate tags with commas'
          },
          startValidDate: {
            name: 'Valid from',
            type: 'date',
            default: new Date()
          },
          endValidDate: {
            name: 'Valid to',
            type: 'date'
          },
          override_flag: {}
        }
      })
    }
  },

  filters: {
    byteToMB(value) {
      return (value / 1024 ** 2).toFixed(2) + ' MB';
    },
  },

  computed: {
    uploadDisabled() {
      return this.files.length === 0 || this.form.processing;
    },
    isProd() {
      return typeof mfC !== 'undefined';
    },
    fileRequirements() {

      return {
        width: {
          min: this.isProd ? mfC.video.min_width : process.env.VUE_APP_VIDEO_MIN_WIDTH,
          max: this.isProd ? mfC.video.max_width : process.env.VUE_APP_VIDEO_MAX_WIDTH
        },
        height: {
          min: this.isProd ? mfC.video.min_height : process.env.VUE_APP_VIDEO_MIN_HEIGHT,
          max: this.isProd ? mfC.video.max_height : process.env.VUE_APP_VIDEO_MAX_HEIGHT
        },
        framerate: this.isProd ? mfC.video.min_frame_rate : process.env.VUE_APP_VIDEO_MIN_FRAME_RATE,
        size: this.isProd ? mfC.video.max_file_size : process.env.VUE_APP_VIDEO_MAX_FILE_SIZE,
        amount: this.isProd ? mfC.video.upload_limit_amount : process.env.VUE_APP_VIDEO_UPLOAD_LIMIT_AMOUNT
      }
    },
    acceptedTypes() {
      return this.isProd ? mfC.video.mime_types : process.env.VUE_APP_VIDEO_MIME_TYPES;
    },
    TTL() {
      return this.isProd ? mfC.ttl_media : process.env.VUE_APP_TTL_MEDIA;
    },

    fileLimitReached() {
      if (this.fileRequirements.amount) {
        return parseInt(this.fileRequirements.amount) <= this.files.length
      } else {
        return false
      }
    },

  },

  mounted() {
    this.$nextTick(() => {
      let div = this.$refs.dropzone;
      if (div) {
        if ((('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window) {
          this.hasDragAndDrop = true;
        }
      }
    })
  },

  methods: {
    addFiles(e) {
      let files = e.target.files;
      ([...files]).forEach(f => {
        if (!this.fileLimitReached) {
          this.form.fields.video_data.push(f);
        }
      });
    },

    removeFile(file) {
      let index = this.form.fields.video_data.indexOf((f) => f === file);
      this.form.fields.video_data.splice(index, 1);
    },

    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      ([...droppedFiles]).forEach(f => {
        if (!this.fileLimitReached) {
          this.form.fields.video_data.push(f);
        }
      });
      this.dragging = false;
    },

    dateRangeValid() {
      this.errors = {};
      let emptyChecks = ['', null, undefined];

      if (emptyChecks.includes(this.form.fields.startValidDate)) {
        this.errors['startValidDate'] = 'Required field';
      }
      if (emptyChecks.includes(this.form.fields.endValidDate)) {
        this.errors['endValidDate'] = 'Required field';
      }

      return !emptyChecks.includes(this.form.fields.startValidDate) && !emptyChecks.includes(this.form.fields.endValidDate);
    },

    containsProfanity() {
      return this.$talker.api.post('/bad-words/check', {
        'fields': [
          {
            'name': 'tags',
            'value': this.form.fields.tags
          }
        ]
      })
        .catch((err) => {
          throw err;
        })
    },

    submit() {
      this.form.processing = true;

      if (!this.dateRangeValid()) {
        this.form.processing = false;
        return
      }

      this.containsProfanity()
        .then(() => {
          let formdata = new FormData();

          for (let i = 0; i < this.form.fields.video_data.length; i++) {
            let file = this.form.fields.video_data[i];
            formdata.append('video_data[' + i + ']', file);
          }

          formdata.append('override_flag', 0);
          formdata.append('tags', this.form.fields.tags.split(','));
          formdata.append('startValidDate', moment(this.form.fields.startValidDate).format('YYYY-MM-DD'));
          formdata.append('endValidDate', moment(this.form.fields.endValidDate).format('YYYY-MM-DD'));

          let config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Accept': 'application/json'
            }
          };

          this.$talker.api.post('media/multiple-video-upload', formdata, config)
            .then(() => {
              this.$notify.success('Media uploaded');
              this.$emit('success')
              this.form.fields.video_data = [];
              this.form.processing = false;
            })
            .catch(error => {
              let response;
              if (typeof error.response.data === 'object') {

                if (Array.isArray(error.response.data.errors)) {
                  response = error.response.data.errors;
                } else {
                  for (let key in error.response.data.errors) {
                    if (key !== 'message') {
                      response = error.response.data.errors[key][0]
                    }
                  }
                }

              } else {
                if (error.response.status === 413) {
                  response = 'Request Entity Too Large';
                } else {
                  response = 'Something went wrong.';
                }
              }
              this.$notify.error(response)
              this.form.processing = false;
            })
        })
        .catch((err) => {
          let errors = {}
          let title = undefined;
          if (err.response && err.response.data && err.response.data.errors) {
            title = err.response.data.message;
            for (let key in err.response.data.errors) {
              err.response.data.errors[key].forEach((e) => {
                errors[e.field] = [e.message]
              });
            }
          }

          this.form.processing = false;
          this.errors = errors;
          this.$notify.error(errors, title)
        })
    },

    validFromChange(value) {
      if (!value) {
        this.errors.startValidDate = 'Required field';
      }
      this.form.fields.startValidDate = value;
    },

    validToChange(value) {
      if (!value) {
        this.errors.endValidDate = 'Required field';
      }
      console.log("value", value);
      this.form.fields.endValidDate = value;
    }
  }
}
</script>

<style scoped lang="scss">
.dropzone {
    width: 100%;
    background: #eee;
    padding: 10px;
    box-sizing: border-box;
}

.dropzone.dragging {
    background: #e7eee7;
}

.dropzone.dragging .files-list {
    pointer-events: none;
}

.files-list {
    text-align: right;
    margin-bottom: 20px;
}

.files-list .file {
    margin-bottom: 5px;
}

.file button {
    border: 1px solid #ccc;
    border-radius: 100px;
    cursor: pointer;
}

.file button i {
    font-size: 10px;
    color: #333;

}

.dropzone {
    position: relative;
}

.dropzone .dropzone-info {
    color: #333;
    text-align: center;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
}

.dropzone .dropzone-info .dropzone-info-link:hover {
    color: #81828b;
    cursor: pointer;
}

.dropzone.dragging .dropzone-info {
    pointer-events: none;
}

.dropzone-info label {
    cursor: pointer;
    padding: 30px;
}

.dropzone-info span {
    font-weight: 900;

}

.dropzone .fa-upload {
    font-size: 3em;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.1);
}

.hidden {
    display: none;
}

.file-requirements {
    position: absolute;
    left: 1em;
    top: 1em;

    .limitReached {
        color: red;
    }
}

.limitReached.dropzone-info {
    pointer-events: none;
}
</style>
