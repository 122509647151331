<template>
    <div class="action-container" :class="{deleting}">
        <sc-table-button tooltip="delete" icon="trash" @click.native.stop="checkUsage" class="delete">
        </sc-table-button>

        <sc-modal class="modal" v-if="deleting" @close="deleting = false">

            <div class="usage-header">
                <span class="attention">{{ item.name }}</span> is used in the following messages and playlists.
            </div>

            <div class="usage" v-if="usage">
                <div class="usage-col messages">
                    <div class="header">Messages</div>
                    <div class="sub-header">
                        These messages will be <span class="attention">permanently</span> deleted
                    </div>
                    <div class="content">
                        <div class="content-line" v-for="message in usage.messages">
                            <div>{{ message.name }}</div>
                            <img :src="message.thumbnail_storage_path"/>
                        </div>
                        <div class="none" v-if="usage.messages.length === 0">
                            No messages found
                        </div>
                    </div>

                </div>
                <div class="usage-col playlists">
                    <div class="header">Playlists</div>
                    <div class="sub-header">
                        The media item will be removed from these playlists
                    </div>
                    <div class="content">
                        <div class="content-line" v-for="playlist in usage.playlists">
                            {{ playlist.name }}
                        </div>
                        <div class="none" v-if="usage.playlists.length === 0">
                            No playlists found
                        </div>
                    </div>

                </div>
            </div>

            <div class="text">{{ text }} <span class="item">{{ item.name }}</span></div>
            <div class="confirm">Are you sure?</div>

            <div class="buttons">
                <sc-button @click="deleting = false">
                    Cancel
                </sc-button>
                <sc-button color="secondary" :disabled="disabled" :running="disabled" @click="runDelete">
                    Yes, delete
                </sc-button>
            </div>
        </sc-modal>
    </div>
</template>

<script>

export default {
  name: "sc-table-button-delete",
  props: {
    text: {
      default: "About to delete"
    },
    item: {}
  },
  data() {
    return {
      deleting: false,
      usage: undefined,
      disabled: true
    }
  },
  methods: {
    destroy() {
      this.$emit('destroy');
      this.deleting = false;
    },

    checkUsage() {
      this.deleting = true;
      this.$talker.api(`media/${this.item.id}/usage`)
        .then(res => {
          this.usage = res.data
          this.disabled = false;
        })
    },

    runDelete() {
      this.disabled = true;
      this.$talker.api.delete(`media/${this.item.id}?forcedelete=1`)
        .then(res => {
          this.$notify.success(`media: ${this.item.name} deleted`);
          this.disabled = false;
          this.deleting = false;
          this.$emit('destroy')
        })
        .catch(err => {
          this.disabled = false;
        })
    }
  }
}
</script>

<style scoped>
.text {
    margin-bottom: 20px;
    text-align: center;
}

.text .item {
    font-weight: 800;
}

.buttons {
    justify-content: center;
}

.buttons .button-container:last-child {
    margin: 0;
}

.confirm {
    margin-bottom: 20px;
    text-align: center;
}

.usage {
    display: flex;
    margin-bottom: 10px;
}

.usage .header {
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: 700;
    margin-bottom: 5px;
}

.usage-col {
    flex: 1;
    width: 400px;
    margin: 20px;
}

.content-line {
    display: flex;
    background: #f0f0f0;
    border-bottom: 1px solid #ccc;
    padding: 10px 15px;
    box-sizing: border-box;
}

.content-line:last-child {
    border: none;
}

.sub-header {
    margin: 5px 0;
    font-size: 0.9em;
}

.usage-header {
    margin-bottom: 10px;
    text-align: center;
}

.attention {
    font-weight: 900;
}

.content-line img {
    max-width: 100px;
    margin-left: 10px;
    max-height: 100px;
    object-fit: contain;
}

.none {
    padding: 10px;
    font-size: 0.8em;
}

.red {
    background: #ec6666;
    border-radius: 7px;
    color: #FFF;
}

.red:hover {
    background: blue;
    color: #000;
}
</style>