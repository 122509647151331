<template>
    <div class="menu-container">
        <div class="menu" v-if="active">
            <template v-if="hasAdminPermissions">
                <div class="menu-button admin" @click.stop="adminMenu = !adminMenu">
                    <div class="icon"><i class="fas fa-wrench"></i></div>
                    <div class="name">Admin</div>
                    <div class="arrow">
                        <i class="fas fa-chevron-right" v-show="!adminMenu"></i>
                        <i class="fas fa-chevron-down" v-show="adminMenu"></i>
                    </div>
                </div>
                <div class="admin-menu" v-if="adminMenu">
                    <div class="admin-menu-option" v-if="$store.getters.UserHasPermission('viewAny-user')"
                         @click.stop="goTo('/user-management')">
                        <i class="fas fa-play"></i>
                        <span>users</span>
                    </div>
                    <div class="admin-menu-option"
                         v-if="$store.getters.UserHasPermission('viewAny-user-group')"
                         @click.stop="goTo('/usergroups')">
                        <i class="fas fa-play"></i>
                        <span>user groups</span>
                    </div>
                    <!--<div class="admin-menu-option" @click.stop="goTo('/locations')">-->
                    <!--<i class="fas fa-play"></i>-->
                    <!--<span>locations</span>-->
                    <!--</div>-->
                    <div class="admin-menu-option" v-if="$store.getters.UserHasPermission('viewAny-bad-word')"
                         @click.stop="goTo('/profanity-filter')">
                        <i class="fas fa-play"></i>
                        <span>bad words</span>
                    </div>
                    <div class="admin-menu-option" v-if="$store.getters.UserHasPermission('viewAny-role')"
                         @click.stop="goTo('/roles')">
                        <i class="fas fa-play"></i>
                        <span>Roles</span>
                    </div>
                </div>
            </template>
            <div class="menu-button" @click.stop="goTo('/media')">
                <div class="icon"><i class="fas fa-upload"></i></div>
                <div class="name">Media library</div>
                <div class="arrow"><i class="fas fa-chevron-right"></i></div>
            </div>
            <div class="menu-button" @click.stop="goTo('/approval/all/mine')">
                <div class="icon"><i class="fas fa-check"></i></div>
                <div class="name">Approvals</div>
                <div class="arrow"><i class="fas fa-chevron-right"></i></div>
            </div>
            <div class="menu-button" @click.stop="goTo('/messages/new/layout')">
                <div class="icon"><i class="fas fa-edit"></i></div>
                <div class="name">Create content</div>
                <div class="arrow"><i class="fas fa-chevron-right"></i></div>
            </div>
            <div class="menu-button" @click.stop="goTo('/playlists')">
                <div class="icon"><i class="fas fa-list"></i></div>
                <div class="name">Manage playlists</div>
                <div class="arrow"><i class="fas fa-chevron-right"></i></div>
            </div>
            <div class="menu-button" @click.stop="goTo('/my-profile')">
                <div class="icon"><i class="fas fa-user"></i></div>
                <div class="name">My profile</div>
                <div class="arrow"><i class="fas fa-chevron-right"></i></div>
            </div>
            <div class="menu-button logout" @click.stop="logout()">
                <div class="icon"><i class="fas fa-lock"></i></div>
                <div class="name">Sign out</div>
                <div class="arrow"><i class="fas fa-chevron-right"></i></div>
            </div>
        </div>
        <div class="menu-backdrop" v-if="active" @click.stop="$emit('close')"></div>
    </div>
</template>

<script>
  export default {
    name: "user-menu",
    props: ['active'],

    data() {
      return {
        adminMenu: false,
      }
    },

    computed: {
      hasAdminPermissions() {
        if (
          this.$store.getters.UserHasPermission('viewAny-user') ||
          this.$store.getters.UserHasPermission('viewAny-user-group') ||
          this.$store.getters.UserHasPermission('viewAny-bad-word') ||
          this.$store.getters.UserHasPermission('viewAny-role')
        ) {
          return true
        }
      }
    },

    methods: {
      goTo(url) {
        this.$router.push(url).catch(() => {
        });
        this.adminMenu = false;
        this.$emit('close');
      },

      comingSoon() {
        this.$notify.error('This is coming soon')
      },

      logout() {
        this.$store.dispatch('logout', {router: this.$router})
      }
    }
  }
</script>

<style scoped>

    .menu {
        position: absolute;
        left: 0;
        min-width: 270px;
        top: 100%;
        box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.3);
        background: #fff;
        /*padding: 1em 0;*/
        z-index: 20;
        color: #333;
    }

    .menu-button {
        font-size: 0.8em;
        font-weight: 700;
        color: #888;
        text-transform: uppercase;
        cursor: pointer;
        /*padding: 1em 2em;*/
        border-top: 1px solid #eee;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .menu-button:first-child {
        border-top: none;
    }

    .menu-button.admin {
        background: var(--gradient);
        color: #ddd;
    }

    .menu-button .icon {
        background: rgba(0, 0, 0, 0.05);
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55px;
    }

    .menu-button.admin .icon {
        background: #410448;
        color: #fff;
    }

    .menu-button .name {
        flex: 1;
        padding: 0 15px;
    }

    .menu-button .arrow {
        padding: 0 15px;
        color: #ccc;
    }

    .menu-button:hover {
        background: rgba(0, 0, 0, 0.05);
    }

    .menu-button.admin:hover {
        background: var(--gradient);
    }

    .menu-backdrop {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 15;
    }

    .menu-button.logout {

    }

    .admin-menu-option {
        display: flex;
        padding: 0 0 0 70px;
        height: 42px;
        align-items: center;
        color: #888;
        text-transform: uppercase;
        transition: background .15s ease-in-out;
        cursor: pointer;
    }

    .admin-menu-option:hover {
        background: #e2e2e2;
    }

    .admin-menu-option i {
        width: 25px;
        font-size: 0.7em;
    }

    .admin-menu-option span {
        font-size: 0.7em;
        font-weight: 700;
    }

</style>
