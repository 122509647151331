import _index from './index.vue'
import _all from './all.vue'
import _styling from './PortalStyling';

import _colors from "./components/styling-color"
import _logo from "./components/styling-logo"


// import _new from './new/index.vue'
// import _edit from './edit/index.vue'
// import _form from './components/form.vue'
// import _schedule from './components/schedule.vue'
// import _locations from './components/locations.vue'

const routes = {

  path: 'admin',
  meta: {name: 'admin'},
  component: _index,
  children: [
    {
      path: '',
      meta: {name: 'all'},
      component: _all,
    },
    {
      path: 'styling',
      meta: {name: 'styling'},
      component: _styling,
      children: [
        {
          path: 'colors',
          meta: {name: 'styling-colors'},
          component: _colors
        },
        {
          path: 'logo',
          meta: {name: 'styling-logo'},
          component: _logo
        }
      ]
      // children: [
      //   {
      //     path: 'create',
      //     name: 'message-create',
      //     meta: {name: 'form'},
      //     component: _form
      //   },
      //   {
      //     path: 'schedule',
      //     name: 'message-schedule-new',
      //     meta: {name: 'schedule'},
      //     component: _schedule
      //   },
      //   {
      //     path: 'locations',
      //     meta: {name: 'locations'},
      //     component: _locations
      //   }
      // ]
    },
    // {
    //   path: ':id',
    //   meta: {name: 'message-edit'},
    //   component: _edit,
    //   children: [
    //     {
    //       path: 'edit',
    //       name: 'message-edit',
    //       meta: {name: 'form'},
    //       component: _form
    //     },
    //     {
    //       path: 'schedule',
    //       meta: {name: 'schedule'},
    //       component: _schedule
    //     },
    //     {
    //       path: 'locations',
    //       meta: {name: 'locations'},
    //       component: _locations
    //     }
    //   ]
    // },
  ]
}

export default routes;
