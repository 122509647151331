<template>
    <div>
        <side-panel>
            <sc-search :query="query" @searched="nameSearch"></sc-search>
            <sc-filter title="orientation" type="radio" :options="['landscape','portrait']"
                       @filter="searched" :value.sync="orientation"/>
            <sc-filter title="status" type="radio" :options="['pending','approved','rejected','expired']"
                       @filter="searched" :value.sync="status"/>
            <sc-filter title="Creator" type="search_select" :options="creatorNames" v-if="availableCreators.length > 0"
                       @filter="searched" :value.sync="creators"/>
        </side-panel>

        <div class="pd">

            <breadcrumbs :items="['messages']"></breadcrumbs>

            <div class="table-buttons pd pdb0">
                <sc-button color="secondary" v-if="$store.getters.UserHasPermission('create-message')"
                           @click="$router.push('/messages?action=new')">
                    <awesome-icon icon="plus"/>New Message
                </sc-button>
            </div>

            <sc-table :table="table" @view="view">
                <template #column-thumbnail="{row}">
                    <sc-table-cell :table="table" field="thumbnail">
                        <span v-if="row.upload_finished_flag === 0">Processing file.</span>
                        <img class="image" v-else-if="row.thumbnail_storage_path"
                             :src="row.thumbnail_storage_path"/>
                    </sc-table-cell>
                </template>

                <template #column-name="{row}">
                    <sc-table-cell :table="table" field="name">
                        <div class="detail text-blue">
                            {{ row.name }}
                        </div>
                        <div class="detail" style="text-transform: capitalize">
                            {{ row.approved }}
                        </div>
                        <expire-checker :item="row" class="detail"/>
                    </sc-table-cell>
                </template>

                <template #column-details="{row}">
                    <sc-table-cell :table="table" field="details">
                        <div v-if="row.description" class="detail">
                            <span class="text-lightgrey">Description: </span> {{ row.description }}
                        </div>
                        <div v-if="row.width && row.height" class="detail">
                            <span class="text-lightgrey">Dimensions: </span> {{ row.width }}x{{ row.height }}
                        </div>
                        <div v-if="row.duration" class="detail">
                            <span class="text-lightgrey">Duration: </span> {{ row.duration }}
                        </div>
                        <div v-if="row.orientation" class="detail">
                            <span class="text-lightgrey">Orientation: </span> {{ row.orientation }}
                        </div>
                        <div v-if="row.creator" class="detail">
                            <span class="text-lightgrey">Creator: </span> {{ row.creator.profile.name }}
                        </div>
                        <div v-if="row.owner" class="detail">
                            <span class="text-lightgrey">Owner: </span> {{ row.owner.name }}
                        </div>
                        <div v-if="row.tags.length > 0" class="detail">
                            <span class="text-lightgrey">Tag(s): </span> {{ row.tags.map(t => t.name).join(', ') }}
                        </div>
                    </sc-table-cell>
                </template>

                <template #column-actions="{row}">
                    <sc-table-cell :table="table" field="actions">
                        <template #actions>
                            <sc-table-button v-if="row.permissions.update"
                                             tooltip="edit"
                                             icon="edit"/>
                            <sc-table-button-delete v-if="row.permissions.delete" :item="row"
                                                    @destroy="table.fetch()">
                            </sc-table-button-delete>
                        </template>
                    </sc-table-cell>
                </template>

            </sc-table>

            <sc-modal v-if="$route.query.action === 'new'" @close="closeNew">
                <breadcrumbs :items="['Choose a Template']"/>
                <new-message></new-message>
            </sc-modal>
        </div>

    </div>
</template>

<script>
import NewMessage from "./new/prompt";
import ExpireChecker from "../../../components/common/expire-checker";

export default {
  name: "all",
  components: {ExpireChecker, NewMessage},
  data() {
    return {
      // Search / filters
      query: '',
      orientation: undefined,
      creators: [],
      availableCreators: [],
      status: undefined,

      args: '',
      table: this.$stable.createTable({
        fetchArguments: () => {
          return this.args
        },
        url: '/media?media_type=2',
        sortBy: 'updated_at',
        sortDir: 'desc',
        columns: {
          thumbnail: {
            sort: false,
            name: 'message',
            width: 250
          },
          name: {fill: true},
          details: {fill: true, sort: false},
          updated_at: {
            name: 'last modified',
            type: 'date'
          },
          created_at: {
            type: 'date'
          },
          actions: {
            target: 'actions',
            visible: true,
            predefined: true,
            sort: false
          },
        },
      }),
    }
  },

  computed: {
    creatorNames() {
      return this.availableCreators.map((c) => c.name).sort()
    }
  },

  mounted() {
    this.fetchFilters();
    this.searched();
  },

  methods: {
    view(item) {
      if (item.permissions.update) {
        this.$router.push({name: 'message-edit', params: {id: item.id}})
      }
    },

    nameSearch(value) {
      this.query = value;
      this.searched()
    },

    searched() {
      let args = [];

      if (this.query) {
        args.push(`where_name=${this.query}`)
      }

      if (this.orientation) {
        if (typeof this.orientation === 'string' && this.orientation !== 'all'
          || typeof this.orientation === 'object' && !this.orientation.includes('all')
        ) {
          args.push(`orientation=${this.orientation}`);
        }
      }

      if (this.status) {
        if (this.status === 'expired') {
          args.push(`valid=expired`);
        } else {
          args.push(`status=${this.status}`)
        }
      }

      if (this.creators && this.creators.length > 0) {
        let ids = this.creators.map(c => this.availableCreators.find(ac => ac.name === c).id)
        args.push(`creator=${ids.join(',')}`)
      }

      if (args.length > 0) {
        this.args = '&' + args.join('&');
      } else {
        this.args = '';
      }

      this.table.fetch()
    },

    closeNew() {
      this.$router.replace({query: {}})
      this.$store.commit('message/clear');
    },

    fetchFilters() {
      // Fetch creators
      this.$talker.api.get('/user/list')
        .then((res) => {
          if (res.data.length > 0) {
            this.availableCreators = res.data
            this.availableCreators.sort();
          }
        })
    }
  }
}
</script>

<style scoped>

.image {
    max-width: 100%;
    max-height: 100px;
    object-fit: contain;
    margin: 2px 0;
}

.table-buttons {
    background: #fff;
    display: flex;
}

.detail {
    margin-bottom: .25em;
}

</style>
