<template>
    <transition name="loader">
        <div class="loader-container" v-if="form.loading === true">
            <div class="sc-loader">
                <div class="text">Loading</div>
                <div class="loader-wrap">
                    <div class="loader"></div>
                    <div class="loader two"></div>
                    <div class="loader three"></div>
                    <div class="loader four"></div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
  export default {
    name: 'sc-loading',
    props: ['form']
  }
</script>

<style scoped>
    .loader-leave-active {
        transition: opacity 0.2s ease-in-out;
    }

    .loader-leave-to {
        opacity: 0;
    }

    .loader-container {
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
        background: #fff;
        z-index: 10;
        position: absolute;
    }

    .sc-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 40px 50px;
        box-sizing: border-box;
        flex-direction: column;
        opacity: 0;
        animation: loaderFadeIn 0.3s ease-in;
        animation-fill-mode: forwards;
        position: relative;
        top: 30px;
    }

    @keyframes loaderFadeIn {
        100% {
            opacity: 1;
        }
    }

    .sc-loader .text {
        font-size: 1.2em;
        margin-bottom: 20px;
        color: rgba(0, 0, 0, .2);
    }

    .sc-loader .loader-wrap {
        animation: loaderRotate 1.6s linear infinite;
        width: 25px;
        height: 25px;
        position: relative;
        transform: rotate(45deg);
    }

    .sc-loader .loader-wrap .loader {
        animation: loaderGrow 1.6s linear infinite;
        height: 20px;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(78, 84, 122, 0.5);
        width: 20px;
        border-radius: 100px;
        margin-left: -10px;
        margin-top: -10px;
        animation-fill-mode: both;
    }

    .sc-loader .loader-wrap .loader.two {
        animation-delay: 1.2s;
        left: 100%;
        background: rgba(115, 109, 124, 0.5);
    }

    .sc-loader .loader-wrap .loader.three {
        animation-delay: 0.4s;
        top: 100%;
        background: rgba(43, 43, 51, 0.5);
    }

    .sc-loader .loader-wrap .loader.four {
        animation-delay: 0.8s;
        top: 100%;
        left: 100%;
        background: rgba(178, 173, 183, 0.5);
    }

    @keyframes loaderRotate {
        0% {
            transform: rotate(45deg);
        }
        100% {
            transform: rotate(405deg);
        }
    }

    @keyframes loaderGrow {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
</style>