<template>
    <div>
        <div class="width-container pd">

            <breadcrumbs :items="['Approvals']"></breadcrumbs>

            <div class="widget pd">
                <sc-tabs>
                    <sc-tab @click.native="goTo('/approvals/mine')"
                            :active="$route.meta.name === 'mine'">
                        <span>My Approval Requests</span>
                    </sc-tab>
                    <sc-tab @click.native="goTo('/approvals/open')"
                            v-if="$store.getters.UserHasPermission('approve-content')"
                            :active="$route.meta.name === 'open'">
                        <span>All Open Approval Requests</span>
                    </sc-tab>
                </sc-tabs>
                <router-view class="section"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      active: 'layout-type',
    }
  },
  methods: {
    goTo(page) {
      this.$router.replace(page);
    },
  }
}
</script>


<style scoped>

.main {
    display: flex;
    flex: 1;
}


.section {
    border: 1px solid #ccc;
}
</style>