<template>
    <div>
        <sc-modal>

            <div class="modal-head">
                <breadcrumbs :items="['manage content', this.playlist.name, 'add media']"></breadcrumbs>
                <div class="close">
                    <sc-button @click="$emit('close')" style="margin:0;">
                        <awesome-icon icon="times"/>
                    </sc-button>
                </div>
            </div>

            <playlist-items-preview v-if="tab === 'schedule' && selected.data.length > 0"
                                    :items="selected.data"
                                    @remove="remove"/>

            <sc-tabs>
                <sc-tab :active="tab === 'media'" @click.native="back()">
                    <div class="step">step 1</div>
                    Media
                </sc-tab>
                <sc-tab :active="tab === 'schedule'" @click.native="next()"
                        :class="{locked:selected.data && selected.data.length === 0}">
                    <div class="step">step 2</div>
                    Scheduling
                </sc-tab>
            </sc-tabs>

            <template v-if="tab === 'media'">
                <div class="panel">
                    <div class="cols">
                        <div class="media-items col">
                            <sc-table :table="table" @view="add" :padded="false">

                                <template #column-media="{row}">
                                    <img class="media-image" :src="row.thumbnail_storage_path"/>
                                </template>
                                <template #column-name="{row}">
                                    <sc-table-cell :table="table" field="name">
                                        <div class="detail text-blue">
                                            {{ row.name }}
                                        </div>
                                        <div class="detail" style="text-transform: capitalize">
                                            {{ row.approved }}
                                        </div>
                                        <expire-checker :item="row"/>
                                    </sc-table-cell>
                                </template>

                            </sc-table>
                        </div>
                        <div class="media-selected col">
                            <sc-table :table="selected" @view="remove" :padded="false">

                                <template #column-media="{row}">
                                    <img class="media-image" :src="row.thumbnail_storage_path"/>
                                </template>

                                <template #column-name="{row}">
                                    <sc-table-cell :table="table" field="name">
                                        <div class="detail text-blue">
                                            {{ row.name }}
                                        </div>
                                        <div class="detail" style="text-transform: capitalize">
                                            {{ row.approved }}
                                        </div>
                                        <expire-checker :item="row"/>
                                    </sc-table-cell>
                                </template>

                            </sc-table>
                        </div>
                    </div>
                </div>
                <div class="buttons media">
                    <sc-button @click="next()" style="text-align: right;margin:0;" color="secondary">Next</sc-button>
                </div>
            </template>

            <template v-if="tab === 'schedule'">
                <div class="panel pd">
                    <div class="pickers">
                        <sc-field-valid-date-range :TTL="TTL" :errors="errors"
                                                   @validFromChange="validFromChange"
                                                   @validToChange="validToChange"
                                                   :validFromDate.sync="validFromTimestamp"
                                                   :validToDate.sync="validToTimestamp" classes="no-border"/>
                    </div>

                    <div class="schedules-header">
                        Time schedules:
                    </div>

                    <div class="schedule-headers" v-if="schedules.length > 0">
                        <div class="schedule-header-time">
                            Time of day
                        </div>
                        <div class="divider"></div>
                        <div class="schedule-header-day">
                            Days
                        </div>
                    </div>

                    <div class="schedules">
                        <div class="schedule" v-for="(schedule,index) in schedules">
                            <div class="time">
                                <div class="time-start time-select">
                                    <input type="time" v-model="schedule['start-time']"/>
                                </div>
                                <div class="divider">-</div>
                                <div class="time-end time-select">
                                    <input type="time" v-model="schedule['end-time']"/>
                                </div>
                            </div>
                            <div class="divider"></div>

                            <sc-days :selected="schedule.days"
                                     :format="(v) => {return v.substring(0,3).toLowerCase()}"
                                     @select="selectDay(index, $event)"/>

                            <div class="remove">
                                <sc-button color="secondary" @click="removeSchedule(index)" style="margin:0" :disabled="saving">Remove
                                </sc-button>
                            </div>
                        </div>
                        <div class="schedule-button">
                            <sc-button color="secondary" @click="addSchedule" :disabled="saving">Add new</sc-button>
                        </div>
                    </div>


                </div>

                <div class="buttons schedule">
                    <sc-button @click="back()" color="secondary" :disabled="saving">Back</sc-button>
                    <sc-button @click="submit()" color="secondary" style="text-align:center" :disabled="saving" :running="saving">Submit</sc-button>
                    <div></div>
                </div>
            </template>


        </sc-modal>
    </div>
</template>

<script>
import ScFieldValidDateRange from "../../../../components/common/sc-field-valid-date-range";
import PlaylistItemsPreview from "./PlaylistItemsPreview";

export default {
  name: "PlaylistItemAdd",
  components: {PlaylistItemsPreview, ScFieldValidDateRange},
  props: {
    playlist: {}
  },

  data() {
    return {

      tab: 'media',

      table: this.$stable.createTable({
        url: 'media',
        name: 'available media (click to add)',
        sortBy: 'updated_at',
        sortDir: 'desc',
        fetchArguments: () => {
          return '&status=approved&orientation=' + this.playlist.frameset.orientation;
        },
        columns: {
          media: {
            sort: false
          },
          name: {
            fill: true
          }
        }
      }),

      selected: this.$stable.createTable({
        name: 'selected media (click to remove)',
        columns: {
          media: {sort: false},
          name: {
            fill: true
          }
        }
      }),

      validFromTimestamp: moment().format('YYYY-MM-DD'),
      validToTimestamp: '',
      errors: {},
      saving: false,
      form: this.$sform.createForm({
        url: 'playlist-items/add',
        fields: {
          'playlist-id': [parseInt(this.$route.params.playlist_id)],
          'media-items': [],
          'valid-range': {
            'use-valid-range': false,
            'start-valid-date': moment().format('YYYY-MM-DD'),
            'end-valid-date': null
          },
          'time-schedules': []
        },
        failure: (e) => {
          this.saving = false;
          // console.log(e.response.data);
          if (e.response) {
            if (e.response.status === 422) {
              // Time slot issue

              let errs = {};
              let notifications = {};

              for (let key in e.response.data.errors) {
                if (key.includes('media-items')) {
                  let item_name = this.selected.data[key.split('.')[1]].name;

                  ['startValidDate', 'endValidDate'].forEach((t) => {
                    if (errs.hasOwnProperty(t)) {
                      if (!errs[t].includes('Range invalid'))
                        errs[t] += '\n Range invalid.';
                    } else {
                      errs[t] = 'Range invalid.';
                    }
                  })

                  notifications[item_name] = e.response.data.errors[key];

                } else {

                  e.response.data.errors[key].forEach((err) => {
                    if (typeof err === 'object') {
                      let message = err.message.join('/\n');
                      notifications[err.field] = message;

                      if (err.field === 'Valid to') {
                        if (!errs.endValidDate) {
                          errs.endValidDate = message
                        } else {
                          errs.endValidDate += '\n' + message;
                        }

                      } else if (err.field === 'Valid from') {
                        if (!errs.startValidDate) {
                          errs.startValidDate = message
                        } else {
                          errs.startValidDate += '\n' + message;
                        }
                      } else {
                        errs[err.field] = message;

                      }
                    } else {
                      if (key.includes('time-schedules')) {
                        let i = key.split('.')[1];
                        if (!errs.hasOwnProperty('schedules'))  errs.schedules = {};
                        if (!errs.schedules.hasOwnProperty(i))  errs.schedules[i] = {days: undefined, time: undefined};

                        let notificationKey = `Time Schedules row ${parseInt(i) + 1}`;
                        if (!notifications.hasOwnProperty(notificationKey)) notifications[notificationKey] = [];

                        if (key.includes('days')) {
                          errs.schedules[i].days = err.replace(`time-schedules.${i}.days`, 'Days')
                          notifications[notificationKey].push(err.replace(`time-schedules.${i}.days`, 'Days'));
                        } else {
                          errs.schedules[i].time = err.replace(`The Time Schedule Time Range is incorrect. `, '')
                          notifications[notificationKey].push(err.replace(`The Time Schedule Time Range is incorrect. `, ''))
                        }
                      }
                    }
                  });
                }
              }

              this.errors = errs;
              this.$notify.error(notifications);

            } else {
              this.$notify.error(e.response);
            }
          } else {
            this.$notify.error("Something went wrong");
          }
        }
      }),

      schedules: [],

    }
  },

  computed: {
    TTL() {
      return typeof mfC !== 'undefined' ? mfC.ttl_playlistitem: process.env.TTL_PLAYLISTITEM;
    }
  },

  mounted() {
    this.table.fetch();

    this.selected.hasData = true;
    this.selected.data = [];
  },

  methods: {

    dateRangeValid() {
      this.errors = {};
      let emptyChecks = ['', null, undefined];

      if (emptyChecks.includes(this.form.fields['valid-range']['start-valid-date'])) {
        this.errors['startValidDate'] = 'Required field';
      }
      if (emptyChecks.includes(this.form.fields['valid-range']['end-valid-date'])) {
        this.errors['endValidDate'] = 'Required field';
      }

      return !emptyChecks.includes(this.form.fields['valid-range']['start-valid-date']) && !emptyChecks.includes(this.form.fields['valid-range']['end-valid-date']);
    },

    submit() {

      if (!this.dateRangeValid()) {
        return;
      }

      this.form.fields['media-items'] = [];
      for (let i = 0; i < this.selected.data.length; i++) {
        this.form.fields['media-items'].push(this.selected.data[i].id)
      }

      this.form.fields['time-schedules'] = this.schedules;
      this.saving = true;
      this.form.post()
        .then(() => {
          this.saving = false;
          this.$emit('close')
        })

    },

    addSchedule() {
      this.schedules.push({
        'start-time': '09:00',
        'end-time': '17:00',
        'days': ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']
      })
    },

    removeSchedule(index) {
      this.schedules.splice(index, 1);
    },

    selectDay(index, day) {
      let schedule = this.schedules[index];
      let dayIndex = schedule.days.indexOf(day);
      if (dayIndex === -1) {
        schedule.days.push(day)
      } else {
        schedule.days.splice(dayIndex, 1);
      }
    },

    startValidCheck(d) {
      return moment(d).isSameOrBefore(this.validFromStartDate)
    },

    validFromChange(value) {
      if (value) {
        this.form.fields['valid-range']['start-valid-date'] = moment(value).format('YYYY-MM-DD')
        this.form.fields['valid-range']['use-valid-range'] = true
      } else {
        this.form.fields['valid-range']['start-valid-date'] = ''
        if (!this.form.fields['valid-range']['end-valid-date']) {
          this.form.fields['valid-range']['use-valid-range'] = false
        }
      }
    },

    validToChange(value) {
      if (value) {
        this.form.fields['valid-range']['end-valid-date'] = moment(value).format('YYYY-MM-DD')
        this.form.fields['valid-range']['use-valid-range'] = true
      } else {
        this.form.fields['valid-range']['end-valid-date'] = ''
        if (!this.form.fields['valid-range']['start-valid-date']) {
          this.form.fields['valid-range']['use-valid-range'] = false
        }
      }
    },

    add(item) {
      let index = this.selected.data.findIndex(x => x.id === item.id);
      if (index === -1) {
        this.selected.data.push(item);
        this.selected.prefetch();
      }
    },

    remove(item) {
      let index = this.selected.data.findIndex(x => x.id === item.id);
      if (index !== -1) {
        this.selected.data.splice(index, 1);
        this.selected.prefetch();
      }
    },

    next() {
      this.tab = 'schedule';
    },

    back() {
      this.tab = 'media'
    }
  }


}
</script>

<style scoped lang="scss">
.cols {
    display: flex;
    padding: 1em 1.5em;
    box-sizing: border-box;

    .col {
        flex: 1;

        &:first-child {
            margin-right: 1.5em;
        }
    }
}

.panel {
    border: 1px solid #ccc;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin: 2.5em 0 1em;

    > div {
        flex: 1;
    }
}


.step {
    text-transform: uppercase;
    font-size: .7em;
    font-weight: 700;
    margin-bottom: 0.5em;
}

.locked {
    pointer-events: none;
}

.valid-custom {
    margin-top: 1em;
    margin-bottom: 1.5em;

    label {
        font-size: 0.9em;
        margin-left: 0.75em;
    }
}

.pickers {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
    margin-top: 1.5em;


    .picker-name {
        font-size: 0.9em;
    }

    .divider {
        width: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1em;
    }
}

.schedules-header {
    border-bottom: 1px solid #ccc;
    margin-top: 2.5em;
    padding-bottom: 0.5em;
    margin-bottom: 1.5em;
}

.schedules {


    .schedule {
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;

        .time {
            display: flex;
            width: 210px;
            align-items: center;

            .divider {
                text-align: center;
            }

            .time-select {
                font-size: 0.8em;

                &.time-start {
                    margin-right: 0.5em;
                }
            }
        }

        .divider {
            width: 5rem;
        }
    }

}

.schedule-headers {
    display: flex;
    font-size: 0.9em;
    margin-bottom: 0.5em;

    .schedule-header-time {
        width: 210px;
    }

    .divider {
        width: 5rem;
    }
}

.schedule-button {
    margin-top: 1.5em;
    margin-bottom: 2em;
}

.modal-head {
    display: flex;
    justify-content: space-between;
}

.clear {
    padding: 1em;
    text-transform: uppercase;
    font-size: 0.7em;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
    margin-top: 1em;
    margin-left: 3em;

    &:hover {
        background: #eee;
        cursor: pointer;
        color: #000;
    }
}

.media-image {
    max-width: 100%;
    height: 5em;
    object-fit: contain;
    margin: auto 0;
}

.detail {
    margin-bottom: .25em;
}

</style>