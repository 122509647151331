<template>
    <div class="tabs">
        <slot></slot>
    </div>
</template>

<script>
  export default {
    name: "sc-tabs"
  }
</script>

<style lang="scss">
    .tabs {
        display: flex;
        max-width: inherit;

        .tab {
            width: 160px;
        }
    }
</style>