<template>
    <div class="location-list-item" :class="{active, excluded}">
        <div class="location-main" @click="open = !open" :class="{noKids:location.children.length === 0}">
            <div class="toggle">
                <template v-if="location.children.length > 0">
                    <awesome-icon icon="chevron-right" v-if="!open"></awesome-icon>
                    <awesome-icon icon="chevron-down" v-else></awesome-icon>
                </template>
            </div>
            <div class="name">{{ location.name }}</div>
            <div class="select" @click.stop="select(location.id)">
                <span>select</span>
            </div>
        </div>

        <div class="children" v-if="open">
            <location-list-item class="child"
                                v-for="child in location.children"
                                :key="child.id"
                                :location="child"
                                :excluded_ids="excluded_ids"
                                :parents="parents"
                                :selected="selected"
                                @select="select">
            </location-list-item>
        </div>
    </div>
</template>

<script>
export default {
  name: "location-list-item",

  props: {
    location: {},
    selected: {},
    parents: {},
    excluded_ids: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      open: false,
    }
  },

  mounted() {
    if (this.parents.includes(this.location.id)) {
      this.open = true
    }
  },

  computed: {
    active() {
      return this.selected === this.location.id
    },
    excluded() {
      return this.excluded_ids.includes(this.location.id)
    },
  },

  methods: {
    select(id) {
      if (!this.excluded_ids.includes(id)) {
        this.$emit('select', id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.location-main {
    display: flex;
    align-items: center;
    min-height: 2em;

    &:hover {
        background: #eee;
        cursor: pointer;
    }

    .toggle {
        width: 2em;
        text-align: center;
        padding: 0.3em 0 0.3em 0.5em;
    }

    &.noKids {
        .toggle {
            pointer-events: none;
        }

    }

    .name {
        flex: 3;
        cursor: pointer;
        padding: 0.3em;
    }

    .select {
        width: 5em;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        cursor: pointer;
        padding: 0 1em;

        span {
            background: var(--color-secondary);
            color: #fff;
            padding: 0.5em;
            box-shadow: var(--shading);
            text-transform: capitalize;
            font-size: 0.8em;
            font-weight: 600;
        }

        &:hover {
            span {
                background: var(--color-secondary);
            }
        }
    }

    &:hover {
        .select {
            opacity: 1;
        }
    }
}

.child {
    padding-left: 2.5em;
}

.active {
    > .location-main {
        background: var(--color-secondary);
        color: #fff;
        font-weight: 600;

        .name {
            pointer-events: none;
        }

        .toggle:hover {
            background: inherit;
        }

        > .select {
            display: none;
        }
    }
}

.excluded > .location-main .select {
    display: none;
}
</style>