import Vue from 'vue';
import Talker from '@/plugins/talker';
import sForm from '@scala-services/scala-form'
import sTable from '@scala-services/scala-table'

const conf = {
  url: undefined,
  cs: undefined,
  cid: undefined,
  cookie: undefined
};

if (typeof mfC !== 'undefined') {
  const c = document.cookie.split(';').find((c) => c.indexOf('vssoc=') !== -1);
  if (c === undefined) {
    throw new Error("Core Cookie not present.");
  } else {
    const secVal = c.split('=')[1];
    conf.url = `https://${mfC.domain}`;
    conf.cs = secVal.split('cid')[0].trim();
    conf.cid = secVal.split('cid')[1].trim();
    conf.cookie = mfC.cookie;

    let title = document.title;
    document.title = `${mfC.name.charAt(0).toUpperCase() + mfC.name.slice(1)} - ${title}`;
  }
} else {
  conf.url = process.env.VUE_APP_URL;
  conf.cs = process.env.VUE_APP_CLIENT_SECRET;
  conf.cid = process.env.VUE_APP_CLIENT_ID;
  conf.cookie = process.env.VUE_APP_COOKIE_NAME;
}

Vue.use(Talker, {
  baseURL: conf.url,
  client_secret: conf.cs,
  client_id: conf.cid,
  cookie: conf.cookie
});

// Intercept 401 response, remove credentials and redirect to login.
Vue.prototype.$talker.api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem(conf.cookie);
    window.location = '/';
  }

  return Promise.reject(error);
})

// Form Class Wrapper. Requires Axios instance.
// Optional success & failure callbacks, which can be overwritten on each form instance
Vue.use(sForm, {
  axiosInstance: Vue.prototype.$talker.api,
  success: () => {
    Vue.prototype.$notify.success('Submit succeeded')
  },
  failure: error => {
    if (error.response && error.response.status === 422) {
      Vue.prototype.$notify.error('Missing required fields')
    } else if (error.response && error.response.status === 500 && error.response.data) {
      Vue.prototype.$notify.error(error.response.data)
    } else if (error.response && error.response.status === 403) {
      this.$router.replace('/');
      Vue.prototype.$notify.error('Access denied')
    } else {
      Vue.prototype.$notify.error('Failed to submit')
    }
  }
});

// Table Class Wrapper. Requires Axios instance.
// Optional success & failure callbacks, which can be overwritten on each table instance
Vue.use(sTable, {
  axiosInstance: Vue.prototype.$talker.api,
  success: () => {
    //
  },
  failure: error => {
    if (error.response && error.response.status === 403) {
      this.$router.replace('/');
      Vue.prototype.$notify.error('Not authorized');
    } else {
      Vue.prototype.$notify.error('Something went wrong');
    }
  }
});