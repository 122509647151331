import _index from './index.vue'
import _all from './all.vue'
import _edit_image from './edit-image.vue'
import _edit_video from './edit-video.vue'

const routes = {
  path: 'media',
  meta: {name: 'media'},
  component: _index,
  children: [
    {
      path: '',
      meta: {name: 'all'},
      component: _all,
    },
    {
      path: 'edit/image/:id',
      meta: {name: 'edit image'},
      component: _edit_image
    },
    {
      path: 'edit/video/:id',
      meta: {name: 'edit video'},
      component: _edit_video
    }
  ]
};

export default routes;
