<template>
    <div class="width-container pd">
        <breadcrumbs :items="[{name:'players',path:'/players'},  'edit']"></breadcrumbs>

        <div class="player-container">

            <sc-form @submit="update">
                <sc-loading :form="form"></sc-loading>

                <div class="content-container">
                    <section>
                        <sc-field :form="form" field="name">
                            <input v-model="form.fields.name" ref="initialInput" required/>
                        </sc-field>

                        <sc-field :form="form" field="polling-interval" description="In minutes">
                            <input v-model="form.fields['polling-interval']" ref="initialInput" type="number" step="1"
                                   min="1"
                                   required/>
                        </sc-field>
                    </section>

                    <section>
                        <div class="schedules">
                            <sc-field :form="form" field='time-slots' name="time-slot">
                                <div class="schedule" v-for="(schedule,index) in form.fields['time-slots']">

                                    <div class="sc-field-error" v-if="errors">
                                        <div v-for="error in errors">
                                            <template v-if="Array.isArray(error)">
                                                <div v-for="e in error">{{e}}</div>
                                            </template>
                                            <template v-else>{{error}}</template>
                                        </div>
                                    </div>

                                    <div class="time">
                                        <div class="checkbox" @click="toggleAllDay()">
                                            <div class="checkbox-label">
                                                All Day
                                            </div>
                                            <sc-table-checkbox :status="allDay"
                                                               color="light"></sc-table-checkbox>
                                        </div>
                                        <div v-if="!allDay" class="time-range">
                                            <div class="time-start time-select">
                                                <input type="time" v-model="schedule['start-time']"/>
                                            </div>
                                            <div class="divider">-</div>
                                            <div class="time-end time-select">
                                                <input type="time" v-model="schedule['end-time']"/>
                                            </div>
                                        </div>
                                    </div>

                                    <sc-days :selected="schedule.days"
                                             :format="(v) => {return v.substring(0,3).toLowerCase()}"
                                             @select="selectDay(index, $event)"/>

                                </div>
                            </sc-field>
                        </div>
                    </section>
                </div>

                <template #buttons>
                    <sc-button type="submit" color="secondary" :disabled="!ready || form.processing"
                               :running="form.processing" v-if="$store.getters.UserHasPermission('update-player')"
                               style="margin-right:0;">
                        <i class="fas fa-save"></i>Update Player
                    </sc-button>
                </template>
            </sc-form>
        </div>
    </div>
</template>

<script>

export default {
  name: "edit",
  data() {
    return {
      running: false,
      ready: false,

      framesetTemplates: [],
      licenses: [],
      schedules: [],
      allDay: 'active',
      errors: {},
      form: this.$sform.createForm({
        url: '/admin/players/' + this.$route.params.id,
        fields: {
          'name': '',
          'polling-interval': '',
          'time-slots': []
        },
        success: () => {
          this.$notify.success('Player updated');
        },
        failure: (e) => {
          if (e.response) {
            if (e.response.status === 422) {
              // Time slot issue
              let errs = [];
              for (let key in e.response.data.errors) {
                e.response.data.errors[key].forEach((err) => errs.push(err.replace('time-slots.0.days', 'Time slot days')));
              }

              this.$notify.error({'Time slot': errs});
              this.errors = errs;
            } else {
              this.$notify.error(e.response);
            }
          } else {
            this.$notify.error("Something went wrong");
          }
        }
      }),
    }
  },

  mounted() {
    this.form.get()
      .then((res) => {
        this.form.fields['polling-interval'] = res.polling_interval;

        res.channels[0].time_slots.forEach((slot) => {
          let startTime = slot.startTime.slice(0, -3);
          let endTime = slot.endTime.slice(0, -3)

          this.form.fields['time-slots'].push({
            'start-time': startTime,
            'end-time': endTime,
            'days': slot.weekdays
          })

          if (startTime !== '00:00' || endTime !== '24:00') {
            this.allDay = false;
          }
        })
      })
      .then(() => {
        this.ready = true;
      })

  },
  methods: {
    selectDay(index, day) {
      let schedule = this.form.fields['time-slots'][index];
      let dayIndex = schedule.days.indexOf(day);
      if (dayIndex === -1) {
        schedule.days.push(day)
      } else {
        schedule.days.splice(dayIndex, 1);
      }
    },

    toggleAllDay() {
      if (this.allDay === 'active') {
        this.allDay = false;
        this.form.fields['time-slots'][0]['end-time'] = '23:59';
      } else if (this.allDay === false) {
        this.allDay = 'active'
      }
    },

    update() {
      let canUpdate = true;

      if (this.allDay === 'active') {
        this.form.fields['time-slots'][0]['start-time'] = '00:00';
        this.form.fields['time-slots'][0]['end-time'] = '24:00';
      }

      if (canUpdate) {
        this.errors = {}
        this.form.put()
      }
    },
  }
}
</script>

<style scoped lang="scss">
.player-container {
    background: #fff;
    padding: 24px;
    width: inherit;
}

.content-container {
    display: flex;

    section {
        flex: 1;
        width: 50%;

        .schedules {
            width: 100%;


            .schedule {
                align-items: center;

                .time {
                    display: flex;
                    width: 100%;
                    height: 50px;
                    justify-content: space-between;
                    align-items: center;

                    .time-range {
                        display: flex;
                        align-items: center;
                        margin-right: .2em;
                    }

                    .checkbox {
                        display: flex;
                        cursor: pointer;
                        align-items: center;
                        margin-right: 2.25em;

                        .checkbox-label {
                            width: 128px;
                        }
                    }

                    .divider {
                        text-align: center;
                    }

                    margin-bottom: .5em;
                }

                .divider {
                    width: 3rem;
                }
            }
        }
    }
}

select[data-placeholder='true'] {
    color: var(--color-tertiary) !important;;
}

.button-row {
    display: flex;
    justify-content: flex-end;
    height: 36px;
    box-sizing: border-box;
}

</style>