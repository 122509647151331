<template>
    <div>
        <div class="cols">

            <div class="col approval">

                <div class="item status" v-if="item">
                    <div class="label">current status:</div>
                    <div class="line">
                        {{ item.status }}
                    </div>
                </div>

                <div class="btns" v-if="mediaItem">
                    <sc-button color="secondary" @click="edit(mediaItem)">Edit item</sc-button>
                </div>

                <div class="history" v-if="item">
                    <div class="label">history</div>
                    <div class="history-line" v-for="history in history">
                        <div class="action">{{ history.action }}</div>
                        <div class="message">{{ history.message }}</div>
                        <div class="created_at">
                            <div class="user">{{ history.name }}</div>
                            <div class="time">{{ history.responded_at | toDateHuman }}</div>
                        </div>
                    </div>
                </div>


            </div>

            <div class="col media-item-col">
                <div class="media-item-header">New Media Item</div>
                <div class="media-item" v-if="mediaItem">

                    <div class="line">
                        <div class="label">Name</div>
                        <div class="value">{{ mediaItem.name }}</div>
                    </div>

                    <div class="line">
                        <div class="label">Type</div>
                        <div class="value">
                            <media-types :type="mediaItem.media_type" style="font-size:0.8em;"></media-types>
                        </div>
                    </div>

                    <div class="line">
                        <div class="label">Valid from</div>
                        <div class="value">{{ mediaItem.start_valid_date }}</div>
                    </div>

                    <div class="line">
                        <div class="label">Valid to</div>
                        <div class="value">{{ mediaItem.end_valid_date }}</div>
                    </div>

                    <div class="label label-fields" v-if="mediaItem.message">fields</div>
                    <div class="fields" v-if="mediaItem.message">
                        <div class="line" v-for="field in mediaItem.message.message_fields">
                            <div class="line-label">{{ field.name }}:</div>
                            <div class="value" v-if="field.value">{{ field.value }}</div>
                            <div class="value" v-else> ---</div>
                        </div>
                    </div>

                    <div class="line">
                        <template v-if="media_type === 'image'">
                            <img class="image-preview"
                                 :src="mediaItem.image_storage_path"/>
                        </template>
                        <template v-else-if="media_type === 'video'">
                            <video class="video-preview" controls autoplay muted loop>
                                <source :src="mediaItem.image_storage_path" type="video/webm"/>
                            </video>
                        </template>
                        <template v-else-if="media_type === 'message'">
                            <img class="image-preview"
                                 :src="mediaItem.image_storage_path"/>
                        </template>
                        <template v-else>
                            Unable to preview Scala Script at this moment.
                        </template>
                    </div>

                </div>

            </div>


        </div>
    </div>
</template>

<script>
import MediaTypes from "../../../../components/common/media-types";

export default {
  components: {MediaTypes},
  data() {
    return {
      item: undefined,
      history: [],
      message: '',
      mediaItem: undefined,
      saving: false,
    }
  },

  computed: {
    media_type() {
      const types = {
        1: "image",
        2: "message",
        3: "scala script",
        4: "video"
      };
      return types[this.mediaItem.media_type]
    }
  },


  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.$talker.api.get(`approvals/${this.$route.params.approval_id}`)
        .then(res => {
          this.item = res.data;
          let history = this.item.history;
          history.reverse();
          this.history = history;
          this.fetchMedia();
        })
    },

    fetchMedia() {
      this.$talker.api.get(`media/${this.item.subject_id}`)
        .then(res => {
          this.mediaItem = res.data;
        })
    },

    edit(item) {
      let route = '';
      let type = '';

      switch (item.media_type) {
        case 1:
          type = 'image';
          route = '/media/edit/image/' + item.id
          break;
        case 2:
          type = 'message';
          route = '/messages/' + item.id + '/edit'
          break;
        case 3:
          type = 'script';
          break;
        case 4:
          type = 'video';
          route = '/media/edit/video/' + item.id
          break;
        default:
          return null
      }

      if (route.length > 0) {
        this.$router.push(route).catch(() => {
          this.$notify.error(`Something went wrong trying to edit: ${type}`);
        });
      } else {
        this.$notify.error(`No route to edit: ${type}`);
      }
    }

  }
}
</script>

<style scoped>
.item {
    margin-bottom: 20px;
}

.status .line {
    font-size: 1.5em;
    font-weight: 500;
}

.media-item {
    margin-bottom: 20px;
}

.btns {
    margin-top: 20px;
    display: flex;
}

.cols {
    display: flex;
    margin: 3em 0 2em;
}

.col {
    padding: 0 2em;
}

.col.approval {
    flex: 3;
}

.col.media-item-col {
    flex: 2;
}

.history {
    margin: 40px 0 30px;
}

.history-line {
    display: flex;
    background: #fafafa;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    font-size: 0.9em;
}

.history-line:last-child {
    border: none;
}

.history-line .action {
    flex: 1;
    font-weight: 700;
    padding-right: 0.5em;
}

.history-line .message {
    flex: 2;
}

.history-line .created_at {
    flex: 1;
    text-align: right;
}

.history-line .created_at .time {
    font-size: 0.8em;
    font-weight: 700;
    margin-top: 3px;
}

.approval .label {
    margin-bottom: 5px;
}

.media-item {
    background: #fafafa;
    border: 1px solid #ccc;
    padding: 15px 20px;
}

.media-item-header {
    text-transform: uppercase;
    margin-bottom: 10px;
}

.media-item .line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.media-item .line .label {
    width: 150px;
    font-size: 0.8em;
}

.media-item .line .line-label {
    width: 150px;
}

.media-item .label-fields {
    margin-bottom: 10px;
    font-size: 0.8em;
}

.media-item .fields {
    margin-left: 20px;
    margin-bottom: 20px;
    font-size: 0.9em;
}

.media-item .fields .label {
    font-size: 0.7em;
}

.image-preview, .video-preview {
    max-width: 100%;
}
</style>
