<template>
    <div class="page" :class="envClass">
        <transition v-if="animate" name="slide-transition"
                    :enter-class="'slide-transition-enter ' + transitionName"
                    :leave-to-class="'slide-transition-leave-to ' + transitionName">
            <router-view class="page-container"></router-view>
        </transition>
        <router-view v-else class="page-container"></router-view>
    </div>
</template>

<script>
  export default {
    name: "children-route",

    props: {
      animate: {
        type: Boolean,
        default: false,
      },
      hasFooter: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'slide'
      },
    },

    data() {
      return {
        transitionName: 'slide-left',
      }
    },
    computed: {
      envClass() {
        return 'env_'+process.env.VUE_APP_TARGET
      }
    }

    // watch: {
    //   '$route'(to, from) {
    //     let toDepth = 0;
    //     if (to.meta && to.meta.depth) {
    //       toDepth = to.meta.depth;
    //     }
    //     let fromDepth = 0;
    //     if (from.meta && from.meta.depth) {
    //       fromDepth = from.meta.depth;
    //     }
    //     this.transitionName = toDepth < fromDepth ? 'right' : 'left'
    //   }
    // },
  }
</script>

<style scoped>

    .page  {
        flex: 1 0 auto;
        display: flex;
        flex-flow: column;
        position: relative;
    }

    .page-container {
        position: relative;
            }

    .page.env_app .page-container {
        height: 100%;
        flex: 1 0 auto;
        display: flex;
        flex-flow: column;
        position: relative;
    }

    .page .slide-transition-enter-active,
    .page .slide-transition-leave-active {
        transition-property: opacity, transform;
        transition-duration: var(--transition-duration);
        transition-timing-function: ease-out;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    /*.page .slide-transition-leave-active:before {*/
    /*content: '';*/
    /*position: fixed;*/
    /*top: 0;*/
    /*left: 0;*/
    /*bottom: 0;*/
    /*right: 0;*/
    /*z-index: 2;*/
    /*!*animation: backdropFade var(--transition-duration) ease-out;*!*/
    /*}*/

    @keyframes backdropFade {
        0% {
            background: rgba(0, 0, 0, .0);
        }
        100% {
            background: rgba(255, 255, 255, 1);
        }
    }

    .page .slide-transition-enter-active {
        z-index: 10;
    }

    .page .slide-transition-enter {
        opacity: 0;
    }

    .page .slide-transition-enter.right {

    }

    .page .slide-transition-enter-to {
        opacity: 1;
    }

    .page .slide-transition-leave-active {
        z-index: 9;
    }

    .page .slide-transition-leave-to {
        opacity: 0;
    }

    .page .slide-transition-leave-to.right {

    }

</style>