var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pd"},[_c('side-panel',[_c('sc-search',{attrs:{"query":_vm.query},on:{"searched":_vm.searched}})],1),_c('breadcrumbs',{attrs:{"items":['players']}}),_c('div',{staticClass:"table-buttons pd pdb0"},[(_vm.$store.getters.UserHasPermission('create-player'))?_c('sc-button',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$router.push('/players/new')}}},[_c('awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" New Player ")],1):_vm._e()],1),_c('sc-table',{attrs:{"table":_vm.table},scopedSlots:_vm._u([{key:"column-featureLicenseType",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"featureLicenseType"}},[_vm._v(" "+_vm._s(row.featureLicenseType.type)+" ")])]}},{key:"column-status",fn:function(ref){
var row = ref.row;
return [(row.state)?_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"status"},scopedSlots:_vm._u([{key:"sub",fn:function(){return [_c('span',{staticStyle:{"text-transform":"lowercase"}},[_vm._v(_vm._s(row.state.planState))])]},proxy:true}],null,true)},[_c('span',{staticStyle:{"text-transform":"lowercase"}},[_vm._v(_vm._s(row.state.state))])]):_vm._e()]}},{key:"column-playback",fn:function(ref){
var row = ref.row;
return [(row.state)?_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"playback"}},[_c('span',{staticStyle:{"text-transform":"lowercase"}},[_vm._v(_vm._s(row.state.active))])]):_vm._e()]}},{key:"column-actions",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"actions"}},[(_vm.$store.getters.UserHasPermission('update-player'))?_c('sc-table-button',{attrs:{"icon":"edit","tooltip":"edit"},nativeOn:{"click":function($event){return _vm.editPlayer(row)}}}):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }