const MixinPagination = {

  data() {
    return {
      page: 1,
      query: '',
      queryField: 'where_name'
    }
  },

  mounted() {
    if (!this.preventUrlDecoding) {
      if (this.$route.query && this.$route.query.search) {
        this.query = this.$route.query.search;
      }
      if (this.$route.query && this.$route.query.page) {
        this.page = this.$route.query.page;
      }
    } else {
      this.$router.replace({
        query: {}
      }).catch(() => {
      });
    }
  },

  computed: {
    sorting: {
      get() {
        return this.$store.state.sorting[this.sortingType]
      },
      set(value) {
        this.$store.commit('setSorting', {type: this.sortingType, sorting: value});
        this.page = 1;
        this.fetch();
      }
    },
  },

  methods: {
    changePage(page) {
      if (page === 'first') {
        this.page = 1;
      } else if (page === 'previous') {
        this.page--;
      } else if (page === 'next') {
        this.page++;
      } else {
        this.page = page
      }
      this.$router.replace({
        query:
          {
            page: this.page,
            search: this.query
          }
      });
      this.fetch();
    },

    searched(text) {
      this.query = text;
      this.page = 1;
      this.$router.replace({
        query:
          {
            page: this.page,
            search: this.query
          }
      });
      this.fetch();
    },

    clearSearch() {
      this.query = '';
      this.page = 1;
      this.$router.replace({
        query:
          {}
      }).then(() => {
        this.fetch();
      }).catch(() => {
      })

    },

    paginatedURL(url) {

      let sorting;
      if (!this.sorting.current.value) {
        sorting = this.sorting.default;
      } else {
        sorting = this.sorting.current;
      }

      if (this.query) {
        return `${url}?page=${this.page}&sortby=${sorting.value}&sortdir=${sorting.dir}&${this.queryField}=${this.query}`;
      } else {
        return `${url}?page=${this.page}&sortby=${sorting.value}&sortdir=${sorting.dir}`;
      }

    }
  }

};

export default MixinPagination;