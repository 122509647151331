<template>
    <div class="panel pd">
        <sc-form :form="{}" class="color-form" @submit="submit">
            <div class="colors">
                <div class="color" v-for="(p,i) in pickers" :style="{color: `var(--color-${p.type})`}"
                     @click="selectedIndex = pickers.findIndex((pick) => pick.type === p.type)"
                     :class="{selected: selectedIndex === i}">
                    <div class="color-preview">
                        <div class="preview-box">
                            <div class="shadow"></div>
                            <div class="face left"
                                 :style="{backgroundColor: `var(--color-${p.type})`, filter: `brightness(${dark})`}"></div>
                            <div class="face front" :style="{backgroundColor: `var(--color-${p.type})`}"
                                 :ref="p.type"></div>
                            <div class="face top"
                                 :style="{backgroundColor: `var(--color-${p.type})`, filter: `brightness(${bright})`}"></div>
                        </div>
                    </div>
                    <div class="color-info">
                        <div class="title">{{ p.label ? p.label : p.type }}</div>
                        <p class="line">{{ p.value.hex }}</p>
                        <p class="line">{{ getRGB(p.value.rgb) }}</p>
                        <p class="line">
                            <awesome-icon class="edit" icon="edit"/>
                        </p>
                    </div>
                </div>
            </div>

            <sc-form-field field="hover style">
                <select v-model="hoverStyle" @change="updateBrightness">
                    <option value="light">light</option>
                    <option value="dark">dark</option>
                </select>
            </sc-form-field>

            <sc-form-field :field="`Hover strength ${brightness}`">
                <input v-model="brightness" min="0" max="1" type="range" step="0.1" @input="updateBrightness">
            </sc-form-field>

            <div class="picker-wrap" v-if="selectedColor">
                <div style="margin-bottom:.5em;">Selected: <span
                        style="font-weight:600;text-transform:capitalize">
                    {{ selectedColor.label || selectedColor.type }}
                    </span>
                </div>

                <picker v-if="selectedColor" :value="selectedColor.value"
                        class="picker"
                        @input="updateColor($event)"/>

            </div>

        </sc-form>

        <affected-elements/>
    </div>
</template>

<script>
import axios from 'axios'
import {Chrome} from 'vue-color'
import AffectedElements from "./AffectedElements"

const root = document.documentElement;

export default {
  name: "styling-color",
  components: {
    AffectedElements,
    'picker': Chrome
  },

  data() {
    return {
      ready: false,
      selectedIndex: undefined,

      hoverStyle: 'light',
      brightness: 0.2,

      pickers: [
        {
          type: 'primary',
          value: '',
          initialColor: undefined
        },
        {
          type: 'secondary',
          value: '',
          initialColor: undefined
        },
        {
          type: 'tertiary',
          value: '',
          initialColor: undefined
        },
        {
          type: 'background',
          value: '',
          initialColor: undefined
        },
        {
          type: 'button',
          label: 'Button Text',
          value: '',
          initialColor: undefined
        },
      ]
    }
  },
  computed: {
    bright() {
      return 1 + parseFloat(this.brightness);
    },
    dark() {
      return 1 - parseFloat(this.brightness);
    },

    selectedColor() {
      if (typeof this.selectedIndex !== "undefined") {
        return this.pickers[this.selectedIndex];
      }
    },
  },

  beforeDestroy() {
    if (this.changed) {
      let r = confirm("Continue without saving?")
      if (r !== null) {
        this.reset()
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      let stored = JSON.parse(localStorage.getItem('tenant-colors'));

      this.pickers.forEach((p) => {
        let color;

        if (!stored) {
          // Fallback to stylesheet defined colors
          let el = this.$refs[p.type];
          let rgb = getComputedStyle(el[0]).backgroundColor;
          let split = rgb.match(/(\d+)/g);
          color = {
            rgb: {r: split[0], g: split[1], b: split[2]},
            hex: this.RGBToHex(rgb)
          };
        } else {
          color = stored.find((s) => s.type === p.type).value;
          this.hoverStyle = stored.find((s) => s.type === 'style').value
          this.brightness = stored.find((s) => s.type === 'brightness').value;
        }

        p.value = color;
        p.initialColor = color; // Store current values for 'reset';

      })
    })
  },

  methods: {
    getRGB(value) {
      if (value) {
        return `rgb(${value.r}, ${value.g}, ${value.b})`;
      }
    },

    RGBToHex(rgb) {
      // Choose correct separator
      let sep = rgb.indexOf(",") > -1 ? "," : " ";
      // Turn "rgb(r,g,b)" into [r,g,b]
      rgb = rgb.substr(4).split(")")[0].split(sep);

      let r = (+rgb[0]).toString(16),
        g = (+rgb[1]).toString(16),
        b = (+rgb[2]).toString(16);

      if (r.length === 1)
        r = "0" + r;
      if (g.length === 1)
        g = "0" + g;
      if (b.length === 1)
        b = "0" + b;

      return "#" + r + g + b;
    },

    updateColor(e) {
      this.selectedColor.value = e;
      root.style.setProperty(`--color-${this.selectedColor.type}`, e.hex);
      this.changed = true;
    },

    updateBrightness(e) {
      let value = 1;
      if (this.hoverStyle === 'dark') {
        value -= parseFloat(this.brightness);
      } else if (this.hoverStyle === 'light') {
        value += parseFloat(this.brightness);
      }
      root.style.setProperty(`--color-brightness`, String(value));
      this.changed = true;
    },

    submit() {
      this.running = true;
      // Gather
      let colors = this.pickers.map((p) => {
        return {
          type: p.type,
          value: {hex: p.value.hex}
        }
      })

      colors.push({
        type: 'style',
        value: this.hoverStyle
      })

      colors.push({
        type: 'brightness',
        value: this.brightness
      })

      let id;
      if (typeof mfC === 'undefined') {
        id = process.env.VUE_APP_TENANT_ID;
      } else {
        id = mfC.id;
      }
      // TODO: Swap out with talker version when operational
      axios.put('https://spoof.test/api/admin/tenant/' + id + '/ui-config', {"tenant-colors": colors})
        .then((res) => {
          this.$notify.success('Colors updated');
          // Write to localstorage
          // Need to write stringified to local storage
          localStorage.setItem('tenant-colors', JSON.stringify(colors));
        })
        .catch((err) => {
          this.$notify.error(err.response.data.message)
        })

      this.running = false;
      this.changed = false;
    },

    reset() {
      // Reset colors & pickers
      for (let i = 0; i < this.pickers.length; i++) {
        this.selectedIndex = i;
        this.updateColor(this.pickers[i].initialColor)
      }
      this.brightness = 0.2;
      this.hoverStyle = 'light'
      this.updateBrightness()
    },
  }
}
</script>

<style scoped lang="scss">
.panel {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1em;
}

.color-form {
    display: flex;

    ::v-deep form {
        width: 100%;
    }

    .colors {
        flex: 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .color {
            position: relative;
            display: flex;
            margin-bottom: 1em;
            cursor: pointer;

            .color-preview {
                left: 0;
                top: 0;
                transform-style: preserve-3d;
                position: relative;

                .preview-box {
                    width: 100px;
                    height: 100px;
                    position: relative;
                    transform-style: preserve-3d;
                    transform: rotateX(350deg) rotateY(20deg);
                    transition: transform var(--easing) var(--transition-duration);
                    will-change: transform;

                    .face {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 60px;
                        height: 60px;
                        transition: filter var(--transition-duration) var(--easing);
                        will-change: filter;
                        filter: drop-shadow(0px 0px 3px currentColor);
                        -webkit-backface-visibility: hidden;

                        &.top {
                            transform-origin: top;
                            transform: translate3d(-50%, -50%, -30px) rotateX(90deg);
                            z-index: 0;
                            filter: brightness(var(--color-brightness));
                        }

                        &.front {
                            transform: translate3d(-50%, -50%, 30px);
                            z-index: 10;
                        }

                        &.left {
                            transform-origin: left;
                            transform: translate3d(-50%, -50%, -30px) rotateY(-90deg);
                            z-index: 10;
                        }
                    }

                    .shadow {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate3d(-50%, -10%, -10px) rotateX(90deg);
                        background: currentColor;
                        opacity: .175;
                        width: 80px;
                        height: 80px;
                        filter: blur(10px);
                        transition: all var(--easing) var(--transition-duration);
                    }
                }

            }

            .color-info {
                text-align: left;
                //border-left: 1px solid blue;
                position: relative;
                margin: 1.5em 1em;
                padding-left: 1em;
                //color: currentColor;

                .title, p {
                    text-align: inherit;
                    color: black;
                }

                .title {
                    margin-bottom: .5em;
                    text-transform: capitalize;
                }

                .line {
                    text-transform: uppercase;
                    font-size: .9em;
                    margin-bottom: .25em;
                }

                .edit {
                    opacity: .25;
                }

                &::after {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 1px;
                    height: 60px;
                    top: 50%;
                    transform: translateY(-50%);
                    background: rgba(0, 0, 0, 0.25);
                    filter: brightness(0.8);
                }

                &::before {
                    display: block;
                    content: '';
                    position: absolute;
                    width: 2em;
                    left: 0;
                    top: 50%;
                    transform: translateX(-100%);
                    height: 1px;
                    background: #2b2b2b;
                    opacity: .5;
                    background: rgba(0, 0, 0, 0.25);
                    filter: brightness(0.8);
                }
            }

            &:hover {
                .preview-box {
                    transform: rotateX(350deg) rotateY(20deg) scale3D(1.1, 1.1, 1.1);

                    .face {
                        filter: drop-shadow(0 0 10px currentColor);
                    }

                    .shadow {
                        transform: translate3d(-50%, -10%, -15px) rotateX(90deg);
                        opacity: .15;
                        filter: blur(11px);
                    }
                }

                .color-info {
                    .edit {
                        opacity: 1;
                    }
                }
            }

            &.selected {
                .color-info {
                    .edit {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .picker-wrap {
        flex: 1;

        display: flex;
        flex-flow: column;

        .picker {
            margin-right: 2em;
        }

        .tints {
        }
    }
}

</style>